import {
  ActionButton,
  DetailsCell,
  ErrorWidget,
  Flex,
  HStack,
  Icon,
  IconName,
  Token,
  VStack,
  Widget,
  Text,
} from '@revolut/ui-kit'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { CellWithItem } from '@src/pages/Forms/GoalForm/common/CellWithItem'
import React, { PropsWithChildren } from 'react'

type BaseWidgetProps = {
  icon: IconName
  title: string
  description?: string
  preferSideAction?: boolean
  emptyState?: {
    description: string
    icon?: IconName
  }
  action?: { handler: () => void; label: string; icon?: IconName; disabled?: boolean }
  variant?: 'preview' | 'form'
}

export const BaseWidget = (props: PropsWithChildren<BaseWidgetProps>) => {
  const {
    icon,
    title,
    description,
    preferSideAction = false,
    children,
    emptyState,
    action,
    variant = 'form',
  } = props

  const isNewTable = useIsNewTable()

  return variant === 'form' ? (
    <CellWithItem
      icon={icon}
      title={title}
      description={description}
      side={
        preferSideAction && !!action ? (
          <ActionButton
            onClick={action?.handler}
            disabled={action.disabled}
            useIcon={action.icon || '16/Plus'}
          >
            {action.label}
          </ActionButton>
        ) : null
      }
    >
      {children ? (
        <VStack space="s-16">
          {!!action && !preferSideAction && (
            <ActionButton
              onClick={action?.handler}
              disabled={action.disabled}
              useIcon={action.icon || '16/Plus'}
            >
              {action.label}
            </ActionButton>
          )}
          {children}
        </VStack>
      ) : emptyState ? (
        <Widget>
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            p="s-24"
          >
            <Icon name={emptyState?.icon} color={Token.color.greyTone20} />
            <ErrorWidget.Description color={Token.color.greyTone20}>
              {emptyState?.description}
            </ErrorWidget.Description>
            {!preferSideAction && !!action && (
              <ErrorWidget.Action onClick={action.handler} disabled={action.disabled}>
                {action.label}
              </ErrorWidget.Action>
            )}
          </Flex>
        </Widget>
      ) : null}
    </CellWithItem>
  ) : (
    <DetailsCell {...(isNewTable && { px: 0, pb: 0 })}>
      <DetailsCell.Title {...(isNewTable && { px: 's-16' })}>
        <HStack space="s-8">
          <Icon name="Map" color={Token.color.greyTone20} />
          <Text variant="h6" color={Token.color.greyTone50}>
            Roadmaps
          </Text>
        </HStack>
      </DetailsCell.Title>
      <DetailsCell.Note>{children}</DetailsCell.Note>
    </DetailsCell>
  )
}
