import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { DynamicGroupInerface } from '@src/interfaces/dynamicGroups'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '../routes'
import pluralize from 'pluralize'

export const dynamicGroupsGroupNameColumn: ColumnInterface<DynamicGroupInerface> = {
  title: 'Group Name',
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.dynamic_groups,
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL, { id: data.id }),
}

export const dynamicGroupsTypeColumn: ColumnInterface<DynamicGroupInerface> = {
  title: 'Type',
  type: CellTypes.text,
  idPoint: 'type',
  dataPoint: 'type.name',
  sortKey: 'type',
  filterKey: 'type',
  selectorsKey: selectorKeys.dynamic_group_types,
}

export const dynamicGroupsDescriptionColumn: ColumnInterface<DynamicGroupInerface> = {
  title: 'Description',
  type: CellTypes.text,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: 'description',
  filterKey: null,
  selectorsKey: selectorKeys.none,
}

export const dynamicGroupsCreatedDateColumn: ColumnInterface<DynamicGroupInerface> = {
  title: 'Created Date',
  type: CellTypes.date,
  idPoint: 'created_date_time',
  dataPoint: 'created_date_time',
  sortKey: 'created_date_time',
  filterKey: 'created_date_time',
  selectorsKey: selectorKeys.none,
}

export const dynamicGroupsOwnerColumn: ColumnInterface<DynamicGroupInerface> = {
  title: 'Owner',
  type: CellTypes.text,
  idPoint: 'owner.id',
  dataPoint: 'owner.full_name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
}

export const dynamicGroupsStatusColumn: ColumnInterface<DynamicGroupInerface> = {
  title: 'Status',
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status.id',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.dynamic_group_statuses,
}

export const dynamicGroupsMemberCountColumn: ColumnInterface<DynamicGroupInerface> = {
  title: 'Employees',
  type: CellTypes.insert,
  idPoint: 'member_count',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => pluralize('employee', data.member_count, true),
}
