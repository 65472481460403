import React from 'react'
import { Text, Token, HStack } from '@revolut/ui-kit'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { ContractStatuses, EmployeeContractsInterface } from '@src/interfaces/employees'
import { formatMoney, formatWithoutTimezone } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import Tooltip from '@components/Tooltip/Tooltip'
import { Document, ExclamationMark } from '@revolut/icons'
import { ContractTypeInterface } from '@src/interfaces/contracts'

export const getContractStatusColor = (status: ContractStatuses) => {
  switch (status) {
    case ContractStatuses.Active:
      return Token.color.green
    case ContractStatuses.Pending:
    case ContractStatuses.PendingCompletion:
    case ContractStatuses.PendingApproval:
      return Token.color.warning
    case ContractStatuses.Inactive:
    case ContractStatuses.Expired:
      return Token.color.greyTone50
    case ContractStatuses.Rejected:
    case ContractStatuses.Cancelled:
    case ContractStatuses.Terminated:
      return Token.color.red
    default:
      return Token.color.foreground
  }
}

export const contractStatusColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.text,
  idPoint: 'contract_status',
  dataPoint: 'contract_status.name',
  sortKey: 'contract_status',
  filterKey: 'contract_status',
  selectorsKey: selectorKeys.employee_contract_status,
  title: 'Status',
  colors: data => getContractStatusColor(data.contract_status.id),
}

export const contractEntityColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.text,
  idPoint: 'company_entity',
  dataPoint: 'company_entity.name',
  sortKey: 'company_entity__name',
  filterKey: 'company_entity__id',
  selectorsKey: selectorKeys.entity,
  title: 'Entity',
}

export const contractTypeColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.insert,
  idPoint: 'is_primary_contract',
  dataPoint: 'is_primary_contract',
  sortKey: 'is_primary_contract',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (data.is_primary_contract ? 'Primary' : 'Secondary'),
  title: 'Type',
}

export const contractRoleColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.text,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Contract',
}

export const contractSeniorityColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.text,
  idPoint: 'seniority',
  dataPoint: 'seniority.name',
  sortKey: 'seniority__name',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const contractTermColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.text,
  idPoint: 'contract_term',
  dataPoint: 'contract_term.name',
  sortKey: 'contract_term',
  filterKey: 'contract_term__id',
  selectorsKey: selectorKeys.contract_terms,
  title: 'Term',
}

export const contractLocationColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.text,
  idPoint: 'location',
  dataPoint: 'location.name',
  sortKey: 'location__name',
  filterKey: 'location__id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
}

export const contractStartAtColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.insert,
  idPoint: 'start_date',
  dataPoint: 'start_date',
  sortKey: 'start_date',
  filterKey: 'start_date',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Start Date',
  insert: ({ data }) => {
    if (data.is_starting_soon_and_not_finalised) {
      return (
        <Tooltip placement="top" text="Contract starting soon & not finalised">
          <HStack space="s-12">
            <Text color={Token.color.red}>{formatWithoutTimezone(data.start_date)}</Text>
            <ExclamationMark size={16} color={Token.color.red} />
          </HStack>
        </Tooltip>
      )
    }
    return data.start_date ? formatWithoutTimezone(data.start_date) : '-'
  },
}

export const contractEndAtColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.insert,
  idPoint: 'end_date',
  dataPoint: 'end_date',
  sortKey: 'end_date',
  filterKey: 'end_date',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'End Date',
  insert: ({ data }) => {
    if (data.end_date && data.is_expiring_soon) {
      return (
        <Tooltip placement="top" text="Contract expiring soon">
          <HStack space="s-12">
            <Text color={Token.color.red}>{formatWithoutTimezone(data.end_date)}</Text>
            <ExclamationMark size={16} color={Token.color.red} />
          </HStack>
        </Tooltip>
      )
    }
    return data.end_date ? formatWithoutTimezone(data.end_date) : '-'
  },
}

export const contractAnnualSalaryColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.insert,
  idPoint: 'annual_salary',
  dataPoint: 'annual_salary',
  sortKey: 'annual_salary',
  filterKey: 'annual_salary',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) =>
    data.annual_salary && data.salary_currency
      ? formatMoney(Number(data.annual_salary), data.salary_currency.iso_code)
      : '-',
  title: 'Annual salary',
}

export const employeeColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.full_name',
  sortKey: 'employee__full_name',
  filterKey: 'employee__id',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.employee.id }),
  insert: ({ data }) => {
    return <UserWithAvatar {...data.employee} />
  },
  selectorsKey: selectorKeys.all_employees,
  title: 'Employee',
}

export const employeeTypeColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.text,
  idPoint: 'employee_type.id',
  dataPoint: 'employee_type.name',
  sortKey: 'employee_type',
  filterKey: 'employee_type',
  selectorsKey: selectorKeys.employee_types,
  title: 'Employee type',
}

export const specialisationRoleNameColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.text,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role (Specialisation)',
}

export const contractRankColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.insert,
  idPoint: 'is_primary_contract',
  dataPoint: 'is_primary_contract',
  sortKey: 'is_primary_contract',
  filterKey: 'is_primary_contract',
  selectorsKey: selectorKeys.yes_no_range_options,
  insert: ({ data }) => (data.is_primary_contract ? 'Primary' : 'Secondary'),
  title: 'Rank',
}

export const contractPaygroupColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.text,
  idPoint: 'pay_group.id',
  dataPoint: 'pay_group.name',
  sortKey: 'pay_group__name',
  filterKey: 'pay_group__id',
  selectorsKey: selectorKeys.contract_pay_groups,
  title: 'Paygroup',
}

export const documentsColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.insert,
  idPoint: 'uploaded_documents',
  dataPoint: 'uploaded_documents',
  sortKey: '',
  filterKey: '',
  selectorsKey: selectorKeys.none,
  title: 'Documents',
  insert: ({ data }) =>
    data.uploaded_documents?.length ? (
      <Document size={16} color={Token.color.blue} />
    ) : (
      '-'
    ),
}

export const ownerColumn: ColumnInterface<EmployeeContractsInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.full_name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.owner?.id }),
  insert: ({ data }) => {
    return <UserWithAvatar {...data.owner} />
  },
  selectorsKey: selectorKeys.all_employees,
  title: 'Owner',
}

export const contractTypeNameColumn: ColumnInterface<ContractTypeInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.contract_types,
  title: 'Name',
}

export const contractTypeDescriptionColumn: ColumnInterface<ContractTypeInterface> = {
  type: CellTypes.text,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const contractTypeEmployeeTypeColumn: ColumnInterface<ContractTypeInterface> = {
  type: CellTypes.text,
  idPoint: 'employee_type.id',
  dataPoint: 'employee_type.name',
  sortKey: null,
  filterKey: 'employee_type',
  selectorsKey: selectorKeys.employee_types,
  title: 'Employee type',
}
