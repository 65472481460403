import React, { useMemo, useRef, useState } from 'react'
import { DropdownButton } from '@components/DropdownButton/DropdownButton'
import {
  CriteriaAssessment,
  PerformanceRating,
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewScorecardInterface,
  SkillCardInterface,
} from '@src/interfaces/performance'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { OptionInterface } from '@src/interfaces/selectors'
import { AssessButtonTypes } from '@components/AssessButtons/AssessButtons'
import { getUpdatedRatingCard } from '@src/utils/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import axios, { CancelTokenSource } from 'axios'
import {
  InterviewReviewScorecardInterface,
  InterviewFeedbackSkillSectionInterface,
} from '@src/interfaces/interviewTool'

export const PrefillingTypes = [
  PerformanceReviewTypes.skills,
  PerformanceReviewTypes.managerSkills,
  PerformanceReviewTypes.cultureFit,
  PerformanceReviewTypes.interviewScorecard,
]

export const PREVIOUS_QUARTER_ID = 'previous_quarter'

const getOptions = (withPreviousQuarter: boolean, isProbation: boolean) =>
  [
    withPreviousQuarter
      ? {
          id: PREVIOUS_QUARTER_ID,
          name: isProbation ? 'Previous checkpoint rating' : 'Previous quarter rating',
        }
      : undefined,
    {
      id: PerformanceRating.poor,
      name: PerformanceRatingTitle[PerformanceRating.poor],
    },
    {
      id: PerformanceRating.basic,
      name: PerformanceRatingTitle[PerformanceRating.basic],
    },
    {
      id: PerformanceRating.intermediate,
      name: PerformanceRatingTitle[PerformanceRating.intermediate],
    },
    {
      id: PerformanceRating.advanced,
      name: PerformanceRatingTitle[PerformanceRating.advanced],
    },
    {
      id: PerformanceRating.expert,
      name: PerformanceRatingTitle[PerformanceRating.expert],
    },
  ].filter(i => i) as OptionInterface[]

export const getData = (
  values: ReviewScorecardInterface | InterviewReviewScorecardInterface,
  type: PerformanceReviewTypes,
  cardIndex: number,
) => {
  switch (type) {
    case PerformanceReviewTypes.skills:
      return values.review_data.functional_skills?.cards?.[cardIndex]
    case PerformanceReviewTypes.managerSkills:
      return values.review_data.manager_skills?.cards?.[cardIndex]
    case PerformanceReviewTypes.cultureFit:
      return values.review_data.culture_skills.cards?.[cardIndex]
    case PerformanceReviewTypes.interviewScorecard:
      return (values as InterviewReviewScorecardInterface).scorecard?.sections?.[
        cardIndex
      ] as InterviewFeedbackSkillSectionInterface
    default:
      return undefined
  }
}

type Props = {
  type: PerformanceReviewTypes
  category: ReviewCategory
  onSetResultLoading: (ids: (number | string)[]) => void
  cardIndex: number
}

export type CardInterface = SkillCardInterface | InterviewFeedbackSkillSectionInterface

export const prefillCard = (card: CardInterface, level: string | number) => {
  const sections = (card.sections as CriteriaAssessment[]) || []
  const maxSectionIdx = sections.findIndex(section => section.level_key === level)

  if (level === 'dont_know') {
    sections[0]?.items?.forEach((_, optionIndex) => {
      const item = (sections[0] as CriteriaAssessment)!.items![optionIndex]

      item.value = AssessButtonTypes.neutral
    })
    return
  }

  for (let sectionIndex = 0, len = sections.length; sectionIndex < len; ++sectionIndex) {
    const section = sections[sectionIndex] as CriteriaAssessment

    let fillValue = AssessButtonTypes.negative

    if (sectionIndex <= maxSectionIdx) {
      fillValue = AssessButtonTypes.positive
    }

    section.items?.forEach((_, optionIndex) => {
      const item = (sections[sectionIndex] as CriteriaAssessment)!.items![optionIndex]

      const previousQuarterValue = item.previous_values?.[0]?.value || null

      item.value = level === PREVIOUS_QUARTER_ID ? previousQuarterValue : fillValue
    })
  }
}

export const updateSingleCard = (card: CardInterface, level: string | number) => {
  prefillCard(card, level)
}

const PrefillScorecardButton = ({
  type,
  onSetResultLoading,
  cardIndex,
  category,
}: Props) => {
  const axiosCancelToken = useRef<CancelTokenSource>()
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const [value, setValue] = useState<OptionInterface>()

  const hasPrevious = useMemo(() => {
    const data: CardInterface | undefined = getData(values, type, cardIndex!)

    if (!data) {
      return false
    }

    let item = Array.isArray(data) ? data[0] : data

    return (
      !!item?.previous_ratings?.length &&
      item.previous_ratings[0].value !== PerformanceRating.skipped
    )
  }, [values, type, cardIndex])

  if (!PrefillingTypes.includes(type)) {
    return null
  }

  const getUpdatedCardRating = async (card: SkillCardInterface) => {
    try {
      const resp = await getUpdatedRatingCard(
        values.id,
        values.category,
        values.reviewed_employee.id!,
        card,
        axiosCancelToken.current,
      )

      card.rating = resp?.rating
      return card
    } catch (e) {
      return undefined
    }
  }

  const onPrefill = async (option: OptionInterface) => {
    const level = option.id
    let updatedValues: CardInterface | undefined = getData(values, type, cardIndex)

    if (!updatedValues) {
      return
    }

    updateSingleCard(updatedValues, level)

    if ('internal_data_id' in updatedValues) {
      // we don't need to update ratings if it's Interview scorecard
      return
    }

    onSetResultLoading([updatedValues.skill_id!])

    if (axiosCancelToken.current !== undefined) {
      axiosCancelToken.current.cancel('Operation canceled due to new request.')
    }

    axiosCancelToken.current = axios.CancelToken.source()

    // to delay a bit to make switching between options smoother
    setTimeout(async () => {
      if (!updatedValues || 'internal_data_id' in updatedValues) {
        return
      }

      updatedValues = await getUpdatedCardRating(updatedValues)

      // if we cancel requests here would be undefined and we reset loading state
      if (updatedValues) {
        onSetResultLoading([])
      }
    }, 300)
  }

  return (
    <DropdownButton
      options={getOptions(hasPrevious, category === ReviewCategory.Probation)}
      onSelect={val => {
        setValue(val)
        onPrefill(val)
      }}
      value={value?.id}
      useTextButton
    >
      Fill with
    </DropdownButton>
  )
}

export default PrefillScorecardButton
