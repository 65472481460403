import { ActionButtonSkeleton, MoreBar } from '@revolut/ui-kit'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { createDraftGoal } from '@src/api/goals'
import { captureException } from '@sentry/react'
import { useGetSelectors } from '@src/api/selectors'
import { GoalContentType } from '@src/interfaces/goals'
import { useLocation } from 'react-router-dom'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { notReachable } from '@src/utils/notReachable'

const useDefaultWriteAccess = () => {
  const { entity } = useOrgEntity()

  const canAddGoals = !!entity?.data.field_options?.permissions?.includes(
    PermissionTypes.CanAddGoals,
  )

  if (!entity) {
    return false
  }

  switch (entity.type) {
    case EntityTypes.company:
    case EntityTypes.companyV2:
    case EntityTypes.function:
    case EntityTypes.role:
    case EntityTypes.specialisation:
      return false
    default:
      return canAddGoals
  }
}

const useOwnerId = () => {
  const user = useSelector(selectUser)
  const { entity } = useOrgEntity()

  const fallbackOwnerId = user.id

  if (!entity) {
    return fallbackOwnerId
  }

  switch (entity.type) {
    case EntityTypes.employee:
    case EntityTypes.employees:
      return entity.data.id
    case EntityTypes.team:
    case EntityTypes.teams:
      return entity.data.team_owner.id
    case EntityTypes.department:
      return entity.data.owner?.id ?? fallbackOwnerId
    case EntityTypes.company:
    case EntityTypes.companyV2:
    case EntityTypes.function:
    case EntityTypes.role:
    case EntityTypes.specialisation:
      return fallbackOwnerId
    default:
      return notReachable(entity)
  }
}

export const CascadeGoalAction = () => {
  const location = useLocation()
  const { navigateWithEntity, entity } = useOrgEntity()
  const ownerId = useOwnerId()
  const { data: contentTypes, isLoading } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
  )
  const canAdd = useDefaultWriteAccess()
  const [pending, setPending] = useState(false)
  const showStatusPopup = useShowStatusPopup()
  const isCommercial = useIsCommercial()
  const isOnboarding = isOnboardingPath()

  if (isCommercial || isOnboarding) {
    return null
  }

  if (isLoading) {
    return <ActionButtonSkeleton />
  }

  const handleNew = async () => {
    setPending(true)

    try {
      const response = await createDraftGoal({
        owner: { id: ownerId },
        is_company: false,
        content_type: contentTypes?.find(({ model }) => model === entity?.type),
        object_id: entity?.data.id,
      })
      navigateWithEntity(pathToUrl(ROUTES.FORMS.CASCADE_GOAL, { id: response.data.id }), {
        reviewCycleId: new URLSearchParams(location.search).get('cycle__id'),
        isNew: true,
      })
    } catch (err) {
      captureException(err)
      const description =
        err.response?.data?.detail || 'Something went wrong. Please try again.'

      showStatusPopup({
        status: 'error',
        title: 'Failed to cascade goal',
        description,
      })
      setPending(false)
    }
  }

  return canAdd ? (
    <MoreBar.Action onClick={handleNew} useIcon="ArrowDowngrade" pending={pending}>
      Cascade goal
    </MoreBar.Action>
  ) : null
}
