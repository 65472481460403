import React from 'react'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'

export const documentsTemplatesName: ColumnInterface<DocumentsTemplatesInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.document_templates,
  title: 'Template Name',
}

export const documentsTemplateType: ColumnInterface<DocumentsTemplatesInterface> = {
  type: CellTypes.text,
  idPoint: 'template_type.id',
  dataPoint: 'template_type.name',
  sortKey: 'template_type',
  filterKey: 'template_type',
  selectorsKey: selectorKeys.document_template_types,
  title: 'Template type',
}

export const documentsTemplatesCategory: ColumnInterface<DocumentsTemplatesInterface> = {
  type: CellTypes.text,
  idPoint: 'category.id',
  dataPoint: 'category.name',
  sortKey: 'category__name',
  filterKey: 'category__id',
  selectorsKey: selectorKeys.document_categories,
  title: 'Category',
}

export const documentsTemplatesOwner: ColumnInterface<DocumentsTemplatesInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.full_name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
}

export const documentsTemplatesCreated: ColumnInterface<DocumentsTemplatesInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Created',
}
