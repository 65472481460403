import React from 'react'
import { Icon, MoreBar, Token } from '@revolut/ui-kit'

type Props = {
  onClick: () => void
}

const SendEmail = ({ onClick }: Props) => {
  return (
    <MoreBar.Action
      useIcon={<Icon name="Envelope" size={16} color={Token.color.blue} />}
      onClick={onClick}
    >
      Send email
    </MoreBar.Action>
  )
}

export default SendEmail
