import { Avatar, Cell, IconName, Item, VStack } from '@revolut/ui-kit'
import React, { PropsWithChildren, ReactNode } from 'react'

export const CellWithItem = ({
  icon,
  avatar,
  title,
  description,
  side,
  children,
}: PropsWithChildren<{
  icon?: IconName
  avatar?: ReactNode
  title: string
  description?: string
  side?: ReactNode
}>) => (
  <Cell width="100%">
    <VStack space="s-16" width="100%">
      <Item p={0}>
        <Item.Avatar>{avatar || <Avatar useIcon={icon} />}</Item.Avatar>

        <Item.Content>
          <Item.Title>{title}</Item.Title>
          {description ? <Item.Description>{description}</Item.Description> : null}
        </Item.Content>
        {!!side && <Item.Side>{side}</Item.Side>}
      </Item>
      {children}
    </VStack>
  </Cell>
)
