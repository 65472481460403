import React from 'react'
import { useTableReturnType } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import GraphIconChart from '@components/Charts/GraphIconChart/GraphIconChart'
import { getKpiPerformanceGraph } from '@src/api/department'
import { RowInterface, Stats } from '@src/interfaces/data'
import Tooltip from '@components/Tooltip/Tooltip'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import IconButton from '@components/ButtonIcon/IconButton'
import {
  kpiCalibratedPerformanceColumn,
  kpiCurrentValueColumn,
  kpiDashboardsColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiParentColumn,
  kpiPerformanceColumn,
  kpiPerformanceColumnWithLooker,
  kpiStatusColumn,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import { formatPercentage } from '@src/utils/format'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { KPISettingDates } from '@src/features/Popups/ImportantKpiMessage'
import useKPIWarning from '@src/hooks/UseKPIWarning'
import {
  Box,
  Cell,
  Flex,
  IconButton as NewIconButton,
  MoreBar,
  Text,
  TextButton,
} from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { BarChart, Info, Pencil, Warning } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useQuery } from '@src/utils/queryParamsHooks'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { TableNames } from '@src/constants/table'
import { rowHighlight } from '@src/features/KPI'
import { useRenderRefreshKPIsTableNote } from '@src/utils/kpi'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { KpiHelpButton } from '@src/features/KPI/KpiHelpButton'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { selectFeatureFlags } from '@src/store/auth/selectors'

interface StructureProps {
  data: DepartmentInterface
  table: useTableReturnType<KpiInterface, Stats>
  warnAboutMandatoryKPIs: boolean
}

const departmentRow = (allowedLooker: boolean): RowInterface<KpiInterface> => ({
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...kpiGenericNameColumn,
    },
    {
      ...kpiWeightColumn,
      width: 100,
    },
    allowedLooker
      ? { ...kpiPerformanceColumnWithLooker, width: 130 }
      : {
          ...kpiPerformanceColumn,
          width: 130,
        },
    {
      ...kpiCalibratedPerformanceColumn,
      width: 130,
    },
    {
      ...kpiInitialValueColumn,
      width: 60,
    },
    {
      ...kpiCurrentValueColumn,
      width: 60,
    },
    {
      ...kpiTargetColumn,
      width: 60,
    },
    {
      ...kpiUnitColumn,
      width: 60,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...kpiParentColumn,
      width: 60,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 150,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 60,
    },
    {
      ...kpiStatusColumn,
      width: 60,
    },
    {
      ...kpiDashboardsColumn,
      width: 110,
      insert: ({ data }) => {
        return (
          <>
            {data.related_dashboards?.length ? (
              <TextButton
                onClick={e => {
                  e.stopPropagation()
                  navigateTo(
                    `${pathToUrl(ROUTES.FORMS.DEPARTMENT.ANALYTICS_DASHBOARDS, {
                      id: data.department?.id,
                    })}?related_kpis=${data.id}`,
                  )
                }}
              >
                View
              </TextButton>
            ) : null}
          </>
        )
      },
    },
  ],
})

const KPI = ({ data, table, warnAboutMandatoryKPIs }: StructureProps) => {
  const { query } = useQuery<{ review_cycle__offset: string }>()
  const featureFlags = useSelector(selectFeatureFlags)
  const { showDepartment, reviewCycleData } = useKPIWarning()
  const allowedLooker = featureFlags.includes(FeatureFlags.Allowed_Looker)
  const canAdd = data.field_options.permissions?.includes(
    PermissionTypes.CanAddKPIsDepartment,
  )
  const user = useSelector(selectUser)

  const canChangeKpiWeight = data.field_options.permissions?.includes(
    PermissionTypes.ChangeKpiWeight,
  )

  const canRefreshKpis = data.field_options.permissions?.includes(
    PermissionTypes.CanRefreshKpis,
  )

  const { refreshNote, refreshButton } = useRenderRefreshKPIsTableNote(
    table,
    canRefreshKpis,
  )

  const handleEditKPIs = () => {
    const baseUrl = pathToUrl(ROUTES.FORMS.ASSIGN_KPIS.DEPARTMENT, { id: data.id })
    const offset = query.review_cycle__offset?.split(',')?.[0] || 0

    navigateTo(`${baseUrl}?review_cycle__offset=${offset}`)
  }

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL, {}), {
      initialValues: getKPIFormInitialValues(user, {
        department: { name: data.name, id: data.id },
      }),
    })
  }

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-24">
          <Stat
            label="Overall Progress"
            val={
              table?.stats?.avg_performance !== undefined ? (
                <Flex>
                  <Tooltip
                    text="Overall progress is calculated as the weighted average of all approved KPIs."
                    placement="right"
                  >
                    <Text
                      use="div"
                      mr="5px"
                      color={getPercentColor((table?.stats?.avg_performance || 0) * 100)}
                    >
                      {formatPercentage(table?.stats?.avg_performance || 0)}
                    </Text>
                  </Tooltip>
                  <GraphIconChart
                    id={data.id}
                    vertical="right"
                    fetchData={getKpiPerformanceGraph}
                  >
                    <Flex pr="s-12">
                      <BarChart cursor="pointer" size={16} color="grey-tone-20" />
                    </Flex>
                  </GraphIconChart>
                  {warnAboutMandatoryKPIs && (
                    <Tooltip
                      placement="right"
                      text="This profile has mandatory KPIs which must have 100% progress by cycle end, otherwise Overall Progress will be set to zero."
                    >
                      <IconButton>
                        <Warning color="red" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Flex>
              ) : undefined
            }
            mr="s-32"
          />
          <CycleFilter
            type={CycleFilterType.NewUI}
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__offset"
            filter={table.filterBy}
            filterInputType={FilterSelectType.SingleSelect}
          />
        </Flex>
        <Flex mb="s-16">
          <MoreBar>
            {canAdd && (
              <MoreBar.Action onClick={handleNewRow} useIcon="Plus">
                Add New KPI
              </MoreBar.Action>
            )}
            {canChangeKpiWeight && (
              <MoreBar.Action useIcon={Pencil} onClick={handleEditKPIs}>
                Edit KPIs
              </MoreBar.Action>
            )}
            {refreshButton}
            <KpiHelpButton kpiChampions={data.kpi_champions} />
          </MoreBar>
          {showDepartment && (
            <Tooltip
              placement="right"
              body={
                <Box minWidth={600} color="background" pb="s-16" pr="s-16" pl="s-32">
                  {reviewCycleData && <KPISettingDates cycle={reviewCycleData} />}
                </Box>
              }
            >
              <NewIconButton useIcon={Info} aria-label="Info" color="red" size={32} />
            </Tooltip>
          )}
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<KpiInterface>
            name={TableNames.DepartmentKPIs}
            useWindowScroll
            dataType="KPI"
            row={departmentRow(allowedLooker)}
            {...table}
            fetchChildren={(parentIndexes, id) => {
              return table.fetchChildren(parentIndexes, id, [
                {
                  filters: [{ name: `${data.id}`, id: data.id }],
                  columnName: 'team__department__id',
                  nonResettable: true,
                },
              ])
            }}
            onRowClick={onKPITableRowClick}
            noDataMessage="Please add your department KPIs to see them here."
            useFetchedChildren
          />
        </Flex>
        {refreshNote}
      </Flex>
    </Cell>
  )
}

export default KPI
