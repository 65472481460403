import React from 'react'
import { Flex } from '@revolut/ui-kit'

import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import Stat from '@components/Stat/Stat'
import { teamGenericNameColumn } from '@src/constants/columns/team'
import {
  departmentHeadcountColumn,
  departmentNameColumn,
  departmentRequisitionsColumn,
} from '@src/constants/columns/department'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { getTeamOwnership, teamOwnershipTransfer } from '@src/api/ownership'

import {
  OwnershipTransferProvider,
  SmallBanner,
  TransferSelections,
  TransferTable,
  TrasferAllButton,
} from './common'
import { TableWrapper } from '@components/Table/TableWrapper'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
}

const TeamsOwnership = ({ data, stats, refreshStats }: Props) => {
  const sortBy = [
    {
      sortBy: 'department__name',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'headcount',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const transferApi = (selections: TransferSelections) =>
    teamOwnershipTransfer(
      data.id,
      Object.entries(selections).map(([id, selection]) => ({
        id,
        team_owner: selection.id,
      })),
    ).then(refreshStats)

  return (
    <OwnershipTransferProvider
      getApi={getTeamOwnership(data.id)}
      sortByInitial={sortBy}
      defaultOwner={data.line_manager}
      transferApi={transferApi}
    >
      <SmallBanner
        title="Employee scheduled for termination. Assign new team owners"
        hidden={!data.has_termination}
      />

      <TableWrapper mb="s-20">
        <Stat label="Teams" val={stats?.owned_teams_count} mb="s-24" />
        <Flex mb="s-24">
          <TrasferAllButton />
        </Flex>

        <TransferTable
          cells={[
            {
              ...teamGenericNameColumn,
              title: 'Team name',
              width: 200,
            },
            {
              ...departmentNameColumn,
              width: 200,
            },
            {
              ...departmentHeadcountColumn,
              width: 100,
            },
            {
              ...departmentRequisitionsColumn,
              width: 100,
              title: 'Requisitions',
            },
          ]}
          noDataMessage="Teams ownership will appear here"
        />
      </TableWrapper>
    </OwnershipTransferProvider>
  )
}

export default TeamsOwnership
