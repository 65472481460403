import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { Box, InputGroup, Side } from '@revolut/ui-kit'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { EMPLOYEE_PROFILE_SUB_SECTIONS } from '@src/interfaces/employees'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useEmployeeDetailsOnAfterSubmit } from '@src/pages/EmployeeProfile/Forms/hooks'

export interface Props {
  dynamicGroups: DynamicGroupIDs
  isSidebarContent?: boolean
  refreshData?: () => void
}

const Bio = ({ dynamicGroups, isSidebarContent, refreshData }: Props) => {
  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <>
      <PageBody>
        <Box mb="s-32">
          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BIO}
            dynamicGroups={dynamicGroups}
          >
            <InputGroup>
              <LapeRadioSelectInput
                name="legal_sex"
                selector={selectorKeys.legal_sexes}
                label="Legal sex"
              />
              <LapeNewMultiSelect
                name="languages"
                selector={selectorKeys.language}
                placeholder="Languages"
                message="Select one or more languages that the employee can speak at least at a conversational level."
                required
              />
              <LapeDatePickerInput
                name="birth_date"
                label="Birth date"
                disabledDays={{ after: new Date() }}
                required
              />
              <LapeRadioSelectInput
                name="marital_status"
                selector={selectorKeys.marital_statuses}
                label="Marital status"
              />
              <LapeNewMultiSelect
                name="nationalities"
                selector={selectorKeys.countries}
                placeholder="Nationalities"
                required
              />
            </InputGroup>
          </NewStepperSectionCustomFields>
        </Box>
      </PageBody>
      <ActionsWrapper>
        <NewSaveButtonWithPopup
          useValidator
          successText="Bio successfully updated."
          onAfterSubmit={onAfterSubmit}
        />
      </ActionsWrapper>
    </>
  )
}

export default Bio
