import React, { useCallback, useState, useEffect } from 'react'
import { Toolbox, Color, Icon } from '@revolut/ui-kit'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $getSelection,
  $isRangeSelection,
  TextNode,
  ElementNode,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
} from 'lexical'
import { $getSelectionStyleValueForProperty, $isAtNodeEnd } from '@lexical/selection'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import { InsertImageDialog } from '@components/Editor/plugins/ImagePlugin'
import { $isListNode, ListNode } from '@lexical/list'
import { $isHeadingNode } from '@lexical/rich-text'
import {
  VariantPlugin,
  BlockType,
  blockTypes,
} from '@components/Editor/plugins/VariantPlugin'
import { AlignPlugin } from '@components/Editor/plugins/AlignPlugin'
import { FontSizePlugin } from '@components/Editor/plugins/FontSizePlugin'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { sanitizeUrl } from '@components/Editor/utils/url'

function getSelectedNode(selection: RangeSelection): TextNode | ElementNode {
  const anchor = selection.anchor
  const focus = selection.focus
  const anchorNode = selection.anchor.getNode()
  const focusNode = selection.focus.getNode()
  if (anchorNode === focusNode) {
    return anchorNode
  }
  const isBackward = selection.isBackward()
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode
  }
  return $isAtNodeEnd(anchor) ? focusNode : anchorNode
}

interface ToolbarPluginProps {
  allowImages: boolean
}
export const ToolbarPlugin = ({ allowImages }: ToolbarPluginProps) => {
  const [editor] = useLexicalComposerContext()
  const [activeEditor, setActiveEditor] = useState(editor)
  const [blockType, setBlockType] = useState<BlockType>('paragraph')
  const [fontSize, setFontSize] = useState<string>('16px')
  const [isLink, setIsLink] = useState(false)
  const [openImagePopup, setOpenImagePopup] = useState(false)

  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow()
      const elementKey = element.getKey()
      const elementDOM = activeEditor.getElementByKey(elementKey)

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode)
          const type = parentList ? parentList.getListType() : element.getListType()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType()
          const foundType = blockTypes.find(t => t === type)
          if (foundType) {
            setBlockType(foundType)
          }
        }
      }

      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '16px'))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }
    }
  }, [activeEditor])

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar()
        setActiveEditor(newEditor)
        return false
      },
      COMMAND_PRIORITY_CRITICAL,
    )
  }, [editor, updateToolbar])

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
    )
  }, [activeEditor, updateToolbar])

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'))
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink])

  return (
    <Toolbox
      width="100%"
      borderBottom="1px solid"
      borderBottomColor={Color.GREY_TONE_10}
      css={{ boxShadow: 'none' }}
    >
      <VariantPlugin editor={activeEditor} blockType={blockType} />
      <FontSizePlugin editor={activeEditor} value={fontSize} />
      <Toolbox.Separator />
      <Toolbox.Action
        aria-label="Bold"
        useIcon="16/EditorBold"
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
        }}
        aria-pressed={isBold}
      />
      <Toolbox.Action
        aria-label="Italic"
        useIcon="16/EditorItalic"
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
        }}
        aria-pressed={isItalic}
      />
      <Toolbox.Action
        aria-label="Underline"
        useIcon="16/EditorUnderline"
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
        }}
        aria-pressed={isUnderline}
      />
      <Toolbox.Separator />
      <Toolbox.Action
        aria-label="Link"
        useIcon={<Icon name="Link" />}
        onClick={insertLink}
      />
      {allowImages && (
        <Toolbox.Action
          aria-label="Image"
          useIcon="Image"
          onClick={() => {
            setOpenImagePopup(true)
          }}
        />
      )}
      <Toolbox.Separator />
      <AlignPlugin editor={activeEditor} />
      {openImagePopup ? (
        <InsertImageDialog
          activeEditor={activeEditor}
          onClose={() => setOpenImagePopup(false)}
        />
      ) : null}
    </Toolbox>
  )
}
