import React, { useRef, useState } from 'react'
import { Box, Button, Dropdown, Flex, H4, Calendar } from '@revolut/ui-kit'
import { ChevronDown, ChevronUp } from '@revolut/icons'
import format from 'date-fns/format'

import Stat from '@src/components/Stat/Stat'

interface MonthSelectProps {
  value: string | number
  onChange: (date: Date | null) => void
}

export const MonthSelect = ({ value, onChange }: MonthSelectProps) => {
  const [monthSelectOpen, setMonthSelectOpen] = useState(false)

  const selectRef = useRef<HTMLButtonElement>(null)

  return (
    <Stat
      label="Month"
      val={
        <Box>
          <Button
            ref={selectRef}
            onClick={() => setMonthSelectOpen(!monthSelectOpen)}
            variant="text"
            height="fit-content"
            p={0}
          >
            <Flex alignItems="center" color="foreground">
              <H4 use="span" fontWeight="bold">
                {format(new Date(value), 'MMMM yyyy')}
              </H4>
              {monthSelectOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
            </Flex>
          </Button>
          <Dropdown
            open={monthSelectOpen}
            anchorRef={selectRef}
            onClose={() => setMonthSelectOpen(false)}
          >
            <Calendar
              variant="month"
              labelEmptyValue="Select month"
              labelPrev="Previous month"
              labelNext="Next month"
              value={new Date(value)}
              onChange={date => {
                onChange(date)
                setMonthSelectOpen(false)
              }}
            />
          </Dropdown>
        </Box>
      }
    />
  )
}
