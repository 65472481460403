import { useEffect, useState } from 'react'
import { FileInterface } from '@src/interfaces/files'
import { downloadFile, getFileMetadata } from '@src/api/files'
import { saveFile } from '@src/utils'
import { downloadUploadedAsBlob } from '@src/api/documents'
import * as Sentry from '@sentry/react'

export const useGenericFileApi = (
  id: number,
  fileInfo?: { id: number; name: string },
) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const [fileUrl, setFileUrl] = useState<string>()
  const [metadata, setMetadata] = useState<FileInterface>()

  useEffect(() => {
    if (!fileInfo) {
      return
    }
    setFileUrl(undefined)
    setIsLoading(true)
    setIsError(false)

    getFileMetadata(fileInfo.id)
      .then(metadataResponse => {
        const { data: fileMetadata } = metadataResponse

        if (!fileMetadata) {
          return Promise.reject(new Error('File metadata was not found'))
        }
        setMetadata(fileMetadata)
        return downloadFile(fileInfo.id)
      })
      .then(downloadResponse => {
        setFileUrl(downloadResponse)
      })
      .catch(error => {
        setIsError(true)
        Sentry.captureException(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [id, fileInfo?.id])

  const isPdf = metadata?.extension === 'pdf'
  const isImage = ['png', 'jpg', 'jpeg'].includes(metadata?.extension || '')

  const canPreview = isPdf || isImage
  const canSave = !!fileUrl && !!fileInfo

  const onSave = () => {
    if (!canSave) {
      return
    }
    saveFile(fileUrl, fileInfo.name)
  }

  return fileInfo
    ? {
        isLoading,
        isError,
        canPreview,
        canSave,
        onSave,
        isPdf,
        isImage,
        fileUrl,
      }
    : undefined
}

export const useUploadedDocumentsApi = (
  id: number,
  document?: { id: number; file_name: string; file_media_type: string } | null,
) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const [fileUrl, setFileUrl] = useState<string>()

  useEffect(() => {
    if (!document) {
      return
    }
    if (document.id === id && document.file_name) {
      setFileUrl(undefined)
      setIsLoading(true)
      setIsError(false)

      downloadUploadedAsBlob(id)
        .then(response => setFileUrl(response))
        .catch(error => {
          setIsError(true)
          Sentry.captureException(error)
        })
        .finally(() => setIsLoading(false))
    }

    if (document.id === id && !document.file_name) {
      setIsLoading(false)
    }
  }, [id, document?.id, document?.file_name])

  const isPdf = document?.file_media_type === 'application/pdf'
  const isImage = ['image/png', 'image/jpg', 'image/jpeg'].includes(
    document?.file_media_type || '',
  )

  const canPreview = isPdf || isImage
  const canSave = !!fileUrl && !!document?.file_name

  const onSave = () => {
    if (!canSave) {
      return
    }
    saveFile(fileUrl!, document!.file_name)
  }

  return document
    ? {
        isLoading,
        isError,
        canPreview,
        canSave,
        onSave,
        isPdf,
        isImage,
        fileUrl,
      }
    : undefined
}
