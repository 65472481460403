import React from 'react'
import { useSelector } from 'react-redux'
import { useTable } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import { kpisRequests } from '@src/api/kpis'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import GraphIconChart from '@components/Charts/GraphIconChart/GraphIconChart'
import { RowInterface, SORT_DIRECTION, Stats } from '@src/interfaces/data'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import {
  kpiCalibratedPerformanceColumn,
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiStatusColumn,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { canAddRoleKpi, selectUser } from '@src/store/auth/selectors'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { getKpiPerformanceGraph } from '@src/api/roles'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import { formatPercentage } from '@src/utils/format'
import { EntityTypes } from '@src/constants/api'
import { PermissionTypes } from '@src/store/auth/types'
import { RoleInterface } from '@src/interfaces/roles'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { ActionButton, Flex, Icon, Text, Token } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'

interface StructureProps {
  data: RoleInterface
}

const departmentRow: RowInterface<KpiInterface> = {
  highlight: data => {
    if (data.target_status === Statuses.pending) {
      return Token.color.orange_5
    }

    return ''
  },
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...kpiGenericNameColumn,
    },
    {
      ...kpiWeightColumn,
      width: 80,
    },
    {
      ...kpiPerformanceColumn,
      width: 130,
    },
    {
      ...kpiCalibratedPerformanceColumn,
      width: 130,
    },
    {
      ...kpiInitialValueColumn,
      width: 80,
    },
    {
      ...kpiCurrentValueColumn,
      width: 90,
    },
    {
      ...kpiTargetColumn,
      width: 80,
    },
    {
      ...kpiUnitColumn,
      width: 80,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 200,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 100,
    },
    {
      ...kpiStatusColumn,
      width: 80,
    },
  ],
}

const KPI = ({ data }: StructureProps) => {
  const initialFilter = [
    {
      filters: [{ name: `${data.id}`, id: data.id }],
      columnName: 'role__id',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      filters: [
        { name: Statuses.active, id: Statuses.active },
        { name: Statuses.draft, id: Statuses.draft },
      ],
      columnName: 'status',
      nonResettable: true,
    },
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'has_children',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const table = useTable<KpiInterface, Stats>(kpisRequests, initialFilter, initialSortBy)

  const canAdd = useSelector(canAddRoleKpi)
  const user = useSelector(selectUser)

  const canChangeKpiWeight = data?.field_options?.permissions?.includes(
    PermissionTypes.ChangeKpiWeight,
  )

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL, {}), {
      initialValues: getKPIFormInitialValues(user, {
        role: { name: data.name, id: data.id },
      }),
    })
  }

  const handleEditWeights = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.KPI_WEIGHTS.GENERAL), {
      initialValues: {
        entity_id: data.id,
        entity_type: EntityTypes.role,
        kpi_weight_mode: data.kpi_weight_mode,
        role: {
          id: data.id,
          name: data.name,
        },
        function: null,
        employee: null,
        team: null,
        department: null,
      },
    })
  }

  return (
    <TableWrapper>
      <Flex mb="s-24">
        <Stat
          label="Progress"
          val={
            table?.stats?.avg_performance !== undefined ? (
              <Flex>
                <Text
                  use="div"
                  mr="5px"
                  color={getPercentColor((table?.stats?.avg_performance || 0) * 100)}
                >
                  {formatPercentage(table?.stats?.avg_performance || 0)}
                </Text>
                <GraphIconChart
                  id={data.id}
                  vertical="right"
                  fetchData={getKpiPerformanceGraph}
                >
                  <Flex pr="s-12">
                    <Icon
                      name="BarChart"
                      size={16}
                      color={Token.color.greyTone20}
                      style={{ cursor: 'pointer' }}
                    />
                  </Flex>
                </GraphIconChart>
              </Flex>
            ) : undefined
          }
          mr="s-32"
        />
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={table.onFilterChange}
          columnName="review_cycle__offset"
          filter={table.filterBy}
          filterInputType={FilterSelectType.SingleSelect}
        />
      </Flex>
      <Flex mb="s-16">
        {canAdd && (
          <ActionButton onClick={handleNewRow} mr="s-16" useIcon="Plus">
            Add New KPI
          </ActionButton>
        )}
        {canChangeKpiWeight && (
          <ActionButton onClick={handleEditWeights} mr="s-16">
            Edit Weights
          </ActionButton>
        )}
      </Flex>
      <AdjustableTable<KpiInterface>
        name={TableNames.RoleKPI}
        useWindowScroll
        dataType="KPI"
        row={departmentRow}
        {...table}
        onRowClick={onKPITableRowClick}
        noDataMessage={'Please add your role KPIs to see them here.'}
        useFetchedChildren
      />
    </TableWrapper>
  )
}

export default KPI
