import { useGetGoalApprovalFlow } from '@src/api/goals'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import React, { useEffect } from 'react'

export const GoalApprovalFlow = ({
  id,
  approvalStatus,
}: {
  id: number
  approvalStatus: ApprovalStatuses
}) => {
  const { data, isLoading, refetch } = useGetGoalApprovalFlow(id)

  useEffect(() => {
    refetch()
  }, [approvalStatus])

  return <ApprovalFlow steps={data || null} isLoading={isLoading} variant="fit" />
}
