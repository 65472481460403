import React from 'react'
import {
  InterviewRoundInterface,
  InterviewFeedbackInterface,
  InterviewType,
} from '@src/interfaces/interviewTool'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewScorecardType } from '@src/interfaces/interviewScorecardTemplates'
import HiringPanelSummary from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/HiringPanelSummary/HiringPanelSummary'
import InterviewFeedbackForm from '@src/features/InterviewFeedbackForm/InterviewFeedbackForm'

type Props = {
  isSidebar?: boolean
  isViewMode?: boolean
  round?: InterviewRoundInterface
  stageType?: InterviewType | null
}

const Index = ({ isSidebar, isViewMode, round, stageType }: Props) => {
  const { values } = useLapeContext<InterviewFeedbackInterface>()
  const type = values.scorecard.scorecard_type

  if (type === InterviewScorecardType.HiringPanel) {
    return <HiringPanelSummary round={round} isViewMode={isViewMode} />
  }

  return (
    <InterviewFeedbackForm
      isSidebar={isSidebar}
      isViewMode={isViewMode}
      stageType={stageType}
    />
  )
}

export default Index
