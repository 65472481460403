import React from 'react'

import { CustomFieldsForSectionInterface } from '@src/interfaces/customFields'
import { CustomFieldTypes } from '@src/constants/customFields'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewMultiSelect from '../Inputs/LapeFields/LapeNewMultiSelect'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { Input } from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import NewMultiSelect from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import { DatePickerInput } from '@components/Inputs/DatePickerInput/DatePickerInput'
import { localDateToUtc } from '@src/utils/timezones'

interface LapeCustomFieldsRouterProps {
  field: CustomFieldsForSectionInterface
  disabled?: boolean
}

interface CustomFieldsRouterProps extends LapeCustomFieldsRouterProps {
  value: any
  onChange: (value: any) => void
  dropdownOptionKey?: string
}

const getInputProps = (field: CustomFieldsForSectionInterface, disabled?: boolean) => {
  const commonProps = {
    label: field.name,
    required: !!field.mandatory,
    disabled,
    message: field.tooltip,
  }

  switch (field?.input_type?.id) {
    case CustomFieldTypes.Number:
    case CustomFieldTypes.Text:
      return commonProps
    case CustomFieldTypes.Dropdown:
      return {
        ...commonProps,
        clearable: !field.mandatory,
        options: field.validation_checks?.validation_choices?.map((choice: any) => ({
          label: choice.name,
          value: choice,
        })),
      }
    case CustomFieldTypes.Date:
      return {
        ...commonProps,
      }
    case CustomFieldTypes.MultipleSelector:
      return {
        ...commonProps,
        placeholder: field.name,
        options: field.validation_checks?.validation_choices?.map((choice: any) => ({
          label: choice.name,
          value: choice,
        })),
      }
    default:
      return null
  }
}

export const CustomFieldsRouter = ({
  field,
  disabled,
  value,
  onChange,
  dropdownOptionKey,
}: CustomFieldsRouterProps) => {
  const inputProps = getInputProps(field, disabled)

  const getValueIfOptionKeySpecified = (currentValue: unknown, key: string) => {
    if (!currentValue) {
      return undefined
    }
    if (typeof currentValue === 'object') {
      // for backwards compatibility with already saved data
      const fieldKey = key as keyof typeof currentValue
      return {
        id: currentValue[fieldKey],
        name: currentValue[fieldKey],
      }
    }
    if (typeof currentValue === 'string') {
      return { id: currentValue, name: currentValue }
    }
    return currentValue
  }

  switch (field?.input_type?.id) {
    case CustomFieldTypes.Number:
    case CustomFieldTypes.Text:
      return (
        <Input
          {...inputProps}
          type={field.input_type?.id === CustomFieldTypes.Number ? 'number' : undefined}
          value={value}
          onChange={e => onChange(e.currentTarget.value)}
        />
      )
    case CustomFieldTypes.Dropdown:
      return (
        <RadioSelectInput
          {...inputProps}
          value={
            dropdownOptionKey
              ? getValueIfOptionKeySpecified(value, dropdownOptionKey)
              : value
          }
          onChange={option => {
            onChange(dropdownOptionKey ? option[dropdownOptionKey] : option)
          }}
        />
      )
    case CustomFieldTypes.Date:
      return (
        <DatePickerInput
          {...inputProps}
          value={value}
          onChange={e => onChange(e ? localDateToUtc(e) : null)}
        />
      )
    case CustomFieldTypes.MultipleSelector:
      return (
        <NewMultiSelect
          {...inputProps}
          value={
            Array.isArray(value)
              ? value.map(val => ({ label: val.name, value: val }))
              : undefined
          }
          onChange={options => onChange(options.map(option => option.value))}
        />
      )
    default:
      return null
  }
}

const LapeCustomFieldsRouter = ({ field, disabled }: LapeCustomFieldsRouterProps) => {
  const inputProps = getInputProps(field, disabled)

  switch (field?.input_type?.id) {
    case CustomFieldTypes.Number:
    case CustomFieldTypes.Text:
      return (
        <LapeNewInput
          name={`custom_fields.${field.uid}`}
          {...inputProps}
          type={field.input_type?.id === CustomFieldTypes.Number ? 'number' : undefined}
        />
      )
    case CustomFieldTypes.Dropdown:
      return <LapeRadioSelectInput name={`custom_fields.${field.uid}`} {...inputProps} />
    case CustomFieldTypes.Date:
      return <LapeDatePickerInput name={`custom_fields.${field.uid}`} {...inputProps} />
    case CustomFieldTypes.MultipleSelector:
      return <LapeNewMultiSelect name={`custom_fields.${field.uid}`} {...inputProps} />
    default:
      return null
  }
}

export default LapeCustomFieldsRouter
