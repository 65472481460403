import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { PermissionsInterface } from '@src/interfaces/permissions'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { ActionBar } from '@revolut/ui-kit'
import React from 'react'

export const permissionsEmployeeNameColumn: ColumnInterface<PermissionsInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.full_name',
  sortKey: 'employee__full_name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.employee,
  insert: ({ data }) => <UserWithAvatar {...data.employee} />,
  title: 'Employee name',
}

export const permissionsPermissionColumn: ColumnInterface<PermissionsInterface> = {
  type: CellTypes.text,
  idPoint: 'permission.id',
  dataPoint: 'permission.name',
  sortKey: 'permission__name',
  filterKey: 'permission__id',
  selectorsKey: selectorKeys.none,
  title: 'Permission',
}

export const permissionsStatusColumn: ColumnInterface<PermissionsInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status__name',
  filterKey: 'status__id',
  selectorsKey: selectorKeys.object_permission_statuses,
  title: 'Status',
}

export const permissionsValidFromColumn: ColumnInterface<PermissionsInterface> = {
  type: CellTypes.date,
  idPoint: 'start_date_time',
  dataPoint: 'start_date_time',
  sortKey: 'start_date_time',
  filterKey: 'start_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Valid from',
}

export const permissionsValidTillColumn: ColumnInterface<PermissionsInterface> = {
  type: CellTypes.date,
  idPoint: 'end_date_time',
  dataPoint: 'end_date_time',
  sortKey: 'end_date_time',
  filterKey: 'end_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Valid till',
}

export const permissionsNotesColumn: ColumnInterface<PermissionsInterface> = {
  type: CellTypes.text,
  idPoint: 'reason',
  dataPoint: 'reason',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Notes',
}

export const permissionsActionsColumn = (
  onRevoke: (data: PermissionsInterface) => void,
): ColumnInterface<PermissionsInterface> => ({
  type: CellTypes.insert,
  idPoint: 'actions',
  dataPoint: 'actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return (
      <ActionBar>
        {data.status?.id !== 'expired' && (
          <ActionBar.Item
            color="red"
            onClick={e => {
              e.stopPropagation()
              onRevoke(data)
            }}
          >
            Revoke
          </ActionBar.Item>
        )}
      </ActionBar>
    )
  },
  title: 'Action',
})
