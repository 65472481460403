import React from 'react'
import { Token, Flex } from '@revolut/ui-kit'

import AdjustableTable from '@src/components/Table/AdjustableTable'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import {
  requestOfReportsEmployeeColumn,
  teamTimeOffRequestsPolicyColumn,
  timeOffRequestApprovalColumn,
  timeOffRequestApprovedOnColumn,
  timeOffRequestApproverColumn,
  timeOffRequestDurationColumn,
  timeOffRequestEndDateColumn,
  timeOffRequestRequestedOnColumn,
  timeOffRequestStartDateColumn,
  timeOffRequestStatusColumn,
} from '@src/constants/columns/timeOff'
import { TeamTimeOffRequestsInterface } from '@src/interfaces/timeOff'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useTable } from '@src/components/Table/hooks'
import {
  getTeamTimeOffRequests,
  useTeamTimeOffRequestYearOptions,
} from '@src/api/timeOff'
import { TeamInterface } from '@src/interfaces/teams'
import Stat from '@src/components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { getDefaultYearOption, TimeOffYearSelect } from '@src/features/TimeOff/YearSelect'
import { TableNames } from '@src/constants/table'

const getRow: (showApprovals?: boolean) => RowInterface<TeamTimeOffRequestsInterface> = (
  showApprovals?: boolean,
) => ({
  linkToForm: ({ id, employee: { id: employeeId } }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, { id, employeeId }),
    ),
  cells: [
    {
      ...requestOfReportsEmployeeColumn,
      width: 160,
    },
    {
      ...teamTimeOffRequestsPolicyColumn,
      width: 80,
    },
    {
      ...timeOffRequestStartDateColumn,
      width: 120,
    },
    {
      ...timeOffRequestEndDateColumn,
      width: 120,
    },
    {
      ...timeOffRequestDurationColumn,
      width: 80,
    },
    ...(showApprovals
      ? [
          {
            ...timeOffRequestRequestedOnColumn,
            width: 120,
          },
          {
            ...timeOffRequestApprovedOnColumn,
            width: 120,
          },
          {
            ...timeOffRequestApproverColumn,
            width: 160,
          },
          {
            ...timeOffRequestApprovalColumn,
            width: 100,
          },
          {
            ...timeOffRequestStatusColumn,
            width: 100,
          },
        ]
      : []),
  ],
})

interface Props {
  data: TeamInterface
  canViewApprovals: boolean
  initialFilters?: FilterByInterface[]
}
export const TimeOffTable = ({ data, canViewApprovals, initialFilters = [] }: Props) => {
  const table = useTable(
    {
      getItems: getTeamTimeOffRequests(data.id),
    },
    [
      {
        columnName: 'year',
        filters: [getDefaultYearOption()],
        nonResettable: true,
      },
      ...initialFilters,
    ],
    [
      {
        sortBy: 'approval_status_sorter',
        nonResettable: true,
      },
    ],
  )

  const canViewPolicyColumn = data.field_options?.permissions?.includes(
    PermissionTypes.ViewTimeOffRequestPolicy,
  )
  const requestYearOptions = useTeamTimeOffRequestYearOptions(data.id)

  return (
    <Flex flexDirection="column" width="100%">
      <Flex mb="s-16" gap="s-32">
        <TimeOffYearSelect
          api={requestYearOptions}
          onChange={option => {
            table.onFilterChange(
              {
                columnName: 'year',
                filters: [{ id: option.id, name: `${option.id}` }],
              },
              false,
            )
          }}
        />
        {canViewApprovals && (
          <>
            <Stat
              label="Pending approval"
              color={Token.color.orange}
              val={data.time_off_request_pending_count}
            />
            <Stat label="In progress" val={data.time_off_request_in_progress_count} />
            <Stat label="Upcoming" val={data.time_off_request_upcoming_count} />
          </>
        )}
      </Flex>

      <Flex style={{ position: 'relative' }} flex="1 0">
        <AdjustableTable
          name={TableNames.TeamTimeOff}
          useWindowScroll
          {...table}
          row={getRow(canViewApprovals)}
          hiddenCells={{
            [teamTimeOffRequestsPolicyColumn.idPoint]: !canViewPolicyColumn,
          }}
        />
      </Flex>
    </Flex>
  )
}
