import React, { useMemo } from 'react'
import { useIsNewTable, useTable } from '@components/TableV2/hooks'
import { RowInterface, Stats } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { roadmapsRequests } from '@src/api/roadmaps'
import {
  departmentColumn,
  getDeleteRoadmapColumn,
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
  teamColumn,
} from '@src/constants/columns/roadmap'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { H5, MoreBar, Token } from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useGetRoadmapSettings } from '@src/api/settings'
import { selectorKeys } from '@src/constants/api'
import { useShowRoadmapDetails, useRoadmapContext, useRoadmapRefresh } from './RoadmapTab'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { foreignGoalColumn } from '@src/constants/columns/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { getCommonFilters } from './common'
import { GoalCycleFilter } from '../components/GoalCycleFilter'
import { GoalsSettingsButton } from '../components/GoalsSettingsButton'
import { EntitySelector } from './EntitySelector'
import { FormattedMessage } from 'react-intl'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { EmptyRoadmapsTable } from '../components/EmptyRoadmapsTable'
import { roadmapsExtraRequests } from '@src/api/roadmaps'
import { DeleteActionProps } from '@src/components/ColumnInserts/DeleteAction/DeleteAction'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'

import Table from '@src/components/TableV2/Table'
import { ProgressWithNavigation } from '../components/ProgressWithNavigation'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'

const getRow = (
  handleDelete: DeleteActionProps['handleDelete'],
): RowInterface<RoadmapInterface> => {
  return {
    cells: [
      {
        ...roadmapGenericNameColumn,
        width: 350,
      },
      {
        ...foreignGoalColumn,
        width: 200,
      },
      {
        ...teamColumn,
        width: 150,
      },
      {
        ...departmentColumn,
        width: 150,
      },
      {
        ...roadmapProgressColumn,
        width: 120,
      },
      {
        ...roadmapStatusColumn,
        width: 100,
      },
      {
        ...roadmapStartDate,
        width: 100,
      },
      {
        ...roadmapDueDate,
        width: 100,
      },
      {
        ...roadmapResolutionAtColumn,
        width: 100,
      },
      {
        ...ownerNameColumn,
        width: 120,
      },
      {
        ...roadmapEpicUrlColumn,
        width: 100,
      },
      {
        ...getDeleteRoadmapColumn({ handleDelete }),
        width: 70,
      },
    ],
  }
}

export const PerformanceRoadmaps = ({
  initialCycle,
}: {
  initialCycle?: ReviewCyclesInterface
}) => {
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const ctx = useRoadmapContext()
  const isNewTable = useIsNewTable()
  const isOnboarding = isOnboardingPath()
  const confirmationPopup = useConfirmationDialog()

  const { data: performanceSettings } = useGetPerformanceSettings()
  const jiraEnabled = roadmapSettings?.jira_epics_enabled
  const permissions = useSelector(selectPermissions)
  const initialFilter = [
    ...getCommonFilters({ reviewCycle: initialCycle }),
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_company',
      nonResettable: true,
    },
  ]

  const table = useTable<RoadmapInterface, Stats>(roadmapsRequests, initialFilter)

  const { renderCount } = useRoadmapRefresh({
    epics: table.data.map(r => r.key),
    onAfterRefresh: () => table.refresh(),
  })
  const cycleID = table.filterBy.find(filter => filter.columnName === 'review_cycle__id')
    ?.filters[0].id

  const { renderSidebar, showRoadmapDetails } = useShowRoadmapDetails({
    changelogState: false,
    jiraEnabled: !!jiraEnabled,
  })

  const filterType = useMemo(() => {
    const typeFilter = table.filterBy.find(
      ({ columnName }) => columnName === 'type',
    )?.filters
    switch (typeFilter?.[0].id) {
      case 'department_roadmap':
        return 'department'
      case 'team_roadmap':
        return 'team'
      default:
        return null
    }
  }, [table.filterBy])

  const canAddRoadmaps = [
    PermissionTypes.CanAddCompanyRoadmap,
    PermissionTypes.CanAddDepartmentRoadmap,
    PermissionTypes.CanAddTeamRoadmap,
  ].some(permission => permissions.includes(permission))

  const canSeeSettings =
    (permissions.includes(PermissionTypes.ViewKPITemplate) &&
      performanceSettings?.enable_kpi_templates) ||
    permissions.includes(PermissionTypes.ViewPerformancePreferences)

  const onDeleteRoadMap = async (itemId: string | number) => {
    confirmationPopup.show({
      showHeader: false,
      body: (
        <H5 color={Token.color.foreground}>
          Are you sure you want to delete this roadmap?
        </H5>
      ),
      noMessage: 'Cancel',
      yesMessage: 'Confirm',
      onConfirm: async () => {
        await roadmapsExtraRequests.deleteItem(itemId)
        table.refresh()
      },
    })
  }

  return (
    <>
      <Table.Widget>
        {!isNewTable ? (
          <Table.Widget.Info>
            <GoalCycleFilter
              isFilterButtonUI={false}
              onFilterChange={table.onFilterChange}
              columnName="review_cycle__id"
              filter={table.filterBy}
              selector={selectorKeys.review_cycles}
            />
            <OverallProgress value={table?.stats?.avg_done} />
          </Table.Widget.Info>
        ) : (
          <Table.Widget.Info>
            <ProgressWithNavigation roadmapFilters={table.filterBy} />
          </Table.Widget.Info>
        )}
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            {/* @TODO DISPLAY BASED ON PERMISSIONS https://revolut.atlassian.net/browse/REVC-6323 
            we don't have permissions at this level to know whether this button should be displayed
            if user do not have permission it will display relevant message on trying to create */}
            <MoreBar.Action
              useIcon="Plus"
              onClick={() =>
                navigateTo(ROUTES.FORMS.ROADMAP.SELECT_LEVEL, {
                  roadmapSelectCycleID: cycleID,
                  isOnboarding,
                })
              }
            >
              <FormattedMessage
                id="performance.roadmaps.create"
                defaultMessage="Create roadmap"
              />
            </MoreBar.Action>
            <GoalsSettingsButton />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        {(canSeeSettings || canAddRoadmaps) && (
          <Table.Widget.Actions>
            <Table.Widget.MoreBar>
              {canAddRoadmaps && (
                <PrimaryAction
                  useIcon="Plus"
                  onClick={() =>
                    navigateTo(ROUTES.FORMS.ROADMAP.SELECT_LEVEL, {
                      roadmapSelectCycleID: cycleID,
                    })
                  }
                >
                  <FormattedMessage
                    id="performance.roadmaps.create"
                    defaultMessage="Add new roadmap"
                  />
                </PrimaryAction>
              )}
              {canSeeSettings && <GoalsSettingsButton />}
            </Table.Widget.MoreBar>
          </Table.Widget.Actions>
        )}
        <Table.Widget.Filters>
          {isNewTable && (
            <GoalCycleFilter
              isFilterButtonUI
              onFilterChange={table.onFilterChange}
              columnName="review_cycle__id"
              filter={table.filterBy}
              selector={selectorKeys.review_cycles}
            />
          )}
          <EntitySelector
            cycleFilter={table.filterBy.find(f => f.columnName === 'review_cycle__id')}
            onFilterChange={table.onFilterChange}
          />
        </Table.Widget.Filters>
        <Table.Widget.Table>
          {!table.data.length && !table.loading ? (
            <EmptyRoadmapsTable cycleId={cycleID} />
          ) : (
            <AdjustableTable<RoadmapInterface>
              name={TableNames.CompanyRoadmap}
              useWindowScroll
              hiddenCells={{
                [teamColumn.idPoint]: filterType !== 'team',
                [departmentColumn.idPoint]: filterType !== 'department',
                action: !canAddRoadmaps,
              }}
              row={getRow(onDeleteRoadMap)}
              onRowClick={showRoadmapDetails}
              {...table}
              dataType={ctx.dataType}
              pendingDataType={ctx.isLoading}
              noDataMessage={ctx.noDataMessage}
              expandableType="chevron"
              fetchChildren={(parentIndexes, id) =>
                table.fetchChildren(parentIndexes, id)
              }
              renderCount={
                table?.stats?.refresh_date_time
                  ? count => renderCount(count, table?.stats?.refresh_date_time!)
                  : undefined
              }
            />
          )}
        </Table.Widget.Table>
      </Table.Widget>
      {renderSidebar({ canEdit: false })}
    </>
  )
}
