import React, { useEffect, useState } from 'react'
import { InputGroup, Portal, TextSkeleton, chain } from '@revolut/ui-kit'
import {
  EngagementResultInterface,
  EngagementResultsComment,
  EngagementResultsScope,
} from '@src/interfaces/engagement'

import SideBar from '@components/SideBar/SideBar'
import {
  getSurveyAdminResultCommentsTableRequests,
  getSurveyPublishedResultCommentsTableRequests,
} from '@src/api/engagement'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { CommentItem } from '@src/apps/People/Engagement/Results/components/Comments/CommentItem'

export const useCommentsSidebar = (
  surveyId: number,
  entity?: { type: EngagementResultsScope; id: number },
) => {
  const [sideBar, setSidebar] = useState<
    | {
        driver: EngagementResultInterface['driver']
        question: EngagementResultInterface['question']
      }
    | undefined
  >()

  return {
    commentsSidebar: sideBar ? (
      <CommentsSideBar
        surveyId={surveyId}
        driver={sideBar?.driver}
        question={sideBar?.question}
        onClose={() => setSidebar(undefined)}
        entity={entity}
      />
    ) : null,
    setSidebar,
  }
}

const useFetchQuestions = (
  surveyId: number,
  driverId?: number,
  questionId?: number,
  entity?: { type: EngagementResultsScope; id: number },
) => {
  const { getItems } = entity
    ? getSurveyPublishedResultCommentsTableRequests({
        scope: entity.type,
        scopeId: entity.id,
      })
    : getSurveyAdminResultCommentsTableRequests()
  const [isLoading, setIsLoading] = useState(false)
  const showPopup = useShowStatusPopup()
  const [data, setData] =
    useState<{ questions: EngagementResultsComment[]; count: number }>()

  const fetchQuestions = async () => {
    setIsLoading(true)
    try {
      const response = await getItems({
        filters: [
          {
            columnName: 'survey__id',
            filters: [{ id: surveyId, name: String(surveyId) }],
            nonResettable: true,
            nonInheritable: true,
          },
          ...(driverId
            ? [
                {
                  columnName: 'driver__id',
                  filters: [{ id: driverId, name: String(driverId) }],
                },
              ]
            : []),
          ...(questionId
            ? [
                {
                  columnName: 'question__id',
                  filters: [{ id: questionId, name: String(questionId) }],
                },
              ]
            : []),
        ],
      })
      setData({
        questions: response.data.results,
        count: response.data.count,
      })
    } catch (err) {
      showPopup({ status: 'error', title: 'Something went wrong' })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchQuestions()
  }, [surveyId, driverId, questionId])

  return { isLoading, data }
}

const CommentsSideBar = ({
  driver,
  question,
  surveyId,
  onClose,
  entity,
}: {
  driver: EngagementResultInterface['driver']
  question: EngagementResultInterface['question']
  surveyId: number
  onClose: VoidFunction
  entity?: { type: EngagementResultsScope; id: number }
}) => {
  const { isLoading, data } = useFetchQuestions(
    surveyId,
    driver?.id,
    question?.id,
    entity,
  )

  return (
    <Portal>
      <SideBar
        variant="wide"
        title={isLoading ? <TextSkeleton size={8} /> : chain('Answers', data?.count)}
        subtitle={`for ${
          question
            ? `question: "${question.question_text}"`
            : `category: "${driver?.name}"`
        }`}
        onClose={onClose}
        isOpen
      >
        <InputGroup>
          {data?.questions.map(item => (
            <CommentItem comment={item} key={item.id} />
          ))}
        </InputGroup>
      </SideBar>
    </Portal>
  )
}
