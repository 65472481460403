import styled from 'styled-components'
import css from '@styled-system/css'
import { Token } from '@revolut/ui-kit'

import { FeatureItemsItemCard } from './FeatureItemsItemCard'

export const FeatureItemsItemCarouselCard = styled(FeatureItemsItemCard)(
  ({ item, swipeable = true }) =>
    css({
      backgroundColor: item.style?.backgroundColor ?? Token.color.greyTone5,
      borderRadius: '20px',
      WebkitTapHighlightColor: 'transparent',
      cursor: swipeable ? 'pointer' : 'default',
      overflow: 'hidden',
    }),
)
