import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CompetencyMatrixInterface, RoleInterface } from '@src/interfaces/roles'
import { API } from '@src/constants/api'
import Icon from '@components/Icon/Icon'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { getDefaultCompetencyMatrix } from '@src/api/roles'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SenioritiesRange from '@src/pages/Forms/RoleForm/CompetencyMatrix/SenioritiesRange'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Flex, Widget, Text, ActionButton, Token } from '@revolut/ui-kit'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import StickyContainerLape from '@src/features/Form/Containers/StickyContainer'
import AutoStepper from '@components/Stepper/AutoStepper'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import { connect } from 'lape'
import { Statuses } from '@src/interfaces'
import { PermissionTypes } from '@src/store/auth/types'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'
import CompetencyMatrixExample from '@src/pages/Forms/RoleForm/CompetencyMatrix/CompetencyMatrixExample'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import CollapsibleStepperSection from '@components/Stepper/CollapsibleStepperSection'
import { useNextRoleStepButtonLink } from '@src/pages/Forms/RoleForm/hooks'
import { useGetSkillsSettings } from '@src/api/settings'
import { getDefaultCompetencyFor } from '@src/features/CompetencyMatrixTable/utils'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

const LockIconWrapper = styled.div`
  display: inline-block;
  color: ${Token.color.greyTone20};
  margin-left: 5px;
`
const CURRENT_STEP = 'competency_matrix'

const CompetencyMatrix = ({
  matrixValidated,
  seniorities,
}: {
  matrixValidated: boolean
  seniorities: SeniorityInterface[]
}) => {
  const { values } = useLapeContext<RoleInterface>()
  const [filteredSeniorities, setFilteredSeniorities] = useState<SeniorityInterface[]>([])
  const [defaultCultureSkills, setDefaultCultureSkills] = useState<
    CompetencyMatrixInterface[]
  >(values.culture_competency_matrix || [])
  const nextLink = useNextRoleStepButtonLink(CURRENT_STEP)
  const { data: skillsSettings } = useGetSkillsSettings()

  const canEdit =
    !values.id ||
    [PermissionTypes.AddRoles, PermissionTypes.ChangeRoles].some(p =>
      values.field_options?.permissions?.includes(p),
    )

  let competencyMatrix = values.functional_competency_matrix

  if (!competencyMatrix?.length) {
    const competencies = filteredSeniorities.map(seniority => ({
      seniority_id: seniority.id,
      seniority_name: seniority.name,
      seniority_level: seniority.level,
      competency_level: null,
    }))

    competencyMatrix = [
      {
        skill: {
          id: null,
          name: null,
        },
        competencies,
      },
    ]
  }

  useEffect(() => {
    if (values.status === 'draft') {
      workspaceLocalStorage.removeItem(pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {}))
    }
  }, [])

  useEffect(() => {
    if (seniorities.length) {
      // it's needed for old roles those don't have seniority_level
      values.functional_competency_matrix = competencyMatrix.map(item => ({
        ...item,
        competencies: item.competencies?.map(competency => {
          const seniorityLevel = seniorities.find(
            seniority => seniority.id === competency.seniority_id,
          )?.level

          return { ...competency, seniority_level: seniorityLevel }
        }),
      }))
    }
  }, [seniorities])

  if (values.function && (!values.seniority_min || !values.seniority_max)) {
    values.seniority_min = seniorities[0]
    values.seniority_max = seniorities[seniorities.length - 1]
  }

  const fetchDefaultMatrix = async () => {
    const response = await getDefaultCompetencyMatrix()

    if (Array.isArray(response.data?.culture_matrix)) {
      setDefaultCultureSkills(response.data.culture_matrix)
    }
  }

  useEffect(() => {
    if (!values.id) {
      fetchDefaultMatrix()
    }
  }, [])

  const onChangeMatrix = (data?: CompetencyMatrixInterface[]) => {
    if (!data) {
      return
    }

    values.functional_competency_matrix = data
  }

  const onSeniorityRangeIncreased = (seniority: SeniorityInterface) => {
    values.functional_competency_matrix = values.functional_competency_matrix.map(
      ({ skill, competencies }) => {
        if (!competencies) {
          return { skill, competencies }
        }

        const lastCompetency = competencies.reduce((lastFilled, current) => {
          return current.competency_level ? current : lastFilled
        }, competencies[0])
        const lastSeniority = lastCompetency.seniority_level || 0

        if (seniority.level < lastSeniority) {
          return { skill, competencies }
        }

        const resultCompetencies = competencies
          .filter(({ competency_level, seniority_level }) =>
            seniority_level && seniority_level >= lastSeniority
              ? !!competency_level
              : true,
          )
          .concat(
            seniorities
              .filter(({ level }) => level > lastSeniority && level <= seniority.level)
              .map(s =>
                getDefaultCompetencyFor(
                  s,
                  (lastCompetency.competency_level && {
                    min: lastCompetency.competency_level,
                  }) ||
                    undefined,
                ),
              ),
          )

        return {
          skill,
          competencies: resultCompetencies,
        }
      },
    )
  }

  const onSeniorityRangeDescreased = (seniority: SeniorityInterface) => {
    values.functional_competency_matrix = values.functional_competency_matrix.map(
      ({ skill, competencies }) => {
        if (!competencies) {
          return { skill, competencies }
        }

        const firstCompetency = competencies[0]
        const firstSeniority = firstCompetency.seniority_level || 0

        if (seniority.level > firstSeniority) {
          return { skill, competencies }
        }

        const resultCompetencies = [
          ...seniorities
            .filter(({ level }) => level < firstSeniority)
            .map(s =>
              getDefaultCompetencyFor(
                s,
                (firstCompetency.competency_level && {
                  max: firstCompetency.competency_level,
                }) ||
                  undefined,
              ),
            ),
          ...competencies,
        ]

        return {
          skill,
          competencies: resultCompetencies,
        }
      },
    )
  }

  const addSkill = () => {
    const competencies = filteredSeniorities.map(seniority =>
      getDefaultCompetencyFor(seniority),
    )

    values.functional_competency_matrix = [
      ...(values.functional_competency_matrix || []),
      {
        skill: {
          id: null,
          name: null,
        },
        competencies,
      },
    ]
  }

  return (
    <AutoStepper pb="100px">
      <Flex flexDirection="column" alignItems="flex-start">
        <AutoStepperTitle
          title="Functional skills"
          subtitle="You can add multiple skills but should use no more than 5 skills for this role."
        />
        <SenioritiesRange
          disabled={!canEdit || !seniorities.length}
          seniorities={seniorities}
          seniorityMaxValue={values.seniority_max}
          seniorityMinValue={values.seniority_min}
          onChangeMax={val => {
            values.seniority_max = val
            onSeniorityRangeIncreased(val)
          }}
          onChangeMin={val => {
            values.seniority_min = val
            onSeniorityRangeDescreased(val)
          }}
          onFilter={(minIndex, maxIndex) => {
            setFilteredSeniorities(seniorities.slice(minIndex, maxIndex + 1))
          }}
        />
        <Widget p="s-16">
          <Flex flexDirection="column" display="flex" alignItems="flex-start">
            <CompetencyMatrixTable
              competencyMatrices={[
                {
                  children: competencyMatrix,
                  sectionTitle: 'Functional skills',
                  staticSkill: false,
                  onChange: onChangeMatrix,
                },
              ]}
              minSeniority={values.seniority_min}
              maxSeniority={values.seniority_max}
              isAdjustable={false}
              readOnly={!canEdit}
            />
            <Text color={Token.color.greyTone50} mt="s-12">
              Skills and ratings adjusted here will be true for all specialisations within
              this role.
            </Text>
            {canEdit && (
              <ActionButton mt="s-24" onClick={addSkill} disabled={!seniorities.length}>
                Add skill
              </ActionButton>
            )}
          </Flex>
        </Widget>
      </Flex>
      <CollapsibleStepperSection title="Competency matrix examples">
        <Flex flexDirection="column" alignItems="flex-start">
          <CompetencyMatrixExample
            minSeniority={values.seniority_min}
            maxSeniority={values.seniority_max}
            seniorities={seniorities}
          />
        </Flex>
      </CollapsibleStepperSection>
      {!skillsSettings?.behaviours_assessment_enabled && (
        <CollapsibleStepperSection
          title={
            <>
              Culture fit skills
              <LockIconWrapper>
                <Icon type="Lock" size="tiny" />
              </LockIconWrapper>
            </>
          }
          subtitle="Culture fit competency matrix is defined at company level. You cannot make any change here."
        >
          <Flex flexDirection="column" alignItems="flex-start">
            <Widget p="s-16">
              <CompetencyMatrixTable
                competencyMatrices={[
                  {
                    children: defaultCultureSkills,
                    sectionTitle: 'Culture fit',
                    disabled: true,
                  },
                ]}
                minSeniority={values.seniority_min}
                maxSeniority={values.seniority_max}
                isAdjustable={false}
              />
            </Widget>
          </Flex>
        </CollapsibleStepperSection>
      )}
      <StickyContainerLape sticky isCenter maxWidth={624}>
        <>
          {values.status === Statuses.draft && (
            <RoleSaveDraftButton
              title="role"
              pathInLocalStorage={pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {})}
              pathAfterSave={ROUTES.FORMS.ROLE.COMPETENCY_MATRIX}
              isNew
              notification={{
                updateMsg: 'Role draft successfully updated.',
                createMsg: 'Role draft successfully created.',
              }}
            />
          )}
          <ContinueRoleButton
            api={API.ROLES}
            type="role"
            step={CURRENT_STEP}
            to={nextLink}
            disabled={!matrixValidated}
          />
        </>
      </StickyContainerLape>
    </AutoStepper>
  )
}

export default connect(CompetencyMatrix)
