import { Box, Token, VStack, ThemeProvider, Badge, Flex } from '@revolut/ui-kit'
import styled from 'styled-components'

import React from 'react'
import { LinkArrowRound } from '@src/pages/Landing/components/LinkArrowRound'
import { css } from '@styled-system/css'
import { Text } from '../components/Text'

const PlansBlockItemBase = styled(Box)<{
  hasLink: boolean
  disabled: boolean
}>(({ hasLink, disabled }) => {
  return {
    listStyle: 'none',
    backgroundColor: disabled ? 'transparent' : Token.color.groupedBackground,
    borderRadius: 20,
    color: disabled ? Token.color.greyTone50 : Token.color.foreground,
    minWidth: 0,
    padding: Token.space.s24,
    paddingBottom: hasLink ? 0 : Token.space.s24,
    borderStyle: disabled ? 'solid' : 'none',
    borderWidth: disabled ? 1 : 0,
    borderColor: disabled ? Token.color.greyTone50 : Token.color.foreground,

    [`@media ${Token.media.xxl}`]: {
      padding: Token.space.s32,
      paddingBottom: hasLink ? 0 : Token.space.s32,
    },
  }
})

const PlansBlockItemLink = styled(LinkArrowRound)({
  display: 'grid',
  gridTemplateRows: '1fr auto',
  height: '100%',
  paddingBottom: Token.space.s16,

  [`@media ${Token.media.xxl}`]: {
    paddingBottom: Token.space.s24,
  },
})

const PlansBlockItemContent = styled(VStack)(
  css({
    alignItems: 'flex-start',
    gap: 's-8',
    marginBottom: ['s-4', null, null, 's-8'],
  }),
)

interface PlansBlockItemProps {
  caption?: string | React.ReactNode
  description?: string
  prefix?: string
  badge?: string
  title: string
  link?: string
  disabled?: boolean
}

const PlansBlockItemTitle = styled(Text)({
  hyphens: 'auto',
  wordBreak: 'break-word',
})

export const PlansBlockItem = ({
  caption,
  description,
  prefix,
  title,
  link,
  badge,
  disabled = false,
}: PlansBlockItemProps) => {
  const itemContent = (
    <PlansBlockItemContent height="100%">
      <Flex
        justifyContent="space-between"
        width="100%"
        gap="s-8"
        alignItems={{ all: 'start', md: 'center' }}
        flexDirection={{ all: 'column-reverse', md: 'row' }}
      >
        <VStack gap={{ all: 's-4', xl: 's-8' }}>
          {prefix && <Text variant="h6">{prefix}</Text>}
          <PlansBlockItemTitle use="h3" variant="h6">
            {title}
          </PlansBlockItemTitle>
        </VStack>
        {badge && (
          <Badge useIcon="Lightning" p="s-16" bg="blue" size={16}>
            <Text variant="tiny" pl="s-8">
              {badge}
            </Text>
          </Badge>
        )}
      </Flex>
      {caption && (
        <Text
          use="h4"
          // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
          variant={'subtitle1'}
          marginTop={0}
        >
          {caption}
        </Text>
      )}
      {description && (
        <Text use="p" variant="secondary" color="grey-50">
          {description}
        </Text>
      )}
    </PlansBlockItemContent>
  )

  return (
    <ThemeProvider mode="light">
      <PlansBlockItemBase use="li" hasLink={Boolean(link)} disabled={disabled}>
        {link ? (
          <PlansBlockItemLink to={link} arrowVariant={'default'}>
            {itemContent}
          </PlansBlockItemLink>
        ) : (
          itemContent
        )}
      </PlansBlockItemBase>
    </ThemeProvider>
  )
}
