import { Group } from '@revolut/ui-kit'

import { navigateReplace } from '@src/actions/RouterActions'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'

import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useLocation } from 'react-router-dom'
import { EntityData } from '@src/pages/Forms/DataAnalyticsDashboardForm/DashboardForm'

const Title = () => (
  <FormattedMessage
    id="analyticsDashboardSelect.page.title"
    defaultMessage="Create new dashboard"
  />
)

const Description = () => (
  <FormattedMessage
    id="analyticsDashboardSelect.page.description"
    defaultMessage="Select dashboard type"
  />
)

export const DashboardSelect = () => {
  const location = useLocation<EntityData>()

  return (
    <PageWrapper>
      <PageHeader
        title={<Title />}
        subtitle={<Description />}
        backUrl={pathToUrl(ROUTES.APPS.DATA_ANALYTICS.DASHBOARD)}
      />

      <PageBody>
        <Group>
          <NewSelectionCard
            icon="BarChart"
            title="Metrics dashboard"
            to={pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD.DETAILS)}
            use={InternalLink}
          />
          <NewSelectionCard
            image="https://assets.revolut.com/assets/apps/Looker.png"
            title="Looker dashboard"
            use="button"
            onClick={() => {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS, {}),
                location.state,
              )
            }}
          />
        </Group>
      </PageBody>
    </PageWrapper>
  )
}
