import React from 'react'
import { TableWidget, Token, VStack } from '@revolut/ui-kit'

import { EmployeeInterface } from '@src/interfaces/employees'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import {
  benefitCategoryColumn,
  benefitChangedOnColumn,
  benefitDescriptionColumn,
  benefitEnrolmentMethodColumn,
  benefitGuideColumn,
  benefitNameColumn,
  benefitPackageColumn,
  benefitProviderColumn,
  benefitStatusColumn,
} from '@src/constants/columns/rewards'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  getEmployeeRewardsBenefits,
  getEmployeeRewardsBenefitsStats,
} from '@src/api/rewards'
import Stat from '@components/Stat/Stat'
import { BenefitsInterface, BenefitsStats } from '@src/interfaces/rewards'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { AnonymizedWarning } from '../components/AnonymizedWarning'

const ROW = (employeeId: number): RowInterface<BenefitsInterface> => ({
  linkToForm: ({ status, template_id }) =>
    navigateTo(
      pathToUrl(
        status.id === 'pending_employee'
          ? ROUTES.FORMS.EMPLOYEE_BENEFIT.SUMMARY
          : ROUTES.FORMS.EMPLOYEE_BENEFIT.PREVIEW,
        { employeeId, id: template_id },
      ),
    ),
  cells: [
    {
      ...benefitNameColumn,
      width: 100,
    },
    {
      ...benefitCategoryColumn,
      width: 100,
    },
    {
      ...benefitDescriptionColumn,
      width: 300,
    },
    {
      ...benefitProviderColumn,
      width: 30,
    },
    {
      ...benefitGuideColumn,
      width: 30,
    },
    {
      ...benefitPackageColumn,
      width: 100,
    },
    {
      ...benefitStatusColumn,
      width: 100,
    },
    {
      ...benefitEnrolmentMethodColumn,
      width: 100,
    },
    {
      ...benefitChangedOnColumn,
      width: 130,
    },
  ],
})

interface Props {
  data: EmployeeInterface
}

export const Benefits = ({ data }: Props) => {
  const table = useTable<BenefitsInterface, BenefitsStats>({
    getItems: getEmployeeRewardsBenefits(data.id),
    getStats: getEmployeeRewardsBenefitsStats(data.id),
  })

  return (
    <VStack>
      <AnonymizedWarning employee={data} />
      <TableWidget>
        <TableWidget.Info>
          <Stat label="Benefits available" val={table.stats?.benefits_available} />
          <Stat label="Enrolled" val={table.stats?.enrolled} color={Token.color.green} />
          <Stat label="Pending" val={table.stats?.pending} color={Token.color.orange} />
          <Stat label="Opted out" val={table.stats?.opted_out} color={Token.color.red} />
        </TableWidget.Info>
        <TableWidget.Table>
          <AdjustableTable
            name={TableNames.NewBenefits}
            useWindowScroll
            dataType="Benefits"
            row={ROW(data.id)}
            {...table}
            noDataMessage="Benefits will appear here"
          />
        </TableWidget.Table>
      </TableWidget>
    </VStack>
  )
}
