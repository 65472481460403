import React from 'react'
import { Dropdown, Icon, MoreBar } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { EmployeeButtonProps } from '../types'
import { navigateTo } from '@src/actions/RouterActions'
import { useCanViewOverdueItems } from '@src/pages/EmployeeProfile/Preview/components/Buttons/common'

export const OverdueItems = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  const canView = useCanViewOverdueItems({ data })
  const onClick = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ACTION_ITEMS, {
        id: data.id,
      }),
    )
  }

  if (!canView) {
    return null
  }

  const icon = <Icon name="CalendarDate" size={16} />

  if (isDropdownItem) {
    return (
      <Dropdown.Item use="button" useIcon={icon} onClick={onClick}>
        View overdue items
      </Dropdown.Item>
    )
  }

  return (
    <MoreBar.Action onClick={onClick} useIcon={icon}>
      View overdue items
    </MoreBar.Action>
  )
}
