import React from 'react'
import { Flex } from '@revolut/ui-kit'

import { SORT_DIRECTION } from '@src/interfaces/data'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import Stat from '@components/Stat/Stat'
import {
  departmentGenericNameColumn,
  departmentHeadcountColumn,
  departmentRequisitionsColumn,
} from '@src/constants/columns/department'
import { departmentOwnershipTransfer, getDepartmentOwnership } from '@src/api/ownership'

import {
  OwnershipTransferProvider,
  SmallBanner,
  TransferSelections,
  TransferTable,
  TrasferAllButton,
} from './common'
import { TableWrapper } from '@components/Table/TableWrapper'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
}

const DepartmentsOwnership = ({ data, stats, refreshStats }: Props) => {
  const sortBy = [
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const transferApi = (selections: TransferSelections) =>
    departmentOwnershipTransfer(
      data.id,
      Object.entries(selections).map(([id, selection]) => ({
        id,
        owner: selection.id,
      })),
    ).then(refreshStats)

  return (
    <OwnershipTransferProvider
      getApi={getDepartmentOwnership(data.id)}
      sortByInitial={sortBy}
      defaultOwner={data.line_manager}
      transferApi={transferApi}
    >
      <SmallBanner
        title="Employee scheduled for termination. Assign new department owners"
        hidden={!data.has_termination}
      />

      <TableWrapper mb="s-20">
        <Stat label="Departments" val={stats?.owned_departments_count} mb="s-24" />
        <Flex mb="s-24">
          <TrasferAllButton />
        </Flex>

        <TransferTable
          cells={[
            {
              ...departmentGenericNameColumn,
              title: 'Department name',
              width: 200,
            },
            {
              ...departmentHeadcountColumn,
              width: 100,
            },
            {
              ...departmentRequisitionsColumn,
              width: 100,
              title: 'Requisitions',
            },
          ]}
          noDataMessage="Departments ownership will appear here"
        />
      </TableWrapper>
    </OwnershipTransferProvider>
  )
}

export default DepartmentsOwnership
