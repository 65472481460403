import { Header, getTextStyle, Token, TextStyle } from '@revolut/ui-kit'
import styled from 'styled-components'
import { css } from '@styled-system/css'

export const SideBarHeaderTitle = styled(Header.Title)(
  css({
    ...getTextStyle(TextStyle.H5),
    marginTop: Token.space.n6,
    marginBottom: Token.space.n6,
    color: Token.color.foreground,
  }),
)
