import React, { useEffect, useState } from 'react'
import { InputGroup, MoreBar, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeFileUploader from '@components/Inputs/LapeFields/LapeFileUploader'
import {
  DetectPlaceholdersInterface,
  OfferTemplateInterface,
} from '@src/interfaces/offerTemplates'
import {
  detectPlaceholdersFromFile,
  getOfferTemplateSample,
} from '@src/api/offerTemplates'
import { deleteFile, downloadFile, uploadFile } from '@src/api/files'
import NewPlaceholdersTable from '@src/pages/Forms/OfferTemplate/NewPlaceholdersTable'
import ExistingPlaceholdersTable from '@src/pages/Forms/OfferTemplate/ExistingPlaceholdersTable'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import isEmpty from 'lodash/isEmpty'
import { saveFile } from '@src/utils'
import SettingsButtons from '@src/features/SettingsButtons'
import { Download } from '@revolut/icons'
import set from 'lodash/set'
import OfferTemplatePreview from '@src/pages/Forms/OfferTemplate/OfferTemplatePreview'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { getStringMessageFromError } from '@src/store/notifications/actions'

const General = () => {
  const user = useSelector(selectUser)
  const { values, initialValues, errors, submit, isSubmitting } =
    useLapeContext<OfferTemplateInterface>()
  const [loading, setLoading] = useState(false)
  const [detectedPlaceholders, setDetectedPlaceholders] =
    useState<DetectPlaceholdersInterface>()
  const [openArchivePopup, setOpenArchivePopup] = useState(false)
  const statusPopup = useStatusPopup()

  const detectPlaceholders = async (id: number) => {
    const resp = await detectPlaceholdersFromFile(id)
    setDetectedPlaceholders(resp.data)
  }

  const onUpload = async (file: File | File[] | null) => {
    if (!file) {
      setDetectedPlaceholders(undefined)
      return
    }

    setLoading(true)
    try {
      const respFile = await uploadFile(file as File, 'offer_form_template', true)
      try {
        await detectPlaceholders(respFile.data.id)
        values.template_file = respFile.data
      } catch (e) {
        deleteFile(respFile.data.id)
        delete values.template_file
      }
    } catch (e) {
      set(
        errors,
        'template_file',
        'Could not fetch the placeholders. Try to refresh the page and upload the file again',
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const initialFetchPlaceholders = async () => {
      if (values.template_file?.id) {
        setLoading(true)
        try {
          await detectPlaceholders(values.template_file.id)
        } finally {
          setLoading(false)
        }
      }
    }

    initialFetchPlaceholders()

    if (!values.id && !values.owner) {
      values.owner = {
        id: user.id,
        name: user.display_name,
      }
    }

    if (!values.template_file?.id) {
      delete values.template_file
    }
  }, [])

  const onDownloadSample = async () => {
    const resp = await getOfferTemplateSample()
    const file = resp.data?.[0]

    if (file?.url) {
      saveFile(file.url, file.name || 'sample_template')
    }
  }

  const onArchive = async () => {
    setOpenArchivePopup(false)
    const fallbackStatus = values.status

    try {
      values.status = isArchived ? 'active' : 'archived'
      await submit()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>
            Offer template {isArchived ? 'unarchived' : 'archived'}
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } catch (e) {
      values.status = fallbackStatus
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Something went wrong</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(e)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    }
  }

  const isArchived = values.status === 'archived'

  return (
    <>
      <ConfirmationDialog
        open={openArchivePopup}
        onClose={() => setOpenArchivePopup(false)}
        onConfirm={onArchive}
        onReject={() => setOpenArchivePopup(false)}
        body={`Are you sure you want to ${
          isArchived ? 'unarchive' : 'archive'
        } the offer template?`}
      />
      <PageBody>
        <SettingsButtons pb="s-16">
          {values.id && (
            <MoreBar.Action
              disabled={isSubmitting}
              useIcon={isArchived ? 'Unarchive' : 'Archive'}
              variant={isArchived ? undefined : 'negative'}
              onClick={() => setOpenArchivePopup(true)}
            >
              {isArchived ? 'Unarchive' : 'Archive'}
            </MoreBar.Action>
          )}

          <MoreBar.Action useIcon={Download} onClick={onDownloadSample}>
            Download sample template
          </MoreBar.Action>
          <OfferTemplatePreview
            id={values?.id}
            message="Can't preview a offer template before saving changes"
            preview={values?.template_file?.id === initialValues?.template_file?.id}
          />
        </SettingsButtons>
        <InputGroup>
          <LapeNewInput name="name" label="Template name" required />
          <LapeRadioSelectInput
            name="owner"
            label="Owner"
            selector={selectorKeys.employee}
          />
          <LapeNewTextArea name="description" label="Description" rows={3} />
          <LapeFileUploader
            name="template_file"
            label="Upload docx"
            onAfterChange={onUpload}
            accept={[
              '.docx',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]}
            loading={loading}
            onOpen={file => {
              if ('id' in file) {
                downloadFile(file.id).then(details => {
                  saveFile(details, file.name)
                })
              }
            }}
            required
          />
          {!isEmpty(detectedPlaceholders?.new_placeholders) && (
            <ActionWidget
              title="New placeholders detected"
              text="We have detected some placeholders that does not currently exist in the platform. Kindly create and define these to proceed."
              data-testid="placeholders-detected"
            />
          )}
        </InputGroup>
        {!isEmpty(detectedPlaceholders?.new_placeholders) && (
          <NewPlaceholdersTable data={detectedPlaceholders!.new_placeholders} />
        )}
        {!isEmpty(detectedPlaceholders?.known_placeholders) && (
          <>
            <ExistingPlaceholdersTable
              data={detectedPlaceholders!.known_placeholders}
              isCustom
            />
            <ExistingPlaceholdersTable data={detectedPlaceholders!.known_placeholders} />
          </>
        )}
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup<OfferTemplateInterface>
          successText="Offer template saved successfully"
          onAfterSubmit={() => {
            navigateReplace(pathToUrl(ROUTES.FORMS.OFFER_FORMS.OFFER_TEMPLATES))
          }}
          useValidator
          hideWhenNoChanges={false}
        />
      </PageActions>
    </>
  )
}

export default connect(General)
