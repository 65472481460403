import { ROUTES } from '@src/constants/routes'
import { GlobalSearchTypes } from '@src/interfaces/globalSearch'
import { pathToUrl } from '@src/utils/router'

export const getGlobalSearchItemFormUrl = (type: GlobalSearchTypes, id?: number) => {
  switch (type) {
    case 'employee':
      return pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id })
    case 'requisition':
      return pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id })
    case 'team':
      return pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id })
    case 'department':
      return pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, { id })
    case 'company':
      return pathToUrl(ROUTES.FORMS.COMPANY.KPI.GOALS, { id })
    case 'function':
      return pathToUrl(ROUTES.FORMS.FUNCTION.KPI, { id })
    case 'role':
      return pathToUrl(ROUTES.FORMS.ROLE.KPI, { id })
    case 'specialisation':
      return pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, { id })
    case 'skill':
      return pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, { id })
    case 'team_kpi':
    case 'department_kpi':
    case 'employee_kpi':
    case 'company_kpi':
      return pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id })
    case 'company_goal':
      return pathToUrl(ROUTES.FORMS.COMPANY_GOALS.PREVIEW, { id })
    default:
      return undefined
  }
}
