import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import pluralize from 'pluralize'
import {
  ActionButton,
  Avatar,
  Button,
  Item,
  ItemSkeleton,
  Header,
  Flex,
  List,
  Popup,
  Progress,
  Text,
  TextSkeleton,
  Token,
  VStack,
  chain,
} from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import {
  createOnboarding,
  getOnboardingList,
  useOnboardingStatsData,
} from '@src/api/onboardingEmployeesV2'
import { useGetEmployeeOnboardingSettings } from '@src/api/settings'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useTable } from '@components/Table/hooks'
import { IdAndName } from '@src/interfaces'
import { EmployeeInterface } from '@src/interfaces/employees'
import { OnboardingInterface, OnboardingStatus } from '@src/interfaces/onboardingV2'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import {
  calculatePercentageBetweenDates,
  formatDate,
  formatDateTime,
  formatDateDistance,
} from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface Props {
  data: EmployeeInterface
}

export const OnboardingWarningV2 = ({ data }: Props) => {
  const showStatusPopup = useShowStatusPopup()
  const permissions = useSelector(selectPermissions)
  const { data: onboardingSettings } = useGetEmployeeOnboardingSettings()
  const [isPending, setIsPending] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)
  const [template, setTemplate] = useState<IdAndName | null>(null)

  const hasHRManagerPermissions = permissions.includes(
    PermissionTypes.HRManagerPermissions,
  )
  const isExternalEmployeeOnboardingDisabled =
    data.employee_type === 'external' &&
    !onboardingSettings?.enable_external_employees_onboarding

  const isHidden = !hasHRManagerPermissions || isExternalEmployeeOnboardingDisabled

  const initialFilters = [
    {
      columnName: 'employee_id',
      filters: [
        {
          id: data.id,
          name: String(data.id),
        },
      ],
      nonResettable: true,
    },
  ]

  const table = useTable<OnboardingInterface>(
    {
      getItems: getOnboardingList,
    },
    initialFilters,
    undefined,
    { disable: isHidden },
  )

  const notStartedOnboarding = table.data.filter(
    onboarding => onboarding.status.id === OnboardingStatus.not_started,
  )?.[0]
  const inProgressOnboarding = table.data.filter(
    onboarding => onboarding.status.id === OnboardingStatus.in_progress,
  )?.[0]
  const isCompletedOnboarding = table.data.filter(
    onboarding => onboarding.status.id === OnboardingStatus.completed,
  )?.[0]

  const { data: statsData, isLoading: isLoadingStatsData } = useOnboardingStatsData(
    inProgressOnboarding?.id,
  )

  const progressMessage = () => {
    if (statsData) {
      const {
        documents_signature_requests_to_send,
        documents_signature_requests_to_sign,
        documents_upload_requests_to_approve,
        documents_upload_requests_to_upload,
      } = statsData

      const documentsToApprove = documents_upload_requests_to_approve
        ? `${pluralize(
            'document',
            documents_upload_requests_to_approve,
            true,
          )} pending approval`
        : undefined
      const documentsToUpload = documents_upload_requests_to_upload
        ? `${pluralize(
            'document',
            documents_upload_requests_to_upload,
            true,
          )} to be uploaded`
        : undefined

      const documentsToSign = documents_signature_requests_to_sign
        ? `${pluralize(
            'document',
            documents_signature_requests_to_sign,
            true,
          )} pending signature`
        : undefined
      const documentsToSend = documents_signature_requests_to_send
        ? `${pluralize(
            'document',
            documents_signature_requests_to_send,
            true,
          )} pending to be sent`
        : undefined

      const tasksToComplete = statsData.tasks_total - statsData.tasks_completed

      const toShowUploadProgress = !!documentsToApprove || !!documentsToUpload
      const toShowSignProgress = !!documentsToSign || !!documentsToSend
      const toShowProgress =
        toShowUploadProgress || toShowSignProgress || !!tasksToComplete

      return toShowProgress ? (
        <List color={Token.color.greyTone50} mt="s-8" space="s-8" variant="compact">
          {toShowUploadProgress && (
            <List.Item useIcon="Document">
              {chain(documentsToApprove, documentsToUpload)}
            </List.Item>
          )}
          {toShowSignProgress && (
            <List.Item useIcon="Pencil">
              {chain(documentsToSign, documentsToSend)}
            </List.Item>
          )}
          {!!tasksToComplete && (
            <List.Item useIcon="Form">
              {pluralize('task', tasksToComplete, true)} not complete
            </List.Item>
          )}
        </List>
      ) : undefined
    }

    return undefined
  }

  const handleCreate = async () => {
    setIsPending(true)

    try {
      const res = await createOnboarding(data.id, template?.id)

      if (res.data.id) {
        navigateTo(
          pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE_V2.OVERVIEW, {
            employeeId: String(data.id),
            id: data.id,
            onboardingId: res.data.id,
          }),
        )
      }
    } catch (err) {
      showStatusPopup({
        status: 'error',
        title: 'Something went wrong',
        description: getStringMessageFromError(err),
      })
    } finally {
      setIsPending(false)
    }
  }

  if (isHidden) {
    return null
  }

  if (table.loading) {
    return <ItemSkeleton />
  }

  if (notStartedOnboarding) {
    return (
      <Item>
        <Item.Avatar>
          <Avatar useIcon="ArrowThinRight" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{data.first_name} is ready to begin onboarding</Item.Title>
          <Item.Description>
            Current start date: {formatDate(data.joining_date_time)}
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            variant="accent"
            to={pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE_V2.OVERVIEW, {
              id: data.id,
              onboardingId: notStartedOnboarding.id,
            })}
            use={InternalLink}
          >
            Begin onboarding
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }

  if (inProgressOnboarding) {
    return (
      <Item>
        <Item.Avatar>
          <Avatar useIcon="ArrowThinRight" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{data.first_name} is currently going through onboarding</Item.Title>
          <Item.Description>
            {isLoadingStatsData ? <TextSkeleton /> : progressMessage()}
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            variant="accent"
            to={pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE_V2.OVERVIEW, {
              id: data.id,
              onboardingId: inProgressOnboarding.id,
            })}
            use={InternalLink}
          >
            Review progress
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }

  if (isCompletedOnboarding) {
    const progress = calculatePercentageBetweenDates(
      isCompletedOnboarding.update_date_time,
      data.joining_date_time,
    )

    return (
      <Item
        to={pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE_V2.OVERVIEW, {
          id: data.id,
          onboardingId: isCompletedOnboarding.id,
        })}
        variant="disclosure"
        use={InternalLink}
      >
        <Item.Avatar>
          <Avatar useIcon="ArrowThinRight" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Employee is waiting for start-date</Item.Title>
          <Item.Description>
            <VStack gap="s-8">
              <Text>in {formatDateDistance(new Date(data.joining_date_time))}</Text>
              <Progress color={Token.color.success} value={progress} />
              <Flex justifyContent="space-between">
                <Text>Candidate onboarding completed</Text>
                <Text>{formatDateTime(isCompletedOnboarding.update_date_time)}</Text>
              </Flex>
            </VStack>
          </Item.Description>
        </Item.Content>
      </Item>
    )
  }

  return (
    <>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="ArrowThinRight" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Candidate is ready to start onboarding</Item.Title>
          <Item.Description>
            Current start date: {formatDate(data.joining_date_time)}
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton onClick={() => setOpenPopup(true)} variant="accent">
            Start onboarding
          </ActionButton>
        </Item.Side>
      </Item>

      <Popup open={openPopup} onClose={() => setOpenPopup(false)} variant="bottom-sheet">
        <Header variant="bottom-sheet">
          <Header.Title>Onboard {data.full_name}</Header.Title>
        </Header>
        <RadioSelectInput
          label="Onboarding template"
          onChange={option => {
            if (option) {
              setTemplate(option)
            }
          }}
          selector={selectorKeys.employee_onboarding_templates}
          value={template}
        />
        <Popup.Actions horizontal>
          <Button onClick={() => setOpenPopup(false)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreate} pending={isPending}>
            Continue
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}
