import React from 'react'
import { IconButton, Dropdown, useDropdown } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { MainHeaderButton } from './MainHeaderButton'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useGetHelpCenterSettings } from '@src/api/settings'
import { useOpenHelpCenter } from '@components/HelpCenter/hooks'
import bugReportSideState from '@components/HelpCenter/components/BugReportSideState'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { REVOLUT_PEOPLE_DOCUMENTATION } from '@src/constants/externalLinks'
import { Link } from 'react-router-dom'

// TODO: https://revolut.atlassian.net/browse/REVCOR-2756 remove
export const HelpCenter = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const canRaiseTickets = featureFlags?.includes(FeatureFlags.TicketsApplication)
  const { data } = useGetHelpCenterSettings()
  const dropdown = useDropdown()
  const { openHelpCenter } = useOpenHelpCenter()

  if (!data?.help_section_enabled) {
    return null
  }

  const handleOpenBugReport = () => {
    dropdown.getTargetProps().onClose()
    bugReportSideState.open = true
  }

  if (!canRaiseTickets) {
    if (isCommercial) {
      return null
    }

    return (
      <MainHeaderButton>
        <IconButton
          useIcon="QuestionOutline"
          aria-label="Help Center"
          size={24}
          onClick={openHelpCenter}
          color="grey-tone-50"
        />
      </MainHeaderButton>
    )
  }

  return (
    <>
      <MainHeaderButton>
        <IconButton
          useIcon="QuestionOutline"
          aria-label="Help Center"
          size={24}
          color="grey-tone-50"
          {...dropdown.getAnchorProps()}
        />
      </MainHeaderButton>
      <Dropdown {...dropdown.getTargetProps()}>
        <Dropdown.Group>
          {isCommercial ? (
            <Dropdown.Item
              use={Link}
              useIcon="Question"
              href={REVOLUT_PEOPLE_DOCUMENTATION}
              target="_blank"
            >
              Documentation
            </Dropdown.Item>
          ) : (
            <Dropdown.Item use="button" useIcon="Question" onClick={openHelpCenter}>
              Get Help
            </Dropdown.Item>
          )}
          <Dropdown.Item use="button" useIcon="Bug" onClick={handleOpenBugReport}>
            Report a bug
          </Dropdown.Item>
          <Dropdown.Item use={InternalLink} to={ROUTES.TICKETS.ALL} useIcon="Chat">
            View my requests
          </Dropdown.Item>
        </Dropdown.Group>
      </Dropdown>
    </>
  )
}
