import React, { useMemo, useRef, useState } from 'react'
import { connect } from 'lape'
import {
  Avatar,
  Box,
  Color,
  Flex,
  Group,
  Icon,
  IconButton,
  Item,
  Link,
  Spacer,
  Spinner,
  Text,
  Token,
  Tooltip,
  TransitionFade,
  VStack,
  Widget,
  useTooltip,
} from '@revolut/ui-kit'
import DOMPurify from 'dompurify'
import { useGetJobDescription } from '@src/api/jobPosting'
import styled, { css } from 'styled-components'
import { getNormalizedLocations } from '@src/pages/Forms/JobPosting/utils'
import { JobPostingLocationInterface } from '@src/interfaces/jobPosting'
import { pushNotification } from '@src/store/notifications/actions'
import {
  SUCCESS_DEFAULT_DURATION,
  ERROR_DEFAULT_DURATION,
} from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { SidebarJobDescription } from '@src/interfaces/jobDescription'
import JobPreviewActionsButton from './JobPreviewActionsButton'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { Video } from '@components/Video/Video'

const copyHTMLContent = async (element: HTMLElement) => {
  try {
    if (element.textContent) {
      const htmlBlob = new Blob([element.outerHTML], { type: 'text/html' })
      const plainBlob = new Blob([element.textContent], { type: 'text/plain' })
      const clipboardItem = new ClipboardItem({
        'text/html': htmlBlob,
        'text/plain': plainBlob,
      })
      await navigator.clipboard.write([clipboardItem])
      pushNotification({
        value: 'Copied to clipboard',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    }
  } catch {
    pushNotification({
      value: 'Failed Copying to clipboard',
      duration: ERROR_DEFAULT_DURATION,
      type: NotificationTypes.error,
    })
  }
}

interface JobDescriptionProps {
  id?: number
  header?: React.ReactNode
  locations?: JobPostingLocationInterface[]
  employmentType?: string
}

const sectionCss = css`
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${Token.color.foreground};
  }

  p {
    margin: 16px 0;
  }

  ol,
  ul {
    padding: 0 0 0 20px;
    margin: 0;
  }
`

const CopyBreak = styled.br`
  display: none;
`

type DetailsProps = {
  icon?: React.ReactNode
  contents: string[]
  title?: string
}

const Details = ({ icon, contents, title }: DetailsProps) => {
  return (
    <Text variant="caption">
      <Box color={Token.color.greyTone50}>
        {icon && (
          <Box
            use="span"
            display="inline-block"
            style={{
              verticalAlign: 'text-top',
            }}
            mr="s-8"
          >
            {icon}
          </Box>
        )}
        {title && (
          <Text use="b" variant="caption">
            {title}:{' '}
          </Text>
        )}
        <Text variant="caption">{contents.join(' | ')}</Text>
      </Box>
    </Text>
  )
}

type SectionProps = {
  fontFamily: string
  section: SidebarJobDescription
}

const Section = ({ fontFamily, section }: SectionProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const tooltip = useTooltip()
  const [displayCopy, setDisplayCopy] = useState(false)
  const isClosing = (title?: string) => title === 'Closing'
  const link = section?.section_url || section.section?.section_url
  const linkTitle = section?.section_url_title || section.section?.section_url_title
  return (
    <Box
      mb="s-24"
      ref={contentRef}
      style={{
        fontFamily,
      }}
      onMouseEnter={() => {
        setDisplayCopy(true)
      }}
      onMouseLeave={() => {
        setDisplayCopy(false)
      }}
    >
      <Flex justifyContent="space-between">
        {section.title && !isClosing(section.title) && (
          <Text use="b" variant="h5" mb="s-16">
            {section.title}
          </Text>
        )}
        <Spacer height="s-24" />
        <TransitionFade in={displayCopy}>
          <Box>
            <IconButton
              color={Color.BLUE}
              onClick={() => {
                if (contentRef.current) {
                  copyHTMLContent(contentRef.current)
                }
              }}
              useIcon="Copy"
              {...tooltip.getAnchorProps()}
            />
            <Tooltip {...tooltip.getTargetProps()}>Copy section content</Tooltip>
          </Box>
        </TransitionFade>
      </Flex>
      <Text
        css={sectionCss}
        use="pre"
        style={{
          fontFamily,
          textAlign: 'justify',
          whiteSpace: 'pre-wrap',
        }}
        variant="caption"
        color={Token.color.foreground}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(section.content),
        }}
      />
      {!!link && (
        <Link href={link} target="_blank" rel="noopener noreferrer">
          {linkTitle || 'See more'}
        </Link>
      )}
      <CopyBreak />
    </Box>
  )
}

const JobPosting = ({ header, locations, employmentType, id }: JobDescriptionProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const linkedinIntegration = featureFlags?.includes(FeatureFlags.LinkedinIntegration)
  const { data, isLoading } = useGetJobDescription(id)
  const contentRef = useRef<HTMLDivElement>(null)

  const fontFamily = useMemo(() => {
    // this is to get the actual value of Token.font.brand
    // we need the actual value for copy functionality
    return window.getComputedStyle(document.body).getPropertyValue('--rui-font-brand')
  }, [])

  if (isLoading) {
    return (
      <Widget p="s-16">
        <Spinner />
      </Widget>
    )
  }

  const loc = getNormalizedLocations(locations || data?.locations || [])

  return (
    <Group>
      {header ?? (
        <Item>
          <Item.Avatar>
            <Avatar useIcon="Newspaper" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Preview</Item.Title>
          </Item.Content>
          <Item.Side>
            <JobPreviewActionsButton
              onCopyJobPosting={() => {
                if (contentRef.current) {
                  copyHTMLContent(contentRef.current)
                }
              }}
            />
          </Item.Side>
        </Item>
      )}
      <Widget py="s-24" px="s-16" data-testid="job-description">
        <Box
          ref={contentRef}
          style={{
            fontFamily,
          }}
        >
          <Text variant="display3" textAlign="center" display="block">
            {data?.name || '<Role name>'}
          </Text>
          <VStack mt="s-24" gap="s-8" align="center">
            {!!loc.office.length && (
              <Details
                icon={<Icon name="Bank" size={16} />}
                contents={loc.office}
                title="Office"
              />
            )}
            {!!loc.remote.length && (
              <Details
                icon={<Icon name="Laptop" size={16} />}
                contents={loc.remote}
                title="Remote"
              />
            )}
            {!loc.remote.length && !loc.office.length && (
              <Text variant="caption">{`<Locations from opened requisitions for this job posting will be shown here>`}</Text>
            )}
            {linkedinIntegration && employmentType && (
              <Details
                icon={<Icon name="Time" size={16} />}
                contents={[employmentType]}
              />
            )}
          </VStack>
          <CopyBreak />
          <Box pt="s-40">
            {data?.sections?.map(section => (
              <Section key={section.id} section={section} fontFamily={fontFamily} />
            ))}
          </Box>
          {data?.presentation_video_url ? (
            <Box pt="s-40">
              <Video url={data.presentation_video_url} />
            </Box>
          ) : null}
        </Box>
      </Widget>
    </Group>
  )
}

export default connect(JobPosting)
