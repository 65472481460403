import React from 'react'
import { FormError } from '@src/features/Form/LapeForm'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import {
  hiringProcessActionColumn,
  hiringProcessDurationColumn,
  hiringProcessEligibleInterviewsLinkColumn,
  hiringProcessPlaybookColumn,
  hiringProcessScorecardLinkColumn,
  hiringProcessSeniorityColumn,
  hiringProcessSkillsColumn,
  hiringProcessTitleWithLockSpecialisationsColumn,
  hiringProcessTypeColumn,
} from '@src/constants/columns/hiringProcess'
import { useOrdering } from '@components/Table/hooks'
import { dragIconColumn } from '@src/constants/columns/ordering'
import { TableNames } from '@src/constants/table'
import HiringStageDeleteButton from '@src/pages/Forms/RoleForm/HiringProcess/HiringStageDeleteButton'
import { noop } from 'lodash'

const Row = (
  handleDelete?: (data: HiringProcessInterface) => Promise<void>,
  handleOrderChange?: (data: HiringProcessInterface[]) => void,
): RowInterface<HiringProcessInterface> => ({
  cells: [
    handleOrderChange ? dragIconColumn : null,
    {
      ...hiringProcessTitleWithLockSpecialisationsColumn,
      width: 185,
    },
    {
      ...hiringProcessTypeColumn,
      width: 125,
    },
    {
      ...hiringProcessDurationColumn,
      width: 100,
    },
    {
      ...hiringProcessSeniorityColumn,
      width: 106,
    },
    {
      ...hiringProcessSkillsColumn,
      width: 200,
    },
    {
      ...hiringProcessPlaybookColumn,
      width: 90,
    },
    {
      ...hiringProcessScorecardLinkColumn,
      width: 139,
    },
    {
      ...hiringProcessEligibleInterviewsLinkColumn,
      width: 240,
    },
    handleDelete
      ? {
          ...hiringProcessActionColumn,
          width: 100,
          insert: ({ data }: { data: HiringProcessInterface }) => (
            <HiringStageDeleteButton onConfirm={() => handleDelete(data)} />
          ),
        }
      : null,
  ].filter(Boolean),
})

type HiringProcessTableProps = {
  hiringProcessRounds?: HiringProcessInterface[]
  hiringProcessErrors?: (FormError<HiringProcessInterface> | undefined)[]
  loading: boolean
  onDelete?: (data: HiringProcessInterface) => Promise<void>
  onOrderChange?: (data: HiringProcessInterface[]) => void
  onRowClick?: (data: HiringProcessInterface, parentIndexes: number[]) => void
}

export const HiringProcessTable = ({
  hiringProcessRounds,
  hiringProcessErrors,
  loading,
  onDelete,
  onOrderChange,
  onRowClick,
}: HiringProcessTableProps) => {
  const ordering = useOrdering(hiringProcessRounds ?? [], onOrderChange ?? noop)
  return (
    <AdjustableTable<HiringProcessInterface>
      name={TableNames.SpecialisationHiringStages}
      dataType="Hiring stage"
      useWindowScroll
      row={Row(onDelete, onOrderChange)}
      cellErrors={hiringProcessErrors}
      data={hiringProcessRounds ?? []}
      count={hiringProcessRounds?.length || 0}
      orderingMode={!!onOrderChange}
      disabledFiltering
      {...ordering}
      onRowClick={onRowClick}
      noDataMessage="Hiring stages will appear here."
      loading={loading}
    />
  )
}
