import { Token, useIntersectViewport, useMatchMedia } from '@revolut/ui-kit'
import { type RefObject, useEffect } from 'react'

export const BLOCK_TRANSITION_DURATION = 300

export function useBlockStyleTransition(ref: RefObject<HTMLDivElement>) {
  const isMd = useMatchMedia(Token.media.md)

  useIntersectViewport(
    ref,
    isIntersecting => {
      if (ref.current) {
        ref.current.style.opacity = isIntersecting ? '1' : '0'
      }
    },
    isMd ? 0.2 : 0,
  )

  useEffect(() => {
    if (ref.current) {
      ref.current.style.transition = `opacity ${BLOCK_TRANSITION_DURATION}ms ease-in-out`
      ref.current.style.opacity = '1'
    }
  }, [ref])
}
