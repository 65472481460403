import React from 'react'
import { EmployeeInterface, EmployeeOptionInterface } from '@src/interfaces/employees'
import { Grid } from '@components/CommonSC/Grid'
import { Avatar, Box, Text } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { getAvatarUrl } from '@src/utils/employees'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

interface MultipleEmployeesProps {
  employees?: (EmployeeInterface | EmployeeOptionInterface)[]
  maxEmployees?: number
}

export const MultipleEmployees = ({
  employees,
  maxEmployees = 3,
}: MultipleEmployeesProps) => {
  const slicedEmployees = employees?.slice(0, maxEmployees)

  if (!employees?.length) {
    return <span>-</span>
  }

  if (employees.length === 1) {
    const employee = employees[0]
    return (
      <UserWithAvatar
        {...employee}
        status={
          employee.status && typeof employee.status === 'object'
            ? employee.status.id
            : employee.status
        }
      />
    )
  }

  const renderRestEmployee = () => {
    const restEmployee = employees?.slice(maxEmployees)

    return (
      <Box p="s-8">
        {restEmployee?.map(employee => (
          <Text key={employee.id} use="div" whiteSpace="nowrap" color="background">
            {employee.full_name}
          </Text>
        ))}
      </Box>
    )
  }

  return (
    <Grid flow="column" justifyContent="start" alignItems="center">
      {slicedEmployees!.map(({ avatar, full_name, id }, index) => (
        <Tooltip
          key={id}
          placement="bottom"
          body={
            <Box p="s-8">
              <Text use="div" whiteSpace="nowrap" color="background">
                {full_name}
              </Text>
            </Box>
          }
        >
          <Avatar
            use={InternalLink}
            onClick={e => e.stopPropagation()}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
              id,
            })}
            data-testid={`multi_employee_${full_name}`}
            style={{ flexShrink: 0 }}
            key={`multi_employee_${full_name}_${index}`}
            size={24}
            image={getAvatarUrl(avatar)}
          >
            {!avatar ? <Text>{full_name?.[0]}</Text> : undefined}
          </Avatar>
        </Tooltip>
      ))}
      {slicedEmployees!.length < employees?.length && (
        <Tooltip placement="bottom" body={renderRestEmployee()}>
          <Avatar
            bg="grey-tone-20"
            data-testid="multi_employee_rest"
            style={{ flexShrink: 0 }}
            size={24}
          >
            {
              <Text color="background">
                +{employees.length - slicedEmployees!.length}
              </Text>
            }
          </Avatar>
        </Tooltip>
      )}
    </Grid>
  )
}
