import React from 'react'
import { useTable, useTableReturnType } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import {
  FilterByInterface,
  SORT_DIRECTION,
  SortByInterface,
  Stats,
  RowInterface,
} from '@src/interfaces/data'
import { Statuses } from '@src/interfaces'
import Tooltip from '@components/Tooltip/Tooltip'
import IconButton from '@components/ButtonIcon/IconButton'
import {
  kpiCurrentValueColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiPerformanceColumnWithLooker,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
} from '@src/constants/columns/kpi'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import { FeatureFlags } from '@src/store/auth/types'
import { Icon } from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useQuery } from '@src/utils/queryParamsHooks'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { TableNames } from '@src/constants/table'
import { rowHighlight } from '@src/features/KPI'
import { useRenderRefreshKPIsTableNote } from '@src/utils/kpi'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import {
  foreignGoalColumn,
  goalTargetNameColumn,
  targetsTypeColumn,
} from '@src/constants/columns/goals'
import { selectorKeys } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { getCommonFilters } from './common'
import { GoalCycleFilter } from '../components/GoalCycleFilter'
import { targetsTableWithoutChildren } from '@src/api/goals'
import { GoalsSettingsButton } from '../components/GoalsSettingsButton'
import Table from '@src/components/TableV2/Table'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { ProgressWithNavigation } from '../components/ProgressWithNavigation'

export const getKpiFilters = (cycleId?: string): FilterByInterface[] => [
  ...getCommonFilters({ reviewCycle: cycleId ? { id: cycleId } : undefined }),
  {
    filters: [{ name: 'True', id: 'True' }],
    columnName: 'is_company',
    nonResettable: true,
    nonInheritable: true,
  },
]

export const kpiSorting: SortByInterface[] = [
  {
    sortBy: 'weight',
    direction: SORT_DIRECTION.ASC,
    nonResettable: true,
  },
  {
    sortBy: 'has_children',
    direction: SORT_DIRECTION.ASC,
  },
]

export const CompanyTargets = ({
  initialCycle,
}: {
  initialCycle?: ReviewCyclesInterface
}) => {
  const { query } = useQuery()
  const table = useTable<KpiInterface, Stats>(
    targetsTableWithoutChildren,
    getKpiFilters(query.cycle__id || !initialCycle ? undefined : String(initialCycle.id)),
    kpiSorting,
  )

  return (
    <KpiPage
      table={table}
      warnAboutMandatoryKPIs={!!table?.stats?.mandatory_kpi_incomplete}
    />
  )
}

interface StructureProps {
  table: useTableReturnType<KpiInterface, Stats>
  warnAboutMandatoryKPIs: boolean
}

const departmentRow = (allowedLooker: boolean): RowInterface<KpiInterface> => ({
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...goalTargetNameColumn,
      width: 300,
    },
    {
      ...foreignGoalColumn,
      width: 150,
    },
    allowedLooker
      ? { ...kpiPerformanceColumnWithLooker, width: 100 }
      : {
          ...kpiPerformanceColumn,
          width: 100,
        },
    {
      ...kpiInitialValueColumn,
      width: 60,
    },
    {
      ...kpiCurrentValueColumn,
      width: 60,
    },
    {
      ...kpiTargetColumn,
      width: 60,
    },
    {
      ...kpiUnitColumn,
      width: 60,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 130,
    },
    {
      ...targetsTypeColumn,
      width: 90,
    },
  ],
})

const KpiPage = ({ table, warnAboutMandatoryKPIs }: StructureProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const allowedLooker = featureFlags.includes(FeatureFlags.Allowed_Looker)
  const isNewTable = useIsNewTable()

  const canRefreshKpis = true // fixme: define the rule

  const { refreshNote, refreshButton } = useRenderRefreshKPIsTableNote(
    table,
    canRefreshKpis,
  )

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {!isNewTable ? (
          <>
            <GoalCycleFilter
              isFilterButtonUI={false}
              onFilterChange={table.onFilterChange}
              columnName="cycle__id"
              selector={selectorKeys.review_cycles}
              filter={table.filterBy}
            />
            <OverallProgress
              value={table?.stats?.avg_performance}
              tooltip="Overall progress is calculated as the weighted average of all approved KPIs."
            />
            {warnAboutMandatoryKPIs && (
              <Tooltip
                placement="right"
                text="This profile has mandatory KPIs which must have 100% progress by cycle end, otherwise Overall Progress will be set to zero."
              >
                <IconButton>
                  <Icon name="16/Warning" color="red" />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          <ProgressWithNavigation />
        )}
      </Table.Widget.Info>
      {isNewTable && (
        <Table.Widget.Filters>
          <GoalCycleFilter
            isFilterButtonUI
            onFilterChange={table.onFilterChange}
            columnName="cycle__id"
            selector={selectorKeys.review_cycles}
            filter={table.filterBy}
          />
        </Table.Widget.Filters>
      )}
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {refreshButton}
          <GoalsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<KpiInterface>
          expandableType="chevron"
          name={TableNames.CompanyKPI}
          useWindowScroll
          dataType="Targets"
          row={departmentRow(allowedLooker)}
          {...table}
          onRowClick={onKPITableRowClick}
          noDataMessage="Please add your company targets to see them here."
        />
        {refreshNote}
      </Table.Widget.Table>
    </Table.Widget>
  )
}
