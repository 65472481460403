import React from 'react'
import { Tag } from '@revolut/ui-kit'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useGetCompanyPreferences } from '@src/api/settings'

export const CompanyUnit = () => {
  const isCommercial = useIsCommercial()
  const { data: settings } = useGetCompanyPreferences()

  if (isCommercial) {
    return <Tag>{settings?.brand_name || 'Company'}</Tag>
  }

  return <Tag>Revolut</Tag>
}
