import React, { useMemo } from 'react'
import { Flex, Icon, HStack, Text, Widget } from '@revolut/ui-kit'
import { useGetEmployeeEquityTimeSeries } from '@src/api/totalCompensation'
import Tooltip from '@components/Tooltip/Tooltip'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { BarChart } from '../Charts/BarChart'
import { useBarProjectedData } from '../helpers'

type Props = {
  compensationParams: CompensationParams
  id: number
}

export const EquityGranted = ({ compensationParams, id }: Props) => {
  const { currency } = compensationParams
  const filters = currency
    ? [
        {
          filters: [{ id: `${currency}`, name: `${currency}` }],
          columnName: 'currency_iso_code',
        },
      ]
    : []

  const { data, isLoading } = useGetEmployeeEquityTimeSeries(id, filters)
  const projectedDataIdx = useBarProjectedData(data)

  const dataKeys = useMemo(() => {
    if (!data) {
      return []
    }

    const allGrantsCategories = data.flatMap(item => item.grants)
    const uniqueGrantsCategories = [
      ...new Set(allGrantsCategories.map(item => item.category)),
    ]

    return uniqueGrantsCategories.map((category, index) => ({
      id: index,
      label: category,
      value: category,
    }))
  }, [data])

  const chartData = useMemo(() => {
    if (!data) {
      return []
    }

    return data
      .filter(value => value.grants.length)
      .map(value => {
        const grants = value.grants.reduce(
          (o, key) => ({ ...o, [key.category]: key.amount }),
          {},
        )

        return {
          ...value,
          ...grants,
          total: value.grant_total,
          label: '',
          tooltip: '',
        }
      })
  }, [data])

  if (!isLoading && !data?.length) {
    return null
  }

  return (
    <Widget height="55vh" p="s-16" data-testid="granted_equity_section">
      <Flex flexDirection="column" height="100%">
        <HStack gap="s-4">
          <Text variant="emphasis2">Granted equity</Text>
          <Tooltip
            placement="bottom"
            text="The total equity awarded to you, including both vested and unvested shares."
          >
            <Icon name="InfoOutline" size={16} />
          </Tooltip>
        </HStack>

        <BarChart
          data={chartData}
          dataKeys={dataKeys}
          isLoading={isLoading}
          projectedDataIdx={projectedDataIdx}
        />
      </Flex>
    </Widget>
  )
}
