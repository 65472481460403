import { Relative } from '@revolut/ui-kit'
import styled from 'styled-components'

import css from '@styled-system/css'

export const FeatureItemsBlockWrapper = styled(Relative)(
  css({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    overflow: 'hidden',
    minHeight: ['640px', null, null, 'max(640px, 90vh)', null, 'max(864px, 90vh)'],
  }),
)
