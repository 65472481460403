import React from 'react'
import { useTable } from '@src/components/Table/hooks'
import { RowInterface, Stats } from '@src/interfaces/data'
import { playbooksRequests } from '@src/api/playbooks'
import { PlaybookInterface } from '@src/interfaces/playbooks'
import {
  playbookLastUpdatedColumn,
  playbookRatingColumn,
  playbookTitleColumn,
} from '@src/constants/columns/playbook'
import { Color, Text } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { FunctionInterface } from '@src/interfaces/functions'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

interface StructureProps {
  data: FunctionInterface
}

const ROW: RowInterface<PlaybookInterface> = {
  cells: [
    {
      ...playbookTitleColumn,
      width: 740,
    },
    {
      ...playbookLastUpdatedColumn,
      width: 350,
    },
    {
      ...playbookRatingColumn,
      width: 350,
    },
  ],
}

const Playbooks = ({ data }: StructureProps) => {
  const initialFilter = [
    {
      filters: [{ name: data.name, id: data.id }],
      columnName: 'entity_id',
      nonResettable: true,
    },
    {
      filters: [{ name: 'function', id: 'function' }],
      columnName: 'entity_type',
      nonResettable: true,
    },
  ]

  const table = useTable<PlaybookInterface, Stats>(playbooksRequests, initialFilter)

  const openUrl = (rowData: PlaybookInterface) => {
    if (rowData.page_url) {
      window.open(rowData.page_url, '_blank')
    }
  }

  return (
    <TableWrapper>
      <Text color={Color.GREY_TONE_50} mb="s-16">
        Add your Function ID <b>FUNCTION-{data.id}</b> as a label to all Confluence pages
        that your want to display here.
      </Text>
      <AdjustableTable<PlaybookInterface>
        name={TableNames.FunctionPlaybooks}
        useWindowScroll
        row={ROW}
        {...table}
        onRowClick={openUrl}
        noDataMessage="All your playbooks will appear here."
      />
    </TableWrapper>
  )
}

export default Playbooks
