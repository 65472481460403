import React, { useEffect, useState } from 'react'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { specialisationsRequestsNew } from '@src/api/roles'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { Box, Flex, Link as UILink, Spinner } from '@revolut/ui-kit'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@components/Table/AdjustableTable'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { getPrevLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { Link } from 'react-router-dom'
import { Queries } from '@src/constants/api'
import { useQuery } from '@src/utils/queryParamsHooks'
import JobDescription from '@src/pages/Forms/RoleForm/JobDescription'
import LapeForm from '@src/features/Form/LapeForm'
import PreviewStageSidebar from '@src/pages/Forms/RoleForm/Preview/PreviewStageSidebar'
import { HiringProcessRow } from '@src/pages/Forms/SpecialisationForm/Preview/Preview'
import { TableNames } from '@src/constants/table'

type Props = {
  data: RequisitionInterface
}

const InterviewProcess = ({ data }: Props) => {
  const [specialisation, setSpecialisation] = useState<SpecialisationInterface>()
  const { changeQueryParam } = useQuery()

  useEffect(() => {
    specialisationsRequestsNew
      .get({ id: `${data.specialisation.id}` })
      .then(res => setSpecialisation(res.data))
  }, [])

  if (!specialisation) {
    return (
      <Flex py="s-48" justifyContent="center" maxWidth={782}>
        <Spinner />
      </Flex>
    )
  }

  const renderEditLink = (url: string) => {
    return (
      <UILink
        to={getPrevLocationDescriptor(
          pathToUrl(url, {
            id: specialisation.id,
          }),
        )}
        use={Link}
      >
        Edit
      </UILink>
    )
  }

  const handleRowEdit = (process: HiringProcessInterface) => {
    if (process.id) {
      changeQueryParam(Queries.StageId, `${process.id}`)
    }
  }

  return (
    <LapeForm
      onSubmit={() => {
        return Promise.resolve({})
      }}
      initialValues={specialisation}
    >
      <PreviewStageSidebar isSpecialisation />
      <AutoStepper maxWidth={782} pb="100px" pt="s-8">
        <Box>
          <AutoStepperTitle
            title="Hiring process"
            rightContent={renderEditLink(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS)}
          />
          <AdjustableTable<HiringProcessInterface>
            name={TableNames.InterviewProcess}
            dataType="Hiring stage"
            row={HiringProcessRow}
            data={specialisation.hiring_process_rounds}
            count={specialisation.hiring_process_rounds?.length || 0}
            onRowClick={handleRowEdit}
            noDataMessage="Hiring stages will appear here."
          />
        </Box>
        <Box>
          <AutoStepperTitle
            title="Job posting"
            rightContent={renderEditLink(
              ROUTES.FORMS.SPECIALISATIONS.CANDIDATE_EXPECTATIONS,
            )}
          />
          <JobDescription type="specialisation" />
        </Box>
      </AutoStepper>
    </LapeForm>
  )
}

export default InterviewProcess
