import React, { useEffect, useState } from 'react'
import { getRequisitionHires } from '@src/api/requisitions'
import { Cell, chain, Flex, MoreBar } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { useTable } from '@components/Table/hooks'
import { HireInterface, RequisitionInterface } from '@src/interfaces/requisitions'
import { selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import {
  getHireActionColumn,
  hireCandidateProfileColumn,
  hireEmployeeProfileColumn,
  hireNameColumn,
  hireRecruiterColumn,
  hireSeniorityColumn,
  hireSpecialisationColumn,
  hireStartDateColumn,
  hireStatusColumn,
} from '@src/constants/columns/hires'
import MoveHireSideBar from '@src/pages/Forms/RequisitionForm/Hires/MoveHireSideBar'
import { PermissionTypes } from '@src/store/auth/types'
import { TableNames } from '@src/constants/table'

interface Props {
  data: RequisitionInterface
}

const getRow = (
  canMoveHire: boolean,
  canCancelHire: boolean,
  onRefresh: () => void,
  onMoveHire: (rowIndex: number) => void,
): RowInterface<HireInterface> => ({
  cells: [
    {
      ...hireNameColumn,
      width: 220,
    },
    {
      ...hireSpecialisationColumn,
      width: 200,
    },
    {
      ...hireSeniorityColumn,
      width: 120,
    },
    {
      ...hireEmployeeProfileColumn,
      width: 160,
    },
    {
      ...hireCandidateProfileColumn,
      width: 160,
    },
    {
      ...hireRecruiterColumn,
      width: 200,
    },
    {
      ...hireStartDateColumn,
      width: 160,
    },
    {
      ...hireStatusColumn,
      width: 120,
    },
    ...(canMoveHire || canCancelHire
      ? [
          {
            ...getHireActionColumn(canMoveHire, canCancelHire, onRefresh, onMoveHire),
            width: 240,
          },
        ]
      : []),
  ],
})

const Hires = ({ data }: Props) => {
  const user = useSelector(selectUser)

  const [myHires, setMyCandidates] = useState<HireInterface[]>()
  const [onlyMine, setOnlyMine] = useState<boolean>()
  const [currentOpenIndex, setCurrentOpenIndex] = useState<number | null>(null)

  const canMoveHires = !!data.field_options.permissions?.includes(
    PermissionTypes.MoveRequisitionHires,
  )

  const canCancelHire = !!data.field_options.permissions?.includes(
    PermissionTypes.CancelHiresRequisitions,
  )

  const table = useTable<HireInterface>({
    getItems: fetchParams => getRequisitionHires(data.id, fetchParams),
  })

  useEffect(() => {
    if (table.data) {
      setMyCandidates(
        table.data.filter(hire => {
          return hire.candidate?.recruiter?.id === user.id
        }),
      )
    } else {
      setMyCandidates(undefined)
    }
  }, [table.data])

  return (
    <>
      <MoveHireSideBar
        initialHireIndex={currentOpenIndex}
        allHires={table.data}
        requisitionId={data.id}
        isOpen={currentOpenIndex !== null}
        onClose={() => setCurrentOpenIndex(null)}
        onAfterSubmit={() => {
          table.refresh()
          setCurrentOpenIndex(null)
        }}
      />
      <Cell width="100%">
        <Flex flexDirection="column" width="100%">
          <Flex mb="s-24">
            <Stat label="Total Hires" val={table.count} />
          </Flex>
          <Flex mb="s-16">
            <MoreBar>
              <MoreBar.Action
                useIcon={onlyMine ? 'SwitchOn' : 'SwitchOff'}
                onClick={() => setOnlyMine(!onlyMine)}
              >
                {chain('My candidates', myHires?.length)}
              </MoreBar.Action>
            </MoreBar>
          </Flex>
          <Flex style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable<HireInterface>
              name={TableNames.Hires}
              useWindowScroll
              dataType="Hire"
              row={getRow(canMoveHires, canCancelHire, table.refresh, index =>
                setCurrentOpenIndex(index),
              )}
              {...table}
              data={myHires && onlyMine ? myHires : table.data}
              noDataMessage="No hires for this requisition"
            />
          </Flex>
        </Flex>
      </Cell>
    </>
  )
}

export default Hires
