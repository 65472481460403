import {
  VStack,
  Cell,
  Input,
  Checkbox,
  LineClamp,
  Icon,
  HStack,
  IconName,
  Grid,
  IconButton,
  Token,
  DetailsCellSkeleton,
  Button,
  useStatusPopup,
  StatusPopup,
} from '@revolut/ui-kit'
import SideBar from '@src/components/SideBar/SideBar'
import React, { useState, useMemo } from 'react'
import { matchSorter } from 'match-sorter'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InternalDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { useQueriesOptions } from '@src/api/dataAnalytics'
import {
  VisualisationType,
  visualisationTypeIconMap,
} from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/common'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { useDashboardQueriesOptions } from '@src/api/analyticsDashboards'
import uniqBy from 'lodash/uniqBy'
import orderBy from 'lodash/orderBy'

interface MagicLinkInstructionsSidebarProps {
  isOpen: boolean
  onClose: () => void
  onRemove: (id: string) => void
  onAdd: (id: string, isInDashboard: boolean) => void
}

const getIcon = (type?: VisualisationType): IconName => {
  if (type) {
    return visualisationTypeIconMap[type]
  }
  return visualisationTypeIconMap.line
}

export const MetricsSidebar = ({
  isOpen,
  onClose,
  onRemove,
  onAdd,
}: MagicLinkInstructionsSidebarProps) => {
  const { values } = useLapeContext<InternalDashboardInterface>()
  const [querySearch, setQuerySearch] = useState('')
  const filters = [
    {
      filters: [{ id: Statuses.active, name: Statuses.active }],
      columnName: 'status',
    },
  ]
  const queries = useQueriesOptions(filters)
  const extraQueries = useDashboardQueriesOptions(values.id, filters)
  const statusPopup = useStatusPopup()

  const allQueries = useMemo(() => {
    if (!values.id) {
      return orderBy(queries, 'name')
    }
    if (!queries || !extraQueries) {
      return undefined
    }
    return orderBy(uniqBy(queries?.concat(extraQueries), 'id'), 'name')
  }, [values.id, queries, extraQueries])

  const options = useMemo(() => {
    if (!allQueries || querySearch === '') {
      return allQueries
    }

    return matchSorter(allQueries, querySearch, {
      keys: ['name', 'description'],
    })
  }, [querySearch, allQueries])

  const handleClick = (checked: boolean, id: number) => {
    const hasAccessToQuery = queries?.some(q => q.id === id)
    const isInDashboard = !!extraQueries?.some(q => q.id === id)
    if (hasAccessToQuery || checked) {
      checked ? onAdd(String(id), isInDashboard) : onRemove(String(id))
      return
    }

    statusPopup.show(
      <StatusPopup variant="warning">
        <StatusPopup.Title>Are you sure?</StatusPopup.Title>
        <StatusPopup.Description>
          Once the query is removed you will lose access to it!
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button
            variant="secondary"
            onClick={() => {
              statusPopup.hide()
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              checked ? onAdd(String(id), isInDashboard) : onRemove(String(id))
              statusPopup.hide()
            }}
          >
            Confirm
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  return (
    <SideBar
      title="Add metric"
      subtitle="Select queries you want to see on the dashboard"
      isOpen={isOpen}
      onClose={onClose}
      useLayout
    >
      <VStack space="s-16">
        <Input
          label="Search query"
          value={querySearch}
          onChange={e => setQuerySearch(e.currentTarget.value)}
        />
        {allQueries ? (
          <Cell>
            <VStack space="s-16">
              {options?.map(option => (
                <Grid columns="1fr 16px" columnGap="s-16" key={option.id}>
                  <Checkbox
                    onChange={e => handleClick(e.currentTarget.checked, option.id)}
                    checked={
                      values.layout &&
                      values.layout.some(item => item.query.id === String(option.id))
                    }
                  >
                    <Checkbox.Label>
                      <HStack align="center" gap="s-8">
                        <Icon
                          name={
                            option.visualisation_type === 'chart'
                              ? getIcon(option.visualisation?.chartType)
                              : '16/ListBullet'
                          }
                          size={16}
                          color={Token.color.blue}
                        />
                        {option.name}
                      </HStack>
                    </Checkbox.Label>
                    {option.description ? (
                      <Checkbox.Description>
                        <LineClamp max={1}>{option.description}</LineClamp>
                      </Checkbox.Description>
                    ) : null}
                  </Checkbox>
                  <IconButton
                    useIcon="LinkExternal"
                    color={Token.color.blue}
                    size={16}
                    use={InternalLink}
                    target="_blank"
                    to={pathToUrl(ROUTES.FORMS.QUERY.GENERAL, { id: option.id })}
                  />
                </Grid>
              ))}
            </VStack>
          </Cell>
        ) : (
          <DetailsCellSkeleton />
        )}
      </VStack>
    </SideBar>
  )
}
