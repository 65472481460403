import React from 'react'
import {
  jobPostingSettings as jobPostingSettingsRequest,
  useGetJobPostingSettings,
} from '@src/api/settings'
import {
  Avatar,
  Group,
  Item,
  StatusPopup,
  Switch,
  Text,
  VStack,
  useStatusPopup,
} from '@revolut/ui-kit'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { LinkedinIntegrationButton } from '@src/pages/Settings/JobPosting/LinkedinIntegrationButton'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FormattedMessage } from 'react-intl'
import ShowIfCommercial from '@src/components/ShowIfCommercial/ShowIfCommercial'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'

type Props = {
  noTitle?: boolean
}

export const CareersIntegrations = ({ noTitle }: Props) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCareerPagePreferences)
  const { data: jobPostingSettings, refetch } = useGetJobPostingSettings()
  const statusPopup = useStatusPopup()

  return (
    <VStack gap="s-16">
      {!noTitle && <Text variant="h5">Job posting integrations</Text>}
      <Group>
        <ShowIfCommercial>
          {featureFlags.includes(FeatureFlags.LinkedinIntegration) && (
            <LinkedinIntegrationButton />
          )}
        </ShowIfCommercial>
        <Item
          use="label"
          disabled={disableEdit}
          onClick={async e => {
            e.preventDefault()

            try {
              await jobPostingSettingsRequest.update(
                {
                  enable_monster_integration:
                    !jobPostingSettings?.enable_monster_integration,
                },
                // this is how setting endpoints work
                { id: '1' },
              )

              refetch()

              statusPopup.show(
                <StatusPopup variant="success">
                  <StatusPopup.Title>
                    <FormattedMessage
                      id="recruitment.settings.job_postings.monster.success"
                      defaultMessage="Monster integration enabled"
                    />
                  </StatusPopup.Title>
                </StatusPopup>,
              )
            } catch (err) {
              statusPopup.show(
                <StatusPopup variant="success">
                  <StatusPopup.Title>
                    <FormattedMessage
                      id="recruitment.settings.job_postings.monster.fail"
                      defaultMessage="Monster integration failed"
                    />
                  </StatusPopup.Title>
                </StatusPopup>,
              )
            }
          }}
        >
          <Item.Avatar>
            <Avatar size={40} label="M" />
          </Item.Avatar>

          <Item.Content>
            <Item.Title>
              <FormattedMessage
                id="recruitment.settings.job_postings.monster.title"
                defaultMessage="Monster"
              />
            </Item.Title>
            <Item.Description>
              <FormattedMessage
                id="recruitment.settings.job_postings.monster.description"
                defaultMessage="Enable this integration to let all your published job postings appear on the Monster.com job board."
              />
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <Switch checked={!!jobPostingSettings?.enable_monster_integration} />
          </Item.Side>
        </Item>
      </Group>
    </VStack>
  )
}

export const CareersIntegrationsPage = () => (
  <PageWrapper>
    <PageHeader
      title="Job posting integrations"
      backUrl={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.PREVIEW}
    />

    <PageBody>
      <CareersIntegrations noTitle />
    </PageBody>
  </PageWrapper>
)
