import { useIntersectViewport } from '@revolut/ui-kit'
import React, {
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'

import { FEATURE_ITEMS_AUTO_SWITCH_TIMEOUT } from './constants'
import { useBlockStyleTransition } from '@src/pages/Landing/hooks/useBlockStyleTransition'
import { useInterval } from '@src/pages/Landing/hooks/useInterval'
import { FeatureItemContent } from '@src/pages/Landing/components/types'

const useFeatureItemsBlock = (
  items: FeatureItemContent[],
  content: Omit<FeatureItemContent, 'items'>,
) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [prevActiveIndex, setPrevActiveIndex] = useState(0)
  const blockRef = useRef<HTMLDivElement>(null)

  const [inViewport, setInViewport] = useState(false)
  const canAutoPlay = useRef(true)

  useIntersectViewport(blockRef, isIntersecting => setInViewport(isIntersecting), 0.2)

  useBlockStyleTransition(blockRef)

  const updateActiveIndex = useCallback(
    (nextActiveIndex: number, isUserEvent = true) => {
      if (isUserEvent) {
        // user interactions disable carousel autoplay
        canAutoPlay.current = false
      }
      setPrevActiveIndex(activeIndex)
      setActiveIndex(nextActiveIndex)
    },
    [activeIndex],
  )

  useEffect(() => {
    if (!inViewport) {
      canAutoPlay.current = true
    }
  }, [inViewport])

  useInterval(
    () => {
      // autoplay carousel when in viewport and user has not interacted
      updateActiveIndex((activeIndex + 1) % items.length, false)
    },
    inViewport && canAutoPlay.current ? FEATURE_ITEMS_AUTO_SWITCH_TIMEOUT : null,
  )

  return {
    content,
    items,
    autoplay: canAutoPlay.current && inViewport,
    activeItem: items[activeIndex],
    activeIndex,
    prevActiveItem: items[prevActiveIndex],
    prevActiveIndex,
    updateActiveIndex,
    blockRef,
  }
}

const FeatureItemsBlockProviderContext = createContext<
  ReturnType<typeof useFeatureItemsBlock> | undefined
>(undefined)

interface FeatureItemsBlockProviderProps {
  items: FeatureItemContent[]
  content: Omit<FeatureItemContent, 'items'>
}

export const FeatureItemsBlockProvider = ({
  children,
  items,
  content,
}: PropsWithChildren<FeatureItemsBlockProviderProps>) => {
  const value = useFeatureItemsBlock(items, content)

  return (
    <FeatureItemsBlockProviderContext.Provider value={value}>
      {children}
    </FeatureItemsBlockProviderContext.Provider>
  )
}

export const useFeatureItemsBlockContext = () => {
  const value = useContext(FeatureItemsBlockProviderContext)

  if (value === undefined) {
    throw new Error(
      '`useFeatureItemsBlockContext` is accessible in `FeatureItemsBlockProvider` component only',
    )
  }

  return value
}
