import React, { useEffect, useMemo } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Box, chain, Flex, Link, Token } from '@revolut/ui-kit'
import { connect, useLape } from 'lape'

import { FunctionInterface, TalentStatsInterface } from '@src/interfaces/functions'
import { functionsRequestsNew, getFunctionsStats } from '@src/api/functions'
import { ROUTES } from '@src/constants/routes'
import {
  Colored,
  ColoredPercent,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { getTalentStatsQuickSummary } from '@src/pages/Forms/CommonTalentTab/TalentStats'
import { EntityPermissions, FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import Loader from '@components/CommonSC/Loader'
import { pathToUrl } from '@src/utils/router'
import { overallScoreToColor } from '@src/apps/People/Engagement/helpers'

import KPI from './KPI/KPI'
import Roadmap from './Roadmap/Roadmap'
import Roles from './Roles/Roles'
import Settings from './Settings/Settings'
import Hierarchy from './Hierarchy/Hierarchy'
import Playbooks from './Playbooks/Playbooks'
import Requisitions from './Requisitions/Requisitions'
import Specialisations from './Specialisations/Specialisations'
import Talent from './Talent/Talent'
import Budget from './Budget/Budget'

import { Summary } from './Summary/Summary'
import { Engagement } from './Engagement/Engagement'
import { FormErrorGuard } from '@src/features/Form/FormErrorGuard'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import {
  useGetRequisitionSettings,
  useGetRoadmapSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { EntityTypes } from '@src/constants/api'
import { Status } from '@components/CommonSC/General'
import { Statuses } from '@src/interfaces'

const Function = () => {
  const params = useParams<{ id: string; tab: string }>()
  const state = useLape<{
    data?: FunctionInterface
    loading: boolean
    stats?: TalentStatsInterface
    error?: any
  }>({
    data: undefined,
    loading: !!params.id,
    stats: undefined,
    error: undefined,
  })
  const backUrl = ROUTES.ORGANISATION.ROLES.FUNCTIONS
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)
  const permissions = useSelector(selectPermissions)

  const { data: settings } = useGetPerformanceSettings()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: requisitionSettings } = useGetRequisitionSettings()
  const {
    settings: { engagement_enabled },
  } = useGlobalSettings()

  const kpiEnabled = !!settings?.enable_function_role_specialisation_kpis

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [resp, respStats] = await Promise.all([
          functionsRequestsNew.get(params),
          getFunctionsStats(params.id),
        ])
        state.data = resp.data
        state.stats = respStats.data
      } catch (error) {
        state.error = error
      } finally {
        state.loading = false
      }
    }

    if (params.id) {
      fetchData()
    }
  }, [params.id, params.tab])

  const entity = useMemo<OrgEntityInterface | undefined>(() => {
    return state.data
      ? {
          type: EntityTypes.function,
          data: state.data,
        }
      : undefined
  }, [state.data])

  if (state.loading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  if (state.error) {
    return <FormErrorGuard error={state.error} />
  }

  if (!state.data) {
    return (
      <PageWrapper>
        <PageHeader title="New Function" backUrl={backUrl} />
        <Settings />
      </PageWrapper>
    )
  }

  const tabs = [
    {
      title: 'Summary',
      path: ROUTES.FORMS.FUNCTION.SUMMARY,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.SUMMARY, params),
      component: Summary,
      canView: !!state.data.id,
    },
    {
      title: 'KPI',
      path: ROUTES.FORMS.FUNCTION.KPI,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.KPI, params),
      component: KPI,
      canView: kpiEnabled && !!state.data.id && !goalsEnabled,
      quickSummary: <ColoredPercent percent={state.data.kpi_performance_percent * 100} />,
    },
    {
      title: 'Roadmap',
      path: ROUTES.FORMS.FUNCTION.ROADMAP,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.ROADMAP, params),
      quickSummary: (
        <ColoredPercent percent={state.data.roadmap_progress_percent * 100} />
      ),
      component: Roadmap,
      canView: !!state.data.id && !!roadmapSettings?.enabled,
    },
    {
      title: 'Roles',
      path: ROUTES.FORMS.FUNCTION.ROLES,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.ROLES, params),
      quickSummary: <QuickSummaryCount count={state.data.role_count} />,
      component: Roles,
      canView: !!state.data.id,
    },
    {
      title: 'Specialisations',
      path: ROUTES.FORMS.FUNCTION.SPECIALISATIONS,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.SPECIALISATIONS, params),
      quickSummary: <QuickSummaryCount count={state.data.specialisation_count} />,
      component: Specialisations,
      canView: !!state.data.id,
    },
    {
      title: 'Talent',
      path: ROUTES.FORMS.FUNCTION.TALENT.ANY,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.TALENT.GENERAL, params),
      quickSummary: getTalentStatsQuickSummary({
        headcount: state.data?.headcount,
        nips: state.stats?.nips,
      }),
      component: Talent,
      canView: !!state.data && !!state.data.id,
    },
    {
      title: 'Hierarchy',
      quickSummary: state.stats?.missing_employee_count ? (
        <Colored color={Token.color.warning}>
          {state.stats.missing_employee_count}
        </Colored>
      ) : null,
      path: ROUTES.FORMS.FUNCTION.HIERARCHY,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.HIERARCHY, params),
      component: Hierarchy,
      canView: !!state.data.id,
    },
    {
      title: 'Requisitions',
      path: ROUTES.FORMS.FUNCTION.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.REQUISITIONS, params),
      component: Requisitions,
      quickSummary: <QuickSummaryCount count={state.data.requisition_headcount} />,
      canView: !!state.data.id && requisitionSettings?.enabled,
    },
    {
      title: 'Engagement',
      path: ROUTES.FORMS.FUNCTION.ENGAGEMENT.ANY,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.ENGAGEMENT.CATEGORIES, params),
      component: Engagement,
      canView:
        engagement_enabled &&
        !!state.data.id &&
        permissions.includes(PermissionTypes.ViewEngagementTabs),
      quickSummary: (
        <QuickSummaryCount
          show0InGrey
          count={state.data.average_score}
          color={overallScoreToColor(state.data.average_score)}
        />
      ),
    },
    {
      title: 'Playbooks',
      path: ROUTES.FORMS.FUNCTION.PLAYBOOKS,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.PLAYBOOKS, params),
      quickSummary: <QuickSummaryCount count={state.data.playbook_count} />,
      component: Playbooks,
      canView: !!state.data.id && !isCommercial,
    },
    {
      title: 'Compensation',
      path: ROUTES.FORMS.FUNCTION.BUDGET,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.BUDGET, params),
      component: Budget,
      canView: state.data?.field_options?.permissions?.includes(
        EntityPermissions.ViewCompensationReviews,
      ),
    },
  ]

  const filteredTabs = tabs.filter(tab => {
    if (tab.canView === undefined) {
      return true
    }

    return tab.canView
  })

  return (
    <OrgEntityProvider entity={entity}>
      <PageWrapper>
        <PageHeader
          title={chain(
            state.data.name || 'New Function',
            state.data.status.id === Statuses.archived && (
              <Status status={state.data.status.id}>{state.data.status.name}</Status>
            ),
          )}
          subtitle={
            state.data.owner ? (
              <Link
                color="inherit"
                href={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                  id: state.data.owner.id,
                })}
                target="_blank"
              >
                Function owner: {state.data.owner.name}
              </Link>
            ) : undefined
          }
          backUrl={backUrl}
        >
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={filteredTabs} />
          </Box>
        </PageHeader>
        <Flex pt="s-8" flex="1 0" flexDirection="column" width="100%" minHeight={0}>
          <Switch>
            {tabs.map(tab => (
              <Route exact path={tab.path} key={tab.path}>
                <tab.component data={state.data!} stats={state.stats} />
              </Route>
            ))}
          </Switch>
        </Flex>
      </PageWrapper>
    </OrgEntityProvider>
  )
}

export default connect(Function)
