import React from 'react'
import { BookingLinkDefinitionInterface } from '@src/interfaces/bookingLinks'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { Color, Flex, Text, TextButton } from '@revolut/ui-kit'
import { pathToUrlWithBaseUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import GenerateBookingLink from '@src/features/BookingLinks/Recruiter/GenerateBookingLink'

type BookingLinkColumn = ColumnInterface<BookingLinkDefinitionInterface>

export const bookingLinkDefinitionJobPostingTitleColumn: BookingLinkColumn = {
  type: CellTypes.text,
  idPoint: 'job_posting.id',
  dataPoint: 'job_posting.name',
  sortKey: 'job_posting__name',
  filterKey: 'job_posting',
  selectorsKey: selectorKeys.job_postings,
  dynamicHyperlinks: data => {
    const id = data?.job_posting?.id
    if (!id) {
      return null
    }
    const specId = data?.job_posting?.specialisation?.id
    if (specId) {
      return pathToUrlWithBaseUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
        specId,
        id,
      })
    }
    return null
  },
  title: 'Job posting',
}

export const bookingLinkDefinitionInterviewStageColumn: BookingLinkColumn = {
  type: CellTypes.text,
  idPoint: 'specialisation_hiring_stage.id',
  dataPoint: 'specialisation_hiring_stage.title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Interview stage',
}

export const bookingLinkDefinitionSourcerNameColumn: BookingLinkColumn = {
  type: CellTypes.insert,
  idPoint: 'sourcer.id',
  dataPoint: 'sourcer.display_name',
  sortKey: 'sourcer__full_name',
  filterKey: 'sourcer',
  selectorsKey: selectorKeys.employee,
  title: 'Sourcer',
  insert: ({ data }) => (data.sourcer ? <UserWithAvatar {...data.sourcer} /> : '-'),
}

export const bookingLinkDefinitionRecruitersColumn: BookingLinkColumn = {
  type: CellTypes.insert,
  idPoint: 'recruiter.id',
  dataPoint: 'recruiter.display_name',
  sortKey: 'recruiters',
  filterKey: 'recruiters',
  selectorsKey: selectorKeys.employee,
  title: 'Recruiter',
  insert: ({ data }) => {
    const { recruiters } = data
    if (!recruiters) {
      return '-'
    }
    return (
      <Flex gap="s-8">
        {recruiters.map(recruiter => (
          <UserWithAvatar
            key={recruiter.id}
            {...recruiter}
            compact={recruiters.length > 1}
          />
        ))}
      </Flex>
    )
  },
}

export const bookingLinkDefinitionLinkColumn: BookingLinkColumn = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Link',
  insert: ({ data }) => {
    return <GenerateBookingLink bookingLinkId={data.id} />
  },
}

export const bookingLinkDefinitionStatusColumn: BookingLinkColumn = {
  type: CellTypes.insert,
  idPoint: 'is_enabled',
  dataPoint: 'is_enabled',
  sortKey: 'is_enabled',
  filterKey: 'is_enabled',
  selectorsKey: () =>
    Promise.resolve({
      options: [
        { id: 'True', name: 'Active' },
        { id: 'False', name: 'Disabled' },
      ],
    }),
  title: 'Status',
  insert: ({ data }) => {
    const { is_enabled } = data
    return (
      <Text color={is_enabled ? Color.GREEN : Color.RED}>
        {is_enabled ? 'Active' : 'Disabled'}
      </Text>
    )
  },
}

export const bookingLinkDefinitionActionColumn = (
  onEdit?: (bookingLink: BookingLinkDefinitionInterface) => void,
): BookingLinkColumn => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    return (
      <TextButton
        fontWeight={500}
        onClick={() => {
          if (onEdit) {
            onEdit(data)
          }
        }}
        disabled={!onEdit}
      >
        Edit
      </TextButton>
    )
  },
})
