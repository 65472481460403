import React from 'react'
import { MoreBar, Dropdown, Token } from '@revolut/ui-kit'

import Icon from '@components/Icon/Icon'
import { useCanViewCreateTicket } from '../../common'
import { EmployeeButtonProps } from '../../types'
import { useCreateEmployeeTicketAction } from './hooks'
import { CreateTicketPopup } from './CreateTicketPopup'

type CreateTicketActionProps = EmployeeButtonProps & {
  pending: boolean
  onClick: () => void
}
export const CreateTicketAction = ({
  data,
  isDropdownItem,
  pending,
  onClick,
}: CreateTicketActionProps) => {
  const canView = useCanViewCreateTicket({ data })

  if (!canView) {
    return null
  }
  const icon = <Icon type="Jira" size="small" color={Token.color.blue} />

  if (isDropdownItem) {
    return (
      <Dropdown.Item use="button" disabled={pending} useIcon={icon} onClick={onClick}>
        Create EL ticket
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action pending={pending} useIcon={icon} onClick={onClick}>
      Create EL ticket
    </MoreBar.Action>
  )
}

export const CreateTicket = ({ data }: EmployeeButtonProps) => {
  const { pending, onClick, showPopup, setShowPopup, setCreatedTicket, createdTicket } =
    useCreateEmployeeTicketAction({ employee: data })
  return (
    <>
      <CreateTicketAction data={data} pending={pending} onClick={onClick} />
      <CreateTicketPopup
        open={showPopup}
        onClose={() => setShowPopup(false)}
        createdTicket={createdTicket}
        setCreatedTicket={setCreatedTicket}
      />
    </>
  )
}
