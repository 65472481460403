import React from 'react'
import { AspectRatio, useMatchMedia, Token, Image } from '@revolut/ui-kit'
import styled from 'styled-components'

import type { FeatureBlockAlign } from './types'

const FEATURE_IMAGE_MAX_HEIGHT = 600
const FEATURE_IMAGE_MAX_WIDTH = 488
const FEATURE_IMAGE_ASPECT_RATIO = FEATURE_IMAGE_MAX_WIDTH / FEATURE_IMAGE_MAX_HEIGHT

interface FeatureBlockImageProps {
  image: string
  align: FeatureBlockAlign
}

const FeatureBlockImageBase = styled(AspectRatio)({
  gridArea: 'image',
  height: '100%',
  position: 'relative',
  width: '100%',
})

export const FeatureBlockImage = ({ image, align }: FeatureBlockImageProps) => {
  const isLg = useMatchMedia(Token.media.lg)

  return (
    <FeatureBlockImageBase ratio={FEATURE_IMAGE_ASPECT_RATIO}>
      <Image
        alt=""
        src={image}
        sizes="100vw"
        style={{ objectFit: align, objectPosition: isLg ? 'center' : '0 0' }}
      />
    </FeatureBlockImageBase>
  )
}
