import { selectorKeys } from '@src/constants/api'
import { DocumentsCollectionsInterface } from '@src/interfaces/documentsCollections'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'

export const documentsCollectionsName: ColumnInterface<DocumentsCollectionsInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.document_collections,
  title: 'Name',
}

export const documentsCollectionsTemplatesCountColumn: ColumnInterface<DocumentsCollectionsInterface> =
  {
    type: CellTypes.text,
    idPoint: 'document_templates_count',
    dataPoint: 'document_templates_count',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Templates included',
  }

export const documentsCollectionsActionsColumn: ColumnInterface<DocumentsCollectionsInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'id',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  }
