import React from 'react'
import { CounterWidget, VStack, Status, Text, Token, Flex } from '@revolut/ui-kit'
import { QueryRunResultInterface } from '@src/interfaces/dataAnalytics'
import { IdAndName } from '@src/interfaces'

interface CounterProps {
  data?: QueryRunResultInterface
  title?: string
  subtitle?: string
  xValue?: IdAndName
  yValue?: IdAndName[]
}

export const getIcon = (value: number) => {
  if (value > 0) {
    return '10/StocksArrowUp'
  }
  if (value < 0) {
    return '10/StocksArrowDown'
  }

  return undefined
}

export const getColor = (value: number) => {
  if (value > 0) {
    return Token.color.green
  }
  if (value < 0) {
    return Token.color.red
  }

  return undefined
}

export const Counter = ({ data, title, subtitle, xValue, yValue }: CounterProps) => {
  if (!data || data.length === 0) {
    return null
  }

  const percent = yValue ? data[0][yValue[0]?.name] : undefined

  return (
    <Flex alignItems="center" width="100%" height="100%">
      <CounterWidget margin="0 auto">
        <CounterWidget.Column>
          <CounterWidget.Title>{title}</CounterWidget.Title>
          {xValue ? (
            <CounterWidget.Description textAlign="center">
              {data[0][xValue?.name]}
            </CounterWidget.Description>
          ) : null}
          <CounterWidget.Subtitle>
            <VStack align="center">
              {percent ? (
                <Status
                  useIcon={getIcon(percent)}
                  iconSize={10}
                  color={getColor(percent)}
                >
                  {percent}%
                </Status>
              ) : null}
              <Text>{subtitle}</Text>
            </VStack>
          </CounterWidget.Subtitle>
        </CounterWidget.Column>
      </CounterWidget>
    </Flex>
  )
}
