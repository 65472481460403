import React, { useState } from 'react'
import { TalentType } from '@src/interfaces/talent/talent'
import { isDepartmentType, isFunctionType } from './utils'
import { LOCAL_STORAGE } from '@src/constants/api'
import { Action } from '@revolut/ui-kit'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import ActionWidget, {
  ActionWidgetProps,
} from '@src/components/ActionWidget/ActionWidget'

export interface TalentHelpBannerProps extends ActionWidgetProps {
  type: TalentType
}

type BannerContext = {
  message: { title: string; text: string }
  lsKey: string
}

const getContext = (type: TalentType): BannerContext | null => {
  if (isDepartmentType(type)) {
    return {
      lsKey: LOCAL_STORAGE.SHOW_TALENT_PERFORMANCE_HOD_HELP_MESSAGE,
      message: {
        title: 'Note for Head of Department',
        text: 'As a Head of Department you can recommend a grade for any of the employees in your department. Please use the HoD Grade column to input the recommended grade.',
      },
    }
  }

  if (isFunctionType(type)) {
    return {
      lsKey: LOCAL_STORAGE.SHOW_TALENT_PERFORMANCE_HOF_HELP_MESSAGE,
      message: {
        title: 'Note for Head of Function',
        text: 'As a Head of Function you can calibrate the grade for any of the employees in your function. Please use the HoF Grade column to change the grade for an employee. If you do not set a grade in the HoF column, the system calculated grade will become final.',
      },
    }
  }

  if (type === TalentType.Company) {
    return {
      lsKey: LOCAL_STORAGE.SHOW_TALENT_PERFORMANCE_COMPANY_HELP_MESSAGE,
      message: {
        title: 'Company Talent Performance Results',
        text: 'Here we show the performance results for the full company talent pool. ',
      },
    }
  }

  return null
}

export const TalentHelpBanner = ({ type, ...props }: TalentHelpBannerProps) => {
  const ctx = getContext(type)
  const [showHelpMessage, setShowHelpMessage] = useState(
    ctx ? !workspaceLocalStorage.getItem(ctx.lsKey) : false,
  )

  if (!ctx || !showHelpMessage) {
    return null
  }

  const { message, lsKey } = ctx

  return (
    <ActionWidget title={message.title} text={message.text} {...props}>
      <Action
        onClick={() => {
          workspaceLocalStorage.setItem(lsKey, '1')
          setShowHelpMessage(false)
        }}
      >
        Don't show again
      </Action>
    </ActionWidget>
  )
}
