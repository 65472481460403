import React from 'react'
import {
  ActionButton,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Tag,
  Text,
  Token,
} from '@revolut/ui-kit'
import { PublishingStatuses } from '@src/interfaces/jobPosting'
import { useOnPublishClick } from '@src/pages/OnboardingChecklistV2/Jobs/utils'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { PUBLIC_ROUTES, ROUTES } from '@src/constants/routes'
import { Link } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import { generatePositionId } from '@src/pages/Careers/utils'
import { SwitcherButtonsWithSidebar } from '@components/SwitcherButtonsWIthSidebar/SwitcherButtonsWithSidebar'
import { LOCAL_STORAGE } from '@src/constants/api'

type Props = {
  title?: string
  status?: PublishingStatuses
  onRefresh: () => void
  id?: string
  internalId?: number
  positionName?: string
  loading?: boolean
}

export const PreviewBanner = ({
  id,
  internalId,
  title,
  positionName,
  status,
  onRefresh,
  loading,
}: Props) => {
  const { onPublishClick, publishLoading } = useOnPublishClick(onRefresh)

  return (
    <Flex
      bg={Token.color.black}
      height={80}
      px="s-40"
      color={Token.color.white}
      mb="s-16"
      alignItems="center"
      width="100%"
    >
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <HStack gap="s-16" align="center">
          <IconButton
            useIcon="Cross"
            onClick={() => {
              window.close()
            }}
          />
          {loading ? <Skeleton width={200} /> : <Text variant="secondary">{title}</Text>}

          {status && (
            <>
              {status === PublishingStatuses.unpublished ? (
                <Tag variant="status" useIcon="EyeHide">
                  Unpublished
                </Tag>
              ) : (
                <Tag color={Token.color.green} useIcon="Globe">
                  Published
                </Tag>
              )}
            </>
          )}
        </HStack>

        {!!internalId && (
          <HStack gap="s-16" align="center">
            <ActionButton
              variant="white"
              useIcon="Pencil"
              use={Link}
              // @ts-expect-error object works fine here, but UI kit expects string
              to={getLocationDescriptor(
                pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.JOBS_EDIT, {
                  id: internalId,
                }),
              )}
            >
              Edit
            </ActionButton>
            {status === PublishingStatuses.unpublished ? (
              <ActionButton
                variant="accent"
                useIcon="EyeShow"
                pending={publishLoading}
                onClick={() =>
                  onPublishClick([internalId], PublishingStatuses.fully_published)
                }
              >
                Publish
              </ActionButton>
            ) : (
              <ActionButton
                variant="negative"
                useIcon="EyeHide"
                pending={publishLoading}
                onClick={() =>
                  onPublishClick([internalId], PublishingStatuses.unpublished)
                }
              >
                Unpublish
              </ActionButton>
            )}
            {positionName && !!id && (
              <SwitcherButtonsWithSidebar
                currentId={generatePositionId(positionName, id)}
                localStorageKey={LOCAL_STORAGE.JOBS_PREVIEW_TABLE_CONTEXT}
                route={PUBLIC_ROUTES.CAREERS.POSITION}
              />
            )}
          </HStack>
        )}
      </Flex>
    </Flex>
  )
}
