import { specialisationsRequestsNew } from '@src/api/roles'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { useParams } from 'react-router-dom'
import SpecialisationSettings from './General'

const SettingsPage = () => {
  const params = useParams<{ id: string }>()

  const isEditing = params.id !== undefined

  const backUrl = isEditing
    ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
        id: String(params?.id),
      })
    : ROUTES.ORGANISATION.ROLES.SPECIALISATIONS

  const title = isEditing ? 'Edit Specialisation' : 'New Specialisation'

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={backUrl} />
      <Form api={specialisationsRequestsNew}>
        <SpecialisationSettings />
      </Form>
    </PageWrapper>
  )
}

export default SettingsPage
