import React from 'react'
import { ErrorWidget, Relative } from '@revolut/ui-kit'
import { useGetCV } from '@src/api/recruitment/interviews'
import DocumentSkeleton from '@components/Skeletons/DocumentSkeleton'
import { getPreviewSrc } from '@src/utils/files'
import { useReloadCVIframe } from '@src/features/CVPreviewSidebar/hooks'
import { CVFileType } from '@src/features/CVPreviewSidebar/CVPreviewSidebar'

type Props = {
  id?: number | null
  loading?: boolean
  file?: CVFileType
}

const CvPreview = ({ file, id, loading }: Props) => {
  const { data, isLoading: loadingCV } = useGetCV(id)

  const url = data ? data.resume.url : file?.url
  const extension = data ? data.resume.extension : file?.extension
  const src = getPreviewSrc(url, extension)

  const { iframeRef, iframeLoaded } = useReloadCVIframe(src)

  if (loadingCV || loading) {
    return <DocumentSkeleton />
  }

  if (!src) {
    return (
      <ErrorWidget>
        <ErrorWidget.Image src="https://assets.revolut.com/media/business/illustrations-repository/alert@2x.png" />
        <ErrorWidget.Title>No CV found or unable to view it</ErrorWidget.Title>
      </ErrorWidget>
    )
  }

  return (
    <Relative height="100%" width="100%">
      {!iframeLoaded && <DocumentSkeleton data-testid="iframe-skeleton" />}
      <iframe
        src={src}
        width="100%"
        height="100%"
        frameBorder="0"
        title="CV"
        data-testid="cv-iframe"
        ref={iframeRef}
        style={{
          position: 'absolute',
          top: 0,
        }}
      />
    </Relative>
  )
}

export default CvPreview
