import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { RecruitmentGroupsInterface } from '@src/interfaces/recruitmentGroups'

export const recruitmentRoleColumn: ColumnInterface<RecruitmentGroupsInterface> = {
  type: CellTypes.insert,
  idPoint: 'none',
  dataPoint: 'none',
  sortKey: null,
  filterKey: 'specialisations__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Specialisations',
  insert: ({ data }) => {
    if (!data.specialisations) {
      return '-'
    }
    return data.specialisations.reduce((prev, curr) => {
      if (prev !== '') {
        return `${prev}, ${curr.name}`
      }
      return curr.name
    }, '')
  },
}

export const recruitmentTeamOwner: ColumnInterface<RecruitmentGroupsInterface> = {
  type: CellTypes.text,
  idPoint: 'team.team_owner.id',
  dataPoint: 'team.team_owner.full_name',
  sortKey: 'team__team_owner__full_name',
  filterKey: 'team__team_owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Team Owner',
}
