import styled from 'styled-components'
import { Token, Image } from '@revolut/ui-kit'
import React from 'react'

export type FeatureImageBackgroundSize =
  | 'contain'
  | 'cover'
  | 'fill'
  | 'none'
  | 'scale-down'

interface FeatureImageBaseProps {
  image: string
  title?: string
  backgroundSize?: FeatureImageBackgroundSize
  backgroundPosition?: string
  prefersCoverOnMobile?: boolean
}

const StyledImage = styled(Image)<{
  $backgroundSize: FeatureImageBackgroundSize
  $prefersCoverOnMobile?: boolean
}>(({ $backgroundSize, $prefersCoverOnMobile }) => ({
  objectFit: $prefersCoverOnMobile ? 'cover' : $backgroundSize,
  [`@media ${Token.media.md}`]: {
    objectFit: $backgroundSize,
  },
}))

export const FeatureImageBase = ({
  image,
  title,
  backgroundPosition = 'center',
  backgroundSize = 'contain',
  prefersCoverOnMobile,
}: FeatureImageBaseProps) => (
  <StyledImage
    alt={title ?? ''}
    src={image}
    width="100vw"
    sizes="100vw"
    $backgroundSize={backgroundSize}
    $prefersCoverOnMobile={prefersCoverOnMobile}
    style={{ objectPosition: backgroundPosition }}
  />
)
