import React from 'react'
import { Cell, Flex } from '@revolut/ui-kit'

import {
  CompensationReviewInterface,
  TeamCompensationReviewInterface,
} from '@src/interfaces/compensation'
import SettingsButtons from '@src/features/SettingsButtons'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import {
  BudgetDistributionHeader,
  EmptyState,
  ShowInLocalCurrenciesButton,
  useBudgetDistributionContext,
} from '@src/features/BudgetDistribution/BudgetDistribution'
import Stat from '@src/components/Stat/Stat'
import { formatMoney } from '@src/utils/format'
import { budgetDistributionViewRow } from './BudgetDistributionTable'
import { TableNames } from '@src/constants/table'

interface TeamAndFunctionBudgetTableProps {
  navigation?: React.ReactElement
}

const isTeamReview = (
  review?: CompensationReviewInterface,
): review is TeamCompensationReviewInterface =>
  review != null && 'allocated_bonus_budget' in review

export const TeamAndFunctionBudgetTable = ({
  navigation,
}: TeamAndFunctionBudgetTableProps) => {
  const { table, selectedCompensationReview, isEmpty } = useBudgetDistributionContext()

  return (
    <>
      <Cell>
        <Flex flexDirection="column" width="100%" id="comment-portal-target">
          {navigation}
          <BudgetDistributionHeader
            stats={
              <>
                <Stat
                  label="Allocated salaries spend"
                  val={
                    isTeamReview(selectedCompensationReview)
                      ? formatMoney(
                          selectedCompensationReview.allocated_salary_budget,
                          selectedCompensationReview.allocated_salary_budget_currency
                            .iso_code,
                        )
                      : undefined
                  }
                />
                <Stat
                  label="Allocated bonus spend"
                  val={
                    isTeamReview(selectedCompensationReview)
                      ? formatMoney(
                          selectedCompensationReview.allocated_bonus_budget,
                          selectedCompensationReview.allocated_bonus_budget_currency
                            .iso_code,
                        )
                      : undefined
                  }
                />
              </>
            }
          />

          <SettingsButtons mb="s-16">
            <ShowInLocalCurrenciesButton />
          </SettingsButtons>

          <Flex style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable
              name={TableNames.TeamAndFunctionBudget}
              {...table}
              loading={isEmpty ? false : table.loading}
              useWindowScroll
              row={budgetDistributionViewRow}
              emptyState={<EmptyState />}
            />
          </Flex>
        </Flex>
      </Cell>
    </>
  )
}
