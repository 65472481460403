import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { isAfter } from 'date-fns'
import { ActionButton, Flex, Group, MoreBar, Text, VStack } from '@revolut/ui-kit'

import { selectUser } from '@src/store/auth/selectors'
import { EmployeeInterface, TerminationInterface } from '@src/interfaces/employees'
import {
  formatDateWithFallback,
  hasOffboardingV2Enabled,
  optionalField,
} from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { terminationRequests, terminationRequestsV2 } from '@src/api/employees'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { canEditSection } from '@src/pages/EmployeeProfile/Preview/common'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { formatDate } from '@src/utils/format'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { getTimeFromString } from '@src/utils/timezones'
import Tooltip from '@components/Tooltip/Tooltip'
import DocumentSidebar from '@src/features/DocumentSidebar/DocumentSidebar'

type Props = {
  data: EmployeeInterface
}
export const TerminationWidget = ({ data }: Props) => {
  const user = useSelector(selectUser)
  const isInternal = data.employee_type === 'internal'

  const [selectedDocumentId, setSelectedDocumentId] = useState<number>()
  const [documentSidebarOpen, setDocumentSidebarOpen] = useState(false)

  const viewingOwnProfile = user.id === data.id

  return (
    <>
      {hasOffboardingV2Enabled(data) ? (
        <FormPreview api={terminationRequestsV2.get}>
          <FormPreview.Context<TerminationInterface>
            insert={termination => {
              if (!termination?.termination_date_time) {
                return null
              }

              let title = isAfter(new Date(termination.termination_date_time), new Date())
                ? 'Employee is scheduled for termination'
                : 'Employee was terminated'

              if (viewingOwnProfile) {
                title = 'You have been scheduled for termination'
              }

              return isInternal ? (
                <ActionWidget
                  title={title}
                  text={
                    <VStack>
                      <Text>
                        Last day in office:{' '}
                        {formatDate(termination.termination_date_time)}
                      </Text>
                      {termination.last_day_of_contract ? (
                        <Text>
                          Last day of contract:{' '}
                          {formatDate(termination.last_day_of_contract)}
                        </Text>
                      ) : null}
                    </VStack>
                  }
                >
                  <MoreBar>
                    {canEditSection(termination) && (
                      <MoreBar.Action
                        use={InternalLink}
                        useIcon="Pencil"
                        to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION_V2, {
                          id: String(data.id),
                        })}
                      >
                        Edit details
                      </MoreBar.Action>
                    )}
                    {!viewingOwnProfile && (
                      <MoreBar.Action
                        use={InternalLink}
                        useIcon="ArrowSend"
                        to={pathToUrl(ROUTES.FORMS.OFFBOARDING.DASHBOARD, {
                          employeeId: String(data.id),
                        })}
                      >
                        Open offboarding dashboard
                      </MoreBar.Action>
                    )}
                  </MoreBar>
                </ActionWidget>
              ) : (
                <ActionWidget title={title}>
                  {canEditSection(termination) && (
                    <ActionButton
                      mt="s-8"
                      use={InternalLink}
                      useIcon="Pencil"
                      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION_V2, {
                        id: String(data.id),
                      })}
                    >
                      Edit details
                    </ActionButton>
                  )}
                </ActionWidget>
              )
            }}
          />
          {isInternal ? null : (
            <Group>
              <FormPreview.Item<TerminationInterface>
                title="Termination date"
                insert={termination =>
                  termination.termination_date_time
                    ? `${formatDate(
                        termination.termination_date_time,
                      )}, ${getTimeFromString(termination.termination_date_time)}, (${
                        termination.termination_date_timezone?.name
                      })`
                    : '-'
                }
              />
              <FormPreview.Item title="Termination type" field="termination_type.name" />
            </Group>
          )}
        </FormPreview>
      ) : (
        <FormPreview api={terminationRequests.get}>
          <FormPreview.Context<TerminationInterface>
            insert={termination => {
              if (!termination?.termination_date_time) {
                return null
              }

              const title = isAfter(
                new Date(termination.termination_date_time),
                new Date(),
              )
                ? 'Employee is scheduled for termination'
                : 'Employee was terminated'

              return (
                <ActionWidget title={title} mb="s-16">
                  {canEditSection(termination) && (
                    <ActionButton
                      mt="s-8"
                      use={InternalLink}
                      useIcon="Pencil"
                      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION, {
                        id: String(data.id),
                      })}
                    >
                      Edit details
                    </ActionButton>
                  )}
                </ActionWidget>
              )
            }}
          />
          <Group>
            <FormPreview.Item<TerminationInterface>
              title="Last day in office"
              insert={termination =>
                termination.termination_date_time
                  ? `${formatDate(
                      termination.termination_date_time,
                    )}, ${getTimeFromString(termination.termination_date_time)}, (${
                      termination.termination_date_timezone?.name
                    })`
                  : '-'
              }
            />
            <FormPreview.Item<TerminationInterface>
              title="Last day of contract"
              insert={termination =>
                formatDateWithFallback(termination.last_day_of_contract, '-')
              }
            />
            <FormPreview.Item title="Termination type" field="termination_type.name" />
            <FormPreview.Item
              title="Reason for termination"
              field="termination_reason.name"
            />
            {isInternal && (
              <>
                <FormPreview.Item<TerminationInterface>
                  title="Pay in lieu of notice (PILON)"
                  insert={termination => (
                    <>
                      {optionalField(termination.pilon_time_units_number, ' ')}
                      {optionalField(termination.pilon_time_unit?.name, ', ')}
                      {optionalField(termination.pilon_salary_type?.name)}
                    </>
                  )}
                />
                <FormPreview.Item<TerminationInterface>
                  title="Lump sum / Severance pay"
                  insert={termination => (
                    <>
                      {optionalField(termination.lump_sum_time_units_number, ' ')}
                      {optionalField(termination.lump_sum_time_unit?.name, ', ')}
                      {optionalField(termination.lump_sum_salary_type?.name, ', ')}
                      {optionalField(termination.lump_sum_payment_type?.name)}
                    </>
                  )}
                />
                <FormPreview.Item<TerminationInterface>
                  title="Additional payments"
                  insert={termination => (
                    <>
                      {optionalField(termination.additional_payment_amount, ' ')}
                      {optionalField(
                        termination.additional_payment_currency?.iso_code,
                        ', ',
                      )}
                      {optionalField(termination.additional_payment_type?.name)}
                    </>
                  )}
                />
                <FormPreview.Item<TerminationInterface>
                  title="Documents"
                  insert={termination =>
                    termination.uploaded_documents ? (
                      <Flex>
                        {termination.uploaded_documents.map(doc => (
                          <Tooltip text={doc.name} placement="bottom" key={doc.id}>
                            <ActionButton
                              onClick={() => {
                                setDocumentSidebarOpen(true)
                                setSelectedDocumentId(Number(doc.id))
                              }}
                              ml="s-16"
                              useIcon="Document"
                            />
                          </Tooltip>
                        ))}
                      </Flex>
                    ) : (
                      '-'
                    )
                  }
                />
              </>
            )}
          </Group>
        </FormPreview>
      )}
      {selectedDocumentId && (
        <DocumentSidebar
          employeeId={data.id}
          id={selectedDocumentId}
          isOpen={documentSidebarOpen}
          onClose={() => setDocumentSidebarOpen(false)}
        />
      )}
    </>
  )
}
