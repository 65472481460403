import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { seniorityGenericNameColumn } from '@src/constants/columns/seniority'
import { Flex } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { getSeniorityList } from '@src/api/seniority'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const RowNotifications: RowInterface<SeniorityInterface> = {
  linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.SENIORITY.GENERAL, { id })),
  cells: [
    {
      ...seniorityGenericNameColumn,
      width: 1632,
    },
  ],
}

const EmployeeTable = () => {
  const table = useTable<SeniorityInterface>({ getItems: getSeniorityList })

  return (
    <TableWrapper>
      <Flex mb="s-24">
        <Stat label="Total" val={table?.count} mr="s-32" />
      </Flex>
      <AdjustableTable<SeniorityInterface>
        name={TableNames.Seniorities}
        useWindowScroll
        dataType="Seniority"
        row={RowNotifications}
        {...table}
        noDataMessage="List of seniority will appear here."
      />
    </TableWrapper>
  )
}

export default EmployeeTable
