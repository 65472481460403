import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import React from 'react'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { ReferCandidateInterface } from '@src/interfaces/referCandidate'
import { Box, Flex, Link, Text, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import { ExclamationMarkOutline } from '@revolut/icons'
import { EMPLOYEE_REFERRAL_PROGRAM_STANDARD_OPERATIONAL_PROCEDURE } from '../externalLinks'

export const referralCandidateNameColumn: ColumnInterface<ReferCandidateInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'full_name',
  sortKey: 'full_name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const referralCandidateSpecialisationColumn: ColumnInterface<ReferCandidateInterface> =
  {
    type: CellTypes.text,
    idPoint: 'specialisation.id',
    dataPoint: 'specialisation.name',
    sortKey: 'specialisation__name',
    filterKey: 'specialisation',
    selectorsKey: selectorKeys.specialisations,
    title: 'Role (Specialisation)',
  }

export const referralCandidateRecruiterColumn: ColumnInterface<ReferCandidateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'candidate.recruiter.id',
    dataPoint: 'candidate.recruiter.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Recruiter',
    insert: ({ data }) =>
      data.candidate?.recruiter ? <UserWithAvatar {...data.candidate.recruiter} /> : '-',
  }

export const referralCandidateCreatedOnColumn: ColumnInterface<ReferCandidateInterface> =
  {
    type: CellTypes.date,
    idPoint: 'created_date_time',
    dataPoint: 'created_date_time',
    sortKey: 'created_date_time',
    filterKey: 'created_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Referred on',
  }

export const referralCandidateInteractedOnColumn: ColumnInterface<ReferCandidateInterface> =
  {
    type: CellTypes.date,
    idPoint: 'candidate.latest_activity_date_time',
    dataPoint: 'candidate.latest_activity_date_time',
    sortKey: 'candidate__latest_activity_date_time',
    filterKey: 'candidate__latest_activity_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Last Interaction Date',
  }

export const referralCandidateStateColumn: ColumnInterface<ReferCandidateInterface> = {
  type: CellTypes.text,
  idPoint: 'current_state',
  dataPoint: 'current_state',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Current Stage',
}

export type ReferralBonusStatusProps = {
  data: ReferCandidateInterface
}

const ReferralBonusStatus = ({ data }: ReferralBonusStatusProps) => {
  const tooltip = useTooltip()
  if (
    data.is_applicable_for_referral_bonus === undefined ||
    data.is_applicable_for_referral_bonus === null
  ) {
    return '-'
  }
  if (!data.is_applicable_for_referral_bonus) {
    return (
      <Flex gap="s-8" color={Token.color.yellow} {...tooltip.getAnchorProps()}>
        <Box>
          <ExclamationMarkOutline size={16} />
        </Box>
        <Text>Not applicable</Text>
        <Tooltip {...tooltip.getTargetProps()} maxWidth={320}>
          Due to GDPR constraints, we cannot share specific details about this referral.
          Reasons for ineligibility might include the candidate being currently active in
          our recruitment process or recently archived. Please see the dedicated{' '}
          <Link
            href={EMPLOYEE_REFERRAL_PROGRAM_STANDARD_OPERATIONAL_PROCEDURE}
            textDecoration="underlined"
            target="_blank"
            rel="noreferrer noopener"
            onClick={event => {
              event.stopPropagation()
            }}
          >
            policy
          </Link>{' '}
          section for further information.
        </Tooltip>
      </Flex>
    )
  }
  return <Text color={Token.color.green}>Applicable</Text>
}

export const referralBonusStatusColumn: ColumnInterface<ReferCandidateInterface> = {
  type: CellTypes.insert,
  idPoint: 'is_applicable_for_referral_bonus',
  dataPoint: 'is_applicable_for_referral_bonus',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bonus',
  insert: ReferralBonusStatus,
}
