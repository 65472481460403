import React, { ReactChild, useEffect, useRef, useState } from 'react'
import { CellTypes, ColumnCellInterface, FilterByInterface } from '@src/interfaces/data'
import styled, { css } from 'styled-components'
import get from 'lodash/get'
import { format } from 'date-fns'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { selectorToUrl } from '@src/constants/api'
import Icon from '../Icon/Icon'
import Tooltip from '../Tooltip/Tooltip'
import { Box, Link as A, Text, Token, opacity, Flex } from '@revolut/ui-kit'
import { Link as LinkIcon } from '@revolut/icons'
import LongNumberCell from '@components/TableV2/AdvancedCells/LongNumberCell/LongNumberCell'
import TableCellLink from '@components/TableCellLink/TableCellLink'
import { RowHeight, TableTypes } from '@src/interfaces/table'
import { utcToLocalDate } from '@src/utils/timezones'
import { LocationDescriptor } from 'history'
import { defaultTheme } from '@src/styles/theme'

export interface CellProps<T> {
  cell: ColumnCellInterface<T>
  dataPath: string
  idPath: string
  data: any
  disabled?: boolean
  children: string
  error?: string
  reduceSize: number
  noAutoResize?: boolean
  onFilterChange?: (filters: FilterByInterface) => void
  type: TableTypes
  parentIndexes: number[]
  prefixContent?: React.ReactNode
  rowHeight: RowHeight
  background?: string
  isFirstCell?: boolean
}

const CellCoverHoverCss = (reduceSize?: number, isFirstCell?: boolean) => css`
  &:hover {
    position: absolute;
    top: 0;
    left: ${reduceSize || 0}px;
    height: 100%;
    flex-grow: 1;
    display: grid;
    align-items: center;
    overflow: visible;
    z-index: ${defaultTheme.zIndex.aboveMain + (isFirstCell ? 3 : 0)};
  }
`

export const cellWrapperRole = 'cell-wrapper'

const CellWrapperMatrixCss = css`
  border-right: 1px solid ${opacity(Token.colorChannel.black, 0.1)};
  min-height: 49px;
`

const DateTimeContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const bigCellCss = css`
  font-weight: 500;
  font-size: 16px;
`

const CellWrapper = styled.div<{
  width?: number
  type: TableTypes
  isError?: boolean
  background?: string
  isBig?: boolean
}>`
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 0;
  position: relative;
  max-width: ${props => (props.width ? `${props.width}px` : 'unset')};
  min-width: ${props => (props.width ? `${props.width}px` : 'unset')};
  width: ${props => (props.width ? `${props.width}px` : 'unset')};
  ${({ type }) => type === TableTypes.Matrix && CellWrapperMatrixCss};
  background-color: ${props => {
    if (props.isError) {
      return Token.color.red_40
    }
    return props.background || 'inherit'
  }};
  ${({ isBig }) => isBig && bigCellCss};

  &:hover {
    background-color: ${props => props.background || 'inherit'};
  }
`

const MaskedWrapper = styled.span<{ type: TableTypes }>`
  display: flex;
  color: ${Token.color.greyTone20};
  ${({ type }) => type === TableTypes.Matrix && 'padding-left: 16px;'};
`

const CellCoverMatrixCss = css`
  padding: 0;
  overflow: initial;
`

const cellCoverLineHeight = {
  small: 16,
  medium: 16,
  large: 22,
}

const CellCover = styled.div<{
  disabled?: string
  color?: string
  type: TableTypes
  reduceSize: number
  notHoverable?: boolean
  rowHeight: RowHeight
  isFirstCell?: boolean
}>`
  line-height: ${({ rowHeight }) => `${cellCoverLineHeight[rowHeight]}px`};
  min-width: ${({ reduceSize }) =>
    reduceSize ? `calc(100% - ${reduceSize}px)` : '100%'};
  color: ${props =>
    props.disabled ? Token.color.greyTone50 : props.color || Token.color.foreground};
  ${({ type }) => type === TableTypes.Matrix && CellCoverMatrixCss};
  ${({ notHoverable, reduceSize, isFirstCell }) =>
    !notHoverable && CellCoverHoverCss(reduceSize, isFirstCell)};
  background: ${Token.color.widgetBackground};
  height: 100%;
  display: flex;
  align-items: center;
`

const CellContent = styled.div<{
  background?: string
  padding?: string
  reduceSize?: number
}>`
  padding: ${({ padding }) => padding || '0 8px 0 12px'};
  ${({ reduceSize }) => (reduceSize ? `padding-left: 0px` : '')};
  width: 100%;
  background: ${({ background }) => background || 'inherit'};
  height: 100%;
  display: flex;
  align-items: center;
`

const FilterByButton = styled.div<{ filterHover: boolean; textAlign: 'left' | 'right' }>`
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: ${props => (props.filterHover ? 'underline' : 'none')};
  text-align: ${props => props.textAlign};
`

const TextPlaceholder = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

const uppercase = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

export interface CellContentProps<T> {
  cell: ColumnCellInterface<T>
  dataPath: string
  idPath: string
  data: any
  children: string
  parentIndexes: number[]
}

export const CellContentByType = <T extends {}>({
  cell,
  data,
  children,
  dataPath,
  idPath,
  parentIndexes,
}: CellContentProps<T>) => {
  let content: React.ReactNode
  switch (cell.type) {
    case CellTypes.date:
      if (children !== '-') {
        content = format(new Date(children), 'd MMM yyyy')
      } else {
        content = children
      }
      break
    case CellTypes.dateInUtc:
      if (children !== '-') {
        content = format(utcToLocalDate(children), 'd MMM yyyy')
      } else {
        content = children
      }
      break
    case CellTypes.dateTime:
      if (children !== '-') {
        content = (
          <DateTimeContainer>
            {format(new Date(children), 'd MMM yyyy')}{' '}
            <Text color={Token.color.greyTone50}>
              {format(new Date(children), 'HH:mm OOO')}
            </Text>
          </DateTimeContainer>
        )
      } else {
        content = children
      }
      break
    case CellTypes.insert:
      if (cell.insert) {
        content = cell.insert({ data, children, dataPath, idPath, parentIndexes })
      }
      break
    case CellTypes.link:
      content =
        children !== '-' ? (
          <A
            onClick={e => e.stopPropagation()}
            href={children}
            target="_blank"
            display="block"
            width={16}
            rel="noreferrer noopener"
          >
            <LinkIcon hoverColor="blue" color="grey-tone-50" size={16} />
          </A>
        ) : (
          children
        )
      break
    case CellTypes.text:
      content = typeof children === 'string' ? uppercase(children) : children
      break
    case CellTypes.longNumber:
      content = <LongNumberCell value={data} />
      break
  }

  return <>{content}</>
}

export const Cell = <T extends {}>({
  cell,
  data,
  dataPath,
  idPath,
  error,
  children,
  disabled,
  onFilterChange,
  reduceSize,
  type,
  parentIndexes,
  prefixContent,
  rowHeight,
  background,
  isFirstCell,
}: CellProps<T>) => {
  let content: React.ReactChild | null
  const [filterHover, setFilterHover] = useState(false)
  const [unmasked, setUnmasked] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (unmasked && cell.masked) {
      setUnmasked(false)
    }
  }, [cell.masked])

  content = (
    <CellContentByType
      cell={cell}
      data={data}
      dataPath={dataPath}
      idPath={idPath}
      parentIndexes={parentIndexes}
    >
      {children}
    </CellContentByType>
  )

  if (!unmasked && cell.masked) {
    content = (
      <MaskedWrapper
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setUnmasked(true)
        }}
        type={type}
      >
        <Tooltip placement="top" text="Click to see hidden information">
          <Icon type="EyeCrossed" size="small" />
        </Tooltip>
      </MaskedWrapper>
    )
  }

  const handleFilteringBy = () => {
    const dataId = get(data, cell.idPoint)
    if (!cell.filterType && cell.filterKey) {
      onFilterChange?.({
        columnName: cell.filterKey,
        filters: [
          {
            id: dataId,
            name: dataId,
          },
        ],
      })
    }
  }

  const renderFilterByButton = (cnt: ReactChild | null) => {
    return (
      <TextPlaceholder>
        <FilterByButton
          filterHover={filterHover}
          onClick={e => {
            if (e.altKey && cell.type === CellTypes.text) {
              handleFilteringBy()
              e.preventDefault()
              e.stopPropagation()
            }
          }}
          onMouseMove={e => {
            if (e.altKey && cell.type === CellTypes.text && !filterHover) {
              setFilterHover(true)
            }
          }}
          onMouseLeave={() => {
            if (filterHover) {
              setFilterHover(false)
            }
          }}
          textAlign={cell.textAlign || 'left'}
        >
          {cnt}
        </FilterByButton>
      </TextPlaceholder>
    )
  }

  const renderContent = () => {
    const dynamicHyperlink = cell?.dynamicHyperlinks?.(data)

    if (
      dynamicHyperlink ||
      (selectorToUrl[cell.selectorsKey as string] &&
        cell.type === CellTypes.text &&
        get(data, cell.idPoint))
    ) {
      const processDynamicHyperlink = (link: string | LocationDescriptor) => {
        return typeof link === 'string' ? getLocationDescriptor(link) : link
      }

      const url = dynamicHyperlink
        ? processDynamicHyperlink(dynamicHyperlink)
        : getLocationDescriptor(
            pathToUrl(selectorToUrl[cell.selectorsKey as string] as string, {
              id: get(data, cell.idPoint),
            }),
          )
      return <TableCellLink to={url}>{renderFilterByButton(content)}</TableCellLink>
    }
    return renderFilterByButton(content)
  }

  const cellBackground = cell.background?.(data)
  const backgroundColor =
    !cellBackground || cellBackground === 'inherit' ? background : cellBackground

  return (
    <CellWrapper
      role={cellWrapperRole}
      data-testid="cell-wrapper"
      data-column-title={cell.title}
      width={cell.width + (cell.resizeWidth || 0)}
      type={type}
      isError={!!error}
      ref={ref}
      css={cell.wrapperCss?.(data)}
      background={Token.color.widgetBackground}
      isBig={cell.isBig}
    >
      <Flex backgroundColor={backgroundColor} height="100%" alignItems="center">
        {prefixContent}
        {error && ref.current && (
          <Tooltip
            placement="bottom"
            forceShow
            anchor={ref.current}
            body={
              <Box minWidth={150} color="background" p="s-12">
                {error}
              </Box>
            }
          />
        )}
      </Flex>
      <CellCover
        disabled={disabled ? 'disabled' : ''}
        color={cell.colors && cell.colors(data)}
        type={type}
        reduceSize={reduceSize}
        notHoverable={cell.notHoverable}
        rowHeight={rowHeight}
        isFirstCell={isFirstCell}
        data-cell-cover
      >
        <CellContent
          padding={cell.padding}
          reduceSize={reduceSize}
          background={backgroundColor}
        >
          {renderContent()}
        </CellContent>
      </CellCover>
    </CellWrapper>
  )
}
