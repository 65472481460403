import React, { useEffect, useState } from 'react'
import {
  InputGroup,
  HStack,
  Text,
  Color,
  Avatar,
  Field,
  createChain,
  Input,
} from '@revolut/ui-kit'
import { useRifm } from 'rifm'
import { AsYouType } from 'libphonenumber-js'
import { set, uniq } from 'lodash'

import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { ApiVersion } from '@src/interfaces'
import { useLapeContext, useLapeField } from '@src/features/Form/LapeForm'
import { PhoneOption } from '@src/interfaces/selectors'
import { getCurrentCountryCode } from '@src/utils/timezones'
import get from 'lodash/get'

type Props = {
  prefixName: string
  prefixPlaceholder?: string
  phoneName: string
  phonePlaceholder?: string
  apiVersion?: ApiVersion
  message?: string | null
  required?: boolean
}
export const LapePhoneInput = ({
  prefixName,
  prefixPlaceholder = 'Country',
  phoneName,
  phonePlaceholder = 'Phone number',
  apiVersion,
  message,
  required = false,
}: Props) => {
  const { values } = useLapeContext<{ [prefixName: string]: PhoneOption | undefined }>()

  const prefixField = useLapeField(prefixName)
  const phoneField = useLapeField(phoneName)

  const [phoneFormatted, setPhoneFormatted] = useState(phoneField.value || '')

  const { options } = useFetchOptions<PhoneOption>(
    selectorKeys.country_calling_codes,
    undefined,
    undefined,
    apiVersion,
  )

  useEffect(() => {
    if (options && !get(values, prefixName)) {
      const countryCode = getCurrentCountryCode()

      if (countryCode) {
        const phoneOption = options.find(item => item.value.id === countryCode)?.value

        if (phoneOption) {
          set(values, prefixName, phoneOption)
        }
      }
    }
  }, [options])

  const phoneNumberRifm = useRifm({
    value: phoneFormatted,
    onChange: value => {
      set(values, phoneName, value.replace(/\D/g, ''))
      setPhoneFormatted(value)
    },
    format: value => {
      const digits = (value.match(/\d+/g) || []).join('').substring(0, 12)
      return prefixField.value?.id
        ? new AsYouType(prefixField.value?.id).input(digits)
        : digits
    },
  })

  const formattedOptions = options.map(({ value }) => ({
    id: value.id,
    name: value.name,
    code: value.code,
  }))

  const invalid = !!(prefixField.apiError || phoneField.apiError)

  const errorMessage = createChain(<br />)(
    uniq([
      typeof prefixField.apiError === 'string' ? prefixField.apiError : null,
      typeof phoneField.apiError === 'string' ? phoneField.apiError : null,
    ]),
  )

  const optionalSuffix = !required ? ' (optional)' : ''

  return (
    <Field message={message} invalid={invalid} errorMessage={errorMessage}>
      {() => (
        <InputGroup variant="horizontal">
          <LapeRadioSelectInput<PhoneOption>
            required
            name={prefixName}
            label={prefixPlaceholder}
            selector={() => Promise.resolve(formattedOptions)}
            inputProps={{
              renderPrefix: () =>
                prefixField.value ? (
                  <CountryFlag countryCode={prefixField.value?.id} />
                ) : null,
              value: prefixField.value?.code || '',
              width: 150,
              errorMessage: null,
              'aria-invalid': !!prefixField.apiError,
            }}
            width={320}
            searchKeys={['value.code']}
          >
            {option => (
              <HStack align="center" space="s-16" key={option.key}>
                <CountryFlag countryCode={option.value.id} />
                <Text color={Color.GREY_TONE_50} minWidth={48}>
                  {option.value.code}
                </Text>
                <Text flex="1">{option.value.name}</Text>
              </HStack>
            )}
          </LapeRadioSelectInput>
          <Input
            value={phoneNumberRifm.value}
            onChange={e =>
              phoneNumberRifm.onChange(e as React.ChangeEvent<HTMLInputElement>)
            }
            label={`${phonePlaceholder}${optionalSuffix}`}
            inputMode="tel"
            aria-invalid={!!phoneField.apiError}
            data-name={phoneName}
          />
        </InputGroup>
      )}
    </Field>
  )
}

interface CountryFlagProps {
  countryCode?: string
}

export const CountryFlag = ({ countryCode }: CountryFlagProps) => {
  if (!countryCode) {
    return null
  }

  return (
    <Avatar
      variant="brand"
      image={`https://assets.revolut.com/assets/flags/${countryCode}.webp`}
      size={20}
    />
  )
}
