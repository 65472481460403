import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Cell, Flex, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { RowInterface } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@src/components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import {
  emailTemplateCreatedDateColumn,
  emailTemplateNameColumn,
  emailTemplateOwnerColumn,
  emailTemplateStatusColumn,
  emailTemplateSubjectColumn,
} from '@src/constants/columns/emailTemplate'
import { EmailTemplateInterface } from '@src/interfaces/emailTemplates'
import { getEmailTemplates } from '@src/api/emailTemplates'
import { Statuses } from '@src/interfaces'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<EmailTemplateInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.EMAIL_TEMPLATE.PREVIEW, { id })),
  cells: [
    {
      ...emailTemplateNameColumn,
      width: 410,
    },
    {
      ...emailTemplateSubjectColumn,
      width: 400,
    },
    {
      ...emailTemplateOwnerColumn,
      width: 250,
    },
    {
      ...emailTemplateCreatedDateColumn,
      width: 150,
    },
    {
      ...emailTemplateStatusColumn,
      width: 150,
    },
  ],
}

export const EmailTemplatesTable = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddEmailTemplate)

  const table = useTable({ getItems: getEmailTemplates }, [
    {
      columnName: 'status',
      filters: [{ id: Statuses.active, name: Statuses.active }],
      nonResettable: true,
    },
  ])

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        {canAdd && (
          <Box mb="s-16">
            <MoreBar>
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.EMAIL_TEMPLATE.GENERAL)}
                useIcon={Plus}
              >
                Create new
              </MoreBar.Action>
            </MoreBar>
          </Box>
        )}
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.EmailTemplates}
            useWindowScroll
            row={ROW}
            {...table}
            noDataMessage="Email templates will appear here"
          />
        </Flex>
      </Flex>
    </Cell>
  )
}
