import React from 'react'
import {
  PerformanceReviewTypes,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { Box, InputGroup, MoreBar, Subheader, Text } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import {
  kpiCalibratedPerformanceColumn,
  kpiEntityColumn,
  kpiGenericNameColumn,
  kpiLevelColumn,
  kpiPerformanceColumn,
  kpiRatingColumn,
  kpiUpdateTypeColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { useTable } from '@components/Table/hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AxiosResponse } from 'axios'
import { GetRequestInterface } from '@src/interfaces'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import KpiStatistic from '@src/pages/Forms/EmployeePerformance/components/KPIStatistic'
import CardJustification from '@components/ScorecardGeneral/CardJustification'
import { KpiInterface } from '@src/interfaces/kpis'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import {
  roadmapAddedOnColumn,
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import {
  RoadmapChangelogContext,
  useRoadmapChangelog,
} from '@src/features/RoadmapChangelog/RoadmapChangelog'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { Grid } from '@components/CommonSC/Grid'
import Tooltip from '@components/Tooltip/Tooltip'
import styled from 'styled-components'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { get, set } from 'lodash'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const CustomTooltip = styled(Tooltip)`
  justify-content: start;
`

export const validator = {}

const KPI_ROW: RowInterface<KpiInterface> = {
  cells: [
    {
      ...kpiGenericNameColumn,
      sortKey: null,
      filterKey: null,
    },
    {
      ...kpiWeightColumn,
      sortKey: null,
      filterKey: null,
      width: 68,
    },
    {
      ...kpiPerformanceColumn,
      sortKey: null,
      filterKey: null,
      width: 130,
    },
    {
      ...kpiCalibratedPerformanceColumn,
      sortKey: null,
      filterKey: null,
      width: 130,
    },
    {
      ...kpiLevelColumn,
      sortKey: null,
      filterKey: null,
      width: 85,
    },
    {
      ...kpiEntityColumn,
      sortKey: null,
      filterKey: null,
      width: 150,
    },
    {
      ...kpiUpdateTypeColumn,
      sortKey: null,
      filterKey: null,
      width: 80,
    },
    {
      ...kpiRatingColumn,
      sortKey: null,
      filterKey: null,
      width: 150,
    },
  ],
}

export const ROADMAP_ROW: RowInterface<RoadmapInterface> = {
  cells: [
    {
      ...roadmapGenericNameColumn,
      notHoverable: false,
    },
    {
      ...roadmapPriorityColumn,
      width: 100,
    },
    {
      ...roadmapProgressColumn,
      width: 170,
    },
    {
      ...roadmapStatusColumn,
      width: 120,
    },
    {
      ...roadmapAddedOnColumn,
      width: 120,
    },
    {
      ...roadmapDueDate,
      width: 130,
    },
    {
      ...roadmapResolutionAtColumn,
      width: 130,
    },
    {
      ...roadmapEpicUrlColumn,
      width: 120,
    },
  ],
}

export const recommendedRatingOptions = [
  {
    id: 'poor',
    name: 'Poor',
    subtitle: 'Simple tasks, often late with poor quality.',
  },
  {
    id: 'basic',
    name: 'Basic',
    subtitle:
      'Standard tasks, usually on time with acceptable quality. Often employee level.',
  },
  {
    id: 'intermediate',
    name: 'Intermediate',
    subtitle: 'Non-standard tasks, always on time with good quality. Often team level.',
  },
  {
    id: 'advanced',
    name: 'Advanced',
    subtitle:
      'Non-standard tasks, always on time with high quality. Often department level.',
  },
  {
    id: 'expert',
    name: 'Expert',
    subtitle:
      'Highly complex tasks, always on time with exceptional quality. Often company level.',
  },
].map(value => ({
  label: value.name,
  value,
}))

interface Props {
  isView: boolean
}

const KPI = ({ isView }: Props) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const { changelogState, changelogSwitcher } = useRoadmapChangelog(
    ((values as ReviewScorecardInterface)?.cycle as ReviewCyclesInterface) || null,
  )

  const kpiSection = isView
    ? (values as ReviewSummaryInterface).summary?.kpis_section
    : (values as ReviewScorecardInterface).review_data.kpis_section

  const roadmapSection = isView
    ? (values as ReviewSummaryInterface).summary?.roadmaps_section
    : (values as ReviewScorecardInterface).review_data.roadmaps_section

  const kpiTable = useTable<KpiInterface>({
    getItems: () =>
      new Promise(resolve => {
        resolve({
          data: {
            results: kpiSection?.kpi_items || [],
            count: kpiSection?.kpi_items.length,
          },
        } as AxiosResponse<GetRequestInterface<KpiInterface>>)
      }),
  })

  const roadmapTable = useTable<RoadmapInterface>({
    getItems: () =>
      new Promise(resolve => {
        resolve({
          data: {
            results: roadmapSection?.roadmap_items || [],
            count: roadmapSection?.roadmap_items.length,
          },
        } as AxiosResponse<GetRequestInterface<RoadmapInterface>>)
      }),
  })

  const onRowClick = (rowData: RoadmapInterface) => {
    if (rowData.epic_url) {
      window.open(rowData.epic_url, '_blank')
    }
  }

  if (!kpiSection && !roadmapSection) {
    return null
  }

  return (
    <Box width={1055} mb="s-32">
      {!!kpiSection && (
        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>KPI</Subheader.Title>
          </Subheader>
          <TableWrapper>
            <KpiStatistic
              performance={kpiSection?.performance}
              rating={kpiSection?.rating}
            />

            <Box mt="s-16">
              <MoreBar>
                <MoreInfoButton href="https://revolut.atlassian.net/wiki/spaces/REV/pages/2525071788/KPIs+in+the+Performance+Review">
                  Rating calculation info
                </MoreInfoButton>
              </MoreBar>
            </Box>

            <Box mt="s-16" width="1020px">
              <AdjustableTable<KpiInterface>
                name={TableNames.EmployeePerformanceKPI}
                useWindowScroll
                dataType="KPI"
                row={KPI_ROW}
                {...kpiTable}
                noDataMessage="No KPI defined"
                onRowClick={onKPITableRowClick}
                noReset
              />
            </Box>
          </TableWrapper>
        </Box>
      )}

      {!!roadmapSection && (
        <Box mt="s-16">
          <RoadmapChangelogContext.Provider value={changelogState}>
            <Subheader variant="nested">
              <Subheader.Title>Roadmap</Subheader.Title>
            </Subheader>
            <TableWrapper>
              <Grid justifyContent="start" gap={8}>
                <CustomTooltip
                  text="Roadmap progress does not contribute directly to the delivery rating. The delivery rating is decided by the calibrated performance of Personal KPIs. Read more here"
                  placement="right"
                >
                  <KpiStatistic performance={roadmapSection?.performance} />
                </CustomTooltip>
                <MoreBar>{changelogSwitcher}</MoreBar>
              </Grid>
              <Box mt="s-16" width="1020px">
                <AdjustableTable<RoadmapInterface>
                  name={TableNames.EmployeePerformanceRoadmap}
                  useWindowScroll
                  dataType="Roadmap"
                  row={ROADMAP_ROW}
                  {...roadmapTable}
                  noDataMessage="No roadmap data"
                  onRowClick={onRowClick}
                  noReset
                />
              </Box>
            </TableWrapper>
          </RoadmapChangelogContext.Provider>
        </Box>
      )}

      <Box mt="s-16">
        <Subheader variant="nested">
          <Subheader.Title>Additional notes</Subheader.Title>
        </Subheader>

        {isView && kpiSection ? (
          <CardJustification
            reviews={kpiSection.comments!.map(item => item.review)}
            card={kpiSection}
            cardIndex={0}
            type={PerformanceReviewTypes.kpi}
            viewMode
          />
        ) : (
          <InputGroup>
            <Box maxWidth={400}>
              <LapeRadioSelectInput
                name="review_data.kpis_section.recommended_rating"
                label="Suggested Delivery Rating"
                optional
                message="Use this to indicate your suggested delivery rating and add your justifications below"
                options={recommendedRatingOptions}
                value={
                  recommendedRatingOptions.find(
                    item =>
                      item.value.id ===
                      get(values, 'review_data.kpis_section.recommended_rating'),
                  )?.value
                }
                onChange={val => {
                  if (val) {
                    set(values, 'review_data.kpis_section.recommended_rating', val.id)
                  }
                }}
                disabled={isView}
              >
                {option => (
                  <>
                    <Text use="p">{option.value.name}</Text>
                    <Text use="p" variant="caption" mt="s-4" color="grey-tone-50">
                      {option.value.subtitle}
                    </Text>
                  </>
                )}
              </LapeRadioSelectInput>
            </Box>

            <LapeNewTextArea
              rows={4}
              label="Comments"
              name="review_data.kpis_section.comment"
              data-testid="comment"
            />
          </InputGroup>
        )}
      </Box>
    </Box>
  )
}

export default connect(KPI)
