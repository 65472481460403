import React from 'react'
import { Flex } from '@revolut/ui-kit'

import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import Stat from '@components/Stat/Stat'
import {
  employeeNameColumn,
  employeeNameIdStatusColumn,
} from '@src/constants/columns/employee'
import { roleNameColumn } from '@src/constants/columns/role'
import { seniorityNameColumn } from '@src/constants/columns/seniority'
import { locationNameColumn } from '@src/constants/columns/location'
import { SORT_DIRECTION } from '@src/interfaces/data'
import {
  functionalReportsOwnershipTransfer,
  getFunctionalReportsOwnership,
} from '@src/api/ownership'
import { teamNameColumn } from '@src/constants/columns/team'

import {
  OwnershipTransferProvider,
  SmallBanner,
  TransferSelections,
  TransferTable,
  TrasferAllButton,
} from './common'
import { TableWrapper } from '@components/Table/TableWrapper'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
}

const FunctionalReportsOwnership = ({ data, stats, refreshStats }: Props) => {
  const sortBy = [
    {
      sortBy: 'full_name',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const transferApi = (selections: TransferSelections) =>
    functionalReportsOwnershipTransfer(
      data.id,
      Object.entries(selections).map(([id, selection]) => ({
        id,
        quality_control: selection.id,
      })),
    ).then(refreshStats)

  return (
    <OwnershipTransferProvider
      getApi={getFunctionalReportsOwnership(data.id)}
      sortByInitial={sortBy}
      defaultOwner={data.line_manager}
      transferApi={transferApi}
    >
      <SmallBanner
        title="Employee scheduled for termination. Transfer functional reports to a new functional manager"
        hidden={!data.has_termination}
      />

      <TableWrapper mb="s-20">
        <Stat
          label="Functional reports"
          val={stats?.functional_reports_count}
          mb="s-24"
        />
        <Flex mb="s-24">
          <TrasferAllButton />
        </Flex>

        <TransferTable
          cells={[
            {
              ...employeeNameColumn,
              width: 200,
            },
            {
              ...roleNameColumn,
              width: 130,
            },
            {
              ...seniorityNameColumn,
              width: 100,
            },
            {
              ...teamNameColumn,
              width: 200,
            },
            {
              ...locationNameColumn,
              width: 140,
            },
            {
              ...employeeNameIdStatusColumn,
              width: 120,
            },
          ]}
          ownerColTitle="New Functional Manager"
          noDataMessage="Functional reports ownership will appear here"
        />
      </TableWrapper>
    </OwnershipTransferProvider>
  )
}

export default FunctionalReportsOwnership
