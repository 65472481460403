import React from 'react'
import { Text, Token } from '@revolut/ui-kit'
import { format } from 'date-fns'

type InterviewAvailabilityDateProps = {
  startDateTime: string
  endDateTime: string
}

export const InterviewAvailabilityDate = ({
  startDateTime,
  endDateTime,
}: InterviewAvailabilityDateProps) => {
  const start = new Date(startDateTime)
  const end = new Date(endDateTime)
  const date = format(start, 'd MMM')
  const weekday = format(start, 'E')
  const fromTime = format(start, 'HH:mm')
  const toTime = format(end, 'HH:mm')
  return (
    <Text>
      {weekday} {date},{' '}
      <Text color={Token.color.greyTone50}>
        {fromTime} - {toTime}
      </Text>
    </Text>
  )
}
