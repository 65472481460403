import { DetailsCell, HStack, Icon, TabBar, Text, Token, VStack } from '@revolut/ui-kit'
import React, { useState } from 'react'
import { GoalDashboardDetails, GoalPreviewInterface } from '@src/interfaces/goals'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DashboardPreview } from '../GoalForm'

export const AnalyticsPreviewWidget = () => {
  const { values } = useLapeContext<GoalPreviewInterface>()
  const [selected, setSelected] = useState<GoalDashboardDetails | undefined>(
    values.dashboards[0],
  )

  return (
    <DetailsCell>
      <DetailsCell.Title>
        <HStack space="s-8">
          <Icon name="ViewGrid" color={Token.color.greyTone20} />
          <Text variant="h6" color={Token.color.greyTone50}>
            Analytics
          </Text>
        </HStack>
      </DetailsCell.Title>
      <DetailsCell.Note>
        <VStack space="s-16">
          <TabBar
            variant="segmented fit"
            value={String(selected?.id)}
            onChange={id =>
              id && setSelected(values.dashboards.find(t => String(t.id) === id))
            }
          >
            {values.dashboards.map(t => (
              <TabBar.Item to={String(t.id)} key={t.id}>
                <Text variant="h6">{t.name}</Text>
              </TabBar.Item>
            ))}
          </TabBar>
          <VStack height="100%" mt="s-16">
            {selected && <DashboardPreview dashboard={selected} minHeight={600} />}
          </VStack>
        </VStack>
      </DetailsCell.Note>
    </DetailsCell>
  )
}
