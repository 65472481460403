import React from 'react'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Action, Subheader, Widget } from '@revolut/ui-kit'
import { RowInterface } from '@src/interfaces/data'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  hiringProcessEligibleInterviewsLinkColumn,
  hiringProcessPlaybookColumn,
  hiringProcessScorecardLinkColumn,
  hiringProcessSkillsColumn,
  hiringProcessTitleWithLockColumn,
} from '@src/constants/columns/hiringProcess'
import { TableNames } from '@src/constants/table'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const HiringProcessRow: RowInterface<HiringProcessInterface> = {
  cells: [
    {
      ...hiringProcessTitleWithLockColumn,
      width: 188,
    },
    {
      ...hiringProcessSkillsColumn,
      width: 144,
    },
    {
      ...hiringProcessPlaybookColumn,
      width: 100,
    },
    {
      ...hiringProcessScorecardLinkColumn,
      width: 134,
    },
    {
      ...hiringProcessEligibleInterviewsLinkColumn,
      width: 154,
    },
  ],
}

export const HiringProcessWidget = () => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const { changeQueryParam } = useQuery()
  const handleRowEdit = (data: HiringProcessInterface) => {
    if (data.id) {
      changeQueryParam(Queries.StageId, `${data.id}`)
    }
  }
  return (
    <>
      <Subheader variant="nested">
        <Subheader.Title>Hiring process</Subheader.Title>
        <Subheader.Side>
          <Action
            onClick={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
                  id: values.id,
                }),
              )
            }
          >
            Edit
          </Action>
        </Subheader.Side>
      </Subheader>
      <Widget p="s-16">
        <AdjustableTable<HiringProcessInterface>
          name={TableNames.SpecialisationHiringProcess}
          dataType="Hiring stage"
          row={HiringProcessRow}
          data={values?.hiring_process_rounds}
          count={values?.hiring_process_rounds?.length || 0}
          onRowClick={handleRowEdit}
          noDataMessage="Hiring stages will appear here."
        />
      </Widget>
    </>
  )
}
