import React from 'react'
import { InterviewAvailabilityStatus as InterviewAvailabilityStatusType } from '@src/interfaces/interviewAvailability'
import { Text, Token } from '@revolut/ui-kit'

type InterviewAvailabilityStatusProps = {
  status: InterviewAvailabilityStatusType
}

const getInterviewAvailabilityStatusColor = (status: InterviewAvailabilityStatusType) => {
  switch (status) {
    case 'booked':
      return Token.color.orange
    case 'pending':
      return Token.color.green
    case 'holiday':
      return Token.color.red
    default:
      return Token.color.foreground
  }
}

export const getInterviewAvailabilityStatusText = (
  status: InterviewAvailabilityStatusType,
) => {
  switch (status) {
    case 'booked':
      return 'Busy'
    case 'pending':
      return 'Available'
    case 'holiday':
      return 'Holiday'
    default:
      return ''
  }
}

export const InterviewAvailabilityStatus = ({
  status,
}: InterviewAvailabilityStatusProps) => {
  const color = getInterviewAvailabilityStatusColor(status)
  return <Text color={color}>{getInterviewAvailabilityStatusText(status)}</Text>
}
