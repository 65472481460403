import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Cell,
  Color,
  Flex,
  Header,
  MoreBar,
  Popup,
  Text,
} from '@revolut/ui-kit'
import { ArrowExchange, ExclamationTriangle } from '@revolut/icons'
import { connect } from 'lape'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import { useParams } from 'react-router-dom'

import Stat from '@src/components/Stat/Stat'
import { useTable } from '@src/components/Table/hooks'
import { DepartmentCompensationReviewInterface } from '@src/interfaces/compensation'
import SettingsButtons from '@src/features/SettingsButtons'
import {
  getDepartmentCompensationReviews,
  updateDepartmentCompensationReviews,
} from '@src/api/compensation'
import {
  departmentCompensationActionColumn,
  departmentCompensationCommentsColumn,
  departmentCompensationCultureColumn,
  departmentCompensationCXColumn,
  departmentCompensationEditableBonusColumn,
  departmentCompensationEditableBudgetColumn,
  departmentCompensationHeadcountColumn,
  departmentCompensationKPIColumn,
  departmentCompensationOwnerColumn,
  departmentCompensationPerformanceColumn,
  departmentCompensationPoolColumn,
  departmentCompensationRoadmapColumn,
  departmentCompensationTypeColumn,
} from '@src/constants/columns/compensation'
import { EditableRowInterface } from '@src/components/Table/EditableTable/EditableTable'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import LapeEditableTable from '@src/components/Table/EditableTable/LapeEditableTable'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { formatMoney } from '@src/utils/format'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { EmptyState } from '@src/features/BudgetDistribution/BudgetDistribution'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { companyCurrencySelector, defaultCurrency, useReviewData } from './common'
import { goBack } from '@src/actions/RouterActions'
import { TableNames } from '@src/constants/table'

const getRow = (
  resetRow: (data: DepartmentCompensationReviewInterface) => void,
  currency?: string,
): EditableRowInterface<DepartmentCompensationReviewInterface> => {
  return {
    cells: [
      {
        ...departmentCompensationPoolColumn,
        width: 200,
      },
      {
        ...departmentCompensationTypeColumn,
        width: 120,
      },
      {
        ...departmentCompensationOwnerColumn,
        width: 140,
      },
      {
        ...departmentCompensationHeadcountColumn,
        width: 100,
      },
      {
        ...departmentCompensationRoadmapColumn,
        width: 50,
      },
      {
        ...departmentCompensationKPIColumn,
        width: 50,
      },
      {
        ...departmentCompensationCXColumn,
        width: 50,
      },
      {
        ...departmentCompensationCultureColumn,
        width: 50,
      },
      {
        ...departmentCompensationPerformanceColumn,
        width: 50,
      },
      {
        ...departmentCompensationEditableBudgetColumn(currency),
        width: 150,
      },
      {
        ...departmentCompensationEditableBonusColumn(currency),
        width: 150,
      },
      {
        ...departmentCompensationCommentsColumn,
        width: 100,
      },
      {
        ...departmentCompensationActionColumn(resetRow),
        width: 100,
      },
    ],
  }
}

interface FormData {
  data?: DepartmentCompensationReviewInterface[]
  initialData?: DepartmentCompensationReviewInterface[]
  currencyId?: number
}

const EditCompanyBudget = connect(() => {
  const { values } = useLapeContext<FormData>()

  const params = useParams<{ id?: string; currencyId?: string }>()
  const id = params.id == null ? null : Number(params.id)
  const currencyId = params.currencyId == null ? null : Number(params.currencyId)

  const [resetPopupOpen, setResetPopupOpen] = useState(false)
  const [initialData, setInitialData] = useState<DepartmentCompensationReviewInterface[]>(
    [],
  )

  const currencies = useFetchOptions<IdAndName>(companyCurrencySelector)
  const currencyCode = (() => {
    if (currencyId == null) {
      return defaultCurrency
    }
    return currencies.options.find(opt => opt.value.id === currencyId)?.value?.name
  })()

  const { yearOptions, selectedCompensationReview, modifiedCompensationReview } =
    useReviewData(currencyId, id)

  const table = useTable(
    { getItems: getDepartmentCompensationReviews(currencyId) },
    [
      {
        columnName: 'company_compensation_review__id',
        filters: id ? [{ id, name: `${id}` }] : [],
        nonResettable: true,
      },
      {
        columnName: 'target_currency_id',
        filters: currencyId ? [{ id: currencyId, name: `${currencyId}` }] : [],
        nonResettable: true,
      },
    ],
    [
      {
        sortBy: 'department__name',
        direction: SORT_DIRECTION.DESC,
        nonResettable: true,
      },
    ],
    {
      disable: !id,
      disableQuery: true,
    },
  )

  useEffect(() => {
    if (table.data.length && !values.data?.length) {
      setInitialData(cloneDeep(table.data))
      values.initialData = table.data
    }
    if (currencyId && values.currencyId !== currencyId) {
      values.currencyId = currencyId
    }
  }, [table.data, values, currencyId])

  const isEmpty =
    (!yearOptions.isLoading && yearOptions.data?.results.length === 0) ||
    (!table.loading && table.data?.length === 0)

  const onResetRow = (row: DepartmentCompensationReviewInterface) => {
    const rowToReset = values.initialData?.find(r => r.id === row.id)

    if (rowToReset) {
      set(
        values,
        'data',
        values.data?.map(r => (r.id === row.id ? cloneDeep(rowToReset) : r)),
      )
    }
  }

  const changes = getChanges(values)

  const { salary, bonus } = useMemo(() => {
    if (
      !modifiedCompensationReview ||
      modifiedCompensationReview.salary == null ||
      modifiedCompensationReview.bonus == null
    ) {
      return { salary: undefined, bonus: undefined }
    }

    const diff = changes
      .map(change => {
        const initialRow = values.initialData?.find(r => r.id === change.id)
        return initialRow
          ? {
              salary: initialRow.salary - change.salary,
              bonus: initialRow.bonus - change.bonus,
            }
          : {
              salary: 0,
              bonus: 0,
            }
      })
      .reduce(
        (total, val) => ({
          salary: val.salary + total.salary,
          bonus: val.bonus + total.bonus,
        }),
        { salary: 0, bonus: 0 },
      )

    return {
      salary: formatMoney(modifiedCompensationReview.salary - diff.salary, currencyCode),
      bonus: formatMoney(modifiedCompensationReview.bonus - diff.bonus, currencyCode),
    }
  }, [currencyCode, changes, modifiedCompensationReview])

  const noChanges = changes.length === 0

  const backUrl = ROUTES.FORMS.COMPANY.COMPENSATION

  return (
    <>
      <PageWrapper>
        <PageHeader title="Edit company budget" backUrl={backUrl} />

        <Cell>
          <Flex flexDirection="column" width="100%" id="comment-portal-target">
            <Flex mb="s-24" gap="s-32">
              <Stat label="Cycle" val={selectedCompensationReview?.year} />
              <Stat label="Currency" val={currencyCode} />
              <Stat label="Salaries budget" val={salary} />
              <Stat label="Bonus budget" val={bonus} />
              <Stat
                label="Departments"
                val={selectedCompensationReview?.department_count}
              />
            </Flex>

            <SettingsButtons mb="s-24">
              <MoreBar.Action
                onClick={() => setResetPopupOpen(true)}
                disabled={noChanges}
                variant="negative"
                useIcon={ArrowExchange}
              >
                Reset all budget changes
              </MoreBar.Action>
            </SettingsButtons>

            <Flex style={{ position: 'relative' }} flex="1 0">
              <LapeEditableTable<DepartmentCompensationReviewInterface>
                name={TableNames.CompanyBudgetEditable}
                dataFieldName="data"
                {...table}
                loading={isEmpty ? false : table.loading}
                replaceOnInitialDataChange
                initialData={initialData}
                disableFilters
                noReset
                useWindowScroll
                row={getRow(onResetRow, currencyCode)}
                emptyState={<EmptyState showDepartmentDetail />}
              />
            </Flex>
          </Flex>
        </Cell>

        <PageActions>
          <NewSaveButtonWithPopup
            onAfterSubmit={() => goBack(backUrl)}
            disabled={noChanges}
          >
            Submit
          </NewSaveButtonWithPopup>
        </PageActions>
      </PageWrapper>

      <Popup
        open={resetPopupOpen}
        onClose={() => setResetPopupOpen(false)}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet" displayMode="inline">
          <Header.Actions>
            <ExclamationTriangle color={Color.RED} size={40} />
          </Header.Actions>
          <Text fontSize="h5">
            Do you want to reset all the changes of budget allocation?
          </Text>
          <Header.Subtitle>
            <Box mt="s-16" mb="-s-16">
              You won't be able to revert this action.
            </Box>
          </Header.Subtitle>
        </Header>
        <Popup.Actions horizontal>
          <Button onClick={() => setResetPopupOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              set(values, 'data', cloneDeep(values.initialData))
              setResetPopupOpen(false)
            }}
            elevated
          >
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
})

const getChanges = (form: FormData) => {
  return (
    form.data
      ?.filter(row => {
        const initialRow = form.initialData?.find(r => r.id === row.id)
        return (
          initialRow &&
          (initialRow.bonus !== row.bonus || initialRow.salary !== row.salary)
        )
      })
      .map(({ id, salary, bonus }) => ({ id, salary, bonus })) || []
  )
}

const onSubmit = async (form: FormData) => {
  const changes = getChanges(form)

  await updateDepartmentCompensationReviews(
    changes.map(change => {
      const payload: {
        id: number
        total_salary_budget?: number
        total_bonus_budget?: number
        currency_id?: number
      } = { id: change.id }

      const initialRow = form.initialData?.find(r => r.id === change.id)

      if (change.salary !== initialRow?.salary) {
        payload.total_salary_budget = change.salary
      }
      if (change.bonus !== initialRow?.bonus) {
        payload.total_bonus_budget = change.bonus
      }
      if (form.currencyId != null) {
        payload.currency_id = form.currencyId
      }
      return payload
    }),
  )

  return Promise.resolve(form)
}

export default () => {
  return (
    <LapeForm<FormData> onSubmit={form => onSubmit(form.values)} disableValidation>
      <EditCompanyBudget />
    </LapeForm>
  )
}
