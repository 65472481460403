import React, { useState } from 'react'
import { Flex, Cell } from '@revolut/ui-kit'
import format from 'date-fns/format'

import { EmployeeShiftColumn } from '@src/constants/columns/attendance'
import { useTable } from '@src/components/Table/hooks'
import { getTeamScheduleApprovalInfo } from '@src/api/attendance'
import {
  dateFilterFormat,
  getInitialMonthFilter,
  MonthToggle,
} from '@src/features/Attendance/MonthToggle'
import { MonthSelect } from '@src/components/MonthSelect/MonthSelect'
import SettingsButtons from '@src/features/SettingsButtons'
import SwitchButton from '@src/components/SwitchButton/SwitchButton'
import { TeamInterface } from '@src/interfaces/teams'
import { useTableRow } from '@src/features/Attendance/useTableRow'
import { ROW, ScheduleTable } from '@src/features/Attendance/ScheduleTable'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'

interface TeamScheduleProps {
  data: TeamInterface
  navigation?: React.ReactElement
}

const TeamSchedule = ({ data, navigation }: TeamScheduleProps) => {
  const currentDate = getInitialMonthFilter()

  const [expandToday, setExpandToday] = useState(false)

  const table = useTable<EmployeeShiftColumn>(
    { getItems: getTeamScheduleApprovalInfo(data.id) },
    [
      {
        columnName: 'date',
        filters: [{ id: currentDate, name: currentDate }],
        nonResettable: true,
      },
      {
        columnName: 'approval',
        filters: [
          { id: ApprovalStatuses.Pending, name: ApprovalStatuses.Pending },
          { id: ApprovalStatuses.Approved, name: ApprovalStatuses.Approved },
          { id: ApprovalStatuses.NoStatus, name: ApprovalStatuses.NoStatus },
        ],
      },
    ],
  )

  const selectedDate =
    table.filterBy.find(col => col.columnName === 'date')?.filters[0].id || currentDate

  const tableRow = useTableRow(ROW, expandToday)

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        {navigation}

        <Flex justifyContent="space-between">
          <Flex mb="s-24" gap="s-32">
            <MonthSelect
              value={selectedDate}
              onChange={date => {
                if (date) {
                  const newMonth = format(date, dateFilterFormat)
                  table.onFilterChange(
                    {
                      columnName: 'date',
                      filters: [{ id: newMonth, name: newMonth }],
                      nonResettable: true,
                    },
                    false,
                  )
                }
              }}
            />
          </Flex>

          <MonthToggle
            value={selectedDate}
            onChange={date => {
              table.onFilterChange([
                {
                  columnName: 'date',
                  filters: [{ id: date, name: date }],
                  nonResettable: true,
                },
              ])
            }}
          />
        </Flex>

        <SettingsButtons mb="s-16">
          <SwitchButton
            checked={expandToday}
            onClick={() => setExpandToday(!expandToday)}
          >
            Expand today
          </SwitchButton>
        </SettingsButtons>

        <Flex style={{ position: 'relative' }} flex="1 0">
          <ScheduleTable table={table} row={tableRow} teamId={data.id} />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default TeamSchedule
