import React, { useState } from 'react'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { useGetCandidatePerformanceProfile } from '@src/api/recruitment/interviews'
import { Avatar, Item, VStack, Widget } from '@revolut/ui-kit'
import { PerformanceSections } from '@src/pages/Forms/Candidate/Performance/Summary/PerformanceSections'
import { OverallImpressions } from '@src/pages/Forms/Candidate/Performance/Summary/OverallImpressions'
import { FilterBar } from '@src/pages/Forms/Candidate/Performance/Summary/FilterBar'

type PerformanceSummaryProps = {
  round?: InterviewRoundInterface
  onViewScorecard: (feedbackIds: number[]) => void
}

export const PerformanceSummary = ({
  round,
  onViewScorecard,
}: PerformanceSummaryProps) => {
  const [interviewersFilter, setInterviewersFilter] = useState<number[]>([])
  const { data, isLoading } = useGetCandidatePerformanceProfile(
    round?.id,
    interviewersFilter,
  )
  return (
    <Widget pb="s-16">
      <Item>
        <Item.Avatar>
          <Avatar useIcon="BarChart" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Performance summary</Item.Title>
        </Item.Content>
      </Item>
      <VStack gap="s-16">
        <FilterBar
          interviewersFilter={interviewersFilter}
          round={round}
          onChangeInterviewersFilter={filter => {
            setInterviewersFilter(filter)
          }}
        />
        <PerformanceSections
          performanceRoundSummary={data}
          loading={isLoading}
          onViewScorecard={onViewScorecard}
        />
        <OverallImpressions round={round} onViewScorecard={onViewScorecard} />
      </VStack>
    </Widget>
  )
}
