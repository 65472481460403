import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { EmployeeTotalCompensationGrantInterface } from '@src/interfaces/totalCompensation'
import { selectorKeys } from '@src/constants/api'

export const grantIdColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> = {
  type: CellTypes.text,
  idPoint: 'external_id',
  dataPoint: 'external_id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'ID',
}

export const grantDateColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> = {
  type: CellTypes.date,
  idPoint: 'effective_date',
  dataPoint: 'effective_date',
  sortKey: 'effective_date',
  filterKey: 'effective_date',
  filterType: FilterType.shortDate,
  selectorsKey: selectorKeys.none,
  title: 'Date',
}

export const grantReasonColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.text,
    idPoint: 'reason',
    dataPoint: 'reason',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Reason',
  }

export const grantScheduleColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.text,
    idPoint: 'vesting_schedule_description',
    dataPoint: 'vesting_schedule_description',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Schedule',
  }

export const grantStartDateColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.date,
    idPoint: 'vesting_start_date',
    dataPoint: 'vesting_start_date',
    sortKey: 'vesting_start_date',
    filterKey: 'vesting_start_date',
    filterType: FilterType.shortDate,
    selectorsKey: selectorKeys.none,
    title: 'Start date',
  }

export const grantGrantedSharesColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.text,
    idPoint: 'total_quantity',
    dataPoint: 'total_quantity',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Granted shares',
  }

export const grantVestedSharesColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.text,
    idPoint: 'vested_quantity',
    dataPoint: 'vested_quantity',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Vested shares',
  }

export const grantUnvestedSharesColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.text,
    idPoint: 'unvested_quantity',
    dataPoint: 'unvested_quantity',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Unvested shares',
  }

export const grantCancelledSharesColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.text,
    idPoint: 'cancelled_quantity',
    dataPoint: 'cancelled_quantity',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Cancelled shares',
  }
