import React, { PropsWithChildren } from 'react'
import {
  Flex,
  Text,
  Token,
  IconName,
  Avatar,
  Ellipsis,
  AvatarProps,
} from '@revolut/ui-kit'

interface ItemCellProps {
  useIcon?: IconName
  iconProps?: Omit<AvatarProps, 'useIcon'>
}

const ItemCellBase = ({
  useIcon,
  iconProps,
  children,
}: PropsWithChildren<ItemCellProps>) => {
  return (
    <Flex gap="s-16" alignItems="center">
      {useIcon ? <Avatar useIcon={useIcon} {...(iconProps || {})} /> : null}
      <Ellipsis>{children}</Ellipsis>
    </Flex>
  )
}

const ItemCellTitle = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Text variant="primary">
      <Ellipsis>{children}</Ellipsis>
    </Text>
  )
}

const ItemCellValue = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Text variant="caption" color={Token.color.greyTone50}>
      <Ellipsis>{children}</Ellipsis>
    </Text>
  )
}

export const ItemCell = Object.assign(ItemCellBase, {
  Title: ItemCellTitle,
  Value: ItemCellValue,
})
