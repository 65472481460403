import React, { useEffect, useMemo } from 'react'
import { FilterInputSelectorType } from '../FilterSelect'
import { OptionInterface } from '@src/interfaces/selectors'
import { FilterByInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  Ellipsis,
  FilterButton,
  FilterButtonSkeleton,
  Flex,
  Icon,
  RadioSelect,
  Text,
  Token,
  useDropdown,
} from '@revolut/ui-kit'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import capitalize from 'lodash/capitalize'
import { getCycleOffsetTitleAndColor } from '@src/utils/performance'
import { OptionItem } from './OptionItem'

interface Props {
  onFilterChange: (filters: FilterByInterface, reset?: boolean) => void
  columnName: string
  label?: string
  filter: FilterByInterface[]
  selector?: FilterInputSelectorType<OptionInterface>
  onChangeCallback?: (val: OptionInterface) => void
}

export const CycleFilterButton = ({
  onFilterChange,
  filter,
  columnName,
  selector = selectorKeys.cycle_offsets,
  label = 'Performance cycle',
  onChangeCallback,
}: Props) => {
  const { options, asyncState } = useFetchOptions<OptionInterface>(selector)
  const dropdown = useDropdown()

  const filteredFilter = useMemo(
    () =>
      filter
        ?.find((filterBy: FilterByInterface) => filterBy.columnName === columnName)
        ?.filters.filter(option => option.id !== '' && option.name !== '')?.[0],
    [filter],
  )

  const selectedPeriod = options.find(
    option => String(option.value.id) === String(filteredFilter?.id),
  )

  useEffect(() => {
    if (selectedPeriod?.value) {
      onChangeCallback && onChangeCallback(selectedPeriod.value)
    }
  }, [selectedPeriod?.value.id])

  const selectedOffset =
    selectedPeriod?.value.offset === undefined
      ? selectedPeriod?.value.id
      : selectedPeriod?.value.offset

  const { title: tagTitle } = getCycleOffsetTitleAndColor(Number(selectedOffset))

  if (asyncState !== 'ready') {
    return <FilterButtonSkeleton width={200} />
  }
  return (
    <>
      <FilterButton
        useIcon="Calendar"
        aria-haspopup="listbox"
        aria-expanded={dropdown.state}
        {...dropdown.getAnchorProps()}
      >
        <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
          <Ellipsis>
            <Text variant="emphasis2">{selectedPeriod?.label || 'Select cycle'}</Text>
          </Ellipsis>
          <Text variant="emphasis2" color={Token.color.greyTone50}>
            {capitalize(tagTitle)}
          </Text>
          <Icon name="ChevronDown" />
        </Flex>
      </FilterButton>
      <RadioSelect
        {...dropdown.getTargetProps()}
        label={label}
        options={options}
        value={selectedPeriod?.value}
        onChange={val => {
          if (val) {
            onFilterChange(
              {
                columnName,
                filters: [val],
              },
              false,
            )
            dropdown.toggle()
          }
        }}
        labelList="People"
      >
        {option => <OptionItem label={option.label} value={option.value} />}
      </RadioSelect>
    </>
  )
}
