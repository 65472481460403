import { Circle, Flex, Icon, Tag, Text, Token } from '@revolut/ui-kit'
import {
  FinalGrade,
  PerfReviewRequestFeedbackInterface,
  ReviewCategory,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { CheckpointType, ProbationCheckpoint } from '@src/interfaces/probationReview'
import React from 'react'
import { CommitteeStepReviewers } from './CommitteeStepReviewers'
import { RecommendationStepReviewers } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/RecommendationStepReviewers'
import { CheckpointStepReviewers } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/CheckpointStepReviewers'
import { GoalsStepReviewers } from './GoalsStepReviewers'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { Statuses } from '@src/interfaces'
import { gradeSettings } from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { EmptyTimelineElement } from '@src/pages/EmployeeProfile/Layout/Performance/PerfomanceStepDetails'

interface Props {
  type: CheckpointType
  canAddGoals: boolean
  checkpoint?: ProbationCheckpoint | null
  requests: PerfReviewRequestFeedbackInterface[] | undefined
  reviewCategory: ReviewCategory
  cycleId: string
  employeeId: number
  performanceLink: string | undefined
  summaryCheckpoint: ReviewSummaryInterface | undefined
  lineManager: EmployeeOptionInterface
  goalsStatus: Statuses.approved | Statuses.pending_approval | null | undefined
  onClickAddGoals: () => void
  isCreateGoalPending: boolean
  finalGrade: FinalGrade | undefined
}

export const ProbationStepDetails = ({
  type,
  finalGrade,
  canAddGoals,
  checkpoint,
  requests,
  reviewCategory,
  cycleId,
  employeeId,
  performanceLink,
  summaryCheckpoint,
  onClickAddGoals,
  isCreateGoalPending,
  lineManager,
  goalsStatus,
}: Props) => {
  const getContent = () => {
    switch (type) {
      case CheckpointType.Goals:
        return (
          <GoalsStepReviewers
            reviewCategory={reviewCategory}
            onClickAddGoals={onClickAddGoals}
            isCreateGoalPending={isCreateGoalPending}
            canAddGoals={canAddGoals}
            goalsStatus={goalsStatus}
            lineManager={lineManager}
          />
        )
      case CheckpointType.Review:
        return (
          <CheckpointStepReviewers
            isNewDesign
            checkpoint={checkpoint!}
            requests={requests}
            grades={summaryCheckpoint?.summary?.final_grades}
            reviewCategory={reviewCategory}
            performanceLink={performanceLink}
          />
        )
      case CheckpointType.Decision:
        return (
          <CommitteeStepReviewers
            isNewDesign
            checkpoint={checkpoint!}
            reviewCategory={reviewCategory}
            cycleId={cycleId}
            employeeId={employeeId}
          />
        )
      case CheckpointType.Recommendation:
        return (
          <RecommendationStepReviewers
            isNewDesign
            checkpoint={checkpoint!}
            reviewCategory={reviewCategory}
            cycleId={cycleId}
            employeeId={employeeId}
          />
        )
      default:
        return finalGrade ? (
          <Flex pr="s-20" style={{ flexGrow: 1 }} justifyContent="space-between">
            <Flex gap="s-16">
              {finalGrade && (
                <Circle bg={gradeSettings[finalGrade].color} size={40}>
                  <Icon name="Trophy" size={24} />
                </Circle>
              )}
              <Flex gap="s-2" flexDirection="column">
                <Text whiteSpace="nowrap" variant="emphasis1">
                  {finalGrade ? gradeSettings[finalGrade].title : 'N/A'}
                </Text>
                {finalGrade && (
                  <Text
                    color={Token.color.greyTone50}
                    whiteSpace="nowrap"
                    variant="body2"
                  >
                    Congratulations, you’ve passed the
                    {reviewCategory === ReviewCategory.Probation
                      ? ' probation '
                      : ' PIP '}
                    period
                  </Text>
                )}
              </Flex>
            </Flex>

            {finalGrade && (
              <Tag variant="outlined" color={Token.color.success}>
                <Icon mr="s-4" name="Check" size={12} />
                Completed
              </Tag>
            )}
          </Flex>
        ) : (
          <EmptyTimelineElement />
        )
    }
  }

  return (
    <Flex style={{ flexGrow: 1 }} justifyContent="space-between">
      {getContent()}
    </Flex>
  )
}
