import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'lape'
import { Box, BoxProps, Flex, Text, VStack, Token, opacity } from '@revolut/ui-kit'
import { SCROLL_ERROR_HASH } from '@src/constants/performance'
import {
  BehaviourAssessmentItem,
  ChangeScorecardInterface,
  PerformanceReviewTypes,
  ReviewCategory,
  ScorecardError,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { ScorecardBehaviourLevel } from '@components/ScorecardGeneral/ScorecardBehaviourLevel'

interface ScorecardCultureValueSectionProps extends Omit<BoxProps, 'onChange'> {
  category: ReviewCategory
  card: ValueBasedCardInterface
  cardIndex: number
  errors?: ScorecardError
  onChange?: (params: ChangeScorecardInterface) => void
  resultLoading?: boolean
  sectionErrors?: ScorecardError[] | null
  superpowersCount: number
  touched?: boolean
  type: PerformanceReviewTypes
  valueId: number
  viewMode?: boolean
}

export const ScorecardValueBasedSectionRevamped = connect(
  ({
    category,
    card,
    cardIndex,
    errors,
    onChange,
    resultLoading,
    sectionErrors,
    superpowersCount,
    touched,
    type,
    viewMode,
    valueId,
    ...boxProps
  }: ScorecardCultureValueSectionProps) => {
    const { hash } = useLocation()
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (`#${valueId}` === hash) {
        ref.current?.scrollIntoView()
      }
    }, [valueId, hash])

    useEffect(() => {
      const alreadyScrolled =
        sectionErrors && sectionErrors.findIndex(section => section) < cardIndex

      if (hash === SCROLL_ERROR_HASH && !alreadyScrolled && errors) {
        ref.current?.scrollIntoView()
      }
    }, [hash])

    return (
      <Box data-testid={`card-${valueId}`} ref={ref} {...boxProps}>
        <Flex
          flexDirection="column"
          width="100%"
          borderRadius={Token.radius.r12}
          backgroundColor={opacity(Token.colorChannel.layoutBackground, 0.8)}
          p="s-12"
        >
          <Text fontSize="caption" color={Token.color.greyTone50}>
            {card.description}
          </Text>
          <VStack mt="s-24" gap="s-16">
            {card.sections.map((section, sectionIndex) => {
              return (
                <ScorecardBehaviourLevel
                  cardIndex={cardIndex}
                  content={section as BehaviourAssessmentItem}
                  error={errors?.sections?.[sectionIndex]}
                  key={sectionIndex}
                  onChange={onChange}
                  sectionIndex={sectionIndex}
                  superpowersCount={superpowersCount}
                  touched={touched}
                  type={type}
                  viewMode={viewMode}
                />
              )
            })}
          </VStack>
        </Flex>
      </Box>
    )
  },
)
