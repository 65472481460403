import React from 'react'
import { DetailsCell, Flex, Group, Tag } from '@revolut/ui-kit'
import { BarChart } from '@revolut/icons'
import { useLookerDashboard } from '@src/api/analyticsDashboards'
import {
  AnalyticsDashboardInterface,
  GenericAnalyticsDashboardInterface,
} from '@src/interfaces/analyticsDashboards'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import SideBar from '@components/SideBar/SideBar'

interface DetailsSideBarProps {
  dashboard?: GenericAnalyticsDashboardInterface
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

interface LookerDetailsProps {
  dashboardId: number
}

const LookerDetails = ({ dashboardId }: LookerDetailsProps) => {
  const { data: dashboardData } = useLookerDashboard(dashboardId)
  return (
    <FormPreview data={dashboardData} title="">
      <Group>
        <FormPreview.Item field="name" title="Name" />
        <FormPreview.Details field="description" title="Description" />
        <FormPreview.Item field="owner" title="Owner" type="employee" />
        <FormPreview.Context<AnalyticsDashboardInterface>
          insert={({ related_kpis }) => (
            <>
              <DetailsCell>
                <DetailsCell.Title>Linked KPIs</DetailsCell.Title>
                {!related_kpis.length && <DetailsCell.Content>-</DetailsCell.Content>}
              </DetailsCell>
              {!!related_kpis.length && (
                <Flex flexWrap="wrap" gap="s-4" px="s-16">
                  {related_kpis.map(item => (
                    <Tag key={item.id} variant="faded">
                      {item.name}
                    </Tag>
                  ))}
                </Flex>
              )}
            </>
          )}
        />
        <FormPreview.Item field="dashboard_type" title="Type" type="capitalized" />
        <FormPreview.Item field="content_url" title="Link to external" type="link" />
        <FormPreview.Item field="creation_date_time" title="Created on" type="dateTime" />
        <FormPreview.Item
          field="update_date_time"
          title="Last updated on"
          type="dateTime"
        />
      </Group>
    </FormPreview>
  )
}

export const DetailsSideBar = ({ dashboard, isOpen, setIsOpen }: DetailsSideBarProps) => {
  return (
    <SideBar
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Dashboard Details"
      useIcon={BarChart}
    >
      {dashboard?.dashboard_type === 'looker' ? (
        <LookerDetails dashboardId={dashboard.id} />
      ) : null}

      {dashboard?.dashboard_type === 'internal' ? (
        <FormPreview data={dashboard} title="">
          <Group>
            <FormPreview.Item field="name" title="Name" />
            <FormPreview.Details field="description" title="Description" />
            <FormPreview.Item field="owner" title="Owner" type="employee" />
            <FormPreview.Item
              field="creation_date_time"
              title="Created on"
              type="dateTime"
            />
            <FormPreview.Item
              field="update_date_time"
              title="Last updated on"
              type="dateTime"
            />
          </Group>
        </FormPreview>
      ) : null}
    </SideBar>
  )
}
