import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { DocumentsCategoriesInterface } from '@src/interfaces/documentsCategories'

export const documentCategoryName: ColumnInterface<DocumentsCategoriesInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.document_categories,
  title: 'Name',
}
