import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { Statuses } from '@src/interfaces'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import { Token } from '@revolut/ui-kit'
import DOMPurify from 'dompurify'

export const interviewScorecardTemplateNameColumn: ColumnInterface<InterviewScorecardTemplateInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.interview_scorecard_templates,
    title: 'Name',
  }

export const interviewScorecardTemplateObjectiveColumn: ColumnInterface<InterviewScorecardTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'interview_objective',
    dataPoint: 'interview_objective',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Interview Objective',
    insert: ({ data }) =>
      data.interview_objective
        ? DOMPurify.sanitize(data.interview_objective, {
            ALLOWED_TAGS: [],
          })
        : '-',
  }

export const interviewScorecardTemplateStatusColumn: ColumnInterface<InterviewScorecardTemplateInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status',
    dataPoint: 'status',
    filterKey: 'status',
    sortKey: 'status',
    selectorsKey: selectorKeys.interview_scorecard_template_statuses,
    colors: data => {
      switch (data.status) {
        case Statuses.completed:
          return Token.color.green
        case Statuses.draft:
          return Token.color.greyTone50
        default:
          return Token.color.accent
      }
    },
    title: 'Status',
  }

export const interviewScorecardTemplateActionColumn: ColumnInterface<InterviewScorecardTemplateInterface> =
  {
    type: CellTypes.insert,
    dataPoint: 'action',
    idPoint: 'action',
    sortKey: null,
    notHoverable: true,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Action',
  }
