import React from 'react'
import { Cell, Color, Flex } from '@revolut/ui-kit'

import { useTable } from '@src/components/Table/hooks'
import {
  getDepartmentScheduleShifts,
  getDepartmentScheduleShiftsStats,
} from '@src/api/attendance'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { RowInterface } from '@src/interfaces/data'
import { ScheduleShiftSummaryInterface } from '@src/interfaces/attendance'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import {
  shiftSummaryActionColumn,
  shiftSummaryApprovalColumn,
  shiftSummaryDateColumn,
  shiftSummaryEmployeeColumn,
  shiftSummaryEndColumn,
  shiftSummaryLocationColumn,
  shiftSummaryOvertimeDurationColumn,
  shiftSummaryRegularDurationColumn,
  shiftSummaryStartColumn,
  shiftSummaryTeamColumn,
  shiftSummaryUnsocialDurationColumn,
} from '@src/constants/columns/attendance'
import Stat from '@src/components/Stat/Stat'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { EntityPermissions } from '@src/store/auth/types'
import { TableNames } from '@src/constants/table'

const Row = (
  onActionComplete: () => void,
): RowInterface<ScheduleShiftSummaryInterface> => ({
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.SCHEDULE_SHIFT.PREVIEW, {
        employeeId: data.employee.id,
        id: data.id,
      }),
    ),
  cells: [
    {
      ...shiftSummaryDateColumn,
      width: 150,
    },
    {
      ...shiftSummaryEmployeeColumn,
      width: 200,
    },
    {
      ...shiftSummaryTeamColumn,
      width: 200,
    },
    {
      ...shiftSummaryLocationColumn,
      width: 100,
    },
    {
      ...shiftSummaryRegularDurationColumn,
      width: 100,
    },
    {
      ...shiftSummaryOvertimeDurationColumn,
      width: 100,
    },
    {
      ...shiftSummaryUnsocialDurationColumn,
      width: 100,
    },
    {
      ...shiftSummaryStartColumn,
      width: 100,
    },
    {
      ...shiftSummaryEndColumn,
      width: 100,
    },
    {
      ...shiftSummaryApprovalColumn,
      width: 100,
    },
    {
      ...shiftSummaryActionColumn(onActionComplete),
      width: 200,
    },
  ],
})

interface DepartmentScheduleProps {
  data: DepartmentInterface
  navigation?: React.ReactElement
}

const DepartmentSchedule = ({ data, navigation }: DepartmentScheduleProps) => {
  const table = useTable({
    getItems: getDepartmentScheduleShifts(data.id),
    getStats: getDepartmentScheduleShiftsStats(data.id),
  })

  const canApproveOrReject = data.field_options?.permissions?.includes(
    EntityPermissions.ApproveSchedule,
  )

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        {navigation}
        <Flex mb="s-24" gap="s-32">
          <Stat
            label="Shifts"
            val={table?.stats?.total != null ? table.stats.total : undefined}
          />
          <Stat
            label="Pending approval"
            val={table?.stats?.pending != null ? table.stats.pending : undefined}
            color={Color.ORANGE}
          />
          <Stat
            label="Approved"
            val={table?.stats?.approved != null ? table.stats.approved : undefined}
            color={Color.GREEN}
          />
          <Stat
            label="Rejected"
            val={table?.stats?.rejected != null ? table.stats.rejected : undefined}
            color={Color.RED}
          />
        </Flex>

        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.DepartmentSchedule}
            row={Row(table.refresh)}
            useWindowScroll
            {...table}
            hiddenCells={{ actions: !canApproveOrReject }}
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default DepartmentSchedule
