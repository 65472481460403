import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import Requests from '@src/pages/People/PeopleSubTabs/TimeOffPage/Requests'
import Policies from '@src/pages/People/PeopleSubTabs/TimeOffPage/Policies'

export const TimeOffPage = () => {
  const tabs = [
    {
      title: 'Requests',
      key: 'requests' as const,
      path: ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.REQUESTS,
      to: ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.REQUESTS,
      component: Requests,
    },
    {
      title: 'Policies',
      key: 'policies' as const,
      path: ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.POLICIES,
      to: ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.POLICIES,
      component: Policies,
    },
  ]

  return (
    <PageWrapper>
      <PageHeader backUrl={ROUTES.APPS.TIME_MANAGEMENT.TIME_MANAGEMENT} title="Time Off">
        <TabBarNavigation mb="s-16" tabs={tabs} />
      </PageHeader>

      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}
