import React from 'react'
import { Flex, Cell, Color, FilterButton, Bar } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Stat from '@components/Stat/Stat'
import { getOffboardingQueue, getOffboardingQueueStats } from '@src/api/offboarding'
import {
  offboardingQueueAccessRetentionApprovalStatusColumn,
  offboardingQueueEmployeeColumn,
  offboardingQueueHRManagerColumn,
  offboardingQueueLastDayInOfficeColumn,
  offboardingQueueLastDayOfContractColumn,
  offboardingQueueLMColumn,
  offboardingQueueLocationColumn,
  offboardingQueueSeniorityColumn,
  offboardingQueueSpecialisationColumn,
  offboardingQueueStatusColumn,
} from '@src/constants/columns/offboarding'
import {
  OffboardingQueueInterface,
  OffboardingQueueStatsInterface,
} from '@src/interfaces/offboarding'
import { selectUser } from '@src/store/auth/selectors'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { TableNames } from '@src/constants/table'
import { useGetLifecycleSettings } from '@src/api/settings'

const Row: RowInterface<OffboardingQueueInterface> = {
  linkToForm: ({ employee }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.OFFBOARDING.DASHBOARD, { employeeId: employee.id }),
    ),
  cells: [
    {
      ...offboardingQueueEmployeeColumn,
      width: 200,
    },
    {
      ...offboardingQueueSpecialisationColumn,
      width: 200,
    },
    {
      ...offboardingQueueSeniorityColumn,
      width: 100,
    },
    {
      ...offboardingQueueLocationColumn,
      width: 100,
    },
    {
      ...offboardingQueueLMColumn,
      width: 100,
    },
    {
      ...offboardingQueueLastDayInOfficeColumn,
      width: 150,
    },
    {
      ...offboardingQueueLastDayOfContractColumn,
      width: 150,
    },
    {
      ...offboardingQueueAccessRetentionApprovalStatusColumn,
      width: 200,
    },
    {
      ...offboardingQueueStatusColumn,
      width: 200,
    },
    {
      ...offboardingQueueHRManagerColumn,
      width: 200,
    },
  ],
}

const OffboardingTable = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_OFFBOARDING_ASSIGNED_TO_ME,
    false,
  )

  const user = useSelector(selectUser)

  const { data: lifecycleSettings } = useGetLifecycleSettings()

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'hr_manager__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = [
      {
        columnName: 'status',
        filters: [
          {
            id: 'pending',
            name: 'Pending',
          },
        ],
        nonResettable: true,
      },
    ]

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const table = useTable<OffboardingQueueInterface, OffboardingQueueStatsInterface>(
    {
      getItems: getOffboardingQueue,
      getStats: getOffboardingQueueStats,
    },
    getInitialFilters(),
  )

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-16" gap="s-32">
          <Stat label="Total" val={table.stats?.total} />
          <Stat label="Pending" val={table.stats?.pending} color={Color.ORANGE} />
        </Flex>
        <Flex mb="s-16" flexDirection="row-reverse">
          <Bar>
            <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
              Assigned to me
            </FilterButton>
          </Bar>
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.Offboarding}
            useWindowScroll
            dataType="Employee"
            row={Row}
            {...table}
            noDataMessage="Offboarding employees will appear here."
            hiddenCells={{
              [offboardingQueueAccessRetentionApprovalStatusColumn.idPoint]:
                !lifecycleSettings?.enable_access_retention,
            }}
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default OffboardingTable
