import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { SendMessage } from '@revolut/icons'
import { SigningStatuses } from '@src/interfaces/offerCreation'
import SendOfferAndEmailSidebar from './SendOfferAndEmailSidebar'
import { useGetOfferSettings } from '@src/api/settings'

type Props = {
  status?: SigningStatuses
  onRefresh: () => void
}

const SendOfferButton = ({ status, onRefresh }: Props) => {
  const [openSendOfferAndEmailSidebar, setOpenSendOfferAndEmailSidebar] = useState(false)
  const { data: offerSettings } = useGetOfferSettings()
  return (
    <>
      {openSendOfferAndEmailSidebar && (
        <SendOfferAndEmailSidebar
          onRefresh={() => {
            setOpenSendOfferAndEmailSidebar(false)
            onRefresh()
          }}
        />
      )}
      <MoreBar.Action
        useIcon={SendMessage}
        onClick={() => setOpenSendOfferAndEmailSidebar(true)}
      >
        {!offerSettings?.enable_offer_signing
          ? 'Send offer'
          : status === 'offer_not_sent'
          ? 'Send offer'
          : 'Re-send offer'}
      </MoreBar.Action>
    </>
  )
}

export default SendOfferButton
