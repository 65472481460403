import {
  Circle,
  Flex,
  Icon,
  Text,
  Token,
  TransitionCollapse,
  VStack,
  chain,
} from '@revolut/ui-kit'
import { getPercentColor } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { FinalGrade, PerformanceRating, Ratings } from '@src/interfaces/performance'
import { formatPercentage } from '@src/utils/format'
import React, { ReactNode, useState } from 'react'
import { RatingWithTooltip } from './RatingWithTooltip'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { getCellBackgroundNewDesign } from '@src/constants/columns/performanceSummary'
import { gradeSettings } from '@src/pages/EmployeeProfile/Layout/Performance/utils'

const MIN_CELL_HEIGHT = 72
const HEADERS_OFFSET = 42
const TABLE_MIN_HEIGHT = 310

export const CollapsableTableWrapper = ({
  children,
  headerTitle,
  headerStat,
  headerRating,
  headerGrade,
  completedReviews,
  icon,
  testId,
  count,
  noHeadersOffset,
  readOnly,
  ratings,
}: {
  children: React.ReactNode
  headerTitle: string
  headerStat?: number
  headerRating?: PerformanceRating | null
  headerGrade?: FinalGrade
  completedReviews?: boolean
  icon?: ReactNode
  testId?: string
  count?: number
  noHeadersOffset?: boolean
  readOnly?: boolean
  ratings?: Ratings[]
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!!readOnly || headerTitle === 'Goals')

  const onCollapse = () => {
    if (readOnly) {
      return
    }
    setIsCollapsed(!isCollapsed)
  }

  return (
    <VStack
      bg={Token.color.grey50_10}
      space="s-16"
      data-testid={testId || `card-table-${headerTitle}`}
      style={{ borderRadius: Token.radius.r16 }}
    >
      <Flex
        style={{ cursor: readOnly ? '' : 'pointer' }}
        onClick={onCollapse}
        padding="s-16"
        justifyContent="space-between"
        alignItems="center"
        data-testid="collapsable-table-wrapper"
      >
        <Flex gap="s-16" alignItems="center">
          {!readOnly && (
            <Icon
              color={Token.color.greyTone50}
              name={isCollapsed ? 'ChevronDown' : 'ChevronRight'}
              size={24}
            />
          )}
          {icon && (
            <Circle size={40} bg={Token.color.accent}>
              {icon}
            </Circle>
          )}
          <Text variant="emphasis1" color={Token.color.foreground}>
            {chain(
              headerTitle,
              headerStat ? (
                <Text color={getPercentColor((headerStat || 0) * 100)}>
                  {formatPercentage(headerStat || 0)}
                </Text>
              ) : undefined,
            )}
          </Text>
        </Flex>

        {completedReviews &&
          headerRating &&
          (ratings ? (
            <RatingWithTooltip
              showAsText
              getTitle={perfRating => {
                return PerformanceRatingTitle[perfRating]
              }}
              rating={headerRating}
              ratings={ratings}
            />
          ) : (
            <Text
              color={getCellBackgroundNewDesign(headerRating)}
              variant="heading3"
              data-testid="card-result"
            >
              {PerformanceRatingTitle[headerRating]}
            </Text>
          ))}
        {completedReviews && headerGrade && (
          <Text color={gradeSettings[headerGrade]?.color} variant="heading3">
            {gradeSettings[headerGrade]?.title}
          </Text>
        )}
      </Flex>
      <TransitionCollapse in={isCollapsed}>
        <Flex
          style={{ position: 'relative', fontWeight: 400 }}
          minHeight={
            count
              ? count * MIN_CELL_HEIGHT + (noHeadersOffset ? 0 : HEADERS_OFFSET)
              : TABLE_MIN_HEIGHT
          }
          flex="1 0"
          pb="s-12"
        >
          {children}
        </Flex>
      </TransitionCollapse>
    </VStack>
  )
}
