import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'lape'
import { dashboardRequestsNew } from '@src/api/analyticsDashboards'
import { ROUTES } from '@src/constants/routes'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DashboardForm } from './DashboardForm'

const DashboardView = connect(() => {
  const { values } = useLapeContext<AnalyticsDashboardInterface>()

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.FORMS.COMPANY.ANALYTICS_DASHBOARDS}
        title={values.name}
      />
      <Route exact path={ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD}>
        <DashboardForm />
      </Route>
    </PageWrapper>
  )
})

const DashboardViewForm = () => {
  return (
    <Form api={dashboardRequestsNew}>
      <DashboardView />
    </Form>
  )
}

export default connect(DashboardViewForm)
