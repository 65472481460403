export const HR_VIDEO_URL = 'https://assets.revolut.com/revoluters/assets/hr.mp4'
export const PERFORMANCE_VIDEO_URL =
  'https://assets.revolut.com/revoluters/assets/performance.mp4'
export const RECRUITMENT_VIDEO_URL =
  'https://assets.revolut.com/revoluters/assets/recruitment.mp4'
export const HR_IMG = 'https://assets.revolut.com/revoluters/assets/hr-poster.png'
export const PERFORMANCE_IMG =
  'https://assets.revolut.com/revoluters/assets/performance-poster.png'
export const RECRUITMENT_IMG =
  'https://assets.revolut.com/revoluters/assets/recruitment-poster.png'
export const TESTIMONIAL_1_IMG =
  'https://assets.revolut.com/revoluters/assets/testimonial-1.png'
export const TESTIMONIAL_2_IMG =
  'https://assets.revolut.com/revoluters/assets/testimonial-2.png'
export const TESTIMONIAL_3_IMG =
  'https://assets.revolut.com/revoluters/assets/testimonial-3.png'
export const HERO_IMG = 'https://assets.revolut.com/revoluters/assets/hero-bg.jpg'
export const HERO_ICONS = 'https://assets.revolut.com/revoluters/assets/hero-icons.png'
export const HERO_MOBILE = 'https://assets.revolut.com/revoluters/assets/hero-mobile.png'
export const HERO_LG = 'https://assets.revolut.com/revoluters/assets/hero-lg.png'
