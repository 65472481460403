import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Token } from '@revolut/ui-kit'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { ReviewInterface } from '@src/interfaces/performance'
import Tooltip from '@components/Tooltip/Tooltip'
import { pathToUrl } from '@src/utils/router'

export const BtnWrapper = styled.div<{ showCounts?: boolean }>`
  position: relative;

  &:not(:first-child) {
    margin-left: ${props => (props.showCounts ? 27 : 8)}px;
  }
`

export const Circle = styled.div<{ checked: boolean; disabled?: boolean }>`
  width: 9.6px;
  height: 9.6px;
  border-radius: 50%;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${props => {
      if (!props.checked && props.disabled) {
        return Token.color.greyTone20
      }
      if (props.checked) {
        return Token.color.foreground
      }
      return Token.color.greyTone50
    }};

  &::before {
    content: '';
    display: block;
    width: 6.9px;
    height: 6.9px;
    border-radius: 50%;
    background-color: ${props => {
      if (props.checked) {
        return Token.color.foreground
      }
      return 'transparent'
    }};
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const Count = styled.div`
  font-size: 10px;
  position: absolute;
  right: 35px;
  line-height: 24px;
`

export const StyledTooltip = styled(Tooltip)`
  height: 100%;
`

const TooltipContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 4px;
  padding: 11px;
  font-size: 11px;
  color: ${Token.color.widgetBackground};
`

const TooltipTitle = styled.div`
  font-weight: bold;
  text-transform: capitalize;
`

const TooltipReviewer = styled(Link)`
  color: ${Token.color.widgetBackground};
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`

const ReviewContainer = styled.div`
  white-space: nowrap;
`

export const ButtonTooltip = ({
  children,
  reviewsList,
}: {
  children: string
  reviewsList: ReviewInterface[]
}) => {
  return (
    <TooltipContainer>
      <TooltipTitle>{children}</TooltipTitle>
      {reviewsList.map(review => {
        return review.reviewer.id ? (
          <ReviewContainer>
            <TooltipReviewer
              to={getLocationDescriptor(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                  id: review.reviewer.id,
                }),
              )}
            >
              {review.reviewer.display_name}
            </TooltipReviewer>
            {review.probation_checkpoint
              ? ` (CP${review.probation_checkpoint.number})`
              : null}
          </ReviewContainer>
        ) : (
          <ReviewContainer>{review.reviewer.display_name}</ReviewContainer>
        )
      })}
    </TooltipContainer>
  )
}
