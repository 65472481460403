import React from 'react'
import type { ReactNode } from 'react'

import type { BoxProps } from '@revolut/ui-kit'
import { Box, Token } from '@revolut/ui-kit'

const TOOLTIP_RADIUS = 10
const TOOLTIP_MIN_WIDTH = 140
const TOOLTIP_OPACITY = 0.8

type LightBackgroundTooltipProps = BoxProps & {
  children: ReactNode
}

export const LightBackgroundTooltip = ({
  children,
  ...rest
}: LightBackgroundTooltipProps) => (
  <Box
    p="s-8"
    color={Token.color.background}
    opacity={TOOLTIP_OPACITY}
    backgroundColor={Token.color.foreground}
    borderRadius={TOOLTIP_RADIUS}
    minWidth={TOOLTIP_MIN_WIDTH}
    {...rest}
  >
    {children}
  </Box>
)
