import React from 'react'
import {
  Widget,
  Item,
  Avatar,
  ActionButton,
  Token,
  Toggle,
  Action,
  TransitionCollapse,
  Flex,
} from '@revolut/ui-kit'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { FormattedMessage } from 'react-intl'
import { mapAudienceIcon } from '@src/pages/Forms/Engagement/helpers'

const INITIAL_QUESTION_DISPLAY_NUMBER = 5

const QuestionsList = ({
  audieces,
}: {
  audieces: EngagementSurveyInterface['audiences']
}) => (
  <Flex flexDirection="column" gap="s-8">
    {audieces.map(audience => (
      <Item key={audience.id} style={{ border: `1px solid ${Token.color.greyTone10}` }}>
        <Item.Avatar>
          <Avatar useIcon={mapAudienceIcon(audience.audience_type.id)} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{audience.name}</Item.Title>
          <Item.Description>
            <FormattedMessage
              id="engagement.audiences.headcount"
              values={{ count: audience.headcount }}
              defaultMessage="{count, plural, one {# employee} other {# employees}}"
            />
          </Item.Description>
        </Item.Content>
      </Item>
    ))}
  </Flex>
)

export const Audience = ({
  audiences,
  onEdit,
}: Pick<EngagementSurveyInterface, 'audiences'> & { onEdit: VoidFunction }) => {
  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Chat" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="engagement.audiences.title"
              values={{ count: audiences.length }}
              defaultMessage="Audience · {count}"
            />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="engagement.audiences.description"
              defaultMessage="What employees are eligible to respond"
            />
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton onClick={onEdit}>
            <FormattedMessage id="common.edit" defaultMessage="Edit" />
          </ActionButton>
        </Item.Side>
      </Item>
      <Flex p="s-16" gap="s-8" pt="0" flexDirection="column">
        <Toggle>
          {({ state, toggle }) => (
            <>
              <QuestionsList
                audieces={audiences.slice(0, INITIAL_QUESTION_DISPLAY_NUMBER)}
              />
              <TransitionCollapse in={state}>
                <QuestionsList
                  audieces={audiences.slice(INITIAL_QUESTION_DISPLAY_NUMBER)}
                />
              </TransitionCollapse>
              {audiences.length > INITIAL_QUESTION_DISPLAY_NUMBER && (
                <Flex justifyContent="center">
                  <Action onClick={() => toggle()}>
                    {state ? 'Collapse' : 'See all'}
                  </Action>
                </Flex>
              )}
            </>
          )}
        </Toggle>
      </Flex>
    </Widget>
  )
}
