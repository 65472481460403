import * as React from 'react'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import { useFormValidator } from '@src/features/Form/FormValidator'
import Editor, { EditorProps } from '@components/Editor/Editor'

interface Props extends Omit<EditorProps, 'value' | 'onChange' | 'label'> {
  name: string
  value?: string
  disabled?: boolean
  onAfterChange?: (value?: string) => void
  /** Marks field as optional otherwise */
  required?: boolean
}

const LapeEditor = ({ name, onAfterChange = () => {}, ...props }: Props) => {
  const lapeProps = useLapeField(name)
  const formValidator = useFormValidator()
  const { value, onChange, hidden } = lapeProps

  if (hidden) {
    return null
  }

  return (
    <Editor
      onChange={(val?: string) => {
        onChange(val || undefined)
        onAfterChange(val || undefined)
      }}
      value={value !== undefined ? value : ''}
      {...props}
      {...getCommonInputProps({ ...props, name }, lapeProps, !!formValidator?.validated)}
    />
  )
}

export default connect(LapeEditor)
