import React, { useCallback, useMemo, useState } from 'react'
import { Button, Flex, Input, InputGroup, Side } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import {
  getSpecialisationSublevels,
  renameSenioritySublevel,
} from '@src/api/specialisations'
import { SenioritySublevelInterface } from '@src/interfaces/seniority'
import { CellInsertParams, CellTypes, RowInterface } from '@src/interfaces/data'
import {
  parentSeniorityNameColumn,
  seniorityDefaultLevelTitleColumn,
  senioritySublevelRankColumn,
  senioritySublevelTitleColumn,
} from '@src/constants/columns/seniority'
import SideBar from '@components/SideBar/SideBar'
import { selectorKeys } from '@src/constants/api'
import { TextButton } from '@revolut/ui-kit'
import { TableNames } from '@src/constants/table'

interface Props {
  id: number
  canEdit?: boolean
}
interface SublevelSidebarProps {
  specialisationId: number
  sublevel: SenioritySublevelInterface | null
  onAfterSubmit: () => void
}

const getRow = (
  onEdit: (sublevel: SenioritySublevelInterface) => void,
  canEdit?: boolean,
): RowInterface<SenioritySublevelInterface> => ({
  cells: [
    {
      ...senioritySublevelRankColumn,
      width: 80,
    },
    {
      ...parentSeniorityNameColumn,
      width: 110,
    },
    {
      ...seniorityDefaultLevelTitleColumn,
      width: 110,
    },
    {
      ...senioritySublevelTitleColumn,
      width: 220,
    },
    ...(canEdit
      ? [
          {
            type: CellTypes.insert,
            idPoint: 'settings',
            dataPoint: 'settings',
            sortKey: null,
            filterKey: null,
            selectorsKey: selectorKeys.none,
            title: 'Settings',
            insert: ({ data }: CellInsertParams<SenioritySublevelInterface>) => (
              <TextButton
                onClick={e => {
                  e.stopPropagation()
                  onEdit(data)
                }}
              >
                Edit
              </TextButton>
            ),
            width: 300,
          },
        ]
      : []),
  ],
})

const SpecialisationSublevels = ({ id, canEdit }: Props) => {
  const table = useTable<SenioritySublevelInterface>({
    getItems: () => getSpecialisationSublevels(id),
  })
  const [selectedSublevel, setSelectedSublevel] =
    useState<SenioritySublevelInterface | null>(null)

  const onEdit = useCallback((sublevel: SenioritySublevelInterface) => {
    setSelectedSublevel(sublevel)
  }, [])

  const row = useMemo(() => getRow(onEdit, canEdit), [onEdit, canEdit])

  return (
    <>
      <Flex flexDirection="column" maxWidth={800} pb="s-32">
        <AdjustableTable<SenioritySublevelInterface>
          name={TableNames.SpecialisationSeniorityLevels}
          hideCount
          dataType="Seniority level"
          row={row}
          {...table}
          onRowClick={canEdit ? onEdit : undefined}
          noDataMessage="Seniority levels will appear here."
        />
      </Flex>

      <SideBar
        title="Seniority levels"
        subtitle="Edit sub-levels"
        isOpen={!!selectedSublevel}
        onClose={() => setSelectedSublevel(null)}
      >
        <SublevelForm
          key={selectedSublevel?.id || 'key'}
          specialisationId={id}
          sublevel={selectedSublevel}
          onAfterSubmit={() => {
            table.refresh()
            setSelectedSublevel(null)
          }}
        />
      </SideBar>
    </>
  )
}

const SublevelForm = ({
  sublevel,
  specialisationId,
  onAfterSubmit,
}: SublevelSidebarProps) => {
  const [newName, setNewName] = useState<string>(sublevel?.job_title || '')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  if (!sublevel) {
    return null
  }

  const submit = async () => {
    setIsSubmitting(true)
    try {
      await renameSenioritySublevel(specialisationId, sublevel.id, newName)
      onAfterSubmit()
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <InputGroup>
        <Input value={sublevel.seniority.name} disabled label="Seniority" />
        <Input value={sublevel.name} disabled label="Level" />
        <Input
          value={newName}
          onChange={e => setNewName(e.currentTarget.value)}
          label="Title"
          disabled={isSubmitting}
        />
      </InputGroup>
      <Side.Actions>
        <Button pending={isSubmitting} disabled={!newName} onClick={submit}>
          Submit
        </Button>
      </Side.Actions>
    </>
  )
}

export default SpecialisationSublevels
