import React from 'react'
import styled from 'styled-components'
import { CellTypes } from '@src/interfaces/data'
import MatrixTable, { MatrixRowInterface } from '@components/Table/MatrixTable'
import { Box, Flex, Text, Token } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import Icon from '@components/Icon/Icon'
import {
  JiraIssueInterface,
  ProbationGoalInterface,
} from '@src/interfaces/probationReview'
import AddTicketDropdown from '@src/pages/Forms/ProbationOverview/ProbationGoals/AddTicketDropdown'
import { getIconKeyByIssue } from '@src/utils/performance'
import Tooltip from '@components/Tooltip/Tooltip'
import produce from 'immer'
import { Widget } from '@revolut/ui-kit'

const Cell = styled.div`
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
`

const RemoveBtn = styled.button`
  color: ${Token.color.greyTone50};

  &:hover {
    color: ${Token.color.blue};
  }
`

const TableWrap = styled.div`
  width: 650px;
`

const RowLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const Key = styled.span`
  color: ${Token.color.blue};
`

interface Props {
  canEdit: boolean
  tickets: ProbationGoalInterface[]
  onDelete?: (id: number) => void
  onChange?: (option?: JiraIssueInterface) => void
}

const statusToColor = (status: string) => {
  switch (status) {
    case 'To Do':
      return Token.color.greyTone50
    case 'Done':
      return Token.color.green
    case 'In Progress':
      return Token.color.foreground
    default:
      return Token.color.foreground
  }
}

const ROW: MatrixRowInterface<ProbationGoalInterface> = {
  cells: [
    {
      type: CellTypes.insert,
      idPoint: 'jira_issue_type',
      dataPoint: 'jira_issue_type',
      title: 'Type',
      width: 30,
      insert: ({ data }) =>
        data.jira_issue_type ? (
          <Icon type={getIconKeyByIssue(data.jira_issue_type)} size="medium" />
        ) : (
          ''
        ),
    },
    {
      type: CellTypes.insert,
      idPoint: 'name',
      dataPoint: 'name',
      title: 'Jira',
      width: 390,
      insert: ({ data }) => (
        <Cell title={data.jira_issue_summary!}>
          <RowLink href={data.jira_issue_url!} target="_blank" rel="noreferrer noopener">
            <Key>{data.jira_issue_key}</Key>: {data.jira_issue_summary}
          </RowLink>
        </Cell>
      ),
    },
    {
      type: CellTypes.insert,
      idPoint: 'status',
      dataPoint: 'status',
      title: 'Progress',
      width: 100,
      insert: ({ data }) => (
        <Cell>
          <Flex justifyContent="flex-end">
            {data.jira_issue_status ? (
              <Text
                color={statusToColor(data.jira_issue_status)}
                style={{ textTransform: 'capitalize' }}
              >
                {data.jira_issue_status.toLowerCase()}
              </Text>
            ) : (
              ' - '
            )}
          </Flex>
        </Cell>
      ),
    },
    {
      type: CellTypes.insert,
      idPoint: 'due_date',
      dataPoint: 'due_date',
      title: 'Deadline',
      width: 100,
      insert: ({ data }) => (
        <Cell>
          {data.jira_issue_duedate ? (
            formatDate(data.jira_issue_duedate)
          ) : (
            <Tooltip placement="top" text="Due date is missing">
              -
            </Tooltip>
          )}
        </Cell>
      ),
    },
  ],
}

const Tickets = ({ canEdit, tickets, onDelete, onChange }: Props) => {
  const row = produce(ROW, draft => {
    if (canEdit && onDelete) {
      draft.cells.push({
        type: CellTypes.insert,
        idPoint: 'remove',
        dataPoint: 'remove',
        title: 'Remove',
        width: 40,
        insert: ({ data }) => (
          <Cell>
            <RemoveBtn
              type="button"
              onClick={() => {
                onDelete(data.id!)
              }}
            >
              <Icon type="Delete" size="tiny" />
            </RemoveBtn>
          </Cell>
        ),
      })
    }
  })

  const onFilter = (issue: JiraIssueInterface) =>
    !tickets.find(ticket => ticket.jira_issue === +issue.id)

  return (
    <>
      {!!tickets.length && (
        <Widget pl="s-16" pr="s-16" mt="s-16">
          <TableWrap>
            <MatrixTable
              rows={row}
              data={tickets}
              noDataMessage="No tickets defined."
              noPadding
              noHeader
              noBorder
            />
          </TableWrap>
        </Widget>
      )}

      {canEdit && onChange && (
        <Box mt="s-16">
          <AddTicketDropdown<ProbationGoalInterface>
            disabled={!canEdit}
            onFilter={onFilter}
            onChange={onChange}
            data={tickets}
          />
        </Box>
      )}
    </>
  )
}

export default Tickets
