import React from 'react'
import { Text, Token } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { WorkScheduleCustomApproversListItemInterface } from '@src/interfaces/workSchedule'
import { selectorKeys } from '../api'

export const timeOffWorkScheduleCustomApproversNameColumn: ColumnInterface<WorkScheduleCustomApproversListItemInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.custom_approvers_flows,
    title: 'Name',
  }

export const timeOffWorkScheduleCustomApproversHeadcountColumn: ColumnInterface<WorkScheduleCustomApproversListItemInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'assigned_count',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Headcount',
  }

export const timeOffWorkScheduleCustomApproversStatusColumn: ColumnInterface<WorkScheduleCustomApproversListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.custom_approvers_flow_statuses,
    title: 'Status',
    insert: ({ data }) => {
      return (
        <Text
          color={
            ['draft', 'archived'].includes(data.status.id)
              ? Token.color.greyTone50
              : Token.color.green
          }
        >
          {data.status.name}
        </Text>
      )
    },
  }
