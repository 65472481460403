import React from 'react'
import { Link } from '@revolut/ui-kit'

import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { getDataOwnership } from '@src/api/ownership'
import { DataOwnershipInterface } from '@src/interfaces/ownership'
import {
  dataOwnershipActionColumn,
  dataOwnershipDependencyColumn,
  dataOwnershipInstructionsColumn,
  dataOwnershipResourceColumn,
} from '@src/constants/columns/ownership'

import { Banner } from './common'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const ROW: RowInterface<DataOwnershipInterface> = {
  cells: [
    {
      ...dataOwnershipDependencyColumn,
      width: 80,
    },
    {
      ...dataOwnershipResourceColumn,
      width: 160,
    },
    {
      ...dataOwnershipInstructionsColumn,
      width: 500,
    },
    {
      ...dataOwnershipActionColumn,
      width: 60,
    },
  ],
}

export const dataBannerTitle =
  'Please ask the employee to transfer ownership for data dependencies'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
}

const DataOwnership = ({ data, stats }: Props) => {
  const table = useTable<DataOwnershipInterface>(getDataOwnership(data.id))

  return (
    <>
      <Banner
        title={dataBannerTitle}
        description={
          <>
            You can check{' '}
            <Link
              target="_blank"
              href="https://revolut.atlassian.net/wiki/spaces/BD/pages/1557963048/Data+Ownership+roles+responsibilities"
            >
              this confluence page
            </Link>{' '}
            for more details on how to transfer each type of dependency.
          </>
        }
      />

      <TableWrapper mt="s-16">
        <Stat label="Data dependencies" val={stats?.owned_data_items_count} mb="s-24" />
        <AdjustableTable
          name={TableNames.EmployeeDataOwnership}
          useWindowScroll
          row={ROW}
          {...table}
          noDataMessage="Data ownership will appear here"
        />
      </TableWrapper>
    </>
  )
}

export default DataOwnership
