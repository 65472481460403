import React, { useContext } from 'react'
import get from 'lodash/get'
import { Checkbox } from '@revolut/ui-kit'
import { SelectContext } from '@components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'

export interface SelectCellProps<T> {
  primaryKey: keyof T
  data: T
  disabled?: boolean
}

export const SelectAllCell = () => {
  const { isAllSelected, onSelectAll, excludeList } = useContext(SelectContext)

  const indeterminate = isAllSelected && !!excludeList.size

  return (
    <Checkbox
      aria-label="select all checkbox"
      checked={isAllSelected && !excludeList.size}
      onChange={e => {
        if (indeterminate) {
          onSelectAll(false)
          return
        }

        onSelectAll(e.currentTarget.checked)
      }}
      indeterminate={indeterminate}
    />
  )
}

export const SelectCell = <T,>({
  primaryKey,
  data,
  disabled = false,
}: SelectCellProps<T>) => {
  const { selectedRowsData, onSelect, isAllSelected, excludeList } =
    useContext(SelectContext)
  const rowId = String(get(data, primaryKey))
  const checked =
    !disabled &&
    (selectedRowsData.has(rowId) || (isAllSelected && !excludeList.has(rowId)))

  return (
    <Checkbox
      disabled={disabled}
      data-testid="select_cell_checkbox"
      aria-label={`${checked ? 'Unselect' : 'Select'} row`}
      checked={checked}
      onChange={e => {
        onSelect(rowId, e.currentTarget.checked, data)
      }}
      onClick={e => {
        e.stopPropagation()
      }}
    />
  )
}

export interface SelectCellFactoryParams<T> {
  disableRow?: (data: T) => boolean
}
export const getSelectCellConfig = <T,>(
  params?: SelectCellFactoryParams<T>,
  width: number = 70,
): ColumnCellInterface<T> => ({
  type: CellTypes.insert,
  idPoint: 'select',
  dataPoint: 'select',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data, idPath }) => (
    <SelectCell<T>
      data={data}
      primaryKey={idPath as keyof T}
      disabled={params?.disableRow ? params.disableRow(data) : false}
    />
  ),
  title: 'Select',
  headerType: 'select_all',
  width,
})
