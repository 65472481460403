import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import {
  PerformanceRating,
  PerformanceSelector,
  Ratings,
  ReviewSummaryInterface,
  SummaryCalculatedDeliverablesRating,
} from '@src/interfaces/performance'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import React from 'react'
import { Box } from '@revolut/ui-kit'
import { SummaryGoalsCollapsable } from './SummaryGoalsCollapsable'
import { NoReviewsGoalsCollapsable } from '../Common/NoReviewsGoalsCollapsable'
import { NoReviewsDeliverablesCollapsable } from '../Common/NoReviewsDeliverablesCollapsable'

interface Props {
  selectedPeriod: Pick<
    PerformanceSelector,
    | 'category'
    | 'end_date_time'
    | 'probation_reviews_deliverables_type'
    | 'id'
    | 'probation_version'
    | 'version'
    | 'offset'
  >
  isSummaryReview?: boolean
  employee: Pick<EmployeeInterface, 'id'>
  lineManagerId?: number
  employeeStats?: Pick<EmployeeStats, 'kpi_performance_percent'>
  reviewSummary?: Pick<
    ReviewSummaryInterface,
    'team' | 'grade_label_mapping' | 'reviewed_employee_type'
  >
  ratings?: Ratings[]
  calculatedDeliverablesRating?:
    | SummaryCalculatedDeliverablesRating
    | PerformanceRating
    | null
}

export const GoalsSummaryCollapsableTable = ({
  selectedPeriod,
  isSummaryReview,
  employee,
  employeeStats,
  reviewSummary,
  lineManagerId,
  calculatedDeliverablesRating,
  ratings,
}: Props) => {
  if (
    (selectedPeriod.category === ReviewCycleCategory.Probation &&
      selectedPeriod.probation_reviews_deliverables_type === 'jira') ||
    selectedPeriod.category === ReviewCycleCategory.PIP
  ) {
    return (
      <SummaryGoalsCollapsable
        ratings={ratings}
        isSummaryReview={isSummaryReview}
        calculatedDeliverablesRating={calculatedDeliverablesRating}
        lineManagerId={lineManagerId}
        data={employee}
        selectedPeriod={selectedPeriod}
      />
    )
  }

  if (
    selectedPeriod.category === ReviewCycleCategory.Performance ||
    selectedPeriod.category === ReviewCycleCategory.Probation
  ) {
    return (
      <Box mt="s-16">
        <NoReviewsGoalsCollapsable
          ratings={ratings}
          calculatedDeliverablesRating={calculatedDeliverablesRating}
          summary={reviewSummary}
          employee={employee}
          selectedPeriod={selectedPeriod}
        />
      </Box>
    )
  }

  return (
    <NoReviewsDeliverablesCollapsable
      data={employee}
      category={selectedPeriod.category}
      stats={employeeStats}
      selectedPeriod={selectedPeriod}
    />
  )
}
