import React from 'react'
import { VStack } from '@revolut/ui-kit'
import {
  InterviewRoundSummaryRatingInterface,
  PerformanceHiringRoundSummaryInterface,
} from '@src/interfaces/interviewTool'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import {
  performanceHiringName,
  performanceHiringInterviewers,
  performanceHiringResult,
} from '@src/constants/columns/performanceHiring'
import { TableNames } from '@src/constants/table'
import { PerformanceSection } from '@src/pages/Forms/Candidate/Performance/components/PerformanceSection'

const performanceSectionTableRow = {
  cells: [
    {
      ...performanceHiringName,
      width: 100,
    },
    {
      ...performanceHiringInterviewers,
      width: 50,
    },
    {
      ...performanceHiringResult,
      width: 50,
    },
  ],
}

type PerformanceSectionTableProps = {
  data?: InterviewRoundSummaryRatingInterface[]
  loading: boolean
  name:
    | TableNames.PerformanceHiringSummaryDeliverables
    | TableNames.PerformanceHiringSummarySkills
    | TableNames.PerformanceHiringSummaryValues
  onViewScorecard: (feedbackIds: number[]) => void
}

const PerformanceSectionTable = ({
  data,
  loading,
  name,
  onViewScorecard,
}: PerformanceSectionTableProps) => {
  return (
    <AdjustableTable<InterviewRoundSummaryRatingInterface>
      data={data || []}
      loading={loading}
      name={name}
      count={data?.length || 0}
      hideCountAndButtonSection
      noDataMessage="No data"
      row={performanceSectionTableRow}
      onRowClick={row => {
        const feedbackIds =
          row.interviewer_ratings?.flatMap(
            ({ interview_feedback_ids }) => interview_feedback_ids,
          ) || []
        onViewScorecard(feedbackIds)
      }}
    />
  )
}

type PerformanceSectionsProps = {
  performanceRoundSummary?: PerformanceHiringRoundSummaryInterface
  loading: boolean
  onViewScorecard: (feedbackIds: number[]) => void
}

export const PerformanceSections = ({
  performanceRoundSummary,
  loading,
  onViewScorecard,
}: PerformanceSectionsProps) => {
  const { delivery, skill, value } = performanceRoundSummary?.skill_ratings || {}
  return (
    <VStack gap="s-16" px="s-16">
      <PerformanceSection
        collapse={!loading && !delivery?.skills?.length}
        grade={delivery?.average_rating}
        loading={loading}
        title="Deliverables"
        type="delivery"
      >
        <PerformanceSectionTable
          data={delivery?.skills}
          loading={loading}
          name={TableNames.PerformanceHiringSummaryDeliverables}
          onViewScorecard={onViewScorecard}
        />
      </PerformanceSection>
      <PerformanceSection
        collapse={!loading && !skill?.skills?.length}
        grade={skill?.average_rating}
        loading={loading}
        title="Skills"
        type="skill"
      >
        <PerformanceSectionTable
          data={skill?.skills}
          loading={loading}
          name={TableNames.PerformanceHiringSummarySkills}
          onViewScorecard={onViewScorecard}
        />
      </PerformanceSection>
      <PerformanceSection
        collapse={!loading && !value?.skills?.length}
        grade={value?.average_rating}
        loading={loading}
        title="Values"
        type="value"
      >
        <PerformanceSectionTable
          data={value?.skills}
          loading={loading}
          name={TableNames.PerformanceHiringSummaryValues}
          onViewScorecard={onViewScorecard}
        />
      </PerformanceSection>
    </VStack>
  )
}
