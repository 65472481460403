import React from 'react'
import styled from 'styled-components'
import { Icon, HStack, Tag, Token } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import Tooltip from '@components/Tooltip/Tooltip'
import { pathToUrl } from '@src/utils/router'
import { CompanyUnit } from '@components/ColumnInserts/CompanyUnit/CompanyUnit'
import Table from '@components/TableV2/Table'

export const mapRatingToColor = (data: GenericAnalyticsDashboardInterface) => {
  if (!data.rating) {
    return Token.color.foreground
  }

  if (data.rating < 3) {
    return Token.color.danger
  }
  if (data.rating > 4) {
    return Token.color.success
  }

  return Token.color.foreground
}

const StyledTooltip = styled(Tooltip)`
  justify-content: flex-start;
`

export const analyticsDashboardNameColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    dynamicHyperlinks: data =>
      pathToUrl(
        data.dashboard_type === 'looker'
          ? ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD
          : ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD_VIEW,
        { id: data.id },
      ),
    title: 'Dashboard Name',
    insert: ({ data }) => (
      <StyledTooltip placement="top" text={data.description} useFormatting>
        {data.name}
      </StyledTooltip>
    ),
  }

export const analyticsDashboardOwnerColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'owner.id',
    dataPoint: 'owner.name',
    sortKey: 'owner__full_name',
    filterKey: 'owner__id',
    selectorsKey: selectorKeys.employee,
    title: 'Owner',
    insert: ({ data }) => <Table.EmployeeCell employee={data.owner} />,
  }

export const analyticsDashboardRelatedKpisColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: null,
    filterKey: 'related_kpis',
    selectorsKey: selectorKeys.dashboard_related_kpis,
    title: 'Related KPIs',
    insert: ({ data }) => {
      return (
        <HStack gap="s-4">
          {data.related_kpis.map(item => (
            <Tag key={item.id} variant="faded">
              {item.name}
            </Tag>
          ))}
        </HStack>
      )
    },
  }

export const analyticsDashboardOrganisationUnitsColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Organisation unit',
    insert: ({ data }) => {
      if (data.company_related) {
        return <CompanyUnit />
      }

      if (data.related_employees.length) {
        return (
          <HStack gap="s-4">
            {data.related_employees.map(item => (
              <Tag key={item.id} variant="faded">
                {item.name}
              </Tag>
            ))}
          </HStack>
        )
      }

      if (data.related_teams.length) {
        return (
          <HStack gap="s-4">
            {data.related_teams.map(item => (
              <Tag key={item.id} variant="faded">
                {item.name}
              </Tag>
            ))}
          </HStack>
        )
      }

      if (data.related_departments.length) {
        return (
          <HStack gap="s-4">
            {data.related_departments.map(item => (
              <Tag key={item.id} variant="faded">
                {item.name}
              </Tag>
            ))}
          </HStack>
        )
      }

      return null
    },
  }

export const analyticsDashboardDetailsColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'details',
    dataPoint: 'details',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Details',
  }

export const analyticsDashboardSourceColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'dashboard_type',
    dataPoint: 'dashboard_type',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Source',
    insert: ({ data }) => {
      if (data.dashboard_type === 'looker') {
        return (
          <Icon src="https://assets.revolut.com/assets/apps/Looker.png" variant="image" />
        )
      }

      return <Icon name="LogoRevolut" />
    },
  }

export const analyticsDashboardRatingColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.text,
    idPoint: 'rating',
    dataPoint: 'rating',
    sortKey: null,
    filterKey: 'rating',
    filterType: FilterType.range,
    selectorsKey: selectorKeys.none,
    colors: mapRatingToColor,
    title: 'Rating',
  }
