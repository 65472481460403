import React from 'react'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { initialRequisitionStatusFilter } from '@src/interfaces/requisitions'
import { FunctionInterface } from '@src/interfaces/functions'
import { CommonRequisitionTable } from '@src/features/CommonRequisitionTable/CommonRequisitionTable'

interface RequisitionsProps {
  data: FunctionInterface
  executive?: boolean
}

const Requisitions = ({ data, executive }: RequisitionsProps) => {
  const filterBy = [
    executive
      ? {
          filters: [{ name: 'true', id: 'true' }],
          columnName: 'leadership',
          nonResettable: true,
          nonInheritable: true,
        }
      : {
          filters: [{ name: data.name, id: data.id }],
          columnName: 'position__function__id',
          nonResettable: true,
        },
    {
      filters: initialRequisitionStatusFilter,
      columnName: 'status',
      nonResettable: true,
    },
  ]
  const sortBy = [
    {
      sortBy: 'pipeline_queue_position',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'priority',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  return <CommonRequisitionTable filterBy={filterBy} sortBy={sortBy} type="function" />
}

export default Requisitions
