import React from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { connect } from 'lape'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { Color, Text, chain } from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { validator } from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/InterviewScorecardTemplateForm'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import Settings from '@src/pages/Forms/InterviewScorecardTemplate/Settings/Settings'
import Preview from '@src/pages/Forms/InterviewScorecardTemplate/Preview/Preview'
import { interviewScorecardTemplateFormRequests } from '@src/api/interviewScorecardTemplate'
import { Statuses } from '@src/interfaces'

const InterviewScorecardTemplate = () => {
  const params = useParams<{ id: string }>()
  const { initialValues } = useLapeContext<InterviewScorecardTemplateInterface>()

  const isFormRoute = !!useRouteMatch(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS)

  const backUrl =
    params.id && isFormRoute
      ? pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW, params)
      : ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATES

  return (
    <PageWrapper>
      <PageHeader
        title={
          params.id
            ? chain(
                initialValues.name,
                initialValues.status === Statuses.draft && (
                  <Text color={Color.GREY_20}>{initialValues.status}</Text>
                ),
              )
            : 'Create a new scorecard'
        }
        backUrl={backUrl}
        subtitle={`Stage: ${initialValues.stage_type?.name || '-'}`}
      />
      <Switch>
        <Route exact path={ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS}>
          <Settings />
        </Route>
        <Route exact path={ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW}>
          <Preview />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => {
  return (
    <Form api={interviewScorecardTemplateFormRequests} validator={validator}>
      <InterviewScorecardTemplate />
    </Form>
  )
})
