import React from 'react'
import { Flex, Token } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { LocationInterface } from '@src/interfaces/locations'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

export const locationGenericNameColumn: ColumnInterface<LocationInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'location_name',
  sortKey: 'location_name',
  filterKey: 'id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
}

export const locationCreatedDateColumn: ColumnInterface<LocationInterface> = {
  type: CellTypes.dateInUtc,
  filterType: FilterType.date,
  idPoint: 'created_date_time',
  dataPoint: 'created_date_time',
  sortKey: 'created_date_time',
  filterKey: 'created_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Created Date',
}

export const locationCreatedByColumn: ColumnInterface<LocationInterface> = {
  type: CellTypes.insert,
  idPoint: 'created_by.id',
  dataPoint: 'created_by.full_name',
  sortKey: 'created_by__full_name',
  filterKey: 'created_by__id',
  selectorsKey: selectorKeys.employee,
  title: 'Created By',
  insert: ({ data }) => <UserWithAvatar {...data.created_by} />,
}

export const locationPeoplePartnerColumn: ColumnInterface<LocationInterface> = {
  type: CellTypes.insert,
  idPoint: 'hr_manager.id',
  dataPoint: 'hr_manager.full_name',
  sortKey: 'hr_manager__full_name',
  filterKey: 'hr_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'People partner',
  insert: ({ data }) => <UserWithAvatar {...data.hr_manager} />,
}

export const locationPeopleSpecialistsColumn: ColumnInterface<LocationInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'People specialists',
  insert: ({ data }) =>
    data.people_specialists.length ? (
      <Flex gap="s-8">
        {data.people_specialists.map(specialist => (
          <UserWithAvatar {...specialist} compact key={specialist.id} />
        ))}
      </Flex>
    ) : (
      '-'
    ),
}

export const locationLabelColumn: ColumnInterface<LocationInterface> = {
  type: CellTypes.insert,
  idPoint: 'location_labels',
  dataPoint: 'location_labels',
  sortKey: 'location_labels',
  filterKey: 'location_labels',
  selectorsKey: selectorKeys.location_labels,
  title: 'Labels',
  insert: ({ data }) =>
    data.location_labels.length ? data.location_labels.join(', ') : '-',
}

export const locationCountryColumn: ColumnInterface<LocationInterface> = {
  type: CellTypes.text,
  idPoint: 'country.id',
  dataPoint: 'country.name',
  sortKey: 'country__name',
  filterKey: 'country__id',
  selectorsKey: selectorKeys.countries,
  title: 'Country',
}

export const locationTypeColumn: ColumnInterface<LocationInterface> = {
  type: CellTypes.text,
  idPoint: 'type.id',
  dataPoint: 'type.name',
  sortKey: 'type',
  filterKey: 'type',
  selectorsKey: selectorKeys.location_types,
  title: 'Type',
}

export const locationStatusColumn: ColumnInterface<LocationInterface> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.location_statuses,
  colors: data => (data.status === 'active' ? Token.color.green : Token.color.greyTone50),
  title: 'Status',
}

export const locationNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'location.id',
  dataPoint: 'location.location_name',
  sortKey: 'location__location_name',
  filterKey: 'location__id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
}

export const locationNameRequisitionColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.insert,
  idPoint: 'main_location.id',
  dataPoint: 'main_location.location_name',
  sortKey: 'main_location__location_name',
  filterKey: 'locations__id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
  insert: ({ data }) =>
    data.locations ? data.locations.map(l => l.location_name).join(', ') : '-',
}
