import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box, Cell, Color, MoreBar, Text, Widget } from '@revolut/ui-kit'
import { Check } from '@revolut/icons'
import { getPerformanceReviewSummary } from '@src/api/performanceReview'
import { approveScorecard } from '@src/api/pip'
import { navigateTo } from '@src/actions/RouterActions'
import { PIP_PLAYBOOK } from '@src/constants/externalLinks'
import { Statuses } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  ExtraPermissionsInterface,
  PerformanceSidebarManager,
  PerfReviewRequestFeedbackInterface,
  RequestFeedbackInterface,
  ReviewCategory,
  ReviewerRelation,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { ProbationCheckpoints } from '@src/interfaces/probationReview'
import { ScorecardTypes } from '@src/interfaces/scorecard'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { Grid } from '@components/CommonSC/Grid'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import PageLoading from '@components/PageLoading/PageLoading'
import PerformanceActions from '@src/pages/EmployeeProfile/Preview/Performance/Common/PerformanceActions'
import SummarySidebar from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummarySidebar'
import RatingLabel from '@src/features/EmployeePerformance/RatingLabel'
import SettingsButtons from '@src/features/SettingsButtons'
import RequestFeedback from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceReview/RequestFeedback'
import {
  CheckpointReviewers,
  GradedRequestInterface,
} from '@src/pages/Forms/Overview/Checkpoint/CheckpointReviewers'
import GradesNotice from '@src/pages/Forms/Overview/Checkpoint/GradesNotice'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { ReviewLayoutDeliverablesPath } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/CheckpointStepReviewers'
import { getStartOfCheckpoint } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary/ProbationPipTimeline/utils'
import { defaultTheme } from '@src/styles/theme'

const Wrap = styled.div`
  display: flex;
`
const RatingLabelStyled = styled(RatingLabel)`
  font-size: 20px;
  font-weight: 600;
`

const SectionRating = styled(RatingLabel)`
  font-size: 16px;
`
const GridSummary = styled(Grid)`
  grid-template-columns: auto auto;
`

const Content = styled.div`
  width: 676px;
`
const Side = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: ${defaultTheme.zIndex.tooltip};
`

export type CheckpointProps = {
  category: ReviewCategory
  checkpointNumber?: number
  checkpoints?: ProbationCheckpoints
  cycleId: string
  data: EmployeeInterface
  extraPermissions?: ExtraPermissionsInterface[]
  requests: PerfReviewRequestFeedbackInterface[]
}

export const Checkpoint = ({
  category,
  checkpointNumber,
  checkpoints,
  cycleId,
  data,
  extraPermissions,
  requests,
}: CheckpointProps) => {
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [commonSummary, setCommonSummary] = useState<ReviewSummaryInterface | undefined>()
  const [managersSummary, setManagersSummary] = useState<
    ReviewSummaryInterface | undefined
  >()
  const [sidebarOpen, setSidebarOpen] = useState<PerformanceSidebarManager>(
    PerformanceSidebarManager.SummaryOpened,
  )
  const [updatedRequests, setUpdatedRequests] =
    useState<PerfReviewRequestFeedbackInterface[]>(requests)

  const permissions = useSelector(selectPermissions)
  const canManagePIPCycle = permissions.includes(PermissionTypes.ManagePipCycles)
  const canManagePIPCommitteeDecision = permissions.includes(
    PermissionTypes.ManagePipCommitteeDecision,
  )
  const canViewPipComments = extraPermissions?.[0]?.permissions.includes(
    PermissionTypes.CanViewPipComments,
  )

  useEffect(() => {
    if (requests) {
      setUpdatedRequests([...requests])
    }
  }, [requests])

  const checkpoint = useMemo(
    () =>
      checkpoints?.checkpoints.find(item => item.checkpoint_number === checkpointNumber),
    [checkpoints, checkpointNumber],
  )

  const fetchSummary = async () => {
    setLoading(true)
    const perfFilters: FilterByInterface[] = [
      {
        filters: [
          {
            name: String(checkpointNumber),
            id: checkpointNumber!,
          },
        ],
        columnName: `${category}_checkpoint__number`,
      },
    ]

    const [result, managersResult] = await Promise.all([
      getPerformanceReviewSummary(cycleId, data.id, category, perfFilters),
      getPerformanceReviewSummary(cycleId, data.id, category, [
        ...perfFilters,
        {
          columnName: 'reviewer_relation',
          filters: [
            {
              id: 'line_manager',
              name: 'line_manager',
            },
            {
              id: 'functional_manager',
              name: 'functional_manager',
            },
          ],
        },
      ]),
    ])
    setCommonSummary(result.data)
    setManagersSummary(managersResult.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchSummary()
  }, [checkpointNumber])

  const reviews: GradedRequestInterface[] | undefined = useMemo(
    () =>
      updatedRequests
        ?.filter(item => item.probation_checkpoint?.number === checkpointNumber)
        .map(item => {
          const gradeValue = commonSummary?.summary?.final_grades?.find(
            rating => rating.review.id === item.id,
          )

          return {
            ...item,
            grade: gradeValue?.value,
            updated_date:
              item.status === Statuses.completed ? item.updated_date : undefined,
          }
        }),
    [updatedRequests, commonSummary, checkpointNumber],
  )

  if (loading) {
    return <PageLoading />
  }

  if (!commonSummary || !managersSummary) {
    return null
  }

  const onClickAddReview = () => {
    const path = ReviewLayoutDeliverablesPath[category]
    navigateTo(
      pathToUrl(path, {
        id: checkpoint?.review?.id,
        employeeId: data.id,
      }),
    )
  }

  const onClickRequestReview = () => {
    setSidebarOpen(
      sidebarOpen === PerformanceSidebarManager.RequestOpened
        ? PerformanceSidebarManager.Closed
        : PerformanceSidebarManager.RequestOpened,
    )
  }

  const onClickViewReview = () => {
    setSidebarOpen(
      sidebarOpen === PerformanceSidebarManager.SummaryOpened
        ? PerformanceSidebarManager.Closed
        : PerformanceSidebarManager.SummaryOpened,
    )
  }

  const onAddRequest = (
    req?: PerfReviewRequestFeedbackInterface | RequestFeedbackInterface,
  ) => {
    if (req) {
      setUpdatedRequests([req as PerfReviewRequestFeedbackInterface, ...updatedRequests])
    }
  }

  const handleApprove = async () => {
    setSubmitting(true)
    try {
      await approveScorecard(data.id, cycleId, checkpoint?.id!)
    } finally {
      setSubmitting(false)
    }
  }

  const submitReviewDisabledReason = checkpoint?.can_be_submitted
    ? null
    : 'You are not allowed to submit review at this time'

  const finalGrade = managersSummary.summary?.rating
  const startOfCheckpoint = checkpoint && getStartOfCheckpoint(checkpoint)

  const functionalSkillRating = (managersSummary.summary || commonSummary.summary)
    ?.functional_skills?.rating

  const deliverablesRating = (managersSummary.summary || commonSummary.summary)
    ?.calculated_deliverables_ratings?.recommended_rating?.rating

  const valuesRating = (managersSummary.summary || commonSummary.summary)?.culture_skills
    ?.rating

  return (
    <Wrap>
      <Content>
        {category === ReviewCategory.PIP_V2 && (
          <>
            <SettingsButtons mb="s-16">
              <MoreInfoButton href={PIP_PLAYBOOK}>Open PIP Playbook</MoreInfoButton>
            </SettingsButtons>
            {checkpoint?.review?.status === Statuses.pending_approval && (
              <ActionWidget
                avatarColor={Color.WARNING}
                my="s-16"
                text="All PIP checkpoints are reviewed by HR for quality of feedback. The employee will not have visibility of the feedback until reviewed and released by HR."
                title="Pending review by HR manager"
              >
                {(canManagePIPCycle || canManagePIPCommitteeDecision) && (
                  <MoreBar>
                    <MoreBar.Action
                      onClick={handleApprove}
                      pending={submitting}
                      useIcon={Check}
                    >
                      Mark as approved & release
                    </MoreBar.Action>
                  </MoreBar>
                )}
              </ActionWidget>
            )}
          </>
        )}
        <Widget p="s-16">
          <Box mb="s-6">
            <RatingLabelStyled rating={finalGrade}>
              {!finalGrade ? 'Grades not visible / not yet submitted' : undefined}
            </RatingLabelStyled>
          </Box>

          <GradesNotice
            lineManagerRating={
              managersSummary.summary?.ratings.find(
                item => item.review.reviewer_relation === ReviewerRelation.LineManager,
              )?.value
            }
            funcManagerRating={
              managersSummary.summary?.ratings.find(
                item =>
                  item.review.reviewer_relation === ReviewerRelation.FunctionalManager,
              )?.value
            }
          />

          {startOfCheckpoint && (
            <Text color={Color.GREY_TONE_50} use="div">
              Checkpoint {checkpointNumber}: {formatDate(startOfCheckpoint)}
            </Text>
          )}

          <Box>
            <PerformanceActions
              canAddReview
              onClickAddReview={onClickAddReview}
              onClickRequestReview={onClickRequestReview}
              onClickViewReview={onClickViewReview}
              submitReviewDisabledReason={submitReviewDisabledReason}
              userReviewStatus={checkpoint?.review?.status}
            />
          </Box>

          <Box mt="s-20">
            <Text color={Color.GREY_TONE_50} fontWeight={500}>
              Summary
            </Text>
            <GridSummary justifyContent="space-between" gap={24} mt="s-20">
              <Text fontSize="primary">Goals and deliverables</Text>
              <SectionRating rating={deliverablesRating}>
                {deliverablesRating ? null : 'N/A'}
              </SectionRating>

              <Text fontSize="primary">Functional skills</Text>
              <SectionRating rating={functionalSkillRating}>
                {functionalSkillRating ? null : 'N/A'}
              </SectionRating>

              <Text fontSize="primary">Values</Text>
              <SectionRating rating={valuesRating}>
                {valuesRating ? null : 'N/A'}
              </SectionRating>
            </GridSummary>
          </Box>
        </Widget>
        <Text use="div" color={Color.GREY_TONE_50} fontWeight={500} mt="s-32">
          Reviews
        </Text>
        <Cell mt="s-16" px="s-16">
          {reviews?.length ? (
            <CheckpointReviewers
              canViewComments={canViewPipComments}
              category={category}
              checkpointStatus={checkpoint?.review?.status}
              cycleId={cycleId}
              employeeId={data.id}
              reviews={reviews}
              onAfterCommentsClick={() =>
                setSidebarOpen(PerformanceSidebarManager.Closed)
              }
              gradesMap={commonSummary.grade_label_mapping}
            />
          ) : (
            <Text use="div" py="s-16">
              No reviews
            </Text>
          )}
        </Cell>
      </Content>
      <Side>
        {sidebarOpen === PerformanceSidebarManager.RequestOpened && (
          <RequestFeedback
            canRequest
            category={category}
            checkpointNumber={checkpointNumber}
            id={data.id}
            isNewFlow
            onAddRequest={onAddRequest}
            onClose={() => setSidebarOpen(PerformanceSidebarManager.Closed)}
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            performanceCycle={{ id: 0, category: ScorecardTypes[category] }}
            requests={updatedRequests.filter(
              item => item.probation_checkpoint?.number === checkpointNumber,
            )}
          />
        )}
      </Side>
      <SummarySidebar
        category={category}
        cycleId={cycleId}
        cycleName={''}
        employeeId={data.id}
        isOpen={sidebarOpen === PerformanceSidebarManager.SummaryOpened}
        onClose={() => setSidebarOpen(PerformanceSidebarManager.Closed)}
        predefinedCheckpointFilter={[
          {
            id: checkpointNumber!,
            name: String(checkpointNumber),
          },
        ]}
      />
    </Wrap>
  )
}
