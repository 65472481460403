import React, { useState } from 'react'
import { useGetRoadmapSettings } from '@src/api/settings'
import { EntityTypes } from '@src/constants/api'
import { AddRoadmapForm } from '@src/pages/Forms/AddRoadmap/AddRoadmap'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { HStack, Icon, MoreBarSkeleton, TextButton, Text } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { RoadmapInterfaceForm, RoadmapPriorities } from '@src/interfaces/roadmaps'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'

export interface AddRoadmapActionProps extends CreateRoadmapProps {
  entityType: EntityTypes
  id: number
  onAfterSubmit: () => void
  reviewCycle: ReviewCyclesInterface | null
  tags?: string[]
  variant?: 'text' | 'default'
}

export type RoadmapInitialValues = Pick<
  RoadmapInterfaceForm,
  | 'team'
  | 'department'
  | 'employee'
  | 'review_cycle'
  | 'owner'
  | 'priority'
  | 'start_date'
  | 'due_date'
  | 'is_company'
>

export interface CreateRoadmapProps {
  backUrl?: string
  name: string
  reviewCycle: ReviewCyclesInterface | null
}

export interface CreateRoadmapLocationState
  extends LocationStateWithInitialValues<RoadmapInitialValues> {
  backUrl?: string
  name: string
  genericRoadmapCreationType?: 'company' | 'department' | 'team'
}

// Feel this needs to be declared somewhere else. perhaps around form implementation where initialValues is read
export interface LocationStateWithInitialValues<T extends {} = {}> {
  initialValues: T
}

const JiraSidebar = ({
  onAfterSubmit,
  reviewCycle,
  entityType,
  id,
  tags,
  variant,
}: AddRoadmapActionProps) => {
  const [openAddRoadmaps, setOpenAddRoadmaps] = useState(false)

  return (
    <>
      <AddRoadmapForm
        entityType={entityType}
        id={id}
        isOpen={openAddRoadmaps}
        onAfterSubmit={onAfterSubmit}
        onClose={() => setOpenAddRoadmaps(false)}
        reviewCycle={reviewCycle}
        tags={tags}
      />
      {variant === 'text' ? (
        <TextButton onClick={() => setOpenAddRoadmaps(true)}>
          <HStack space="s-8">
            <Icon name="Plus" size={16} />
            <Text>Create roadmap</Text>
          </HStack>
        </TextButton>
      ) : (
        <PrimaryAction onClick={() => setOpenAddRoadmaps(true)} useIcon="Plus">
          Add new roadmap
        </PrimaryAction>
      )}
    </>
  )
}

const CreateRoadmap = ({
  backUrl,
  name,
  entityType,
  id,
  reviewCycle,
  variant,
}: AddRoadmapActionProps) => {
  const user = useSelector(selectUser)
  const state: CreateRoadmapLocationState = {
    backUrl,
    name,
    initialValues: {
      // we need to set null values to clean up previous state
      // these null values are removed from the API payload before sending
      // @ts-expect-error
      team: null,
      // @ts-expect-error
      employee: null,
      // @ts-expect-error
      department: null,
      [entityType]: { id },
      review_cycle: reviewCycle!,
      owner: { id: user.id, name: user.display_name },
      priority: { id: RoadmapPriorities.MAJOR, name: RoadmapPriorities.MAJOR },
      start_date: reviewCycle!.start_date_time,
      due_date: reviewCycle!.end_date_time,
    },
  }
  return variant === 'text' ? (
    <TextButton onClick={() => navigateTo(ROUTES.FORMS.ROADMAP.NEW, state)}>
      <HStack space="s-8">
        <Icon name="Plus" size={16} />
        <Text>Create roadmap</Text>
      </HStack>
    </TextButton>
  ) : (
    <PrimaryAction
      onClick={() => navigateTo(ROUTES.FORMS.ROADMAP.NEW, state)}
      useIcon="Plus"
    >
      Create roadmap
    </PrimaryAction>
  )
}

export const AddRoadmapMoreBarAction = (props: AddRoadmapActionProps) => {
  const { data: roadmapSettings } = useGetRoadmapSettings()
  if (!roadmapSettings || !props.reviewCycle) {
    return <MoreBarSkeleton data-testid="add-roadmap-action-skeleton" />
  }
  return (
    <>
      {roadmapSettings?.jira_epics_enabled ? (
        <JiraSidebar {...props} />
      ) : (
        <CreateRoadmap {...props} />
      )}
    </>
  )
}
