import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import { Box } from '@revolut/ui-kit'
import Form from '@src/features/Form/Form'
import Loader from '@src/components/CommonSC/Loader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import General from './General'
import { useGetUserStats, userRequest } from '@src/api/accessManagement'
import Groups from '@src/pages/Forms/AccessManagementUser/Groups'
import { UserAccessManagementInterface } from '@src/interfaces/accessManagement'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import Permissions from '@src/pages/Forms/AccessManagementUser/Permissions'

const User = () => {
  const permissions = useSelector(selectPermissions)
  const { values } = useLapeContext<UserAccessManagementInterface>()
  const params = useParams<{ id?: string }>()
  const { data: userStats } = useGetUserStats(params.id)

  if (!values) {
    return <Loader />
  }

  const tabs = [
    {
      title: 'General info',
      path: ROUTES.FORMS.ACCESS_MANAGEMENT_USER.GENERAL,
      to: pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_USER.GENERAL, params),
      component: General,
      canView: true,
    },
    {
      title: 'Groups',
      path: ROUTES.FORMS.ACCESS_MANAGEMENT_USER.GROUPS,
      to: pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_USER.GROUPS, params),
      component: Groups,
      quickSummary: userStats?.groups,
      canView: permissions?.includes(PermissionTypes.ViewUserGroups),
    },
    {
      title: 'Permissions',
      path: ROUTES.FORMS.ACCESS_MANAGEMENT_USER.PERMISSIONS,
      to: pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_USER.PERMISSIONS, params),
      component: Permissions,
      quickSummary: userStats?.permissions,
      canView: permissions?.includes(PermissionTypes.ViewPermissions),
    },
  ].filter(tab => tab.canView)

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title="User details"
        backUrl={ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS}
        subtitle={
          values.user_type === 'employee' ? (
            <UserWithAvatar {...values.employee} />
          ) : (
            values.email
          )
        }
      >
        {tabs.length > 1 && (
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={tabs} />
          </Box>
        )}
      </PageHeader>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={userRequest}>
    <User />
  </Form>
))
