import React from 'react'
import { useSelector } from 'react-redux'
import { Flex, MoreBar, TableWidget } from '@revolut/ui-kit'
import { RowInterface } from '@src/interfaces/data'
import {
  timeOffCategoryDescriptionColumn,
  timeOffCategoryNameColumn,
} from '@src/constants/columns/timeOff'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@src/components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import { getTimeOffCategories } from '@src/api/timeOff'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { TimeOffCategoryInterface } from '@src/interfaces/timeOff'

const ROW: RowInterface<TimeOffCategoryInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.TIME_OFF_CATEGORIES.GENERAL, { id })),
  cells: [
    {
      ...timeOffCategoryNameColumn,
      width: 200,
    },
    {
      ...timeOffCategoryDescriptionColumn,
      width: 500,
    },
  ],
}

export const TimeOffCategories = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddTimeOffPolicyCategory)

  const table = useTable({ getItems: getTimeOffCategories })

  return (
    <>
      <PageHeader
        title="Time management settings"
        subtitle="Settings for the time off functionalities"
        backUrl={ROUTES.SETTINGS.TIME_OFF.GENERAL}
      />
      <PageBody>
        <TableWidget>
          <TableWidget.Info>
            <Stat label="Total" val={table?.loading ? undefined : table?.count} />
          </TableWidget.Info>
          <TableWidget.Actions>
            {canAdd && (
              <Flex mb="s-16">
                <MoreBar>
                  <MoreBar.Action
                    use={InternalLink}
                    to={pathToUrl(ROUTES.FORMS.TIME_OFF_CATEGORIES.GENERAL)}
                    useIcon="Plus"
                  >
                    Add category
                  </MoreBar.Action>
                </MoreBar>
              </Flex>
            )}
          </TableWidget.Actions>
          <TableWidget.Table>
            <AdjustableTable
              name={TableNames.TimeOffCategories}
              useWindowScroll
              row={ROW}
              {...table}
              noDataMessage="Categories will appear here"
            />
          </TableWidget.Table>
        </TableWidget>
      </PageBody>
    </>
  )
}
