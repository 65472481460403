import * as React from 'react'
import { connect } from 'lape'

import { useLapeField } from '@src/features/Form/LapeForm'
import {
  SwitchProps,
  Switch,
  Text,
  TextProps,
  Item,
  Avatar,
  Token,
} from '@revolut/ui-kit'
import { ImageProp } from '@revolut/ui-kit/types/dist/types/image'

export interface LapeNewSwitchProps extends SwitchProps {
  name: string
  label?: string
  labelProps?: TextProps
  itemTypeProps?: {
    title?: string | React.ReactNode
    description?: string | React.ReactNode
    image?: ImageProp
    avatar?: React.ReactNode
    onLabelClick?: (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void
  }
  onAfterChange?: (checked: boolean) => void
}

const defaultProps: Partial<LapeNewSwitchProps> = {
  label: '',
  labelProps: {},
}

const LapeNewSwitch = ({
  name,
  children,
  itemTypeProps,
  label,
  onAfterChange,
  ...props
}: LapeNewSwitchProps) => {
  const { value, onChange, hidden, disabled, apiError } = useLapeField(name)

  if (hidden || props.hidden) {
    return null
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    onChange(checked)
    onAfterChange?.(checked)
  }

  if (itemTypeProps) {
    const { image, avatar } = itemTypeProps
    const avatarNode =
      itemTypeProps.image || itemTypeProps.avatar ? (
        <Item.Avatar>
          {avatar || <Avatar variant="brand" size={40} image={image} />}
        </Item.Avatar>
      ) : null
    const showDescription = Boolean(itemTypeProps.description || apiError)

    return (
      <Item
        use="label"
        data-name={name}
        onClick={e => {
          if (itemTypeProps.onLabelClick) {
            itemTypeProps.onLabelClick(e)
          }
        }}
      >
        {avatarNode}
        <Item.Content>
          <Item.Title>{itemTypeProps.title}</Item.Title>
          {showDescription && (
            <Item.Description>
              {itemTypeProps.description}
              {apiError ? (
                <>
                  <br />
                  <Text color={Token.color.danger}>{apiError}</Text>
                </>
              ) : null}
            </Item.Description>
          )}
        </Item.Content>
        <Item.Side>
          <Switch
            onChange={handleChange}
            value={value}
            checked={!!value}
            disabled={disabled}
            {...props}
          />
        </Item.Side>
      </Item>
    )
  }

  return (
    <Switch
      onChange={handleChange}
      value={value || false}
      checked={!!value}
      disabled={disabled}
      {...props}
    >
      {label ? <Text {...props.labelProps}>{label}</Text> : null}
    </Switch>
  )
}

LapeNewSwitch.defaultProps = defaultProps
export default connect(LapeNewSwitch)
