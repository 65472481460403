import { API } from '@src/constants/api'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { AxiosPromise } from 'axios'
import { api, apiWithoutHandling } from '.'
import { BenchmarkInterface, CompensationBandInterface } from '../interfaces/benchmarks'
import { getCommentsAPI } from './comments'

export const getBenchmarks = ({ sortBy, filters, page }: FetchDataQueryInterface) =>
  api.get<GetRequestInterface<BenchmarkInterface>>(`${API.BENCHMARKS}`, {
    params: {
      ...filterSortPageIntoQuery(sortBy, filters, page),
    },
  })

export const benchmarkRequests: RequestInterfaceNew<BenchmarkInterface> = {
  get: async ({ id }) => api.get(`${API.BENCHMARKS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.BENCHMARKS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.BENCHMARKS, data),
  delete: async ({ id }) => api.delete(`${API.BENCHMARKS}/${id}`),
}

export const useBenchmarkApprovals = (id: number) =>
  useFetch<ApprovalFlowResponse>(`${API.BENCHMARKS}/${id}/approvals`)

export const approveBenchmark = (id: number) =>
  api.post(`${API.BENCHMARKS}/${id}/approve`)

export const rejectBenchmark = (id: number, rejection_comment: string) =>
  api.post(`${API.BENCHMARKS}/${id}/reject`, { rejection_comment })

export const changeBenchmarkStatus = (id: number, action: 'archive' | 'unarchive') =>
  api.post(`${API.BENCHMARKS}/${id}/${action}`)

export const bulkApproveBenchmarks = (data: { id: number }[]) =>
  apiWithoutHandling.post(`${API.BENCHMARKS}/bulkApprove`, data)

export const bulkArchiveBenchmarks = (data: { id: number }[]) =>
  apiWithoutHandling.post(`${API.BENCHMARKS}/bulkArchive`, data)

export const bulkUnarchiveBenchmarks = (data: { id: number }[]) =>
  apiWithoutHandling.post(`${API.BENCHMARKS}/bulkUnarchive`, data)

export const getBenchmarkChangelog =
  (id: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<ChangelogInterface<BenchmarkInterface>>
  > =>
    api.get(`${API.BENCHMARKS}/${id}/changelogFields`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getBenchmarkCommentsAPI = (id: number) =>
  getCommentsAPI({
    baseUrl: `${API.BENCHMARKS}/${id}/comments`,
  })

const getLocationFilter = (locationIds?: number[]) => ({
  columnName: 'location_id',
  filters:
    locationIds?.map(locationId => ({
      id: locationId,
      name: String(locationId),
    })) || [],
})

export const useGetRequisitionCompensationBands = (
  id: number | null,
  locationIds?: number[],
) => {
  return useFetch<CompensationBandInterface[]>(
    id && locationIds?.length ? `${API.BENCHMARKS}/requisition_salary_bands` : null,
    undefined,
    {
      params: {
        ...filterSortPageIntoQuery(undefined, [
          {
            columnName: 'requisition_id',
            filters: [
              {
                id: id!,
                name: String(id),
              },
            ],
          },
          getLocationFilter(locationIds),
        ]),
      },
    },
  )
}

export const useGetPostingCompensationBands = (
  id: number | null,
  locationIds?: number[],
) => {
  return useFetch<CompensationBandInterface[]>(
    id && locationIds?.length ? `${API.BENCHMARKS}/posting_salary_bands` : null,
    undefined,
    {
      params: {
        ...filterSortPageIntoQuery(undefined, [
          {
            columnName: 'posting_id',
            filters: [
              {
                id: id!,
                name: String(id),
              },
            ],
          },
          getLocationFilter(locationIds),
        ]),
      },
    },
  )
}

export const useGetOfferFormBenchmark = (
  seniorityId?: number | string,
  specialisationId?: number | string,
  locationId?: number | string,
  targetCurrency: string = 'GBP',
) =>
  useFetch<BenchmarkInterface>(
    seniorityId !== undefined &&
      specialisationId !== undefined &&
      locationId !== undefined
      ? `${API.BENCHMARKS}/offerFormSalaryBands`
      : null,
    undefined,
    {
      params: {
        seniority_id: seniorityId,
        specialisation_id: specialisationId,
        location_id: locationId,
        target_currency: targetCurrency,
      },
    },
    true,
  )
