import React, { useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { navigateReplace } from '@src/actions/RouterActions'
import {
  addRoadmaps,
  getUnassignedCompanyRoadmaps,
  getUnassignedRoadmaps,
} from '@src/api/roadmaps'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { RoadmapInterface, UnassignedRoadmapInterface } from '@src/interfaces/roadmaps'
import {
  Box,
  chain,
  InputGroup,
  Input,
  IconButton,
  Button,
  useStatusPopup,
  StatusPopup,
} from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'

import { useLocation } from 'react-router-dom'
import { CreateRoadmapLocationState } from './common/AddRoadmapMoreBarAction'
import { PageBody } from '@src/components/Page/PageBody'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { LapeFormInterface, useLapeContext } from '@src/features/Form/LapeForm'
import { useGetDepartment } from '@src/api/department'
import { useGetTeam } from '@src/api/teams'
import { parseUnassignedRoadmaps } from '../AddRoadmap/AddRoadmap'

import { EmptySearchDescription } from '../AddRoadmap/EmptySearchDescription'
import { InitialSearchDescription } from '../AddRoadmap/InitialSearchDescription'
import { Tags } from '../AddRoadmap/Tags'
import { SidebarOption } from '@src/components/Inputs/SidebarMultiselect/types'
import { SelectMode } from '@src/components/Inputs/SidebarMultiselect/components/SelectMode'
import { SearchMode } from '@src/components/Inputs/SidebarMultiselect/components/SearchMode'

const parseKeys = (roadmaps: SidebarOption[]) => {
  return roadmaps.map(option => option?.link?.name || '')
}

const getData = (
  values: LapeFormInterface<RoadmapInterface>['values'],
  entityType: 'company' | 'team' | 'department' | undefined,
) => {
  switch (entityType) {
    case 'department':
      return {
        entityType: EntityTypes.department,
        id: values.department?.id,
        settingUrl: pathToUrl(ROUTES.FORMS.DEPARTMENT.SETTINGS, {
          id: values.department?.id,
        }),
        url: pathToUrl(ROUTES.FORMS.DEPARTMENT.ROADMAP, {
          id: values.department?.id,
        }),
      }
    case 'team':
      return {
        entityType: EntityTypes.team,
        id: values.team?.id,
        settingUrl: pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, {
          id: values.team?.id,
        }),
        url: pathToUrl(ROUTES.FORMS.TEAM.ROADMAP, {
          id: values.team?.id,
        }),
      }
    default:
      return {
        entityType: EntityTypes.companyV2,
        url: pathToUrl(ROUTES.PERFORMANCE.GOALS.ROADMAPS),
      }
  }
}

type SidebarSelection = { [key: string]: SidebarOption }

/**
 * This is mostly copy & paste from /src/pages/Forms/AddRoadmap/AddRoadmap.tsx and /src/components/Inputs/SidebarMultiselect/SidebarMultiselect.tsx
 * with few adjmustments so that it works as a separate page for adding JIRA roadmaps on various levels
 */
export const NewJiraRoadmapForm = () => {
  const location = useLocation<CreateRoadmapLocationState>()

  const subtitle = location.state?.name || 'Jira roadmap'
  const [result, setResult] = useState<SidebarSelection>({})
  const [isSubmitPending, setIsSubmitPending] = useState<boolean>(false)
  const [isSearchPending, setIsSearchPending] = useState<boolean>(false)
  const [options, setOptions] = useState<SidebarOption[]>([])
  const [showSelected, setShowSelected] = useState(false)
  const backUrl = location.state?.backUrl || ROUTES.PERFORMANCE.GOALS.ROADMAPS
  const genericRoadmapCreationType = location.state.genericRoadmapCreationType
  const { values } = useLapeContext<RoadmapInterface>()
  const [searchValue, setSearchValue] = useState('')
  const statusPopup = useStatusPopup()

  const { data: departmentData } = useGetDepartment(values.department?.id)
  const { data: teamData } = useGetTeam(values.team?.id)

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    if (value) {
      setIsSearchPending(true)
      fetchOptions(value)
    } else {
      setOptions([])
    }
  }

  let tags: string[] = []
  switch (genericRoadmapCreationType) {
    case 'department':
      tags = departmentData?.jira_projects || []
      break
    case 'team':
      tags = teamData?.jira_projects || []
      break
  }

  const selectedData = getData(values, genericRoadmapCreationType)

  const fetchOptions = useMemo(
    () =>
      debounce(async (search: string) => {
        let request: Promise<UnassignedRoadmapInterface[]>
        if (selectedData.entityType === EntityTypes.companyV2) {
          request = getUnassignedCompanyRoadmaps(search).then(resp => resp.data.results)
        } else {
          if (!selectedData.id) {
            return
          }
          request = getUnassignedRoadmaps(
            selectedData.entityType,
            selectedData.id,
            search,
          ).then(resp => resp.data.results)
        }

        try {
          const roadmaps = await request
          setOptions(parseUnassignedRoadmaps(roadmaps))
        } finally {
          setIsSearchPending(false)
        }
      }, 1000),
    [selectedData.entityType, selectedData.id],
  )

  const handleClearAll = () => {
    setResult({})
    setShowSelected(false)
  }

  const handleItemClick = (item: SidebarOption, checked: boolean) => {
    const copyOfResult = { ...result }
    if (checked) {
      delete copyOfResult[`${item.id}`]
      setResult(copyOfResult)

      if (showSelected && !Object.keys(copyOfResult).length) {
        setShowSelected(false)
      }
    } else {
      copyOfResult[`${item.id}`] = item
      setResult(copyOfResult)
    }
  }

  const emptyDescription =
    searchValue === '' ? <InitialSearchDescription /> : <EmptySearchDescription />

  const onSubmit = async () => {
    setIsSubmitPending(true)
    const keys = parseKeys(Object.values(result))
    if (selectedData.entityType === EntityTypes.companyV2) {
      await addRoadmaps({
        is_company: true,
        keys,
        review_cycle: values.review_cycle?.id,
      })
    } else if (selectedData.id) {
      await addRoadmaps({
        entityType: selectedData.entityType,
        id: selectedData.id,
        keys,
        review_cycle: values.review_cycle?.id,
      })
    } else {
      setIsSubmitPending(false)
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to update accrual amount</StatusPopup.Title>
          <StatusPopup.Description>Try again</StatusPopup.Description>
          <StatusPopup.Actions>
            <Button
              onClick={() => {
                statusPopup.hide()
                navigateReplace(ROUTES.FORMS.ROADMAP.SELECT_LEVEL, location.state)
              }}
              elevated
            >
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )

      return
    }
    setIsSubmitPending(false)
    navigateReplace(location.state.backUrl || selectedData.url)
  }

  return (
    <PageWrapper>
      <PageHeader title="Create Roadmap" subtitle={subtitle} backUrl={backUrl} />
      <PageBody>
        <InputGroup>
          {genericRoadmapCreationType === 'department' && (
            <LapeRadioSelectInput
              required
              name="department"
              selector={selectorKeys.department}
              label="Department"
            />
          )}
          {genericRoadmapCreationType === 'team' && (
            <LapeRadioSelectInput
              required
              name="team"
              selector={selectorKeys.team}
              label="Team"
            />
          )}
          {(values.department || values.team || values.is_company) && (
            <>
              <LapeRadioSelectInput
                required
                name="review_cycle"
                label="Review Cycle"
                selector={selectorKeys.review_cycles}
              />
              {selectedData.entityType !== EntityTypes.companyV2 && (
                <Tags
                  tags={tags}
                  onEditClick={() =>
                    selectedData.settingUrl && navigateReplace(selectedData.settingUrl)
                  }
                />
              )}

              <Box mb="s-24">
                <Input
                  label="Search epics"
                  value={searchValue}
                  data-testid="sidebar-multiselect-new-input"
                  pending={isSearchPending}
                  onChange={e => handleSearchChange(e.currentTarget.value)}
                  renderAction={() =>
                    searchValue && (
                      <IconButton
                        useIcon="CrossCircle"
                        size={24}
                        onClick={e => {
                          e.preventDefault()
                          setSearchValue('')
                          handleSearchChange('')
                        }}
                      />
                    )
                  }
                />
              </Box>
              {showSelected ? (
                <SelectMode
                  pending={isSearchPending}
                  selection={result}
                  handleClearAll={handleClearAll}
                  handleItemClick={handleItemClick}
                />
              ) : (
                <SearchMode
                  pending={isSearchPending}
                  selection={result}
                  emptyDescription={emptyDescription}
                  searchValue={searchValue}
                  handleClearAll={handleClearAll}
                  handleItemClick={handleItemClick}
                  options={options}
                  emptySearchMessage={chain('Showing epics', options.length)}
                />
              )}
            </>
          )}
          {!!Object.keys(result).length && (
            <Button
              data-testid="jira-form-submit-button"
              onClick={() => onSubmit()}
              pending={isSubmitPending}
              elevated
            >
              {chain('Add roadmap', Object.keys(result).length)}
            </Button>
          )}
        </InputGroup>
      </PageBody>
    </PageWrapper>
  )
}
