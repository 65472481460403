import { useEffect, useRef } from 'react'

export const useInterval = (callback: VoidFunction, delay: number | null = 1000) => {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay !== null) {
      const tick = () => savedCallback.current()
      const id = setInterval(tick, delay)

      return () => clearInterval(id)
    }
    return () => {}
  }, [delay])
}
