import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { VStack, type VStackProps } from '@revolut/ui-kit'
import css from '@styled-system/css'
import { LAYOUT_MAX_WIDTH } from '@src/pages/Landing/styles'
import { Button } from '@src/pages/Landing/components/Button'
import { InternalLink } from '@components/InternalLink/InternalLink'

import { Text } from '../Text'

interface FeatureItemsBlockContentProps extends VStackProps {
  color?: string
  caption?: string
  title?: string
  description?: string
  footnote?: string
  button?: { link: string; label: string }
  backgroundAlign: string
}

const FeatureItemsBlockMainContainer = styled(VStack)<{
  backgroundAlign: string
}>(({ backgroundAlign, align }) =>
  css({
    gap: ['s-16', null, null, 's-24', null, 's-32'],
    paddingX: ['s-20', null, null, 0],
    maxWidth: LAYOUT_MAX_WIDTH,
    alignItems: ['start', null, 'center', align ?? null],
    marginBottom:
      backgroundAlign === 'center'
        ? ['s-24', null, null, 's-32', null, 's-48']
        : ['s-24', null, null, 0],
  }),
)

export const FeatureItemsBlockMain = forwardRef<
  HTMLDivElement,
  FeatureItemsBlockContentProps
>(
  (
    {
      color = 'foreground',
      title,
      description,
      footnote,
      caption,
      button,
      backgroundAlign,
      ...props
    },
    ref,
  ) => {
    return (
      <FeatureItemsBlockMainContainer
        color={color}
        backgroundAlign={backgroundAlign}
        ref={ref}
        {...props}
      >
        {caption && (
          <Text color="grey-tone-50" variant="h6">
            {caption}
          </Text>
        )}
        {title && (
          <>
            <Text use="h2" variant="h2" hide="*-md">
              {title}
            </Text>
            <Text use="h2" variant="h2" hide="md-*">
              {title}
            </Text>
          </>
        )}
        <VStack
          gap={{ all: 's-4', md: 's-8' }}
          width={
            props.align === 'center' ? { all: '100%', lg: '60%', xxl: '80%' } : undefined
          }
        >
          {description && (
            <Text
              // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
              variant="subtitle1"
            >
              {description}
            </Text>
          )}
          {footnote && <Text variant="small">{footnote}</Text>}
        </VStack>
        {button && (
          <Button
            variant="primary"
            use={InternalLink}
            to={button.link}
            mt={{ all: 's-4', lg: 's-8' }}
          >
            {button.label}
          </Button>
        )}
      </FeatureItemsBlockMainContainer>
    )
  },
)

FeatureItemsBlockMain.displayName = 'FeatureItemsBlockMain'
