import React, { useEffect, useRef, useState } from 'react'
import {
  VStack,
  Box,
  InputGroup,
  Token,
  Footnote,
  Flex,
  Icon,
  Link,
} from '@revolut/ui-kit'
import styled from 'styled-components'
import { connect } from 'lape'

import { HeroBlockContentBase, HeroBlockContentBaseLayout } from './HeroBlockContent'
import { Button } from './Button'
import { salesContactRequests } from '../api'
import { contactUsBackgroundColor } from '../styles'
import { Text } from '../components/Text'
import Form from '@src/features/Form/Form'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { LapePhoneInput } from '@src/components/Inputs/LapeFields/LapePhoneInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReCaptcha } from '@src/components/ReCaptcha/ReCaptcha'
import { ReCaptchaHandle } from '@src/interfaces/reCaptcha'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PUBLIC_ROUTES, ROUTES } from '@src/constants/routes'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import { pathToUrl } from '@src/utils/router'

const ContactUsFormContents = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const captchaRef = useRef<ReCaptchaHandle>(null)
  const successMessageRef = useRef<HTMLDivElement>(null)
  const { values, errors, isSubmitting, submit } = useLapeContext<{ captcha: string }>()
  const { validate } = useSafeFormValidator()

  useEffect(() => {
    if (hasSubmitted) {
      successMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [hasSubmitted])

  const onSubmit = async () => {
    const token = await captchaRef.current?.verify()

    if (token) {
      values.captcha = token
    }

    return submit()
      .then(() => {
        setHasSubmitted(true)
      })
      .finally(() => {
        captchaRef.current?.reset()
      })
  }

  if (hasSubmitted) {
    return (
      <VStack
        space="s-8"
        style={{ textAlign: 'center' }}
        align="center"
        ref={successMessageRef}
      >
        <Icon name="CheckSuccess" color={Token.color.blue} />
        <Text variant="h6">Thanks!</Text>
        <Text variant="secondary">
          We'll get back to you within 24 hours. In the meantime, check out our{' '}
          <Link use={InternalLink} to={ROUTES.MAIN}>
            website
          </Link>
        </Text>
      </VStack>
    )
  }

  return (
    <InputGroup data-form="contact-us">
      <InputGroup variant="horizontal">
        <LapeNewInput name="first_name" label="First name" required />
        <LapeNewInput name="last_name" label="Last name" required />
      </InputGroup>
      <LapeNewInput name="email" label="Email" required />
      <LapeNewInput name="company_name" label="Company name" required />
      <LapePhoneInput
        prefixName="phone_country_code"
        phoneName="phone_number_short"
        required
      />
      <LapeRadioSelectInput
        name="country"
        label="Country"
        selector={selectorKeys.countries}
        required
      />
      <LapeRadioSelectInput
        name="company_size"
        label="Company size"
        selector={selectorKeys.company_sizes}
        required
      />
      <LapeNewInput name="comment" label="Add comment" required />
      <ReCaptcha
        onVerify={value => {
          values.captcha = value
        }}
        name="captcha"
        error={errors?.captcha}
        ref={captchaRef}
      />

      <Footnote style={{ marginTop: Token.space.s8 }}>
        By proceeding you agree to the{' '}
        <Link href={pathToUrl(PUBLIC_ROUTES.TERMS_AND_CONDITIONS)} target="_blank">
          Revolut People Terms of Service
        </Link>{' '}
        and our{' '}
        <Link href={pathToUrl(PUBLIC_ROUTES.PRIVACY_NOTICE)} target="_blank">
          Privacy Policy
        </Link>
      </Footnote>
      <Button
        variant="primary"
        onClick={validate(onSubmit)}
        pending={isSubmitting}
        width="100%"
        maxWidth={{ sm: '720px' }}
      >
        Send contact request
      </Button>
    </InputGroup>
  )
}

const ContactUsForm = connect(() => {
  return (
    <Box
      style={{ textAlign: 'start' }}
      maxWidth={{ all: 560, lg: 375 }}
      minWidth={{ lg: 375 }}
      backgroundColor={contactUsBackgroundColor}
      p={{ all: 0, md: 's-16' }}
      radius={Token.radius.widget}
    >
      <Form api={salesContactRequests} disableLocalStorageCaching>
        <ContactUsFormContents />
      </Form>
    </Box>
  )
})

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: ${Token.space.s32};

  @media ${Token.media.lg} {
    flex-direction: row;
  }
`

const TextContainer = styled(VStack)`
  text-align: start;

  @media ${Token.media.md} {
    text-align: center;
  }
  @media ${Token.media.lg} {
    text-align: start;
  }
`

export const ContactUsHeroBlockContent = () => {
  return (
    <HeroBlockContentBaseLayout>
      <HeroBlockContentBase align="center">
        <Container>
          <TextContainer
            align={{ all: 'start', md: 'center', lg: 'start' }}
            space={{ all: 's-8', lg: 's-24' }}
            width="100%"
          >
            <Text
              use="p"
              whiteSpace="pre-wrap"
              width={{ all: '100%', lg: '60%', xxl: '80%' }}
              // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
              variant="subtitle1"
              color={Token.color.grey20}
            >
              Contact our sales team now
            </Text>
            <Text use="h2" variant="h2" whiteSpace="pre-wrap" width="100%">
              Ready to supercharge your company?
              <br />
              Talk to us
            </Text>
            <Text
              use="p"
              whiteSpace="pre-wrap"
              width={{ all: '100%', lg: '60%', xxl: '80%' }}
              // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
              variant="subtitle1"
            >
              Unlock unparalleled productivity with our HR, performance and recruitment
              tools
            </Text>
          </TextContainer>

          <ContactUsForm />
        </Container>
      </HeroBlockContentBase>
    </HeroBlockContentBaseLayout>
  )
}
