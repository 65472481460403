import React from 'react'

import { EmployeeInterface } from '@src/interfaces/employees'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

import AdjustableTable from '@components/Table/AdjustableTable'
import { LinkedAccountsInterface } from '@src/interfaces/linkedAccounts'
import {
  linkedApplicationColumn,
  linkedDateColumn,
  linkedEmailColumn,
  linkedPhoneNumberColumn,
  linkedStatusColumn,
} from '@src/constants/columns/linkedAccounts'
import { linkedAccountRequests } from '@src/api/linkedAccounts'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

interface Props {
  data: EmployeeInterface
}

const ROW: RowInterface<LinkedAccountsInterface> = {
  cells: [
    {
      ...linkedApplicationColumn,
      width: 300,
    },
    {
      ...linkedPhoneNumberColumn,
      width: 300,
    },
    {
      ...linkedEmailColumn,
      width: 300,
    },
    {
      ...linkedDateColumn,
      width: 150,
    },
    {
      ...linkedStatusColumn,
      width: 150,
    },
  ],
}

const LinkedAccounts = ({ data }: Props) => {
  const table = useTable<LinkedAccountsInterface>(linkedAccountRequests(data.id))

  const handleRowClick = (check: LinkedAccountsInterface) => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.LINKED_ACCOUNT.PREVIEW, {
        employeeId: data.id,
        id: check.id,
      }),
    )
  }

  return (
    <TableWrapper>
      <AdjustableTable<LinkedAccountsInterface>
        name={TableNames.EmployeeLinkedAccounts}
        useWindowScroll
        dataType="Linked account"
        row={ROW}
        {...table}
        noDataMessage="Employee has no linked accounts"
        onRowClick={handleRowClick}
      />
    </TableWrapper>
  )
}

export default LinkedAccounts
