import React, { useState } from 'react'
import groupBy from 'lodash/groupBy'
import pluralize from 'pluralize'
import {
  Avatar,
  Details,
  ExpandableCell,
  Group,
  HStack,
  Icon,
  IconButton,
  IconName,
  Item,
  ItemSkeleton,
  StatusWidget,
  Text,
  TextButton,
  Token,
  VStack,
} from '@revolut/ui-kit'

import {
  PayrollTimelineChangeInterface,
  PayrollTimelineDomainCategory,
} from '@src/interfaces/payrollV2'
import { formatWithoutTimezone } from '@src/utils/format'
import { formatSnakeCase } from '@src/utils/string'
import {
  domainNameToFieldsConfig,
  ParsedDomainFieldChanges,
  parseDomainFieldChanges,
} from '.'
import { ValuesDiff } from './ValuesDiff'

type DomainChangesWidgetProps = {
  title: string
  subtitle?: string
  fieldsChanges: ParsedDomainFieldChanges[]
  isOpenByDefault?: boolean
}
const DomainChangesWidget = ({
  title,
  subtitle,
  fieldsChanges,
  isOpenByDefault = true,
}: DomainChangesWidgetProps) => {
  const [expanded, setExpanded] = useState(!!isOpenByDefault)

  if (!fieldsChanges.length) {
    return null
  }
  return (
    <ExpandableCell expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      <ExpandableCell.Title>{title}</ExpandableCell.Title>
      {!!subtitle && (
        <ExpandableCell.Content>
          <Text color={Token.color.greyTone50}>{subtitle}</Text>
        </ExpandableCell.Content>
      )}
      <ExpandableCell.Note>
        {fieldsChanges.map(parsedField => {
          const { from, to, label, changeType } = parsedField
          return (
            <Details key={label}>
              <Details.Title>{label}</Details.Title>
              <Details.Content>
                <ValuesDiff from={from} to={to} type={changeType} />
              </Details.Content>
            </Details>
          )
        })}
      </ExpandableCell.Note>
    </ExpandableCell>
  )
}

type Props = {
  isLoading: boolean
  isError: boolean
  timelineChanges: PayrollTimelineChangeInterface[] | undefined
  onClose: () => void
  groupChanges?: boolean
}
export const DetailsSidebarContent = ({
  isLoading,
  isError,
  timelineChanges,
  groupChanges,
  onClose,
}: Props) => {
  const [selectedCategory, setSelectedCategory] =
    useState<PayrollTimelineDomainCategory>()

  if (isLoading) {
    return (
      <VStack space="s-16">
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </VStack>
    )
  }
  if (isError) {
    return (
      <StatusWidget>
        <StatusWidget.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images/3D083.png',
            '2x': 'https://assets.revolut.com/assets/3d-images/3D083@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images/3D083@3x.png',
          }}
        />
        <StatusWidget.Title>Something went wrong</StatusWidget.Title>
        <StatusWidget.Description>Please, try again later</StatusWidget.Description>
        <StatusWidget.Action>
          <TextButton onClick={onClose}>Close</TextButton>
        </StatusWidget.Action>
      </StatusWidget>
    )
  }

  const formattedTimelineChanges = timelineChanges
    ?.map(change => {
      const fieldsConfig = domainNameToFieldsConfig[change.domain_name] || []
      return {
        change,
        fieldsChanges: fieldsConfig
          .map(cfg => parseDomainFieldChanges(change, cfg))
          .filter(conf => conf.changeType !== 'none'),
      }
    })
    .filter(widgetProps => !!widgetProps.fieldsChanges.length)

  if (!formattedTimelineChanges?.length) {
    return (
      <StatusWidget>
        <StatusWidget.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D259.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@3x.png',
          }}
        />
        <StatusWidget.Title>No changes found for this employee</StatusWidget.Title>
        <StatusWidget.Action>
          <TextButton onClick={onClose}>Close</TextButton>
        </StatusWidget.Action>
      </StatusWidget>
    )
  }

  if (groupChanges) {
    const changesByCategory = groupBy(
      formattedTimelineChanges,
      item => item.change.domain_category,
    )
    const availableCategories: {
      key: PayrollTimelineDomainCategory
      title: string
      icon: IconName
    }[] = [
      { key: 'work_details', title: 'Work Details', icon: 'Services' },
      { key: 'contract', title: 'Contract', icon: 'Document' },
      { key: 'personal_details', title: 'Personal Details', icon: 'NationalId' },
      { key: 'time_off', title: 'Time Off', icon: 'CalendarDate' },
    ]

    if (!selectedCategory) {
      return (
        <Group>
          {availableCategories.map(category => {
            const changes = changesByCategory[category.key]
            const hasChanges = !!changes?.length

            return (
              <Item
                key={category.key}
                use="button"
                disabled={!hasChanges}
                onClick={() => setSelectedCategory(category.key)}
              >
                <Item.Avatar>
                  <Avatar useIcon={category.icon} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{category.title}</Item.Title>
                  <Item.Description>
                    {hasChanges
                      ? `${pluralize('change', changes.length, true)}`
                      : 'No changes in selected cycle'}
                  </Item.Description>
                </Item.Content>
                {hasChanges && (
                  <Item.Side>
                    <IconButton useIcon="ChevronRight" color={Token.color.greyTone50} />
                  </Item.Side>
                )}
              </Item>
            )
          })}
        </Group>
      )
    }
    const selectedCategoryChanges = changesByCategory[selectedCategory]?.reverse() || []
    const selectedCategoryProps = availableCategories.find(
      category => category.key === selectedCategory,
    )
    if (!selectedCategoryChanges.length || !selectedCategoryProps) {
      return null
    }
    return (
      <VStack space="s-16">
        <TextButton onClick={() => setSelectedCategory(undefined)}>
          <HStack space="s-4" align="center">
            <Icon name="ArrowThinLeft" size={12} color={Token.color.blue} />
            <Text>All categories</Text>
          </HStack>
        </TextButton>
        <Group>
          <Item>
            <Item.Avatar>
              <Avatar useIcon={selectedCategoryProps.icon} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Contract</Item.Title>
              <Item.Description>
                {pluralize('changes', selectedCategoryChanges.length, true)}
              </Item.Description>
            </Item.Content>
          </Item>
          {selectedCategoryChanges.map(changeData => {
            const { change, fieldsChanges } = changeData
            return (
              <DomainChangesWidget
                key={change.id}
                title={formatSnakeCase(change.domain_name)}
                subtitle={formatWithoutTimezone(change.effective_date_time, true)}
                fieldsChanges={fieldsChanges}
              />
            )
          })}
        </Group>
      </VStack>
    )
  }

  return (
    <VStack space="s-20">
      {formattedTimelineChanges?.map(widget => {
        const { change, fieldsChanges } = widget
        const name = change.domain_name
        const category = change.domain_category
        const title = `${formatSnakeCase(name)} in ${formatSnakeCase(category)}`
        const subtitle = formatWithoutTimezone(change.effective_date_time, true)

        return (
          <DomainChangesWidget
            key={change.id}
            title={title}
            subtitle={subtitle}
            fieldsChanges={fieldsChanges}
          />
        )
      })}
    </VStack>
  )
}
