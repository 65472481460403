import React, { useEffect, useMemo, useState } from 'react'
import { Box, Flex, Item, ItemSkeleton, Subheader, Text } from '@revolut/ui-kit'
import ButtonFilters, {
  ButtonFilterConfig,
} from '@components/ButtonFilters/ButtonFilters'
import { GroupedVirtuoso } from 'react-virtuoso'
import TimelineAvatar from '@src/pages/Forms/Candidate/Timeline/TimelineAvatar'
import TimelineItemContent from '@src/pages/Forms/Candidate/Timeline/TimelineItemContent'
import {
  format,
  isAfter,
  isThisMonth,
  isToday,
  isYesterday,
  previousMonday,
} from 'date-fns'
import { FetchDataQueryInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { TimeLineEventCategory, TimelineEventInterface } from '@src/interfaces/timeline'
import { getCandidateTimeline } from '@src/api/hiringProcess'
import { selectorKeys } from '@src/constants/api'
import { getAllCandidateStages } from '@src/api/recruitment/interviews'
import { useParams } from 'react-router-dom'
import { formatDateTime } from '@src/utils/format'

const hasSidebar: TimeLineEventCategory[] = [
  'file_added',
  'comment_added',
  'scorecard_submitted',
  'interview_scheduled',
  'email_thread',
]

type Props = {
  roundId?: number
  setSelectedEvent?: (event: TimelineEventInterface) => void
}

const TimelineContent = ({ roundId, setSelectedEvent }: Props) => {
  const { id } = useParams<{ id: string }>()
  const [currentGroupCounts, setCurrentGroupCounts] = useState<number[]>([])
  const [currentGroups, setCurrentGroups] = useState<string[]>([])

  const sortBy = [
    {
      sortBy: 'event_datetime',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const table = useTable<TimelineEventInterface>(
    {
      getItems: (requestData: FetchDataQueryInterface) =>
        getCandidateTimeline(id, requestData),
    },
    undefined,
    sortBy,
  )

  const getGroup = (item: TimelineEventInterface) => {
    const date = new Date(item.event_datetime)
    if (isToday(date)) {
      return 'Today'
    }

    if (isYesterday(date)) {
      return 'Yesterday'
    }

    const monday = previousMonday(new Date())
    if (isAfter(date, monday)) {
      return 'This week'
    }

    const lastMonday = previousMonday(monday)
    if (isAfter(date, lastMonday)) {
      return 'Last week'
    }

    if (isThisMonth(date)) {
      return 'This month'
    }

    return format(date, 'MMMM yyyy')
  }

  const updateGroups = (items: TimelineEventInterface[]) => {
    const groups: string[] = []
    const groupCounts: number[] = []
    for (const item of items) {
      const groupName = getGroup(item)
      if (groups[groups.length - 1] !== groupName) {
        groups.push(groupName)
      }
      groupCounts[groups.length - 1] = (groupCounts[groups.length - 1] || 0) + 1
    }

    setCurrentGroupCounts(groupCounts)
    setCurrentGroups(groups)
  }

  useEffect(() => {
    updateGroups(table.data)
  }, [table.data])

  const getCount = (end: number) => {
    return currentGroupCounts.slice(0, end).reduce((a, b) => a + b, 0)
  }

  const filtersConfig: ButtonFilterConfig = useMemo(
    () => ({
      event_datetime: {
        title: 'Date',
        selector: selectorKeys.none,
        useIcon: 'Calendar' as const,
        type: 'DateRange',
      },
      employee: {
        title: 'Employee',
        selector: selectorKeys.employee,
        useIcon: 'Profile' as const,
        type: 'MultiSelect',
      },
      interview_stage: {
        title: 'Stage',
        selector: () =>
          getAllCandidateStages(id).then(resp => ({
            options: resp.data.options.map(stage => ({
              id: stage.id,
              name: stage.name,
              sub_title: stage.specialisation_name,
            })),
          })),
        type: 'SingleSelect',
      },
      category: {
        title: 'Category',
        selector: selectorKeys.candidate_timeline_categories,
        type: 'SingleSelect',
      },
    }),
    [roundId],
  )

  return (
    <>
      <Flex mb="s-16">
        <ButtonFilters
          filtersConfig={filtersConfig}
          onChange={table.onFilterChange}
          filters={table.filterBy}
        />
      </Flex>
      <GroupedVirtuoso
        useWindowScroll
        endReached={table.fetchNextPage}
        overscan={1000}
        groupCounts={currentGroupCounts}
        groupContent={index => (
          <Box pt={index === 0 ? 0 : 's-16'}>
            <Subheader variant="nested">
              <Subheader.Title>{currentGroups[index]}</Subheader.Title>
            </Subheader>
          </Box>
        )}
        itemContent={(index, groupIndex) => {
          const item = table.data[index]
          if (!item) {
            return null
          }

          // @TODO: make it right when we have BE resources
          let recommendation
          item.summary.split(',').forEach(str => {
            if (str.includes('Recommendation')) {
              recommendation = str.replace('Recommendation:', '').trim()
            }
          })
          return (
            <Box
              backgroundColor="widget-background"
              borderRadius={`${index === getCount(groupIndex) ? '12px 12px' : '0 0'} ${
                index + 1 === getCount(groupIndex + 1) ? '12px 12px ' : '0 0 '
              }`}
              key={item.id}
              data-testid={`timeline-item-${item.id}`}
            >
              <Item
                {...(hasSidebar.includes(item.category) &&
                  setSelectedEvent && {
                    use: 'button',
                    onClick: () => setSelectedEvent(item),
                  })}
              >
                <TimelineAvatar
                  user={item.employee}
                  personName={item.person_name}
                  category={item.category}
                  recommendation={recommendation}
                />
                <Item.Content>
                  <Item.Title>{item.title}</Item.Title>
                  <Item.Description>
                    <Text>{item.person_name}</Text>
                    <TimelineItemContent event={item} />
                  </Item.Description>
                </Item.Content>
                <Item.Side>
                  <Item.Value variant="secondary">
                    {formatDateTime(item.event_datetime)}
                  </Item.Value>
                </Item.Side>
              </Item>
            </Box>
          )
        }}
      />
      {table.loading && <ItemSkeleton data-testid="item-skeleton" />}
      {!table.loading && table.count === 0 && (
        <Item useIcon="InfoOutline">
          <Item.Content>
            <Item.Title color="grey-tone-50">No events in timeline</Item.Title>
          </Item.Content>
        </Item>
      )}
    </>
  )
}

export default TimelineContent
