import React from 'react'
import { FilterButton, FilterButtonSkeleton, Flex } from '@revolut/ui-kit'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { EmployeeFilter } from '@src/pages/Forms/Candidate/Performance/components/EmployeeFilter'
import { useGetCandidatePerformanceProfile } from '@src/api/recruitment/interviews'

type FilterBarProps = {
  interviewersFilter: number[]
  round?: InterviewRoundInterface
  onChangeInterviewersFilter: (interviewersFilter: number[]) => void
}

export const FilterBar = ({
  interviewersFilter,
  round,
  onChangeInterviewersFilter,
}: FilterBarProps) => {
  const { data, isLoading } = useGetCandidatePerformanceProfile(round?.id)
  return (
    <Flex
      gap="s-8"
      px="s-16"
      flexWrap="wrap"
      data-testid="performance-summary-filter-bar"
    >
      <FilterButton
        useIcon="People"
        labelClear="All"
        active={!interviewersFilter.length}
        onClick={() => {
          onChangeInterviewersFilter([])
        }}
      >
        All
      </FilterButton>
      {isLoading ? (
        <>
          <FilterButtonSkeleton />
          <FilterButtonSkeleton />
        </>
      ) : (
        data?.all_interviewers?.map(interviewer => {
          const { id, full_name } = interviewer
          const filtering = interviewersFilter.includes(id)
          return (
            <EmployeeFilter
              key={id}
              active={interviewersFilter.includes(id)}
              userWithAvatarProps={{ ...interviewer }}
              onClick={() => {
                onChangeInterviewersFilter(
                  filtering
                    ? interviewersFilter.filter(fid => fid !== id)
                    : [...interviewersFilter, id],
                )
              }}
            >
              {full_name}
            </EmployeeFilter>
          )
        })
      )}
    </Flex>
  )
}
