import React from 'react'
import { Box, Cell, Flex, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { useSelector } from 'react-redux'

import { selectPermissions } from '@src/store/auth/selectors'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PermissionTypes } from '@src/store/auth/types'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { BenefitsTableInterface } from '@src/interfaces/benefits'
import { getBenefits } from '@src/api/benefits'
import {
  benefitsCategoryColumn,
  benefitsCreatedByColumn,
  benefitsCreatedOnColumn,
  benefitsDescriptionColumn,
  benefitsGroupsColumn,
  benefitsNameColumn,
  benefitsStatusColumn,
} from '@src/constants/columns/benefits'
import { Statuses } from '@src/interfaces'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'

const Row: RowInterface<BenefitsTableInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.BENEFITS.PREVIEW, { id: data.id })),
  cells: [
    {
      ...benefitsNameColumn,
      width: 150,
    },
    {
      ...benefitsCategoryColumn,
      width: 150,
    },
    {
      ...benefitsGroupsColumn,
      width: 150,
    },
    {
      ...benefitsDescriptionColumn,
      width: 300,
    },
    {
      ...benefitsCreatedOnColumn,
      width: 150,
    },
    {
      ...benefitsCreatedByColumn,
      width: 150,
    },
    {
      ...benefitsStatusColumn,
      width: 90,
    },
  ],
}

const Templates = () => {
  const permissions = useSelector(selectPermissions)

  const table = useTable({ getItems: getBenefits }, [
    {
      columnName: 'status',
      filters: [{ name: Statuses.active, id: Statuses.active }],
      nonResettable: true,
    },
  ])

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-16">
          <Stat
            label="Benefits templates"
            val={table?.loading ? undefined : table?.count}
            mr="s-16"
          />
        </Flex>
        {permissions.includes(PermissionTypes.AddBenefitsTemplate) && (
          <Box mb="s-16">
            <MoreBar>
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.BENEFITS.GENERAL)}
                useIcon={Plus}
              >
                Add benefit
              </MoreBar.Action>
            </MoreBar>
          </Box>
        )}
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.BenefitsTemplates}
            useWindowScroll
            row={Row}
            {...table}
            noDataMessage="Benefits will appear here."
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default Templates
