import { useGetGoals } from '@src/api/goals'
import RadioSelectInput, {
  RadioSelectInputProps,
} from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { FormError } from '@src/features/Form/LapeForm'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { GoalsInterface } from '@src/interfaces/goals'
import React from 'react'

export const StrategicGoalField = ({
  error,
  ...props
}: {
  error: FormError<GoalsInterface | null> | undefined
} & RadioSelectInputProps<GoalsInterface>) => {
  const isCommercial = useIsCommercial()

  const {
    data,
    isLoading,
    error: fetchError,
    isError,
  } = useGetGoals({
    page: 1,
    filters: [{ columnName: 'is_top_level', filters: [{ id: 'True', name: 'True' }] }],
  })

  if (isCommercial) {
    return null
  }

  return (
    <RadioSelectInput<GoalsInterface>
      loading={isLoading}
      label="Strategic goal"
      showCreateNewButton
      options={
        data?.results.map(option => ({
          value: option,
          label: option.name,
          key: option.id,
        })) || []
      }
      hasError={isError || !!error}
      message={error || fetchError}
      {...props}
    />
  )
}
