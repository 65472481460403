import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import { chain, Box, Color, Text } from '@revolut/ui-kit'
import { cultureValuesRequestsNew } from '@src/api/cultureValues'
import { ROUTES } from '@src/constants/routes'
import { CultureValueInterface } from '@src/interfaces/cultureValues'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import General from '@src/pages/Forms/CultureValueForm/General'
import Preview from '@src/pages/Forms/CultureValueForm/Preview'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'

const backUrl = ROUTES.FUNCTION.SKILLS

const getTabs = (id: string) => [
  {
    title: 'Preview',
    path: ROUTES.FORMS.VALUE.PREVIEW,
    to: pathToUrl(ROUTES.FORMS.VALUE.PREVIEW, { id }),
    component: Preview,
    canView: !!id,
  },
  {
    title: 'Settings',
    path: ROUTES.FORMS.VALUE.GENERAL,
    to: pathToUrl(ROUTES.FORMS.VALUE.GENERAL, { id }),
    component: General,
    canView: true,
  },
]

const ValueForm = connect(() => {
  const { id } = useParams<{ id: string }>()
  const { values } = useLapeContext<CultureValueInterface>()
  const tabs = getTabs(id).filter(tab => tab.canView)

  return (
    <PageWrapper>
      <PageHeader
        backUrl={backUrl}
        title={
          values.id
            ? chain(
                values.name,
                <Text color={values.is_enabled ? Color.GREEN : Color.GREY_TONE_50}>
                  {values.is_enabled ? 'Enabled' : 'Disabled'}
                </Text>,
              )
            : 'Create a new value'
        }
      >
        <Box>
          <TabBarNavigation tabs={tabs} />
        </Box>
      </PageHeader>
      <Switch>
        {tabs.map(tab => (
          <Route exact key={tab.path} path={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
})

const PageCultureValue = () => {
  return (
    <Form api={cultureValuesRequestsNew}>
      <ValueForm />
    </Form>
  )
}

export default connect(PageCultureValue)
