import React from 'react'
import { Absolute, Relative, useMatchMedia, Token } from '@revolut/ui-kit'
import styled from 'styled-components'
import css from '@styled-system/css'

import * as SS from 'styled-system'
import { FeatureItemContent } from '@src/pages/Landing/components/types'
import { useFeatureItemsBlockContext } from '@src/pages/Landing/components/FeatureItemsBlock/useFeatureItemsBlockContext'
import { FEATURE_ITEMS_STYLES } from '@src/pages/Landing/components/FeatureItemsBlock/constants'
import { FeatureItemsBlockMain } from '@src/pages/Landing/components/FeatureItemsBlock/FeatureItemsBlockMain'
import { FeatureItemsCarouselRight } from '@src/pages/Landing/components/FeatureItemsBlock/FeatureItemsCarouselRight'
import { FeatureItemsCarouselCenter } from '@src/pages/Landing/components/FeatureItemsBlock/FeatureItemsCarouselCenter'
import { FeatureItemsItemCarouselCard } from '@src/pages/Landing/components/FeatureItemsBlock/FeatureItemsItemCarouselCard'
import { FeatureItemsBlockBase } from '@src/pages/Landing/components/FeatureItemsBlock/FeatureItemsBlockBase'

const FeatureBlockCarouselBase = styled(FeatureItemsBlockBase)(
  css({
    paddingBottom: [
      FEATURE_ITEMS_STYLES.all.padding + FEATURE_ITEMS_STYLES.all.pageIndicatorOffset,
      null,
      null,
      FEATURE_ITEMS_STYLES.lg.padding + FEATURE_ITEMS_STYLES.lg.pageIndicatorOffset,
      null,
      FEATURE_ITEMS_STYLES.xxl.padding + FEATURE_ITEMS_STYLES.xxl.pageIndicatorOffset,
    ],
  }),
  SS.variant({
    prop: 'contentAlign',
    variants: {
      center: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '100%',
        overflow: 'hidden',
        textAlign: ['left', null, 'center'],
      },
      // equals to left - we don't support the carousel on the left side of the screen
      right: {
        gridTemplateAreas: [`'content' 'media'`, null, null, `'content media'`],
        gridTemplateRows: ['1fr', null, null, 'initial'],
        gridTemplateColumns: ['1fr', null, null, '488px 450px', null, '696px 600px'],
        justifyContent: 'space-between',
      },
    },
  }),
)

const CARD_SIZES = {
  all: { height: 412 },
  lg: { height: 568 },
  xxl: { height: 752 },
}

interface FeatureItemsBlockCarouselProps {
  caption?: string
  title?: string
  description?: string
  footnote?: string
  button?: { link: string; label: string }
  style: {
    color?: string
    align?: string
  }
  items: FeatureItemContent[] // at least one item
}

const FEATURE_CAROUSEL_IMAGE_ASPECT_RATIO = 360 / 508

export const FeatureItemsBlockCarousel = ({
  title: blockTitle,
  description: blockDescription,
  footnote: blockFootnote,
  button: blockButton,
  caption: blockCaption,
  items,
  style: { color: blockColor, align = 'left' },
}: FeatureItemsBlockCarouselProps) => {
  const isTablet = useMatchMedia(Token.media.md)
  const isDesktop = useMatchMedia(Token.media.lg)
  const { activeIndex, updateActiveIndex, activeItem, blockRef } =
    useFeatureItemsBlockContext()
  const {
    title = blockTitle,
    description = blockDescription,
    footnote = blockFootnote,
    button = blockButton,
    caption = blockCaption,
    style: { color = blockColor } = {},
  } = activeItem

  const isCentered = isTablet && (align === 'center' || !isDesktop)
  const aspectRatio = FEATURE_CAROUSEL_IMAGE_ASPECT_RATIO

  return (
    <>
      <FeatureBlockCarouselBase contentAlign={align} ref={blockRef}>
        <FeatureItemsBlockMain
          gridArea="content"
          color={color}
          title={title}
          caption={caption}
          description={description}
          footnote={footnote}
          button={button}
          backgroundAlign={align}
          align={align === 'center' ? 'center' : 'start'}
          alignSelf="center"
        />

        <Relative
          gridArea="media"
          alignSelf={isCentered ? 'center' : { all: 'flex-start', lg: 'center' }}
          marginLeft={isCentered ? 'initial' : { all: 's-20', lg: 0 }}
          // styled-components (or styled/css) adds px unit to the aspect ratio value that why we have to use `style`
          style={isCentered ? { aspectRatio: aspectRatio.toString() } : {}}
          width={{ all: '100%', lg: 'initial' }}
          height="100%"
          minHeight={{
            all: CARD_SIZES.all.height,
            md: 'initial',
          }}
          maxHeight={{
            all: CARD_SIZES.all.height,
            lg: CARD_SIZES.lg.height,
            xxl: CARD_SIZES.xxl.height,
          }}
        >
          {isCentered && (
            <FeatureItemsCarouselCenter
              swipeable
              items={items}
              ratio={aspectRatio}
              activeIndex={activeIndex}
              updateActiveIndex={updateActiveIndex}
            >
              {({ item }) => (
                <FeatureItemsItemCarouselCard
                  objectFit="cover"
                  item={item}
                  ratio={aspectRatio}
                />
              )}
            </FeatureItemsCarouselCenter>
          )}

          {!isCentered && (
            <Absolute top={0} left={0} height="100%" style={{ aspectRatio: '360 / 508' }}>
              <FeatureItemsCarouselRight
                items={items}
                ratio={aspectRatio}
                activeIndex={activeIndex}
                updateActiveIndex={updateActiveIndex}
              >
                {({ item }) => (
                  <FeatureItemsItemCarouselCard
                    objectFit="cover"
                    item={item}
                    ratio={aspectRatio}
                  />
                )}
              </FeatureItemsCarouselRight>
            </Absolute>
          )}
        </Relative>
      </FeatureBlockCarouselBase>
    </>
  )
}
