import React from 'react'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { getCandidateComments } from '@src/api/hiringProcess'
import { PageBody } from '@components/Page/PageBody'

type Props = {
  roundId?: number
  refreshStats: () => void
  onClickSeeAll?: () => void
}

const Comments = ({ roundId, refreshStats, onClickSeeAll }: Props) => {
  if (!roundId) {
    return null
  }

  const commentsApi = getCandidateComments(roundId)
  return (
    <PageBody>
      <CommentsSection
        onRefetch={refreshStats}
        api={commentsApi}
        onSideOpen={onClickSeeAll}
      />
    </PageBody>
  )
}

export default Comments
