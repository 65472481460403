export const FEATURE_HIDDEN_CARD_SCALE = 0.6
export const FEATURE_CARD_SCALE = 0.9
export const FEATURE_CAROUSEL_CARD_PADDING_COEFFICIENT =
  ((1 - FEATURE_CARD_SCALE) * 100) / 2
export const FEATURE_CAROUSEL_TRANSITION_DURATION = 350
export const FEATURE_CAROUSEL_TRANSITION_STEP_DURATION = 100

export const FEATURE_ITEMS_AUTO_SWITCH_TIMEOUT = 8000

export const FEATURE_ITEMS_STYLES = {
  paddingTop: [48, null, null, 64, null, 80],
  all: {
    padding: 48,
    margin: 24,
    pageIndicatorHeight: 6,
    pageIndicatorOffset: 16,
    tabsHeight: 42,
  },
  lg: {
    padding: 64,
    margin: 24,
    pageIndicatorHeight: 6,
    pageIndicatorOffset: 24,
    tabsHeight: 42,
  },
  xxl: {
    padding: 80,
    margin: 48,
    pageIndicatorHeight: 6,
    pageIndicatorOffset: 32,
    tabsHeight: 56,
  },
} as const
