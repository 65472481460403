import React from 'react'
import { VStack } from '@revolut/ui-kit'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import * as SS from 'styled-system'

import { FeatureBlockImage } from './FeatureBlockImage'
import { Text } from '../components/Text'
import { ContentBlockLayout } from '@src/pages/Landing/components/ContentBlockLayout'
import { FeatureBlockVideo } from '@src/pages/Landing/components/FeatureBlockVideo'

export enum FeatureBlockVariant {
  Left = 'left',
  Right = 'right',
}

export enum FeatureBlockAlign {
  Cover = 'cover',
  Contain = 'contain',
}

const FeatureBlockBase = styled(ContentBlockLayout)<{ variant: FeatureBlockVariant }>(
  css({
    alignItems: 'center',
    display: 'grid',
    gap: ['s-24', null, null, null, null, 's-40'],
  }),
  SS.variant({
    variants: {
      [FeatureBlockVariant.Left]: {
        gridTemplateColumns: ['auto', null, null, '50%'],
        gridTemplateAreas: [`'content' 'image'`, null, null, `'image content'`],
      },
      [FeatureBlockVariant.Right]: {
        gridTemplateColumns: ['auto', null, null, '50%'],
        gridTemplateAreas: [`'content' 'image'`, null, null, `'content image'`],
      },
    },
  }),
)

export interface FeatureBlockContent {
  description?: string | React.ReactNode
  image?: string
  video?: string
  title?: string | React.ReactNode
}

export const FeatureBlock = ({
  image,
  video,
  title,
  description,
}: FeatureBlockContent) => {
  return (
    <FeatureBlockBase variant={FeatureBlockVariant.Right}>
      {!video && image && (
        <FeatureBlockImage image={image} align={FeatureBlockAlign.Contain} />
      )}
      {video && <FeatureBlockVideo video={video} poster={image} />}
      <VStack
        align={{ all: 'start', md: 'center', lg: 'start' }}
        gridArea="content"
        space={{ all: 's-16', md: 's-24', xxl: 's-32' }}
      >
        {title && (
          <Text
            use="h2"
            variant="h3"
            whiteSpace="pre-wrap"
            textAlign={{ all: 'start', md: 'center', lg: 'start' }}
          >
            {title}
          </Text>
        )}
        {description && (
          <Text
            // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
            variant="subtitle1"
            whiteSpace="pre-wrap"
            use="div"
            textAlign={{ all: 'start', md: 'center', lg: 'start' }}
          >
            {description}
          </Text>
        )}
      </VStack>
    </FeatureBlockBase>
  )
}
