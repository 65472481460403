import React, { useMemo, useState } from 'react'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { IdAndName } from '@src/interfaces'
import { HStack, IconButton, Token } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import Tooltip from '@components/Tooltip/Tooltip'
import { SwitchItemsSidebar } from '@components/SwitchItemsSidebar/SwitchItemsSidebar'

type Props = {
  currentId?: number | string
  localStorageKey: string
  route: string
}

export const SwitcherButtonsWithSidebar = ({
  currentId,
  localStorageKey,
  route,
}: Props) => {
  const [savedDataContext] = useLocalStorage<{
    data?: IdAndName[]
  }>(localStorageKey, { data: [] })
  const [openSidebar, setOpenSidebar] = useState(false)

  const currentIdx = savedDataContext.data?.findIndex(item => item.id === currentId)

  const sidebarItems = useMemo(
    () =>
      savedDataContext.data?.map(item => ({
        id: item.id,
        avatar: {
          id: item.id,
          name: item.name,
        },
      })) || [],
    [savedDataContext],
  )

  if (
    !savedDataContext.data?.length ||
    !currentId ||
    currentIdx === undefined ||
    currentIdx === -1
  ) {
    return null
  }

  const next = savedDataContext.data[currentIdx + 1]
  const prev = savedDataContext.data[currentIdx - 1]

  return (
    <>
      <HStack gap="s-16">
        <Tooltip placement="top" text={prev?.name} hide={!prev}>
          <IconButton
            useIcon="ChevronLeft"
            color={!prev ? Token.color.greyTone20 : undefined}
            disabled={!prev}
            onClick={() => {
              if (prev) {
                navigateTo(pathToUrl(route, { id: prev.id }))
              }
            }}
          />
        </Tooltip>
        <Tooltip placement="top" text={next?.name} hide={!next}>
          <IconButton
            useIcon="ChevronRight"
            disabled={!next}
            color={!next ? Token.color.greyTone20 : undefined}
            onClick={() => {
              if (next) {
                navigateTo(pathToUrl(route, { id: next.id }))
              }
            }}
          />
        </Tooltip>
        <IconButton useIcon="Menu" onClick={() => setOpenSidebar(true)} />
      </HStack>
      <SwitchItemsSidebar
        title="Jobs"
        items={sidebarItems}
        isOpen={openSidebar}
        onClose={() => setOpenSidebar(false)}
        selectedIndex={currentIdx}
        onSelect={item => {
          navigateTo(pathToUrl(route, { id: item.id }))
        }}
        avatarUrlFunc={id => pathToUrl(route, { id })}
      />
    </>
  )
}
