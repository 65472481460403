import React from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { Group } from '@revolut/ui-kit'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OfferSettingsInterface } from '@src/interfaces/settings'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetDocuSignPreferences } from '@src/api/integrations'

const OffersGeneral = () => {
  const { values } = useLapeContext<OfferSettingsInterface>()
  const { data: docusignSettings } = useGetDocuSignPreferences()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeOfferFormPreferences)

  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle title="General" subtitle="Basic settings for Offers app" />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable Offers',
                description:
                  'Enable or disable the Offers app functionality. When enabled, users can set up dynamic offer templates, define and use custom placeholders during the offer creation process, release offers to candidates, and track the process in detail.',
              }}
              name="enable_offer_templates"
              disabled={disableEdit}
            />
            {values.enable_offer_templates && (
              <>
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable DocuSign integration',
                    description: (
                      <>
                        Allows offers to be generated and signed by candidates through
                        DocuSign. This function is only available when the DocuSign
                        integration is configured{' '}
                        <InternalLink
                          to={pathToUrl(ROUTES.FEATURES.INTEGRATION.DOCUSIGN)}
                        >
                          here
                        </InternalLink>
                        .
                      </>
                    ),
                  }}
                  name="enable_offer_signing"
                  disabled={disableEdit || !docusignSettings?.enabled}
                />
                <ApprovalSteps
                  title="Enable Offers approval"
                  description="Enable and customise the approval flow for Offers. Define custom steps that need approval whenever a new offer is created or an existing one is edited."
                  switchField="enable_approvals"
                  approvalProcess="offer_approvals"
                  entity="offers"
                  disabled={disableEdit}
                  formRef={values}
                />
              </>
            )}
          </Group>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.OFFER_SETTINGS)
            queryClient.invalidateQueries(API.APP_SETTINGS)
          }}
          useValidator
          isExistingData
        />
      </PageActions>
    </>
  )
}

export default OffersGeneral
