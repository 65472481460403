import {
  Token,
  ActionButton as UIKitButton,
  ThemeProvider,
  ActionButtonProps,
} from '@revolut/ui-kit'
import styled from 'styled-components'
import { css, SystemStyleObject } from '@styled-system/css'
import { variant } from 'styled-system'
import React from 'react'

// TODO: REVPI-28 Migrate LandingPage to standard UI Kit
export const ButtonBase = styled(UIKitButton)(
  ({ maxWidth }) =>
    css({
      height: 'auto',
      maxWidth: maxWidth || ['240px', null, '440px', '720px', null, '1024px'],
      paddingX: ['s-24', null, null, null, null, 's-32'],
      paddingY: ['10px', null, null, null, null, '14px'],
      '&:focus-visible': {
        boxShadow: `0 0 0 0.125rem rgb(${Token.colorChannel.blue} / 0.5) inset, 0 0 0 0.225rem ${Token.color.background} inset`,
      },
      cursor: 'pointer',
      fontSize: '16px',
      lineHeight: '22px',
    } as SystemStyleObject),
  variant({
    variants: {
      default: {
        bg: 'background',
        color: 'foreground',
        '&:hover': { bg: 'background', opacity: 0.85 },
        '&:active': { bg: 'background', opacity: 0.75 },
        '&:disabled': { bg: 'background', color: 'grey-50' },
      },
      text: {
        '&:hover': { bg: 'grey-tone-2', opacity: 0.85 },
        '&:active': { bg: 'grey-tone-2', opacity: 0.75 },
        '&:disabled': { color: 'grey-50' },
      },
      primary: {
        bg: 'blue',
        color: 'background',
        '&:hover': { bg: 'blue', opacity: 0.85 },
        '&:active': { bg: 'blue', opacity: 0.75 },
        '&:disabled': { bg: 'blue', color: 'grey-50' },
      },
      secondary: {
        bg: 'transparent',
        color: 'foreground',
        borderColor: 'foreground',
        borderStyle: 'solid',
        borderWidth: 2,
        '&:hover': { bg: 'transparent', opacity: 0.85 },
        '&:active': { bg: 'transparent', opacity: 0.75 },
        '&:disabled': { bg: 'transparent', borderColor: 'grey-50', color: 'grey-50' },
      },
    },
  }),
)

export const Button = (props: ActionButtonProps) => {
  return (
    <ThemeProvider mode={props.variant === 'primary' ? 'light' : 'dark'}>
      <ButtonBase {...props} />
    </ThemeProvider>
  )
}
