import React from 'react'
import {
  Header,
  DetailsCell,
  Group,
  Cell,
  Text,
  VStack,
  Subheader,
  Widget,
} from '@revolut/ui-kit'
import {
  CandidateSidebarTypes,
  InterviewStageWithoutRoundInterface,
  ScheduleSidebarModeType,
} from '@src/interfaces/interviewTool'
import StageActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StageActions'
import { PerformanceRatingTitle } from '@src/constants/performance'
import ScheduledDetails from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduledDetails'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { useGetSchedulingInterview } from '@src/api/recruitment/interviewerScheduling'
import ScheduledStatus from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduledStatus'
import SideBar from '@components/SideBar/SideBar'
import { StagesTableFeedback } from '@src/pages/Forms/Candidate/StagesTable/StagesTableFeedback'

type Props = {
  onClose: () => void
  stage?: InterviewStageWithoutRoundInterface
  canAddFeedback: boolean
  stages: InterviewStageWithoutRoundInterface[]
  roundId?: number
  canCancel: boolean
  onRefresh: () => void
  currentStageId?: number
  onOpenSidebar: (
    data: InterviewStageWithoutRoundInterface,
    mode?: ScheduleSidebarModeType,
    actionType?: CandidateSidebarTypes,
  ) => void
  isOpen?: boolean
  canViewEditOffer: boolean
  disableActions?: boolean
  candidateId?: number
}

const CandidateShortSummarySidebar = ({
  isOpen,
  onClose,
  stage,
  stages,
  roundId,
  onRefresh,
  currentStageId,
  canCancel,
  canAddFeedback,
  onOpenSidebar,
  canViewEditOffer,
  disableActions,
  candidateId,
}: Props) => {
  const { data: interview, isLoading: isInterviewLoading } = useGetSchedulingInterview(
    isOpen ? stage?.id : undefined,
    roundId,
  )

  if (!isOpen || !roundId || !stage || !candidateId) {
    return null
  }

  const title = stage.title
  const feedbacks = stage.interview_feedbacks

  return (
    <SideBar
      variant="wide"
      title={title}
      isOpen
      onClose={onClose}
      subtitle={<ScheduledStatus status={stage.scheduling_status} />}
      headerContent={
        !disableActions ? (
          <Header.Bar>
            <StageActions
              stages={stages}
              stage={stage}
              roundId={roundId}
              canCancel={canCancel}
              canAddFeedback={canAddFeedback}
              onRefresh={onRefresh}
              currentStageId={currentStageId}
              onOpenSidebar={onOpenSidebar}
              canViewEditOffer={canViewEditOffer}
              menuType="primary"
              candidateId={candidateId}
            />
          </Header.Bar>
        ) : undefined
      }
    >
      <Group>
        <DetailsCell>
          <DetailsCell.Title>Result</DetailsCell.Title>
          <DetailsCell.Content>
            {stage.average_rating ? PerformanceRatingTitle[stage.average_rating] : '-'}
          </DetailsCell.Content>
        </DetailsCell>
        {interview && (
          <>
            <ScheduledDetails interview={interview} isLoading={isInterviewLoading} />
            {interview.interviewer && (
              <DetailsCell>
                <DetailsCell.Title>Lead interviewer</DetailsCell.Title>
                <DetailsCell.Content>
                  <UserWithAvatar
                    full_name={interview.interviewer.name}
                    id={interview.interviewer.id}
                    avatar={interview.interviewer.avatar}
                  />
                </DetailsCell.Content>
              </DetailsCell>
            )}
            {!!interview?.additional_interviewers?.length && (
              <DetailsCell>
                <DetailsCell.Title>Additional interviewers</DetailsCell.Title>
                <DetailsCell.Content>
                  <VStack gap="s-16" align="end">
                    {interview.additional_interviewers.map(item => (
                      <UserWithAvatar
                        key={item.id}
                        full_name={item.name}
                        id={item.id}
                        avatar={item.avatar}
                      />
                    ))}
                  </VStack>
                </DetailsCell.Content>
              </DetailsCell>
            )}
          </>
        )}
      </Group>
      {!!feedbacks?.length && (
        <>
          <Subheader variant="nested">
            <Subheader.Title>Feedbacks</Subheader.Title>
          </Subheader>
          <VStack gap="s-16">
            {feedbacks.map(feedback => {
              return (
                <Widget key={feedback.id}>
                  <StagesTableFeedback
                    feedback={feedback}
                    canCancel={false}
                    canAddFeedback={false}
                    disableActions
                    itemProps={{
                      use: 'div',
                    }}
                  />
                  {feedback.overall_impressions && (
                    <Cell>
                      <VStack gap="s-16">
                        <Text variant="primary">Notes from interviewer</Text>
                        <Text>{feedback.overall_impressions}</Text>
                      </VStack>
                    </Cell>
                  )}
                </Widget>
              )
            })}
          </VStack>
        </>
      )}
    </SideBar>
  )
}

export default CandidateShortSummarySidebar
