import React from 'react'
import { formatDistanceToNow, isFuture, isPast } from 'date-fns'
import { Text } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { CheckpointType, ProbationCheckpoint } from '@src/interfaces/probationReview'
import { ReviewCategory } from '@src/interfaces/performance'
import { getDateDistance } from '@src/pages/EmployeeProfile/Layout/Performance/utils'

export const getStepState = (startDate: string, endDate: string) => {
  if (isFuture(new Date(startDate))) {
    return 'default'
  }

  if (isPast(new Date(endDate))) {
    return 'done'
  }

  return 'pending'
}

export const getStepDescriptionWithDistance = (checkpoint: ProbationCheckpoint) => {
  if (isPast(new Date(checkpoint.checkpoint_deadline))) {
    return (
      <Text>{`Closed ${formatDate(
        checkpoint.checkpoint_deadline,
        'd MMMM yyyy',
      )} - ${formatDistanceToNow(new Date(checkpoint.checkpoint_deadline))} ago`}</Text>
    )
  }

  const startOfCheckpoint = getStartOfCheckpoint(checkpoint)
  if (isFuture(new Date(startOfCheckpoint))) {
    return (
      <Text>
        {`Opens ${formatDate(startOfCheckpoint, 'd MMMM yyyy')} - `}
        {getDateDistance(startOfCheckpoint)}
      </Text>
    )
  }

  if (isFuture(new Date(checkpoint.checkpoint_deadline))) {
    return (
      <Text>
        {`Closing ${formatDate(checkpoint.checkpoint_deadline, 'd MMMM yyyy')} - `}
        {getDateDistance(checkpoint.checkpoint_deadline)}
      </Text>
    )
  }

  return <>-</>
}

export const getStartOfCheckpoint = (checkpoint: ProbationCheckpoint) => {
  return checkpoint.checkpoint_start || checkpoint.checkpoint_date_time
}

export const getStepDescription = (checkpoint: ProbationCheckpoint) => {
  if (isPast(new Date(checkpoint.checkpoint_deadline))) {
    return (
      <Text>{`Closed ${formatDate(checkpoint.checkpoint_deadline, 'dd MMM')} `}</Text>
    )
  }

  const startOfCheckpoint = getStartOfCheckpoint(checkpoint)
  if (isFuture(new Date(startOfCheckpoint))) {
    return <Text>{`Opens ${formatDate(startOfCheckpoint, 'dd MMM')} `}</Text>
  }

  if (isFuture(new Date(checkpoint.checkpoint_deadline))) {
    return <Text>{`Closes ${formatDate(checkpoint.checkpoint_deadline, 'dd MMM')}`}</Text>
  }

  return <>-</>
}

const num = ['First', 'Second', 'Third']

export const getStepTitle = (
  checkpoint: ProbationCheckpoint,
  category: ReviewCategory,
) => {
  if (checkpoint?.checkpoint_type === CheckpointType.Goals) {
    return `${category === ReviewCategory.Probation ? 'Probation' : 'PIP'} goals`
  }
  if (checkpoint?.checkpoint_type === CheckpointType.Review) {
    const checkpointNum = num[checkpoint.checkpoint_number - 1]
    return checkpointNum
      ? `${checkpointNum} checkpoint`
      : `Checkpoint ${checkpoint.checkpoint_number}`
  }
  if (checkpoint?.checkpoint_type === CheckpointType.Recommendation) {
    return 'Recommendation'
  }
  return 'Committee'
}
