import React from 'react'
import { Flex } from '@revolut/ui-kit'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import Stat from '@components/Stat/Stat'
import { getOffersOwnership, offersOwnershipTransfer } from '@src/api/ownership'
import {
  OwnershipTransferProvider,
  SmallBanner,
  TransferSelections,
  TransferTable,
  TrasferAllButton,
} from './common'
import { TableWrapper } from '@components/Table/TableWrapper'
import {
  offerCandidateNameColumn,
  offerRecruiterColumn,
  offerSigningStatusColumn,
  offerSpecialisationColumn,
  offerStatusColumn,
} from '@src/constants/columns/offerForm/offers'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
}

const OffersOwnership = ({ data, stats, refreshStats }: Props) => {
  const transferApi = (selections: TransferSelections) =>
    offersOwnershipTransfer(
      data.id,
      Object.entries(selections).map(([id, selection]) => ({
        id,
        owner: selection.id,
      })),
    ).then(refreshStats)

  return (
    <OwnershipTransferProvider
      getApi={getOffersOwnership(data.id)}
      defaultOwner={data.line_manager}
      transferApi={transferApi}
    >
      <SmallBanner
        title="Employee scheduled for termination. Transfer offers to a new owner"
        hidden={!data.has_termination}
      />

      <TableWrapper mb="s-20">
        <Stat label="Offers" val={stats?.owner_offer_form_count} mb="s-24" />
        <Flex mb="s-24">
          <TrasferAllButton />
        </Flex>

        <TransferTable
          cells={[
            { ...offerCandidateNameColumn, width: 220 },
            { ...offerSpecialisationColumn, width: 250 },
            { ...offerRecruiterColumn, width: 250 },
            { ...offerStatusColumn, width: 150 },
            { ...offerSigningStatusColumn, width: 180 },
          ]}
          noDataMessage="Offers ownership will appear here"
          ownerColTitle="New owner"
        />
      </TableWrapper>
    </OwnershipTransferProvider>
  )
}

export default OffersOwnership
