import React from 'react'
import { css } from 'styled-components'
import { companyKpiRequests } from '@src/api/kpis'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { KpiInterface } from '@src/interfaces/kpis'
import { CompanyGoalInterface } from '@src/interfaces/companyGoals'
import {
  companyGoalsKPILevel,
  companyGoalsWeight,
  companyKPICategory,
} from '@src/constants/columns/companyGoals'
import { useTable } from '@components/Table/hooks'
import { BaseChartInner } from '@components/Charts/BaseChart/BaseChartInner'
import {
  kpiCalibratedPerformanceColumn,
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiStatusColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
} from '@src/constants/columns/kpi'
import {
  companyGoalsRequestsNew,
  getCompanyGoalsPerformanceGraph,
} from '@src/api/companyGoals'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { Bar, Box, BoxProps } from '@revolut/ui-kit'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AdjustableTable from '@components/Table/AdjustableTable'
import { DeleteButtonLape } from '@src/pages/Forms/FormButtons'
import { ROUTES } from '@src/constants/routes'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { Statuses } from '@src/interfaces'
import { KPITypes, TableNames } from '@src/constants/table'
import { useGetCompany } from '@src/api/company'

const WordWrapCss = css`
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
`

interface Props {
  data: CompanyGoalInterface
}

const cycleRows: (companyName: string) => RowInterface<KpiInterface> = companyName => ({
  cells: [
    {
      ...kpiGenericNameColumn,
    },
    {
      ...companyGoalsWeight,
      width: 100,
    },
    {
      ...kpiPerformanceColumn,
      width: 130,
    },
    {
      ...kpiCalibratedPerformanceColumn,
      width: 130,
    },
    {
      ...companyKPICategory,
      width: 180,
    },
    {
      ...companyGoalsKPILevel,
      insert: ({ data }) => {
        if (data.id === -1) {
          return ' '
        }
        if (data.type === KPITypes.company_goal || data.type === KPITypes.company_kpi) {
          return companyName
        }
        if (data.type === KPITypes.employee_kpi) {
          return data.owner?.full_name || ''
        }

        if (data.type === KPITypes.function_kpi) {
          return data?.function?.name || ''
        }

        if (data.type === KPITypes.department_kpi) {
          return data?.department?.name || ''
        }

        if (data.type === KPITypes.team_kpi) {
          return data?.team?.name || ''
        }

        return ' '
      },
      width: 180,
    },
    {
      ...kpiInitialValueColumn,
      width: 100,
    },
    {
      ...kpiCurrentValueColumn,
      width: 100,
    },
    {
      ...kpiTargetColumn,
      width: 100,
    },
    {
      ...kpiUnitColumn,
      width: 80,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 200,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 100,
    },
    {
      ...kpiStatusColumn,
      width: 100,
    },
  ],
})

const StepWrapper = (props: BoxProps) => (
  <Box
    css={WordWrapCss}
    width={576}
    bg="widget-background"
    p="s-16"
    radius="widget"
    {...props}
  />
)

const Preview = ({ data }: Props) => {
  const { data: company, isLoading } = useGetCompany()
  const filters: FilterByInterface[] = [
    {
      columnName: 'parent__id',
      filters: [
        {
          name: `${data.id}`,
          id: data.id,
        },
      ],
    },
    {
      filters: [{ name: Statuses.active, id: Statuses.active }],
      columnName: 'status',
    },
  ]
  const table = useTable<KpiInterface>(companyKpiRequests, filters)

  return (
    <AutoStepper mt="s-8">
      <Bar>
        <DeleteButtonLape<CompanyGoalInterface>
          data={data}
          backUrl={ROUTES.FORMS.COMPANY.KPI.GOALS}
          deleteApi={companyGoalsRequestsNew.delete!}
          title="Company Goal"
        />
      </Bar>
      <Box mt="s-32">
        <NewStepperTitle title="Description" />
        <StepWrapper>{data.description}</StepWrapper>
      </Box>
      <Box mt="s-32">
        <NewStepperTitle title="Progress Chart" />
        <StepWrapper height={380} pr={0} pl="s-8" pt="s-16" pb="s-8">
          <BaseChartInner
            fetchData={getCompanyGoalsPerformanceGraph}
            id={data.id}
            showButtons
            isNew
          />
        </StepWrapper>
      </Box>
      <Box mt="s-32">
        <NewStepperTitle title="Company KPIs" />
        <StepWrapper width="100%" maxWidth={1600}>
          <Box mb="s-32">
            <CycleFilter
              type={CycleFilterType.NewUI}
              onFilterChange={table.onFilterChange}
              columnName="review_cycle__offset"
              filter={table.filterBy}
            />
          </Box>
          <AdjustableTable<KpiInterface>
            name={TableNames.CompanyGoalsPreview}
            row={cycleRows(company?.name || '')}
            {...table}
            loading={isLoading || table.loading}
            noDataMessage="All your KRIs will appear here."
            key="kri_tab"
            onRowClick={onKPITableRowClick}
          />
        </StepWrapper>
      </Box>
    </AutoStepper>
  )
}

export default Preview
