import React from 'react'
import { Cell, Flex, MoreBar } from '@revolut/ui-kit'
import { Plus, ShareIOs } from '@revolut/icons'
import { useSelector } from 'react-redux'

import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import SettingsButtons from '@src/features/SettingsButtons'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import {
  actionsColumn,
  bonusCreatedByColumn,
  bonusCreatedOnColumn,
  bonusDateColumn,
  bonusGrantTypeColumn,
  bonusPeriodColumn,
  bonusReasonColumn,
  bonusTaxabilityColumn,
  bonusUpdatedByColumn,
  bonusUpdatedOnColumn,
  bonusValueColumn,
} from '@src/constants/columns/rewards'
import { useTable } from '@src/components/Table/hooks'
import { getEmployeeBonuses } from '@src/api/importData'
import { CompensationBonusInterface } from '@src/interfaces/compensation'
import Stat from '@src/components/Stat/Stat'
import {
  compensationBonusesDetailsColumn,
  compensationBonusesEmployeeColumn,
} from '@src/constants/columns/importBonuses'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { EditBonusSidebar } from '@src/features/EditBonusSidebar'
import { useBonusSidebar } from '@src/features/EditBonusSidebar/hooks'
import { BonusActions } from '@components/ColumnInserts/BonusActions/BonusActions'

const getRows: (
  refresh: () => void,
) => RowInterface<CompensationBonusInterface> = refresh => ({
  cells: [
    {
      ...compensationBonusesEmployeeColumn,
      width: 200,
    },
    {
      ...bonusDateColumn,
      width: 80,
    },
    {
      ...bonusReasonColumn,
      width: 240,
    },
    {
      ...bonusPeriodColumn,
      width: 80,
    },
    {
      ...bonusValueColumn,
      width: 80,
    },
    {
      ...bonusGrantTypeColumn,
      width: 80,
    },
    {
      ...bonusTaxabilityColumn,
      width: 80,
    },
    {
      ...bonusCreatedOnColumn,
      width: 100,
    },
    {
      ...bonusCreatedByColumn,
      width: 150,
    },
    {
      ...bonusUpdatedOnColumn,
      width: 100,
    },
    {
      ...bonusUpdatedByColumn,
      width: 150,
    },
    {
      ...compensationBonusesDetailsColumn,
      width: 240,
    },
    {
      ...actionsColumn,
      width: 180,
      insert: ({ data }) => <BonusActions id={data.id} onDelete={refresh} />,
    },
  ],
})

export const Bonuses = () => {
  const permissions = useSelector(selectPermissions)

  const table = useTable<CompensationBonusInterface>({
    getItems: getEmployeeBonuses,
  })

  const { openBonusSidebar, closeBonusSidebar } = useBonusSidebar()

  const canImport = permissions.includes(
    PermissionTypes.AddEmployeeBonusBulkUploadSession,
  )
  const canAdd = permissions.includes(PermissionTypes.AddEmployeeBonus)

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Stat label="Bonuses" val={table?.loading ? undefined : table?.count} mb="s-16" />

        <SettingsButtons mb="s-16">
          {canImport && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.BONUSES.UPLOAD_FILE)}
              useIcon={ShareIOs}
            >
              Import data
            </MoreBar.Action>
          )}
          {canAdd && (
            <MoreBar.Action useIcon={Plus} onClick={() => openBonusSidebar()}>
              Add new
            </MoreBar.Action>
          )}
        </SettingsButtons>

        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.CompensationBonuses}
            useWindowScroll
            row={getRows(table.refresh)}
            {...table}
            noDataMessage="Bonuses will appear here."
          />
        </Flex>
      </Flex>
      <EditBonusSidebar
        onAfterSubmit={() => {
          closeBonusSidebar()
          table.refresh()
        }}
      />
    </Cell>
  )
}
