import React from 'react'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Dots, Flex } from '@revolut/ui-kit'
import { Route, Switch } from 'react-router-dom'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { TableWrapper } from '@components/Table/TableWrapper'

interface RiskProps {
  tabs: TabBarTableNavigationInterface[]
  wrapNavigation?: boolean
}

const RiskTab = ({ tabs, wrapNavigation = false }: RiskProps) => {
  const statsLoaded = tabs.every(tab => !!tab.quickSummary)

  return (
    <Flex flexDirection="column" minHeight={0}>
      {!wrapNavigation ? (
        <Flex alignItems="flex-start" mb="s-24">
          {statsLoaded ? <TabBarNavigation isSubtab tabs={tabs} /> : <Dots height={44} />}
        </Flex>
      ) : null}

      <Switch>
        <TableWrapper useWrapper={wrapNavigation}>
          {wrapNavigation ? (
            <Flex alignItems="flex-start" mb="s-24">
              {statsLoaded ? (
                <TabBarNavigation isSubtab tabs={tabs} />
              ) : (
                <Dots height={44} />
              )}
            </Flex>
          ) : null}
          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.url}>
              {tab.component}
            </Route>
          ))}
        </TableWrapper>
      </Switch>
    </Flex>
  )
}

export default RiskTab
