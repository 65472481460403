import { AxiosPromise } from 'axios'

import { FetchDataQueryInterface } from '@src/interfaces/data'
import { api, apiWithoutHandling } from '.'
import { uploadFile } from './files'
import { ApiVersion, GetRequestInterface, IdAndName } from '@src/interfaces'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  BulkUploadConfigurableField,
  ConfigurableBulkUploadEntity,
  ImportInterface,
  ImportSessionInterface,
  ImportSessionStatsInterface,
} from '@src/interfaces/bulkDataImport'
import { useFetch } from '@src/utils/reactQuery'
import { getFileNameFromHeaders } from '@src/utils/files'
import { useQuery } from '@src/utils/queryParamsHooks'
import { IS_EMPLOYEE_UPDATE_QUERY_KEY } from '@src/features/EditableEmployeesTable/common/constants'
import { API } from '@src/constants/api'

export const importFile = async (
  file: File,
  endpoint: string,
  category: string,
  uploadParams?: Record<string, string | boolean | IdAndName<string>[]>,
) => {
  const uploadResult = await uploadFile(file, category, true, apiWithoutHandling)

  return apiWithoutHandling.post(endpoint, {
    file: { id: uploadResult.data.id },
    ...uploadParams,
  })
}

export const getImportFileTemplate = async (
  endpoint: string,
  params?: Record<string, string>,
  fallbackName?: string,
) => {
  const res = await api.get<Blob>(`${endpoint}/template`, {
    responseType: 'blob',
    params,
  })

  return {
    data: URL.createObjectURL(res.data),
    fileName: getFileNameFromHeaders(res.headers, fallbackName),
  }
}

export const getUploadHistory = (
  endpoint: string,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<GetRequestInterface<ImportSessionInterface>> =>
  api.get(endpoint, { params: filterSortPageIntoQuery(sortBy, filters, page) })

export const useGetImportSessionData = (
  apiEndpoint: string,
  id?: string | number,
  enabled = true,
) => {
  const data = useFetch<ImportSessionInterface>(
    `${apiEndpoint}/${id}`,
    undefined,
    undefined,
    true,
    {
      refetchInterval: session =>
        session?.state.id === 'pending' ||
        session?.state.id === 'processing_file' ||
        session?.state.id === 'applying'
          ? 1000
          : false,
      enabled: enabled && !!id,
    },
  )

  return data
}

const transformSessionStats = (data: ImportSessionStatsResponseInterface) => ({
  total: data.total,
  errors: data.errors,
  warnings: data.warnings,
  success: data.total - data.errors,
  state_pending: data.state.pending,
  state_success: data.state.success,
  state_failure: data.state.failure,
})

export const useGetImportSessionDataStats = (
  apiEndpoint: string,
  id?: number,
  enabled = false,
) => {
  const response = useFetch<ImportSessionStatsResponseInterface>(
    `${apiEndpoint}/${id}/items/stats`,
    undefined,
    undefined,
    true,
    {
      refetchInterval: 1000,
      enabled: enabled && !!id,
    },
  )

  return {
    ...response,
    data: response.data ? transformSessionStats(response.data) : undefined,
  }
}

export const getUploadSessionTable =
  <T>(apiEndpoint: string, id: string | number, apiVersion?: ApiVersion) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ImportInterface<T>>> => {
    return api.get(
      `${apiEndpoint}/${id}/items`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      apiVersion,
    )
  }

export interface ImportSessionStatsResponseInterface {
  total: number
  errors: number
  warnings: number
  state: { pending: number; success: number; failure: number }
}

export const getUploadSessionTableStats =
  (apiEndpoint: string, id: string | number, apiVersion?: ApiVersion) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<ImportSessionStatsInterface> => {
    return api
      .get<ImportSessionStatsResponseInterface>(
        `${apiEndpoint}/${id}/items/stats`,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
        apiVersion,
      )
      .then(response => ({
        ...response,
        data: transformSessionStats(response.data),
      }))
  }

export const deleteImportSessionRow =
  (apiEndpoint: string, apiVersion?: ApiVersion) => (sessionId: number, rowId: number) =>
    apiWithoutHandling.delete(
      `${apiEndpoint}/${sessionId}/items/${rowId}`,
      undefined,
      apiVersion,
    )

export const editImportSessionRow = <T>(
  apiEndpoint: string,
  sessionId: number | string,
  rowId: number,
  data: Pick<ImportInterface<T>, 'data'>,
  apiVersion?: ApiVersion,
) =>
  apiWithoutHandling.patch<ImportInterface<T>>(
    `${apiEndpoint}/${sessionId}/items/${rowId}`,
    data,
    undefined,
    apiVersion,
  )

export const applyImportSession = (apiEndpoint: string, sessionId: number | string) =>
  apiWithoutHandling.post(`${apiEndpoint}/${sessionId}/apply`)

export const useImportSessionBulkItemSelector = (
  apiEndpoint: string,
  sessionId: number | string | null,
  params: Record<string, string>,
) => {
  const result = useFetch<{ ids: number[] }>(
    `${apiEndpoint}/${sessionId}/items/ids`,
    'v1',
    { params },
    true,
    {
      enabled: !!sessionId,
    },
  )
  return { options: result.data?.ids || [], refetch: result.refetch }
}

export const getImportSessionBulkItems = (
  apiEndpoint: string,
  sessionId: number | string,
) => apiWithoutHandling.get<{ ids: number[] }>(`${apiEndpoint}/${sessionId}/items/ids`)

export const bulkDeleteImportSessionRows = (
  apiEndpoint: string,
  sessionId: number | string,
  item_ids: number[],
) =>
  apiWithoutHandling.post(`${apiEndpoint}/${sessionId}/items/bulkDelete`, {
    item_ids,
  })

export const bulkEditImportSessionRows = (
  apiEndpoint: string,
  sessionId: number | string,
  item_ids: number[],
  edit_fields: Record<string, string | null | IdAndName[]>,
) =>
  apiWithoutHandling.post<{ edited: number }>(
    `${apiEndpoint}/${sessionId}/items/bulkEdit`,
    {
      item_ids,
      edit_fields,
    },
  )

export const createImportFromEntities = (
  apiEndpoint: string,
  entity_ids: number[],
  fields?: string[],
) =>
  apiWithoutHandling.post<{ bulk_upload_id: number }>(
    `${apiEndpoint}/createFromEntities`,
    {
      entity_ids,
      fields,
    },
  )

export const useGetConfigurableBulkUploadFields = (
  entity?: ConfigurableBulkUploadEntity,
) => {
  const { query } = useQuery()

  const isBulkEdit = !!query[IS_EMPLOYEE_UPDATE_QUERY_KEY] && entity === 'employees'

  const endpoint =
    entity === 'contracts'
      ? `/contracts/bulkUpload/fields`
      : entity === 'employees'
      ? `/employeeUploads/fields`
      : entity === 'goals'
      ? `/goalBulkUpload/fields`
      : null
  return useFetch<BulkUploadConfigurableField[]>(
    endpoint,
    undefined,
    isBulkEdit ? { params: { is_bulk_edit: 'true' } } : undefined,
  )
}

export const refreshBulkImportSessionValidation = (
  apiEndpoint: string,
  sessionId: number | string,
) => api.post(`${apiEndpoint}/${sessionId}/refreshValidation`)

export type CreateEmployeeUploadSource = 'google' | 'slack'

export const createEmployeeUpload = (source: CreateEmployeeUploadSource) =>
  apiWithoutHandling.post<{ id: number }>(API.EMPLOYEE_UPLOADS, { source })
