import React from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION, Stats } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { refreshRoadmap, roadmapsRequests } from '@src/api/roadmaps'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import {
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapJiraLabelColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapProjectColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import { ownerNameColumn } from '@src/constants/columns/employee'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { formatPercentage } from '@src/utils/format'
import { getLocalDateTime } from '@src/utils/roadmap'
import isUndefined from 'lodash/isUndefined'
import { Cell, Color, Flex, Text } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { FunctionInterface } from '@src/interfaces/functions'
import { TableNames } from '@src/constants/table'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import { useGetRoadmapSettings } from '@src/api/settings'
import {
  OptionalRoadmapActions,
  RoadmapActionsBar,
  useShowRoadmapDetails,
} from '@src/features/Roadmaps'

interface StructureProps {
  data: FunctionInterface
}

const ROW: RowInterface<RoadmapInterface> = {
  cells: [
    {
      ...roadmapGenericNameColumn,
    },
    {
      ...roadmapStartDate,
      width: 130,
    },
    {
      ...roadmapDueDate,
      width: 130,
    },
    {
      ...roadmapProgressColumn,
      width: 200,
    },
    {
      ...roadmapStatusColumn,
      width: 100,
    },
    {
      ...roadmapPriorityColumn,
      width: 100,
    },
    {
      ...roadmapResolutionAtColumn,
      width: 170,
    },
    {
      ...ownerNameColumn,
      width: 180,
    },
    {
      ...roadmapProjectColumn,
      width: 120,
    },
    {
      ...roadmapJiraLabelColumn,
      width: 130,
    },
    {
      ...roadmapEpicUrlColumn,
      width: 150,
    },
  ],
}

export const Roadmap = ({ data }: StructureProps) => {
  const { query } = useQuery()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const jiraEnabled = roadmapSettings?.jira_epics_enabled
  const cycleOffset = query.review_cycle__offset || 0
  const { data: reviewCycle } = useGetReviewCycleByOffset(cycleOffset)
  const initialFilter = [
    {
      filters: [{ name: data.name, id: data.id }],
      columnName: 'function__id',
      nonResettable: true,
    },
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'due_date',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const table = useTable<RoadmapInterface, Stats>(
    roadmapsRequests,
    initialFilter,
    initialSortBy,
  )
  const { renderSidebar, showRoadmapDetails } = useShowRoadmapDetails({
    changelogState: false,
    jiraEnabled: !!jiraEnabled,
  })

  const triggerRefresh = async () => {
    await refreshRoadmap(
      `FUNCTION-${data.id}`,
      !isUndefined(reviewCycle) ? { id: Number(reviewCycle.id) } : undefined,
    )
  }
  const moreBarPending = !roadmapSettings || !reviewCycle

  return (
    <Cell mt="s-8" minHeight={0}>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-24">
          <Stat
            label="Progress"
            val={
              table?.stats?.avg_done !== undefined ? (
                <Text color={getPercentColor((table?.stats?.avg_done || 0) * 100)}>
                  {formatPercentage(table?.stats?.avg_done)}
                </Text>
              ) : undefined
            }
            mr="s-32"
          />
          <CycleFilter
            type={CycleFilterType.NewUI}
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__offset"
            filter={table.filterBy}
          />
        </Flex>
        <Flex mb="s-16">
          <RoadmapActionsBar pending={moreBarPending}>
            <OptionalRoadmapActions refresh={triggerRefresh} allowRefresh />
          </RoadmapActionsBar>
        </Flex>
        {!!table?.stats?.refresh_date_time && (
          <Text mb="s-12" mt="s-8">
            Progress last updated:{' '}
            <Text color={Color.GREY_TONE_50}>
              {getLocalDateTime(table?.stats?.refresh_date_time)}
            </Text>
          </Text>
        )}
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<RoadmapInterface>
            name={TableNames.FunctionRoadmap}
            useWindowScroll
            row={ROW}
            onRowClick={showRoadmapDetails}
            {...table}
            dataType={jiraEnabled ? 'epic' : 'roadmap items'}
            expandableType="chevron"
            noDataMessage={`All your ${
              jiraEnabled ? 'epics' : 'roadmap items'
            } will appear here.`}
          />
          {renderSidebar({ canEdit: false })}
        </Flex>
      </Flex>
    </Cell>
  )
}

export default Roadmap
