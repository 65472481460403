import React, { useEffect, useState } from 'react'
import { useTable } from '@components/Table/hooks'
import { getAuditTickets, getStaticCounts } from '@src/api/audit'
import { EntityTypes, LOCAL_STORAGE } from '@src/constants/api'
import { RESOLVING_FINDING_TICKETS } from '@src/constants/externalLinks'
import { TableNames } from '@src/constants/table'
import { FilterByInterface, RowInterface, SortByInterface } from '@src/interfaces/data'
import { AuditInterface, AuditTypes } from '@src/interfaces/audit'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { EmployeeInterface } from '@src/interfaces/employees'
import { TeamInterface } from '@src/interfaces/teams'
import AdjustableTable from '@components/Table/AdjustableTable'
import AuditCircles from '@components/ColumnInserts/AuditCircles/AuditCircles'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import { Cell, Dots, Flex, HStack, Switch, TabBar, Text } from '@revolut/ui-kit'
import { FindingsStats } from './FindingsStats'
import {
  otherTypes,
  getDataForAuditCircles,
  getMultipleDataForAuditCircles,
  getAuditTypeFilter,
  getHistoryFilter,
  getSubtasksFilter,
} from './utils'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

interface Tab {
  title: string
  id: string
  quickSummary?: React.ReactNode
  isValid?: boolean
  to?: { pathname: string; state: any }
  invalidTabMessage?: React.ReactNode
}

interface FindingsTabProps {
  data?: TeamInterface | EmployeeInterface | DepartmentInterface
  entity: EntityTypes
  initialFilter?: FilterByInterface[]
  initialSort?: SortByInterface[]
  row: RowInterface<AuditInterface>
}

const auditTableNames = {
  [EntityTypes.company]: TableNames.Findings,
  [EntityTypes.department]: TableNames.DepartmentAuditTickets,
  [EntityTypes.employee]: TableNames.EmployeeAuditTickets,
  [EntityTypes.team]: TableNames.TeamAuditTickets,
}

export const FindingsTab = ({
  data,
  entity,
  initialFilter,
  initialSort,
  row,
}: FindingsTabProps) => {
  const [showHistory, setShowHistory] = useState(
    !!workspaceLocalStorage.getItem(LOCAL_STORAGE.SHOW_HISTORY),
  )
  const [showSubtasks, setShowSubtasks] = useState(
    !!workspaceLocalStorage.getItem(LOCAL_STORAGE.SHOW_ONLY_SUBTASKS),
  )

  const table = useTable<AuditInterface>(
    { getItems: getAuditTickets(entity, data?.id) },
    initialFilter,
    initialSort,
  )

  const staticCountsData = useTable<AuditInterface>(
    { getItems: getStaticCounts(entity, data?.id) },
    undefined,
    undefined,
  )

  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const staticCounts = staticCountsData.data

  const tabs: Readonly<Tab[]> = [
    {
      id: '',
      title: 'All Findings',
      quickSummary: <AuditCircles data={getDataForAuditCircles(entity, staticCounts)} />,
    },
    {
      id: AuditTypes.AUDIT,
      title: 'PHIX',
      quickSummary: (
        <AuditCircles
          data={getDataForAuditCircles(entity, staticCounts, AuditTypes.AUDIT)}
        />
      ),
    },
    {
      id: AuditTypes.RISK,
      title: 'Risk',
      quickSummary: (
        <AuditCircles
          data={getDataForAuditCircles(entity, staticCounts, AuditTypes.RISK)}
        />
      ),
    },
    {
      id: AuditTypes.VALIDATION,
      title: 'Model Risk',
      quickSummary: (
        <AuditCircles
          data={getDataForAuditCircles(entity, staticCounts, AuditTypes.VALIDATION)}
        />
      ),
    },
    {
      id: AuditTypes.OTHER,
      title: 'Other',
      quickSummary: (
        <AuditCircles
          data={getMultipleDataForAuditCircles(entity, staticCounts, otherTypes)}
        />
      ),
    },
  ]

  const [selectedTab, setSelectedTab] = useState<string>(AuditTypes.AUDIT)

  const onChangeTab = (tab: string) => {
    setSelectedTab(tab)
    applyTabFilter(tab)
  }

  const applyTabFilter = (tab: string) => {
    const auditTypeFilter = getAuditTypeFilter(tab)
    table.onFilterChange(auditTypeFilter)
  }

  const onToggleShowHistory = () => {
    if (showHistory) {
      workspaceLocalStorage.removeItem(LOCAL_STORAGE.SHOW_HISTORY)
    } else {
      workspaceLocalStorage.setItem(LOCAL_STORAGE.SHOW_HISTORY, 'true')
    }

    setShowHistory(!showHistory)
    const historyFilter = getHistoryFilter(!showHistory)
    table.onFilterChange(historyFilter)
  }

  const onToggleShowSubtasks = () => {
    if (showSubtasks) {
      workspaceLocalStorage.removeItem(LOCAL_STORAGE.SHOW_ONLY_SUBTASKS)
    } else {
      workspaceLocalStorage.setItem(LOCAL_STORAGE.SHOW_ONLY_SUBTASKS, 'true')
    }

    setShowSubtasks(!showSubtasks)
    const subtasksFilter = getSubtasksFilter(!showSubtasks)
    table.onFilterChange(subtasksFilter)
  }

  useEffect(() => {
    const auditTypeFilter = table.filterBy.find(
      filter => filter.columnName === 'category',
    )
    const allFindingsTabId = tabs[0].id
    const otherTabId = tabs[4].id

    if (auditTypeFilter && auditTypeFilter.filters.length) {
      if (auditTypeFilter.filters.length === 1) {
        const filterId = auditTypeFilter.filters[0]?.id
        if (filterId) {
          const tab = tabs.find(item => item.id === filterId)!

          setSelectedTab(tab ? tab.id : otherTabId)
        }
      } else {
        setSelectedTab(otherTabId)
      }
    } else {
      setSelectedTab(allFindingsTabId)
    }
  }, [table.filterBy])

  const openUrl = (rowData: AuditInterface) => {
    window.open(rowData.issue_link, '_blank')
  }

  return (
    <Flex flexDirection="column" minHeight={0}>
      <Flex alignItems="flex-start" mb="s-24">
        {staticCountsData.loading ? (
          <Dots height={44} color="grey-tone-50" duration={900} />
        ) : (
          <TabBar mr="s-16" variant="segmented fit">
            {tabs.map(tab => {
              return (
                <TabBar.Item
                  key={tab.id}
                  onClick={() => onChangeTab(tab.id)}
                  aria-selected={selectedTab === tab.id}
                >
                  <Flex justifyContent="flex-start" alignItems="center">
                    {tab.title}
                    {tab.quickSummary ? (
                      <>
                        <Text use="div" mx="5px" color="gray500">
                          ·
                        </Text>
                        {tab.quickSummary}
                      </>
                    ) : null}
                  </Flex>
                </TabBar.Item>
              )
            })}
          </TabBar>
        )}
      </Flex>
      <Cell>
        <Flex flexDirection="column" width="100%">
          <FindingsStats entity={entity} table={table} />
          <Flex mb="s-16">
            <HStack space="s-16">
              {entity === EntityTypes.company && !isCommercial && (
                <MoreInfoButton href={RESOLVING_FINDING_TICKETS} useMoreBar={false} />
              )}
              <Switch
                checked={showHistory}
                onChange={onToggleShowHistory}
                value="Show Historical data"
              >
                <Text>Show Historical data</Text>
              </Switch>
              <Switch
                checked={showSubtasks}
                onChange={onToggleShowSubtasks}
                value="Show only subtasks"
              >
                <Text>Show only subtasks</Text>
              </Switch>
            </HStack>
          </Flex>
          <Flex style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable<AuditInterface>
              {...table}
              dataType="Audit ticket"
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              name={auditTableNames[entity]}
              noDataMessage="All audit tickets relevant to your team will appear here."
              onRowClick={openUrl}
              row={row}
              useWindowScroll
            />
          </Flex>
        </Flex>
      </Cell>
    </Flex>
  )
}
