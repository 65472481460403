import React from 'react'
import { connect } from 'lape'
import { Box, ErrorWidget, Token } from '@revolut/ui-kit'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { urlRegExp } from '@src/utils/string'

const LOOKER_EMBED_PATH = '/embed'
const QUERY_ID_PARAM = 'qid'
const QUERY_VISUALIZATION_EMBED_PATH = '/embed/query-visualization/'

export const getLookerEmbedUrl = (str: string) => {
  const newUrl = new URL(str)
  const urlParams = new URLSearchParams(newUrl.search)
  const params = Object.fromEntries(urlParams)

  if (params[QUERY_ID_PARAM]) {
    return `${newUrl.origin}${QUERY_VISUALIZATION_EMBED_PATH}${params[QUERY_ID_PARAM]}`
  }
  return `${newUrl.origin}${LOOKER_EMBED_PATH}${newUrl.pathname}`
}

export const PreviewForm = connect(() => {
  const { values } = useLapeContext<AnalyticsDashboardInterface>()

  if (!values.content_url || !urlRegExp.test(values.content_url)) {
    return (
      <ErrorWidget>
        <ErrorWidget.Image />
        <ErrorWidget.Description>
          Error loading the dashboard, please provide a valid link to a Looker dashboard
        </ErrorWidget.Description>
      </ErrorWidget>
    )
  }

  return (
    <PageBody maxWidth={Token.breakpoint.xxl}>
      <Box
        border={`1px solid ${Token.color.greyTone50}`}
        borderRadius="16px"
        height="100%"
        mt="s-16"
        width="100%"
      >
        <iframe
          data-testid="looker-iframe"
          height="100%"
          src={getLookerEmbedUrl(values.content_url)}
          style={{
            border: 0,
            borderRadius: '16px',
          }}
          title="Dashboard"
          width="100%"
        />
      </Box>
    </PageBody>
  )
})
