import React, { useMemo, useRef, useState } from 'react'
import {
  Box,
  Circle,
  createChain,
  Ellipsis,
  Flex,
  HStack,
  Icon,
  IconName,
  Text,
  Token,
  Tooltip,
  useTooltip,
  Widget,
} from '@revolut/ui-kit'
import FilterSelect from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import { FilterOption, FilterType } from '@src/interfaces/data'
import { FilterNumberRange } from '@components/Inputs/Filters/FilterNumberRange/FilterNumberRange'
import FilterTextInput, {
  FilterTextInputType,
} from '@components/Inputs/Filters/FilterTextInput/FilterTextInput'
import {
  FilterDateRange,
  FilterDateRangeProps,
} from '@components/Inputs/Filters/FilterDateRange/FilterDateRange'
import { formatDate, formatPercentage } from '@src/utils/format'
import FilterBoolean from '@components/Inputs/Filters/FilterBoolean/FilterBoolean'
import { FilterSingleDate } from '@components/Inputs/Filters/FilterSingleDate/FilterSingleDate'

export interface FiltersSidebarItemInterface {
  label: string
  icon?: IconName
  selector?: selectorKeys
  field: string
  filterType: FilterType
  renderOption?: <T>(option: T) => React.ReactNode
  numberRangeFilterUnitLabel?: string
}

interface Props
  extends Pick<
    FiltersSidebarItemInterface,
    | 'label'
    | 'icon'
    | 'selector'
    | 'filterType'
    | 'renderOption'
    | 'numberRangeFilterUnitLabel'
  > {
  value: FilterOption[]
  onChange: (value: FilterOption[]) => void
  fitDateRangeInAnchor?: boolean
}

const PERCENT_RANGE_MULTIPLIER = 100

const FiltersSidebarItem = ({
  label,
  icon: iconName,
  selector,
  value,
  onChange,
  filterType,
  fitDateRangeInAnchor,
  renderOption,
  numberRangeFilterUnitLabel,
}: Props) => {
  const labelTooltip = useTooltip()
  const { data: options } = useGetSelectors(
    filterType === FilterType.boolean
      ? selectorKeys.yes_no_uppercase_value_options
      : selector || null,
  )
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const selectedOptions = useMemo(() => {
    if (!value.length) {
      return null
    }

    if (filterType === FilterType.selector || filterType === FilterType.boolean) {
      return value
        .map(item => options?.find(option => String(item.id) === String(option.id))?.name)
        .filter(item => typeof item === 'string') as string[]
    }

    return value.map(item => item.name).filter(item => typeof item === 'string')
  }, [options, value])

  const renderFilter = () => {
    const commonProps: Pick<
      FilterDateRangeProps,
      'onChange' | 'value' | 'onClose' | 'anchorRef' | 'open' | 'fitInAnchor'
    > = {
      onChange: val => {
        setOpen(false)
        onChange(val)
      },
      value,
      onClose: () => setOpen(false),
      anchorRef: ref,
      open,
      fitInAnchor: true,
    }

    switch (filterType) {
      case FilterType.range:
        return <FilterNumberRange {...commonProps} />

      case FilterType.rangeWithSelector:
        return (
          <FilterNumberRange
            withRangeSelector
            rangeInputUnitLabel={numberRangeFilterUnitLabel}
            {...commonProps}
          />
        )

      case FilterType.percentRange:
        return (
          <FilterNumberRange {...commonProps} multiplier={PERCENT_RANGE_MULTIPLIER} />
        )

      case FilterType.text:
        return <FilterTextInput {...commonProps} type={FilterTextInputType.Text} />

      case FilterType.number:
        return <FilterTextInput {...commonProps} type={FilterTextInputType.Number} />

      case FilterType.date:
      case FilterType.shortDate:
        return (
          <FilterDateRange
            {...commonProps}
            fitInAnchor={fitDateRangeInAnchor}
            type={filterType}
          />
        )

      case FilterType.singleDate:
        return <FilterSingleDate {...commonProps} fitInAnchor={fitDateRangeInAnchor} />

      case FilterType.boolean:
        return <FilterBoolean {...commonProps} useQuery />

      default: {
        if (!selector) {
          console.error('Selector is missing!')
          return null
        }

        return (
          <FilterSelect
            {...commonProps}
            selector={selector}
            renderOption={renderOption}
            useQuery
          />
        )
      }
    }
  }

  const getLabel = () => {
    let formattedValue

    switch (filterType) {
      case FilterType.range:
        formattedValue = selectedOptions?.join(' - ')
        break

      case FilterType.rangeWithSelector: {
        const [from, to] = selectedOptions || []

        if (from && to) {
          formattedValue = `between ${from} and ${to}`
        } else if (from) {
          formattedValue = `more than ${from}`
        } else if (to) {
          formattedValue = `less than ${to}`
        }
        break
      }

      case FilterType.percentRange:
        formattedValue = selectedOptions
          ?.map(item => formatPercentage(item === '' ? null : +item, 10))
          .join(' - ')
        break

      case FilterType.shortDate:
      case FilterType.date:
      case FilterType.singleDate:
        formattedValue = selectedOptions?.map(item => formatDate(item)).join(' - ')
        break

      default:
        formattedValue = selectedOptions?.join(', ')
    }

    return createChain(': ')(label, formattedValue)
  }

  return (
    <>
      {filterType === FilterType.selector &&
        !!value.length &&
        !!selectedOptions?.length &&
        !open && (
          <Tooltip {...labelTooltip.getTargetProps()}>
            <Text whiteSpace="pre">{selectedOptions?.join('\n')}</Text>
          </Tooltip>
        )}
      <Widget
        p="s-16"
        ref={ref}
        onClick={() => setOpen(!open)}
        style={{ cursor: 'pointer' }}
        data-testid={`FiltersSidebarItem-${label}`}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          {...labelTooltip.getAnchorProps()}
        >
          <HStack gap="s-8" align="center" overflow="auto">
            {iconName && (
              <Box>
                <Icon name={iconName} color={Token.color.foreground} size={18} />
              </Box>
            )}
            <Ellipsis>{getLabel()}</Ellipsis>
          </HStack>
          <Box>
            <HStack gap="s-4" align="center">
              <Circle size={18} bg={Token.color.grey10}>
                <Text variant="small" data-testid="circle-count">
                  {value.length}
                </Text>
              </Circle>
              <Icon name={open ? 'ChevronUp' : 'ChevronDown'} />
            </HStack>
          </Box>
        </Flex>
      </Widget>
      {renderFilter()}
    </>
  )
}

export default FiltersSidebarItem
