import React, { useMemo } from 'react'
import capitalize from 'lodash/capitalize'
import { Box, DataPointSkeleton, Flex, Tag, Text, Token, VStack } from '@revolut/ui-kit'
import { PayCycleInterface, PayCycleStatus } from '@src/interfaces/payroll'
import DropdownStatAction from '@components/Inputs/DropdownStatAction/DropdownStatAction'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { formatPeriod } from '@src/utils/format'

interface Props {
  cycles?: PayCycleInterface[]
  isLoading: boolean
  onChange: (selector?: PayCycleInterface) => void
  selectedCycleId: number
}

const getTitleAndColor = (status?: PayCycleStatus) => {
  if (status === 'open') {
    return {
      title: 'current',
      color: Token.color.green,
      bgColor: Token.color.green_20,
    }
  }

  if (status === 'scheduled') {
    return {
      title: 'upcoming',
      color: Token.color.greyTone50,
      bgColor: Token.color.greyTone10,
    }
  }

  if (status === 'closed' || status === 'pending_closure') {
    return {
      title: 'history',
      color: Token.color.warning,
      bgColor: Token.color.orange_20,
    }
  }

  return { title: '', color: '', bgColor: '' }
}

export const PayCycleSelect = ({
  cycles = [],
  isLoading,
  onChange,
  selectedCycleId,
}: Props) => {
  const options = useMemo(
    () =>
      cycles.map(option => {
        const period = formatPeriod(
          new Date(option.start_date),
          new Date(option.end_date),
        )
        return {
          label: period,
          value: { ...option, name: period },
        }
      }),
    [cycles],
  )

  const selectedPeriod = useMemo(() => {
    return options.filter(option => option?.value.id === selectedCycleId)[0]?.value
  }, [options, selectedCycleId])

  const { bgColor, color, title } = getTitleAndColor(selectedPeriod?.status.id)

  return (
    <Box>
      <RadioSelectInput
        loading={isLoading}
        onChange={val => {
          if (val) {
            onChange(cycles?.find(item => item.id === val.id))
          }
        }}
        options={options}
        renderInput={(open, setOpen, ref) => (
          <Box ref={ref}>
            {selectedPeriod ? (
              <DropdownStatAction onClick={() => setOpen(!open)} open={open} ref={ref}>
                <Flex alignItems="center" gap="s-8">
                  <Text>
                    {formatPeriod(
                      new Date(selectedPeriod.start_date),
                      new Date(selectedPeriod.end_date),
                    )}
                  </Text>
                  <Tag bg={bgColor} color={color} variant="faded">
                    {capitalize(title)}
                  </Tag>
                </Flex>
              </DropdownStatAction>
            ) : (
              <DataPointSkeleton.Value />
            )}
          </Box>
        )}
        value={selectedPeriod}
      >
        {option => {
          const {
            bgColor: optionBgColor,
            color: optionColor,
            title: optionTitle,
          } = getTitleAndColor(option.value.status.id)
          return (
            <VStack gap="s-4">
              <Text>{option.label}</Text>
              <Tag bg={optionBgColor} color={optionColor} variant="faded">
                {optionTitle}
              </Tag>
            </VStack>
          )
        }}
      </RadioSelectInput>
      <Text color={Token.color.greyTone50} use="div">
        Payroll cycle
      </Text>
    </Box>
  )
}
