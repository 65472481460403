import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import capitalize from 'lodash/capitalize'
import { chain, Text } from '@revolut/ui-kit'
import { queryRequestsNew } from '@src/api/dataAnalytics'
import { ROUTES } from '@src/constants/routes'
import { QueryInterface } from '@src/interfaces/dataAnalytics'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PermissionTypes } from '@src/store/auth/types'
import { formatDateTime } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { BACK_URL } from './constants'
import { HistoryTable } from './HistoryTable'
import { PermissionTable } from './PermissionTable'
import { EditQuery } from './QueryForm'
import { MetaData } from './QueryMetaDataForm'
import { QueryContextProvider } from './QueryContextProvider'
import { queryStatusToColor } from './utils'

const getTabs = (id: string, canManagePermissions: boolean) => [
  {
    title: 'Query',
    path: ROUTES.FORMS.QUERY.GENERAL,
    to: pathToUrl(ROUTES.FORMS.QUERY.GENERAL, { id }),
    component: EditQuery,
    canView: true,
  },
  {
    title: 'Query Metadata',
    path: ROUTES.FORMS.QUERY.META_DATA,
    to: pathToUrl(ROUTES.FORMS.QUERY.META_DATA, { id }),
    component: MetaData,
    canView: true,
  },
  {
    title: 'Access Details',
    path: ROUTES.FORMS.QUERY.PERMISSIONS,
    to: pathToUrl(ROUTES.FORMS.QUERY.PERMISSIONS, { id }),
    component: PermissionTable,
    canView: canManagePermissions,
  },
  {
    title: 'Run History',
    path: ROUTES.FORMS.QUERY.RUN_HISTORY,
    to: pathToUrl(ROUTES.FORMS.QUERY.RUN_HISTORY, { id }),
    component: HistoryTable,
    canView: true,
  },
]

const Query = connect(() => {
  const params = useParams<{ id: string }>()
  const { values } = useLapeContext<QueryInterface>()

  const permissions = values.field_options?.permissions || []

  const canManagePermissions = permissions.includes(
    PermissionTypes.AccessObjectPermissionsSavedQuery,
  )

  const isNewQuery = !params.id
  const tabs = isNewQuery
    ? []
    : getTabs(params.id, canManagePermissions).filter(tab => tab.canView)

  return (
    <PageWrapper>
      <PageHeader
        backUrl={BACK_URL}
        subtitle={
          isNewQuery
            ? null
            : chain(
                <Text color={queryStatusToColor(values.status)}>
                  {capitalize(values.status)}
                </Text>,
                <Text>{`Edited ${formatDateTime(values.update_date_time)}`}</Text>,
              )
        }
        title={isNewQuery ? 'Create query' : values.name}
      >
        {!isNewQuery && <TabBarNavigation tabs={tabs} />}
      </PageHeader>
      <Switch>
        <Route exact path={ROUTES.FORMS.QUERY.GENERAL}>
          <EditQuery />
        </Route>
        <Route exact path={ROUTES.FORMS.QUERY.META_DATA}>
          <MetaData />
        </Route>
        <Route exact path={ROUTES.FORMS.QUERY.PERMISSIONS}>
          <PermissionTable />
        </Route>
        <Route exact path={ROUTES.FORMS.QUERY.RUN_HISTORY}>
          <HistoryTable />
        </Route>
      </Switch>
    </PageWrapper>
  )
})

const QueryForm = () => {
  return (
    <QueryContextProvider>
      <Form
        api={queryRequestsNew}
        fieldsToExclude={['columns', 'parameters', 'name', 'description', 'owner']}
        disableLocalStorageCaching
      >
        <Query />
      </Form>
    </QueryContextProvider>
  )
}

export default connect(QueryForm)
