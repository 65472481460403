import { CellTypes, ColumnInterface } from '../../interfaces/data'
import { selectorKeys } from '../api'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { SenioritySublevelInterface } from '@src/interfaces/seniority'
import React from 'react'
import { IdAndName } from '@src/interfaces'
import Table from '@components/TableV2/Table'

export const seniorityGenericNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority name',
}

export const seniorityNameColumn: ColumnInterface<{ seniority?: IdAndName }> = {
  type: CellTypes.insert,
  idPoint: 'seniority.id',
  dataPoint: 'seniority.name',
  sortKey: 'seniority__level',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => <Table.SeniorityCell seniority={data.seniority?.name} />,
}

export const seniorityNameRevolutersColumn: ColumnInterface<{
  seniority: IdAndName & { level: number }
}> = {
  type: CellTypes.insert,
  idPoint: 'seniority.id',
  dataPoint: 'seniority.name',
  sortKey: 'seniority__name',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => <Table.SeniorityCell seniority={data?.seniority?.name} />,
}

export const seniorityNameRequisitionsColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.insert,
  idPoint: 'seniority_max.id',
  dataPoint: 'seniority_max.name',
  sortKey: 'seniority_max__level,seniority_min__level',
  filterKey: 'seniority_range_contains',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => (
    <Table.SeniorityCell
      seniority={{ min: data.seniority_min?.name, max: data.seniority_max?.name }}
    />
  ),
}

export const senioritySublevelRankColumn: ColumnInterface<SenioritySublevelInterface> = {
  type: CellTypes.text,
  idPoint: 'rank',
  dataPoint: 'rank',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Rank',
}

export const parentSeniorityNameColumn: ColumnInterface<SenioritySublevelInterface> = {
  type: CellTypes.text,
  idPoint: 'seniority__id',
  dataPoint: 'seniority__name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
}

export const seniorityDefaultLevelTitleColumn: ColumnInterface<SenioritySublevelInterface> =
  {
    type: CellTypes.text,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Level',
  }

export const senioritySublevelTitleColumn: ColumnInterface<SenioritySublevelInterface> = {
  type: CellTypes.text,
  idPoint: 'job_title',
  dataPoint: 'job_title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Title',
}
