import React, { ChangeEvent, useEffect, useState } from 'react'
import toNumber from 'lodash/toNumber'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import isNaN from 'lodash/isNaN'
import {
  ActionButton,
  Box,
  Dropdown,
  DropdownProps,
  Input,
  InputGroup,
  useDropdown,
  Text,
  HStack,
} from '@revolut/ui-kit'

import { FilterOption } from '@src/interfaces/data'
import NewGrid from '@components/CommonSC/NewGrid'
import { IdAndName } from '@src/interfaces'

type InputValue = string | number | undefined

const fromOption = { id: 'from', name: 'More' } as const
const toOption = { id: 'to', name: 'Less' } as const
const betweenOption = { id: 'between', name: 'Between' } as const

type RangeSelector = IdAndName<'from' | 'to' | 'between'>

type InputsWithRangeSelectorProps = Pick<
  FilterNumberRangeProps,
  'rangeInputUnitLabel'
> & {
  inputFrom: InputValue
  inputTo: InputValue
  filterValueFrom: number
  filterValueTo: number
  setInputFrom: (newValue: InputValue) => void
  setInputTo: (newValue: InputValue) => void
  onFromChange: (e: ChangeEvent<HTMLInputElement>) => void
  onToChange: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur: () => void
}
const InputsWithRangeSelector = ({
  inputFrom,
  inputTo,
  filterValueFrom,
  filterValueTo,
  setInputFrom,
  setInputTo,
  onFromChange,
  onToChange,
  onBlur,
  rangeInputUnitLabel,
}: InputsWithRangeSelectorProps) => {
  const rangeSelectorValueByCurrentFilter =
    filterValueFrom && filterValueTo
      ? betweenOption
      : filterValueFrom
      ? fromOption
      : toOption

  const [rangeSelector, setRangeSelector] = useState<RangeSelector>()
  const rangeSelectorDropdown = useDropdown<HTMLInputElement>()

  const currentRangeSelector = rangeSelector || rangeSelectorValueByCurrentFilter

  return (
    <>
      <HStack space="s-8" align="center">
        <Box
          type="button"
          aria-label="Select range type"
          {...rangeSelectorDropdown.getAnchorProps()}
          onClick={e => {
            e.preventDefault()
            rangeSelectorDropdown.getAnchorProps().onClick()
          }}
          minWidth={130}
        >
          <Input value={currentRangeSelector.name} useIcon="ChevronDown" type="button" />
        </Box>
        {(currentRangeSelector.id === fromOption.id ||
          currentRangeSelector.id === toOption.id) && (
          <Box>
            <Text>than</Text>
          </Box>
        )}
        {(currentRangeSelector.id === fromOption.id ||
          currentRangeSelector.id === betweenOption.id) && (
          <Input
            type="number"
            value={inputFrom}
            label={rangeInputUnitLabel}
            onChange={onFromChange}
            onBlur={onBlur}
          />
        )}
        {currentRangeSelector.id === betweenOption.id && (
          <Box>
            <Text>and</Text>
          </Box>
        )}
        {(currentRangeSelector.id === toOption.id ||
          currentRangeSelector.id === betweenOption.id) && (
          <Input
            type="number"
            value={inputTo}
            label={rangeInputUnitLabel}
            onChange={onToChange}
            onBlur={onBlur}
          />
        )}
      </HStack>
      <Dropdown
        {...rangeSelectorDropdown.getTargetProps()}
        positionStrategy="fixed"
        placement="bottom-start"
        closeOnEsc
        autoClose
        fitInAnchor
      >
        <Dropdown.Item
          use="button"
          aria-pressed={currentRangeSelector.id === fromOption.id}
          onClick={() => {
            setInputTo('')
            setRangeSelector(fromOption)
          }}
        >
          {fromOption.name}
        </Dropdown.Item>
        <Dropdown.Item
          use="button"
          aria-pressed={currentRangeSelector.id === toOption.id}
          onClick={() => {
            setInputFrom('')
            setRangeSelector(toOption)
          }}
        >
          {toOption.name}
        </Dropdown.Item>
        <Dropdown.Item
          use="button"
          aria-pressed={currentRangeSelector.id === betweenOption.id}
          onClick={() => setRangeSelector(betweenOption)}
        >
          {betweenOption.name}
        </Dropdown.Item>
      </Dropdown>
    </>
  )
}

interface FilterNumberRangeProps
  extends Pick<DropdownProps, 'anchorRef' | 'fitInAnchor'> {
  open: boolean
  onClose: () => void
  multiplier?: number
  onChange: (options: FilterOption[]) => void
  value?: FilterOption[]
  withRangeSelector?: boolean
  rangeInputUnitLabel?: string
}

export const FilterNumberRange = ({
  value,
  onChange,
  multiplier,
  onClose,
  open,
  anchorRef,
  fitInAnchor,
  withRangeSelector,
  rangeInputUnitLabel,
}: FilterNumberRangeProps) => {
  const filterValueFrom = get(value, '[0].id', '')
  const filterValueTo = get(value, '[1].id', '')

  const [inputFrom, setInputFrom] = useState<InputValue>(filterValueFrom)
  const [inputTo, setInputTo] = useState<InputValue>(filterValueTo)

  useEffect(() => {
    if (!inputFrom) {
      setInputFrom(filterValueFrom)
    }
    if (!inputTo) {
      setInputTo(filterValueTo)
    }
  }, [filterValueFrom, filterValueTo])

  useEffect(() => {
    if (multiplier) {
      setInputFrom(inputFrom ? `${toNumber(inputFrom) * multiplier}` : '')
      setInputTo(inputTo ? `${toNumber(inputTo) * multiplier}` : '')
    }
  }, [multiplier])

  const onFromChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!isNaN(toNumber(e.target.value)) || '-.'.includes(e.target.value)) {
      setInputFrom(e.target.value)
    }
  }
  const onToChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!isNaN(toNumber(e.target.value)) || '-.'.includes(e.target.value)) {
      setInputTo(e.target.value)
    }
  }

  const onBlur = () => {
    if (inputFrom) {
      setInputFrom(`${toNumber(inputFrom)}`)
    }
    if (inputTo) {
      setInputTo(`${toNumber(inputTo)}`)
    }
  }

  const onClear = () => {
    onChange([])
    onClose()
  }

  const onApply = () => {
    const filters = []

    if (isNil(inputTo) && isNil(inputFrom)) {
      onClear()
      return
    }

    let resultFrom = toNumber(inputFrom)

    if (multiplier) {
      resultFrom /= multiplier
    }
    filters.push({ id: resultFrom || '', name: `${resultFrom || ''}` })

    let resultTo = toNumber(inputTo)
    if (multiplier) {
      resultTo /= multiplier
    }
    filters.push({ id: resultTo || '', name: `${resultTo || ''}` })

    if (resultTo || resultFrom) {
      onChange(filters)
    }
  }

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Enter') {
      onApply()
    } else if (e.key === 'Escape') {
      onClose()
    }
  }

  return (
    <Dropdown
      open={open}
      anchorRef={anchorRef}
      onClose={onClose}
      maxHeight={null}
      fitInAnchor={fitInAnchor}
      variant={withRangeSelector ? 'nested' : undefined}
    >
      <Dropdown.Group sticky="top" p="s-16" onKeyDown={onKeyDown}>
        {withRangeSelector ? (
          <InputsWithRangeSelector
            inputFrom={inputFrom}
            inputTo={inputTo}
            filterValueFrom={filterValueFrom}
            filterValueTo={filterValueTo}
            setInputFrom={setInputFrom}
            setInputTo={setInputTo}
            onFromChange={onFromChange}
            onToChange={onToChange}
            onBlur={onBlur}
            rangeInputUnitLabel={rangeInputUnitLabel}
          />
        ) : (
          <InputGroup>
            <Input
              type="number"
              value={inputFrom}
              label="From"
              onChange={onFromChange}
              onBlur={onBlur}
            />
            <Input
              type="number"
              value={inputTo}
              label="To"
              onChange={onToChange}
              onBlur={onBlur}
            />
          </InputGroup>
        )}
      </Dropdown.Group>
      <Dropdown.Group sticky="bottom" p="s-16" onKeyDown={onKeyDown}>
        <NewGrid gridAutoFlow="column" gridColumnGap="1rem">
          <ActionButton width="100%" onClick={onClear}>
            Clear
          </ActionButton>
          <ActionButton variant="accent" width="100%" onClick={onApply}>
            Apply
          </ActionButton>
        </NewGrid>
      </Dropdown.Group>
    </Dropdown>
  )
}
