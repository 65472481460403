import React from 'react'

import {
  GlobalSettingsRecord,
  useGetEmployeeSettings,
  useGetScreeningSettings,
  useGetTimeOffSettings,
  useGlobalSettings,
} from '@src/api/settings'
import {
  EmployeeSettingsInterface,
  ScreeningSettingsInterface,
  TimeOffSettingsInterface,
} from '@src/interfaces/settings'
import PageLoading from '@src/components/PageLoading/PageLoading'
import PageAccessDenied from '@src/pages/PageAccessDenied/PageAccessDenied'

type CheckBySettings = {
  global?: (settings?: GlobalSettingsRecord) => boolean
  employee?: (settings?: EmployeeSettingsInterface) => boolean
  timeOff?: (settings?: TimeOffSettingsInterface) => boolean
  screening?: (settings?: ScreeningSettingsInterface) => boolean
}
type Props = {
  canView: boolean
  checkBySettings: CheckBySettings
  children: React.ReactNode
}
export const VisibilityWrapper = ({ canView, children, checkBySettings }: Props) => {
  const hasGlobalSettingsCheck = 'global' in checkBySettings
  const { settings: globalSettings, isLoading: isGlobalSettingsLoading } =
    useGlobalSettings(!hasGlobalSettingsCheck)
  const isAllowedByGlobalSettings = hasGlobalSettingsCheck
    ? checkBySettings.global?.(globalSettings)
    : true

  const hasEmployeeSettingsCheck = 'employee' in checkBySettings
  const { data: employeeSettings, isLoading: isEmployeeSettingsLoading } =
    useGetEmployeeSettings(!hasEmployeeSettingsCheck)
  const isAllowedByEmployeeSettings = hasEmployeeSettingsCheck
    ? checkBySettings.employee?.(employeeSettings)
    : true

  const hasTimeOffSettingsCheck = 'timeOff' in checkBySettings
  const { data: timeOffSettings, isLoading: isTimeOffSettingsLoading } =
    useGetTimeOffSettings(!hasTimeOffSettingsCheck)
  const isAllowedByTimeOffSettings = hasTimeOffSettingsCheck
    ? checkBySettings.timeOff?.(timeOffSettings)
    : true

  const hasScreeningSettingsCheck = 'screening' in checkBySettings
  const { data: screeningSettings, isLoading: isScreeningSettingsLoading } =
    useGetScreeningSettings(!hasScreeningSettingsCheck)
  const isAllowedByScreeningSettings = hasScreeningSettingsCheck
    ? checkBySettings.screening?.(screeningSettings)
    : true

  const isLoading =
    isGlobalSettingsLoading ||
    isEmployeeSettingsLoading ||
    isTimeOffSettingsLoading ||
    isScreeningSettingsLoading

  const isAllowed =
    canView &&
    isAllowedByGlobalSettings &&
    isAllowedByEmployeeSettings &&
    isAllowedByTimeOffSettings &&
    isAllowedByScreeningSettings

  if (isLoading) {
    return <PageLoading />
  }
  if (!isAllowed) {
    return <PageAccessDenied />
  }
  return <>{children}</>
}
