import React, { useState } from 'react'
import { connect } from 'lape'
import { MoreBar } from '@revolut/ui-kit'

import { archiveQuery } from '@src/api/dataAnalytics'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { QueryInterface } from '@src/interfaces/dataAnalytics'
import { EntityPermissions } from '@src/store/auth/types'

export const ArchiveButton = connect(() => {
  const { values, reset } = useLapeContext<QueryInterface>()
  const [archivePending, setArchivePending] = useState(false)

  const canChange = values.field_options?.actions?.includes(EntityPermissions.Change)

  const onChangeStatus = (action: 'archive' | 'unArchive') => {
    setArchivePending(true)

    archiveQuery(values.id, action)
      .then(() => {
        reset({ ...values, status: action === 'archive' ? 'archived' : 'draft' })
      })
      .finally(() => setArchivePending(false))
  }

  return (
    <>
      {values.status === 'archived' && canChange && (
        <MoreBar.Action
          onClick={() => onChangeStatus('unArchive')}
          useIcon="Unarchive"
          pending={archivePending}
        >
          Unarchive
        </MoreBar.Action>
      )}
      {(values.status === 'active' || values.status === 'draft') && canChange && (
        <MoreBar.Action
          onClick={() => onChangeStatus('archive')}
          useIcon="Archive"
          variant="negative"
          pending={archivePending}
        >
          Archive
        </MoreBar.Action>
      )}
    </>
  )
})
