import React from 'react'
import { useTableReturnType } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import {
  RiskControlsInterface,
  RiskLevel,
  RiskStatsInterface,
  RiskTabData,
} from '@src/interfaces/risk'
import {
  riskActionsColumn,
  riskControlEffectivenessColumn,
  riskIdColumn,
  riskNameColumn,
  riskNextReviewDateColumn,
  riskOwnerColumn,
  riskResidualScoreColumn,
  riskTargetControlDateColumn,
  riskTypeL2Column,
  riskUrlColumn,
} from '@src/constants/columns/risk'
import { Flex, MoreBar, Text, Token } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { InfoOutline } from '@revolut/icons'
import Tooltip from '@components/Tooltip/Tooltip'
import AdjustableTable from '@components/Table/AdjustableTable'
import { teamNameColumn } from '@src/constants/columns/team'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

interface Props {
  data?: RiskTabData
  table: useTableReturnType<RiskControlsInterface, RiskStatsInterface>
  isEmployee?: boolean
  useWrapper?: boolean
}

const ROW = (isEmployee: boolean): RowInterface<RiskControlsInterface> => ({
  highlight: data => {
    if (data.risk_level === RiskLevel.high) {
      return Token.color.red_20
    }
    return ''
  },
  cells: [
    {
      ...riskIdColumn,
      width: 80,
    },
    {
      ...riskTypeL2Column,
      width: 150,
    },
    {
      ...riskNameColumn,
      width: 250,
    },
    {
      ...riskResidualScoreColumn,
      width: 120,
    },
    {
      ...(isEmployee ? teamNameColumn : riskOwnerColumn),
      width: 140,
    },
    {
      ...riskControlEffectivenessColumn,
      width: 80,
    },
    {
      ...riskTargetControlDateColumn,
      width: 160,
    },
    {
      ...riskNextReviewDateColumn,
      width: 160,
    },
    {
      ...riskActionsColumn,
      width: 180,
    },
    {
      ...riskUrlColumn,
      width: 100,
    },
  ],
})

const Controls = ({ table, data, isEmployee = false, useWrapper }: Props) => {
  return (
    <TableWrapper useWrapper={useWrapper}>
      <Flex mb="s-24">
        {data?.risk_composite_index && (
          <Stat label="Risk score" val={data?.risk_composite_index.name} mr="s-32" />
        )}
        <Stat
          label="High"
          val={
            table.stats?.high !== undefined ? (
              <Tooltip
                title="Risks outside of appetite and with either no or overdue proposed controls."
                text="Please click on the risk and review the proposed controls and timelines."
                placement="right"
              >
                <Text color="red">{table.stats?.high}</Text>
              </Tooltip>
            ) : undefined
          }
          mr="s-32"
        />
        <Stat
          label="Medium"
          val={
            table.stats?.medium !== undefined ? (
              <Tooltip
                title="Risks outside of appetite with proposed controls."
                text="Please click on the risk to review whether on track to implement these controls on time."
                placement="right"
              >
                <Text color="warning">{table.stats?.medium}</Text>
              </Tooltip>
            ) : undefined
          }
          mr="s-32"
        />
        <Stat
          label="Low"
          val={
            table.stats?.medium !== undefined ? (
              <Tooltip
                title="Risks within appetite."
                text="Please periodically review to ensure information is up to date."
                placement="right"
              >
                <Text color="green">{table.stats?.low}</Text>
              </Tooltip>
            ) : undefined
          }
          mr="s-32"
        />
      </Flex>
      <Flex mb="s-16">
        <MoreBar>
          {data?.risk_score && (
            <MoreBar.Action
              target="_blank"
              rel="noreferrer noopener"
              href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1710528923/Risk+Profile+Indices+CCI+and+RCI"
              use="a"
              useIcon={InfoOutline}
            >
              What is risk score?
            </MoreBar.Action>
          )}
          <MoreBar.Action
            target="_blank"
            rel="noreferrer noopener"
            href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1430881442/Risks+and+Controls+Identification+and+Assessment"
            use="a"
            useIcon={InfoOutline}
          >
            How to manage risks & controls?
          </MoreBar.Action>
        </MoreBar>
      </Flex>
      <AdjustableTable<RiskControlsInterface, RiskStatsInterface>
        name={TableNames.Controls}
        useWindowScroll
        dataType="Risk"
        row={ROW(isEmployee)}
        {...table}
        noDataMessage="This team has no data on their Risk"
      />
    </TableWrapper>
  )
}

export default Controls
