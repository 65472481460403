import React from 'react'
import { TableWidget, Token } from '@revolut/ui-kit'

import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  KeyPersonSuccessionPlanInterface,
  KeyPersonSuccessionPlansStatsInterface,
} from '@src/interfaces/keyPerson'
import { keyPersonsSuccessionPlansTableRequests } from '@src/api/keyPerson'
import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import { StatFilters } from '@components/StatFilters/StatFilters'
import {
  keyPersonSuccessionPlanEmployeeColumn,
  keyPersonSuccessionPlanResponsibilitiesColumn,
  keyPersonSuccessionPlanUpdatedOnColumn,
  keyPersonSuccessionPlanValidUntilColumn,
  keyPersonSuccessionPlanStatusColumn,
  keyPersonSuccessionPlanActionsColumn,
  keyPersonSuccessionPlanEmployeeStatusColumn,
  keyPersonSuccessionPlanTypeColumn,
} from '@src/constants/columns/keyPersonSuccessionPlans'
import { ActionButtons } from './ActionButtons'
import { useFilterButtons } from '@src/apps/People/KeyPersons/Responsibility/hooks'

const getRow = (refresh: () => void): RowInterface<KeyPersonSuccessionPlanInterface> => ({
  linkToForm: (data: KeyPersonSuccessionPlanInterface) =>
    navigateTo(
      pathToUrl(ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.DETAILS, {
        id: data.id,
      }),
    ),
  cells: [
    {
      ...keyPersonSuccessionPlanEmployeeColumn,
      width: 300,
    },
    {
      ...keyPersonSuccessionPlanEmployeeStatusColumn,
      width: 130,
    },
    {
      ...keyPersonSuccessionPlanResponsibilitiesColumn,
      width: 200,
    },
    {
      ...keyPersonSuccessionPlanTypeColumn,
      width: 175,
    },
    {
      ...keyPersonSuccessionPlanUpdatedOnColumn,
      width: 120,
    },
    {
      ...keyPersonSuccessionPlanValidUntilColumn,
      width: 120,
    },
    {
      ...keyPersonSuccessionPlanStatusColumn,
      width: 200,
    },
    {
      ...keyPersonSuccessionPlanActionsColumn,
      width: 200,
      insert: ({ data }) => <ActionButtons data={data} refresh={refresh} />,
    },
  ],
})

const statsConfig: StatsConfig<KeyPersonSuccessionPlansStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'pending_completion',
    title: 'Pending completion',
    filterKey: 'pending_completion',
    color: 'orange',
  },
  {
    key: 'pending_approval',
    title: 'Pending approval',
    filterKey: 'pending_approval',
    color: 'orange',
  },
  {
    key: 'approved',
    title: 'Approved',
    filterKey: 'approved',
    color: 'green',
  },
  {
    key: 'rejected',
    title: 'Rejected',
    filterKey: 'rejected',
    color: 'red',
  },
  {
    key: 'expired',
    title: 'Expired',
    filterKey: 'expired',
    color: 'red',
  },
  {
    key: 'archived',
    title: 'Archived',
    filterKey: 'archived',
    color: 'grey-tone-50',
  },
]

export const KeyPersonsSuccessionPlansTable = () => {
  const table = useTable<
    KeyPersonSuccessionPlanInterface,
    KeyPersonSuccessionPlansStatsInterface
  >(keyPersonsSuccessionPlansTableRequests, [
    {
      columnName: 'active_stats',
      filters: [{ id: 'pending_approval', name: 'pending_approval' }],
    },
  ])

  const filtersButtons = useFilterButtons<
    KeyPersonSuccessionPlanInterface,
    KeyPersonSuccessionPlansStatsInterface
  >(table)

  const statFiltersProps = useSelectableTableStats<
    KeyPersonSuccessionPlanInterface,
    KeyPersonSuccessionPlansStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
  })

  return (
    <TableWidget>
      <TableWidget.Info>
        <StatFilters {...statFiltersProps} />
      </TableWidget.Info>
      <TableWidget.Actions />
      <TableWidget.Filters>{filtersButtons}</TableWidget.Filters>
      <TableWidget.Table>
        <AdjustableTable<
          KeyPersonSuccessionPlanInterface,
          KeyPersonSuccessionPlansStatsInterface
        >
          name={TableNames.KeyPersonsSuccessionPlans}
          useWindowScroll
          row={getRow(table.refresh)}
          {...table}
          onRowClick={(row: { id: number }) => {
            navigateTo(
              pathToUrl(ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.DETAILS, {
                id: row.id,
              }),
            )
          }}
          noDataMessage="Succession plans will appear here"
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
