import React, { useRef, useState } from 'react'
import {
  ActionButton,
  Box,
  Cell,
  Group,
  Input,
  Item,
  Link,
  MoreBar,
  Subheader,
  Text,
  Token,
} from '@revolut/ui-kit'
import { useRouteMatch } from 'react-router-dom'

import { PageBody } from '@src/components/Page/PageBody'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeBenefitInterface } from '@src/interfaces/benefits'
import SideBar from '@src/components/SideBar/SideBar'
import HTMLEditor from '@src/components/HTMLEditor/HTMLEditor'
import { PackageDetailItem } from '@src/pages/Forms/Benefits/common'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  CutOffWarning,
  DependantItem,
  NoDependantsItem,
  useEmployeeBenefitsParams,
} from './common'
import { HR_SERVICE_DESK_LINK } from '@src/constants/externalLinks'
import { UploadedFileToDownload } from '@src/components/Inputs/FileUploader/UploadedFile'
import { EntityPermissions } from '@src/store/auth/types'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import {
  approveBenefit,
  getEmployeeBenefitsCommentsAPI,
  optOutOfBenefit,
  rejectBenefit,
  useEmployeeBenefitsApprovals,
} from '@src/api/benefits'
import CommentsSection from '@src/features/Comments/CommentsSection'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { ApproveButton } from '@src/features/SettingsButtons'
import { handleError } from '@src/api'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

export const Summary = () => {
  const { initialValues, reset } = useLapeContext<EmployeeBenefitInterface>()
  const params = useEmployeeBenefitsParams()

  const isPreviewRoute = !!useRouteMatch(ROUTES.FORMS.EMPLOYEE_BENEFIT.PREVIEW)

  const [instructionsOpen, setInstructionsOpen] = useState(true)
  const [showMore, setShowMore] = useState(false)
  const [optOutConfirmationVisible, setOptOutConfirmationVisible] = useState(false)
  const [optOutPending, setOptOutPending] = useState(false)
  const [isApproveLoading, setIsApproveLoading] = useState(false)
  const [isRejectLoading, setIsRejectLoading] = useState(false)
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)
  const [rejectionComment, setRejectionComment] = useState('')

  const setCommentsSidebarOpen = useRef((_: boolean) => {})

  const enrolment = initialValues.enrolment
  const selectedPackage = initialValues.template?.packages.find(
    p => enrolment && p.id! === enrolment.selected_package?.id,
  )
  const anyPending = isApproveLoading || isRejectLoading
  const isPendingApproval = initialValues.status?.id === 'pending_approval'
  const isOptedOut = initialValues.status?.id === 'opted_out'
  const isRejected = initialValues.status?.id === 'rejected'
  const isPendingEmployee = initialValues.status?.id === 'pending_employee'

  const approvalsDisabled =
    isPendingEmployee || selectedPackage?.enrolment_method?.id === 'automatic'

  const canOptOut =
    initialValues.field_options?.actions?.includes(EntityPermissions.OptOut) &&
    initialValues.template?.employee_can_opt_out
  const canEnrol = initialValues.field_options?.actions?.includes(EntityPermissions.Enrol)
  const canApprove =
    isPendingApproval &&
    initialValues.field_options?.actions?.includes(EntityPermissions.Approve)
  const canReject =
    isPendingApproval &&
    initialValues.field_options?.actions?.includes(EntityPermissions.Reject)
  const canViewComments =
    initialValues.field_options?.actions?.includes(EntityPermissions.ViewComments) &&
    !isPendingEmployee
  const canAddComments = initialValues.field_options?.actions?.includes(
    EntityPermissions.AddComments,
  )

  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useEmployeeBenefitsApprovals(params.id, params.employeeId, approvalsDisabled)

  const commentsApi = getEmployeeBenefitsCommentsAPI(params.id, params.employeeId)
  const getComments = commentsApi.useGetComments(false, !canViewComments)

  const onOptOut = () => {
    setOptOutPending(true)

    optOutOfBenefit({ employeeId: params.employeeId, id: params.id })
      .then(response => {
        reset({ ...response.data, field_options: initialValues.field_options })
        setOptOutConfirmationVisible(false)
      })
      .finally(() => setOptOutPending(false))
  }

  const onApprove = async () => {
    const response = await approveBenefit(params.id, params.employeeId)
    reset({ ...response.data, field_options: initialValues.field_options })
  }

  const onReject = async () => {
    setIsRejectLoading(true)

    try {
      const response = await rejectBenefit(params.id, params.employeeId, rejectionComment)
      reset({ ...response.data, field_options: initialValues.field_options })
    } finally {
      setRejectionComment('')
      setRejectDialogOpen(false)
      refetchApproval()
      setIsRejectLoading(false)
      getComments.refetch()
    }
  }

  return (
    <>
      <PageBody>
        <Box mb="s-16">
          <MoreBar>
            <ApproveButton
              submit={onApprove}
              onBeforeSubmit={() => setIsApproveLoading(true)}
              onAfterSubmit={() => {
                setIsApproveLoading(false)
                refetchApproval()
              }}
              onSubmitFailed={handleError}
              statusFieldName="approval_status"
              isVisible={canApprove}
            />
            {canReject && (
              <MoreBar.Action
                onClick={() => setRejectDialogOpen(true)}
                useIcon="CrossSmall"
                variant="negative"
                pending={isRejectLoading}
                disabled={anyPending}
              >
                Reject
              </MoreBar.Action>
            )}
            <EditButton
              route={pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT.EDIT, params)}
              isVisible={!isOptedOut && isPreviewRoute}
            />
            {(isOptedOut || isPendingEmployee) && canEnrol ? (
              <MoreBar.Action
                onClick={() =>
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT.PACKAGE_SELECT, params),
                  )
                }
                useIcon="Plus"
              >
                Enrol
              </MoreBar.Action>
            ) : null}
            <MoreBar.Action
              onClick={() => setInstructionsOpen(!instructionsOpen)}
              useIcon="InfoOutline"
            >
              Instructions
            </MoreBar.Action>
            {canOptOut && !isOptedOut && !isRejected ? (
              <MoreBar.Action
                onClick={() => setOptOutConfirmationVisible(true)}
                useIcon="CrossSmall"
                variant="negative"
              >
                Opt out
              </MoreBar.Action>
            ) : null}
          </MoreBar>
        </Box>

        {!approvalsDisabled && (approvalSteps === undefined || approvalSteps?.length) ? (
          <ApprovalFlow
            isLoading={isApprovalLoading || isApproveLoading}
            steps={approvalSteps || null}
            onViewRejectionReasonClick={() => {
              setCommentsSidebarOpen.current(true)
            }}
          />
        ) : null}

        <FormPreview title="Benefit details" data={initialValues}>
          <Group>
            <FormPreview.Item title="Category" field="template.category.name" />
            <FormPreview.Item
              title="Provider"
              insert={() => {
                if (!initialValues.template?.provider_url) {
                  return '-'
                }
                return (
                  <Link
                    href={initialValues.template.provider_url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Open
                  </Link>
                )
              }}
            />
            <FormPreview.Item
              title="Benefit guide"
              insert={() => {
                if (!initialValues.template?.confluence_url) {
                  return '-'
                }
                return (
                  <Link
                    href={initialValues.template.confluence_url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Open
                  </Link>
                )
              }}
            />
            <FormPreview.Details title="Description" field="template.description" />
          </Group>
        </FormPreview>

        <CutOffWarning />

        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Packages summary</Subheader.Title>
          </Subheader>
          <Group>
            {initialValues.template?.packages
              ?.filter(benefitPackage => {
                if (selectedPackage) {
                  return showMore ? true : benefitPackage.id === selectedPackage.id
                }
                return true
              })
              .sort((packageA, packageB) => {
                /** "Selected" package should always be at the top */
                if (selectedPackage && packageA.id === selectedPackage.id) {
                  return -1
                }
                if (selectedPackage && packageB.id === selectedPackage.id) {
                  return 1
                }

                return 0
              })
              .map((benefitPackage, index) => (
                <PackageDetailItem
                  onClick={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT.PACKAGE_DETAILS, {
                        employeeId: params.employeeId,
                        packageId: benefitPackage.id,
                        id: params.id,
                      }),
                    )
                  }
                  benefitPackage={benefitPackage}
                  benefit={initialValues.template!}
                  selected={
                    benefitPackage.id === initialValues.enrolment?.selected_package?.id
                  }
                  enrolment={initialValues.enrolment}
                  key={index}
                />
              ))}
            {selectedPackage && initialValues.template!.packages!.length > 1 ? (
              <Item>
                <ActionButton
                  onClick={() => setShowMore(!showMore)}
                  useIcon={showMore ? 'EyeHide' : 'EyeShow'}
                >
                  {showMore ? 'Show less' : 'Show more'}
                </ActionButton>
              </Item>
            ) : null}
          </Group>
        </Box>

        {initialValues.enrolment?.documents.length ? (
          <Box mt="s-16">
            <Subheader variant="nested">
              <Subheader.Title>Enrolment documents required</Subheader.Title>
            </Subheader>
            <Group>
              {initialValues.enrolment?.documents?.map(document => (
                <UploadedFileToDownload
                  id={document.id}
                  title={document.name}
                  size={document.size}
                  key={document.id}
                />
              ))}
            </Group>
          </Box>
        ) : null}

        {selectedPackage?.dependants_allowed ? (
          <Box mt="s-16">
            <Subheader variant="nested">
              <Subheader.Title>Dependants</Subheader.Title>
            </Subheader>
            <Group>
              {!initialValues.enrolment?.dependants.length ? <NoDependantsItem /> : null}
              {initialValues.enrolment?.dependants.map(dependant => (
                <DependantItem
                  onClick={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.PREVIEW, {
                        employeeId: params.employeeId,
                        id: params.id,
                        dependantId: dependant.id,
                      }),
                    )
                  }
                  dependant={dependant}
                  key={dependant.id}
                />
              ))}
            </Group>
          </Box>
        ) : null}

        {canViewComments ? (
          <Box mt="s-16">
            <CommentsSection
              api={commentsApi}
              setSidebarOpen={setCommentsSidebarOpen}
              canAddComments={canAddComments}
            />
          </Box>
        ) : null}
      </PageBody>

      <SideBar
        title="Help &amp; instructions"
        isOpen={instructionsOpen}
        onClose={() => setInstructionsOpen(false)}
        sideProps={{ resizable: true }}
      >
        <Subheader variant="nested">
          <Subheader.Title>Instructions</Subheader.Title>
        </Subheader>
        {initialValues.template?.instructions ? (
          <HTMLEditor
            value={initialValues.template.instructions}
            onChange={() => {}}
            readOnly
            height="auto"
          />
        ) : (
          <Cell>No instructions available</Cell>
        )}

        <HideIfCommercial>
          <Box mt="s-16">
            <Subheader variant="nested">
              <Subheader.Title>Need help?</Subheader.Title>
            </Subheader>
          </Box>
          <Group>
            <Cell
              use="a"
              variant="disclosure"
              href={HR_SERVICE_DESK_LINK}
              target="_blank"
              rel="noreferrer noopener"
            >
              Raise a ticket with the rewards team
            </Cell>
          </Group>
        </HideIfCommercial>
      </SideBar>

      <ConfirmationDialog
        open={optOutConfirmationVisible}
        onClose={() => setOptOutConfirmationVisible(false)}
        onConfirm={onOptOut}
        loading={optOutPending}
        onReject={() => setOptOutConfirmationVisible(false)}
        label="You're opting out from this benefit"
        body="This action cannot be undone. All changes made will be efective since the cut-off date in the next month."
        yesMessage="Confirm"
        noMessage="Cancel"
      />

      <ConfirmationDialog
        open={rejectDialogOpen}
        onClose={() => setRejectDialogOpen(false)}
        onConfirm={onReject}
        loading={isRejectLoading}
        onReject={() => setRejectDialogOpen(false)}
        label="Please enter rejection reason"
        body={
          <Box>
            <Text mb="s-16" display="inline-block" color={Token.color.greyTone50}>
              Kindly let the employee know why you are rejecting
            </Text>
            <Input
              label="Add reason here"
              value={rejectionComment}
              onChange={e => setRejectionComment(e.currentTarget.value)}
            />
          </Box>
        }
        yesMessage="Reject"
        noMessage="Go back"
      />
    </>
  )
}
