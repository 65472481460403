import { EmployeeInterface, EmployeeOptionInterface } from '@src/interfaces/employees'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { OptionInterface } from '@src/interfaces/selectors'

export enum RiskLevel {
  high = 'high',
  medium = 'medium',
  low = 'low',
}
export enum RiskStatus {
  closed = 'closed',
  progress = 'progress',
}

export enum RiskScores {
  Critical = 'Critical',
  Major = 'Major',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Trivial = 'Trivial',
}

export interface RiskSummaryInterface {
  assignee: EmployeeOptionInterface
  backoffice_url: string
  category: string
  category_order: number
  confluence_url: string
  department?: DepartmentInterface
  due_date?: string
  due_in_days?: string
  id: number
  jira_issue_key: string
  jira_url: string
  reason: string
  risk_id: number
  risk_level: RiskLevel
  risk_level_order: number
  status: string
  team?: TeamInterface
  title: string
  solution_display: string
  reason_display: string
}

export interface RiskIndicatorInterface {
  assignee: EmployeeOptionInterface
  backoffice_url: string
  quality_score: string
  current_value: number
  department?: DepartmentInterface
  due_date?: string
  due_in_days?: string
  formatted_limit_1: string
  formatted_limit_2: string
  formatted_limit_3: string
  formatted_value: string
  id: number
  jira_issue_key: string
  jira_url: string
  last_breach_date: string
  last_breach_url: string
  lower_limit_1?: number
  lower_limit_2?: number
  lower_limit_3?: number
  reason: string
  risk_level: RiskLevel
  risk_level_order: number
  status: string
  team?: TeamInterface
  title: string
  type: string
  type_order: number
  upper_limit_1?: number
  upper_limit_2?: number
  upper_limit_3?: number
  solution_display: string
  reason_display: string
}

export interface RiskIncidentsInterface {
  assignee: EmployeeOptionInterface
  backoffice_url: string
  department?: DepartmentInterface
  due_date?: string
  due_in_days?: string
  id: number
  jira_issue_key: string
  jira_url: string
  reporting_date: string
  responsible_team?: TeamInterface
  risk_level: string
  risk_level_order: number
  severity: string
  status: RiskStatus
  team?: TeamInterface
  title: string
}

export interface RiskControlsInterface {
  actions: string
  control_effectiveness: number
  department: DepartmentInterface
  id: number
  jira_issue_key: string
  next_review_date: string
  owner: EmployeeInterface
  residual_score: 'amber' | 'green' | 'red'
  risk_level: string
  risk_level_order: number
  risk_type_2: string
  target_controls_date: string
  team: TeamInterface
  title: string
  url: string
}

export interface RiskStatsInterface {
  high: number
  low: number
  medium: number
}

export enum RiskTabs {
  summary = 'summary',
  risk_incidents = 'risk_incidents',
  risk_indicators = 'risk_indicators',
}

export interface RiskTabData {
  risk_score: RiskScores
  risk_composite_index?: OptionInterface
  risk_score_reason: string
}
