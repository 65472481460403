import React from 'react'
import { useSelector } from 'react-redux'
import { MoreBar, TableWidget } from '@revolut/ui-kit'
import { connectionsTableRequests } from '@src/api/dataAnalytics'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import {
  connectionsDatabaseTableColumn,
  connectionsDatabaseUserTableColumn,
  connectionsNameTableColumn,
  connectionsOwnerColumn,
  connectionsServerTableColumn,
  connectionsStatusColumn,
  connectionsTypeTableColumn,
} from '@src/constants/columns/dataAnalytics'
import { TableNames } from '@src/constants/table'
import { Statuses } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { ConnectionInterface } from '@src/interfaces/dataAnalytics'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Stat from '@components/Stat/Stat'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

const ROW: RowInterface<ConnectionInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.GENERAL, { id })),
  cells: [
    {
      ...connectionsNameTableColumn,
      width: 200,
    },
    {
      ...connectionsOwnerColumn,
      width: 200,
    },
    {
      ...connectionsStatusColumn,
      width: 200,
    },
    {
      ...connectionsTypeTableColumn,
      width: 200,
    },
    {
      ...connectionsServerTableColumn,
      width: 200,
    },
    {
      ...connectionsDatabaseTableColumn,
      width: 200,
    },
    {
      ...connectionsDatabaseUserTableColumn,
      width: 200,
    },
  ],
}

const initialFilterBy = [
  {
    columnName: 'status',
    filters: [{ id: Statuses.active, name: Statuses.active }],
  },
]

export const ConnectionsTable = () => {
  const table = useTable<ConnectionInterface>(connectionsTableRequests, initialFilterBy)
  const featureFlags = useSelector(selectFeatureFlags)
  const canAdd = featureFlags?.includes(FeatureFlags.ReportingConnectionsManagement)

  const isArchived =
    table.filterBy.find(item => item.columnName === 'status')?.filters?.[0]?.id ===
    'archived'

  const onSwitchArchived = () => {
    table.onFilterChange([
      {
        columnName: 'status',
        filters: isArchived
          ? [
              {
                id: Statuses.active,
                name: Statuses.active,
              },
            ]
          : [
              {
                id: Statuses.archived,
                name: Statuses.archived,
              },
            ],
      },
    ])
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat
          label="Total"
          mb="s-12"
          val={table?.loading ? undefined : table?.data.length}
        />
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          {canAdd && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.GENERAL)}
              use={InternalLink}
              useIcon="Plus"
            >
              Add new connection
            </MoreBar.Action>
          )}
          <SwitchButton checked={isArchived} onClick={onSwitchArchived}>
            Show archived connections
          </SwitchButton>
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable
          {...table}
          dataType="Connections"
          hideCount
          name={TableNames.ReportingAppConnections}
          noDataMessage="List of all connections will appear here."
          row={ROW}
          useWindowScroll
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
