import React from 'react'
import {
  Avatar,
  Columns,
  IconButton,
  IconName,
  Item,
  Token,
  Widget,
} from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  canViewFindings,
  canViewRisk,
} from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { useGetOrganisationSettings } from '@src/api/settings'

type Props = {
  data: EmployeeInterface
}
export const ComplianceLayoutTab = ({ data }: Props) => {
  const { data: organisationSettings } = useGetOrganisationSettings()

  const complianceItems: Array<{
    title: string
    icon: IconName
    path: string
    canView: boolean
  }> = [
    {
      title: 'Findings',
      icon: '16/ListNumber' as const,
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.FINDINGS,
      canView: canViewFindings(data, !!organisationSettings?.findings?.enabled),
    },
    {
      title: 'Risk',
      icon: '16/Warning' as const,
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.SUMMARY,
      canView: canViewRisk(data, !!organisationSettings?.risk?.enabled),
    },
  ].filter(({ canView }) => canView)

  return (
    <Widget>
      <Columns calc={rect => rect.width / 400}>
        {complianceItems.map(item => (
          <Item
            key={item.title}
            use={InternalLink}
            to={pathToUrl(item.path, { id: String(data.id) })}
          >
            <Item.Avatar>
              <Avatar useIcon={item.icon} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{item.title}</Item.Title>
            </Item.Content>
            <Item.Side>
              <IconButton useIcon="ChevronRight" color={Token.color.greyTone20} />
            </Item.Side>
          </Item>
        ))}
      </Columns>
    </Widget>
  )
}
