import {
  Box,
  ErrorWidget,
  FilterButton,
  FilterButtonSkeleton,
  Flex,
  ItemSkeleton,
  Layout,
  Overlay,
  OverlayProps,
  Search,
  Sticky,
  Text,
  IconButton,
  Button,
  Group,
} from '@revolut/ui-kit'
import React, { FocusEvent, useCallback, useEffect, useState } from 'react'
import { getSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import { Grid } from '@components/CommonSC/Grid'
import { css } from 'styled-components'
import { fetchGlobalSearch } from '@src/api/globalSearch'
import { GlobalSearch, GlobalSearchTypes } from '@src/interfaces/globalSearch'
import { debounce, groupBy } from 'lodash'
import SearchIcon from '@src/assets/3D/Search@2x.png'
import GlobalSearchItem from '@components/GlobalSearchSidebar/GlobalSearchItem/GlobalSearchItem'
import { useSelector } from 'react-redux'
import { selectAuthenticated, selectUser } from '@src/store/auth/selectors'
import { HubAppType } from '@src/interfaces/hub'
import GlobalSearchAppResults from '@components/GlobalSearchSidebar/GlobalSearchAppResults/GlobalSearchAppResults'
import GlobalSearchSection from '@components/GlobalSearchSidebar/GlobalSearchSection/GlobalSearchSection'
import { useHubAppSearch } from '@src/pages/Hub/hooks'
import { useGetFavourites } from '@src/api/favourites'
import { IdStatuses } from '@src/interfaces/employees'
import { defaultTheme } from '@src/styles/theme'
import {
  GlobalSearchSettingsResults,
  useSettingsSearchResults,
} from './GlobalSearchSettingsResults'
import { SettingsLinkItem } from '@src/pages/Settings/common/config'
import { useGlobalSearchShortcut } from './useGlobalSearchShortcut'
import globalSearchState from '@components/GlobalSearchSidebar/GlobalSearchSidebarState'

interface GlobalSearchSidebarProps extends OverlayProps {
  isOpen: boolean
  showAction: boolean
  onClose: () => void
}

interface GlobalSearchSidebarContentsProps {
  onClose: () => void
  onClear: () => void
  value: string
  isCoreNavigation: boolean
}

const overflowScroll = css`
  overflow: auto;
  padding-bottom: 16px;
`

const AppFilter = 'App'
const SettingsFilter = 'Settings'
export const ResultLimit = 3

export const GlobalSearchSidebarContents = ({
  value,
  onClear,
  onClose,
  isCoreNavigation,
}: GlobalSearchSidebarContentsProps) => {
  const { data: favourites } = useGetFavourites()
  const [searchResults, setSearchResults] = useState<GlobalSearch[]>()
  const [searchPending, setSearchPending] = useState(false)
  const [filters, setFilters] = useState<OptionInterface[]>([])
  const [resultsByKey, setResultsByKey] = useState<{ [index: string]: GlobalSearch[] }>()
  const [activeFilter, setActiveFilter] = useState<string>()
  const [hubResults, setHubResults] = useState<HubAppType[]>([])
  const [settingsResults, setSettingsResults] = useState<SettingsLinkItem[]>([])
  const { findApps } = useHubAppSearch()
  const findSettings = useSettingsSearchResults()

  /** We do not want to render the `App` and `Settings` categories in 'favourites-mode' because favouriting them is not possible */
  const isFavouritesMode = globalSearchState.open === 'favourites-mode'

  useEffect(() => {
    fetchSelector()
  }, [])

  const searchHub = (query: string) => {
    setHubResults(findApps(query))
  }

  const searchSettings = (query: string) => {
    setSettingsResults(findSettings(query))
  }

  const search = async (query: string) => {
    if (activeFilter === AppFilter || activeFilter === SettingsFilter) {
      return
    }

    try {
      setSearchPending(true)
      const results = await fetchGlobalSearch(query)

      if (results?.data) {
        const keyByType = groupBy(results.data.results, 'category.name') || {}
        setResultsByKey(keyByType)
        setSearchResults(results.data.results)
      }
    } finally {
      setSearchPending(false)
    }
  }

  useEffect(() => {
    if (value) {
      search(value)
    }
  }, [activeFilter])

  useEffect(() => {
    if (activeFilter !== AppFilter && activeFilter !== SettingsFilter) {
      setSearchPending(true)
      searchThrottled(value)
    }
    if (!isFavouritesMode) {
      searchHub(value)
      searchSettings(value)
    }
  }, [value])

  const searchThrottled = useCallback(
    debounce(search, 1000, {
      leading: false,
      trailing: true,
    }),
    [],
  )

  const fetchSelector = async () => {
    const result = await getSelectors(selectorKeys.search_item_categories)
    if (result.data) {
      setFilters(
        isFavouritesMode
          ? [...result.data?.options]
          : [
              ...result.data?.options,
              { id: AppFilter, name: AppFilter },
              { id: SettingsFilter, name: SettingsFilter },
            ],
      )
    }
  }

  const renderFilterButtons = () => {
    if (!filters.length) {
      return (
        <Grid justifyContent="start" css={overflowScroll} gap={8} flow="column">
          <FilterButtonSkeleton />
          <FilterButtonSkeleton />
          <FilterButtonSkeleton width="10rem" />
        </Grid>
      )
    }

    let filteredFilters =
      resultsByKey && !!value.length
        ? filters
            .filter(
              o =>
                !!resultsByKey[o.name] ||
                o.name === activeFilter ||
                (o.id === AppFilter && !!hubResults?.length) ||
                (o.id === SettingsFilter && !!settingsResults?.length),
            )
            .sort(d => (d.name === activeFilter ? -1 : 1))
        : filters.sort(d => (d.name === activeFilter ? -1 : 1))

    return (
      <Grid justifyContent="start" css={overflowScroll} gap={8} flow="column">
        {filteredFilters.map(filter => (
          <FilterButton
            key={filter.id}
            onClear={
              activeFilter === filter.name ? () => setActiveFilter(undefined) : undefined
            }
            active={activeFilter === filter.name}
            onClick={() => setActiveFilter(filter.name as string)}
          >
            <Text whiteSpace="nowrap">{filter.name}</Text>
          </FilterButton>
        ))}
      </Grid>
    )
  }

  const renderLoadingState = () => {
    return (
      <Box>
        {isFavouritesMode ? null : (
          <>
            <GlobalSearchAppResults
              apps={hubResults}
              onItemClick={onClose}
              onShowMore={() => setActiveFilter(AppFilter)}
              isCoreNavigation={isCoreNavigation}
            />
            <GlobalSearchSettingsResults
              results={settingsResults}
              onItemClick={onClose}
              onShowMore={() => setActiveFilter(SettingsFilter)}
              isCoreNavigation={isCoreNavigation}
            />
          </>
        )}
        <Box mb={isCoreNavigation ? 's-0' : 's-32'}>
          <Text color="grey-tone-50" use="div" mb={isCoreNavigation ? 's-0' : 's-16'}>
            Teams
          </Text>
          <Group>
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </Group>
        </Box>
        <Box mb={isCoreNavigation ? 's-0' : 's-32'}>
          <Text color="grey-tone-50" use="div" mb={isCoreNavigation ? 's-0' : 's-16'}>
            Department
          </Text>
          <ItemSkeleton />
        </Box>
        <Box>
          <Text color="grey-tone-50" use="div" mb={isCoreNavigation ? 's-0' : 's-16'}>
            Employees
          </Text>
          <Group>
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </Group>
        </Box>
      </Box>
    )
  }

  const renderItem = (
    key: string,
    data: GlobalSearch[],
    limitResults: boolean = false,
  ) => {
    const filteredData = limitResults ? data.slice(0, ResultLimit) : data

    return (
      <GlobalSearchSection
        key={key}
        name={key}
        showMore={
          limitResults && data.length > ResultLimit
            ? () => setActiveFilter(key)
            : undefined
        }
        isCoreNavigation={isCoreNavigation}
      >
        {filteredData?.map(item => (
          <GlobalSearchItem
            onClose={onClose}
            key={item.id}
            data={item}
            type={item.category.id as GlobalSearchTypes}
          />
        ))}
      </GlobalSearchSection>
    )
  }

  const renderResults = () => {
    if (!value && favourites?.count && favourites.count > 0) {
      const groupedFavourites = groupBy(
        favourites.results,
        'favourite_object.category.name',
      )

      return (
        <Flex flexDirection="column">
          <Text variant="h4">Starred Items</Text>
          {Object.keys(groupedFavourites).map(key => {
            const objects = groupedFavourites[key].map(i => i.favourite_object)
            return renderItem(key, objects)
          })}
        </Flex>
      )
    }

    if (!value) {
      return (
        <ErrorWidget
          // @ts-ignore but there is bg here and it works
          bg="transparent"
        >
          <ErrorWidget.Image src={SearchIcon} />
          <ErrorWidget.Title>
            <Text color="grey-tone-50">Start searching</Text>
          </ErrorWidget.Title>
        </ErrorWidget>
      )
    }

    if (searchPending) {
      return renderLoadingState()
    }

    if (!searchResults || !resultsByKey) {
      return null
    }

    if (
      (!searchResults.length && !hubResults.length && !settingsResults.length) ||
      (activeFilter &&
        activeFilter !== AppFilter &&
        activeFilter !== SettingsFilter &&
        !resultsByKey[activeFilter]?.length)
    ) {
      return (
        <ErrorWidget
          // @ts-ignore but there is bg here and it works
          bg="transparent"
        >
          <ErrorWidget.Image src={SearchIcon} />
          <ErrorWidget.Title>No results found</ErrorWidget.Title>
          <ErrorWidget.Description>
            No results found for “{value}”
          </ErrorWidget.Description>
          <ErrorWidget.Action onClick={onClear}>Clear</ErrorWidget.Action>
        </ErrorWidget>
      )
    }

    if (activeFilter === AppFilter) {
      return (
        <GlobalSearchAppResults
          apps={hubResults}
          onItemClick={onClose}
          onShowMore={() => setActiveFilter(AppFilter)}
          limitResults={false}
          isCoreNavigation={isCoreNavigation}
        />
      )
    }

    if (activeFilter === SettingsFilter) {
      return (
        <GlobalSearchSettingsResults
          results={settingsResults}
          onItemClick={onClose}
          onShowMore={() => setActiveFilter(SettingsFilter)}
          limitResults={false}
          isCoreNavigation={isCoreNavigation}
        />
      )
    }

    if (activeFilter && activeFilter !== AppFilter && activeFilter !== SettingsFilter) {
      return (
        <Flex flexDirection="column">
          {renderItem(activeFilter, resultsByKey[activeFilter])}
        </Flex>
      )
    }

    return (
      <Flex flexDirection="column">
        <GlobalSearchAppResults
          apps={hubResults}
          onItemClick={onClose}
          onShowMore={() => setActiveFilter(AppFilter)}
          isCoreNavigation={isCoreNavigation}
        />
        <GlobalSearchSettingsResults
          results={settingsResults}
          onItemClick={onClose}
          onShowMore={() => setActiveFilter(SettingsFilter)}
          isCoreNavigation={isCoreNavigation}
        />
        {Object.keys(resultsByKey).map(key => {
          return renderItem(key, resultsByKey[key], true)
        })}
      </Flex>
    )
  }

  return (
    <>
      {renderFilterButtons()}
      <Box mt="s-16">{renderResults()}</Box>
    </>
  )
}

export default ({
  isOpen,
  onClose,
  showAction,
  ...sideProps
}: GlobalSearchSidebarProps) => {
  const [value, setValue] = useState('')

  const authenticated = useSelector(selectAuthenticated)
  const user = useSelector(selectUser)

  useGlobalSearchShortcut()

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.currentTarget.select()
  }

  if (authenticated && user?.status?.id === IdStatuses.active) {
    return (
      <Overlay closeOnEsc open={isOpen} onExited={onClose} {...sideProps}>
        <Layout>
          <Layout.Main>
            <Sticky
              style={{ zIndex: defaultTheme.zIndex.main }}
              top={0}
              py="s-16"
              bg="layout-background"
              variant="bottom-sheet"
            >
              <Box mb="s-24">
                <IconButton useIcon="Cross" aria-label="Close" onClick={onClose} />
              </Box>
              <Search
                autoFocus
                value={value}
                onFocus={handleFocus}
                data-testid="global-search-search-input"
                placeholder="Search"
                style={{ flexGrow: 1 }}
                onChange={setValue}
              />
            </Sticky>
            <GlobalSearchSidebarContents
              value={value}
              onClose={onClose}
              onClear={() => setValue('')}
              isCoreNavigation={false}
            />
          </Layout.Main>
          {showAction ? (
            <Layout.Actions>
              <Button onClick={onClose} elevated>
                Complete &amp; return
              </Button>
            </Layout.Actions>
          ) : null}
        </Layout>
      </Overlay>
    )
  }

  return null
}
