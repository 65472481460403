import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import { ActionButton, Token, TableWidget } from '@revolut/ui-kit'
import {
  queryPermissionsRequests,
  queryPermissionsRequestsNew,
} from '@src/api/permissionManagement'
import {
  permissionsActionsColumn,
  permissionsEmployeeNameColumn,
  permissionsNotesColumn,
  permissionsPermissionColumn,
  permissionsStatusColumn,
  permissionsValidFromColumn,
  permissionsValidTillColumn,
} from '@src/constants/columns/permissions'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { PermissionsInterface } from '@src/interfaces/permissions'
import { PageBody } from '@src/components/Page/PageBody'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import PermissionTransferRevokeDialogue from '@src/pages/PermissionTransfer/Dialogue/PermissionTransferRevokeDialogue'
import { pathToUrl } from '@src/utils/router'

const getRow = (
  onRevoke: (data: PermissionsInterface) => void,
): RowInterface<PermissionsInterface> => ({
  cells: [
    {
      ...permissionsEmployeeNameColumn,
      width: 250,
    },
    {
      ...permissionsPermissionColumn,
      width: 250,
    },
    {
      ...permissionsStatusColumn,
      width: 200,
    },
    {
      ...permissionsValidFromColumn,
      width: 200,
    },
    {
      ...permissionsValidTillColumn,
      width: 150,
    },
    {
      ...permissionsNotesColumn,
      width: 150,
    },
    {
      ...permissionsActionsColumn(onRevoke),
      width: 150,
    },
  ],
})

export interface OptionInterface {
  id: string
  name: string
  fields: string[]
}

export const PermissionTable = connect(() => {
  const params = useParams<{ id: string }>()
  const table = useTable<any>(queryPermissionsRequests(params.id))
  const [isDialogueOpen, setDialogueOpen] = useState(false)
  const [revokingData, setRevokingData] = useState<PermissionsInterface | null>()

  const onRevoke = (data: PermissionsInterface) => {
    setDialogueOpen(true)
    setRevokingData(data)
  }

  return (
    <>
      <PermissionTransferRevokeDialogue
        isOpen={isDialogueOpen}
        onAfterSubmit={() => {
          setDialogueOpen(false)
          setRevokingData(null)
          table.refresh()
        }}
        permission={(revokingData || undefined) as PermissionsInterface}
        revoke={id => queryPermissionsRequestsNew(params.id).delete?.({ id }, params)}
        setOpen={setDialogueOpen}
      />
      <PageBody maxWidth={Token.breakpoint.xxl}>
        <TableWidget>
          <TableWidget.Actions>
            <ActionButton
              to={pathToUrl(ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.QUERY, {
                entityId: params.id,
                tab: 'form',
              })}
              use={InternalLink}
              useIcon="ShareAndroid"
            >
              Add new permission
            </ActionButton>
          </TableWidget.Actions>
          <TableWidget.Table>
            <AdjustableTable<PermissionsInterface>
              dataType="Permission"
              name={TableNames.ReportingAppQueriesPermissions}
              noDataMessage="All permissions will appear here"
              row={getRow(onRevoke)}
              useWindowScroll
              {...table}
            />
          </TableWidget.Table>
        </TableWidget>
      </PageBody>
    </>
  )
})
