import React, { useEffect } from 'react'
import {
  PerformanceRating,
  PerformanceReviewTypes,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { connect, useLape } from 'lape'
import { Box, MoreBar } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { CellInsertParams, RowInterface } from '@src/interfaces/data'
import {
  kpiCalibratedPerformanceColumn,
  kpiEntityColumn,
  kpiGenericNameColumn,
  kpiLevelColumn,
  kpiPerformanceColumn,
  kpiRecommendationColumn,
  kpiUpdateTypeColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { useTable } from '@components/Table/hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AxiosResponse } from 'axios'
import { GetRequestInterface } from '@src/interfaces'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import CardJustification from '@components/ScorecardGeneral/CardJustification'
import { KpiInterface } from '@src/interfaces/kpis'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { PerformanceRatingTitle } from '@src/constants/performance'
import styled from 'styled-components'
import { RadioSelect } from '@components/Inputs/RadioSelect/RadioSelect'
import produce from 'immer'
import { ratingToColor } from '@src/utils/performance'
import { getProbationKPIRecommendation } from '@src/api/probationReview'
import KpiStatistic from '@src/pages/Forms/EmployeePerformance/components/KPIStatistic'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const RadioSelectStyled = styled(RadioSelect)`
  min-height: 32px;
  border: 0;
  padding: 0;
`

const ratingOptions = Object.values(PerformanceRating)
  .filter(id => id !== PerformanceRating.skipped && id !== PerformanceRating.dont_know)
  .map(id => ({
    id,
    name: PerformanceRatingTitle[id],
  }))

const ROW = (
  changeRating: (id: number, rating: PerformanceRating) => void,
  isView?: boolean,
): RowInterface<KpiInterface> => ({
  cells: [
    {
      ...kpiGenericNameColumn,
      sortKey: null,
      filterKey: null,
    },
    {
      ...kpiWeightColumn,
      sortKey: null,
      filterKey: null,
      width: 68,
    },
    {
      ...kpiPerformanceColumn,
      sortKey: null,
      filterKey: null,
      width: 130,
    },
    {
      ...kpiCalibratedPerformanceColumn,
      sortKey: null,
      filterKey: null,
      width: 130,
    },
    {
      ...kpiLevelColumn,
      sortKey: null,
      filterKey: null,
      width: 85,
    },
    {
      ...kpiEntityColumn,
      sortKey: null,
      filterKey: null,
      width: 150,
    },
    {
      ...kpiUpdateTypeColumn,
      sortKey: null,
      filterKey: null,
      width: 80,
    },
    {
      ...kpiRecommendationColumn,
      width: 150,
      background: data => (data.rating ? ratingToColor(data.rating) || '' : ''),
      insert: ({ data }: CellInsertParams<KpiInterface>) => {
        const value = ratingOptions.find(item => item.id === data.rating) || {
          id: '',
          name: '',
          text: '',
        }

        const canChange = !isView

        if (!canChange) {
          return (
            <PerformanceRatingLabelTag
              rating={data.rating}
              fontWeight={400}
              emptyMessage="-"
              data-testid={`label_rating__${data.id}`}
            />
          )
        }

        return (
          <div onClick={e => e.stopPropagation()}>
            <RadioSelectStyled
              id={`select_rating__${data.id}`}
              options={ratingOptions}
              onChange={option => {
                changeRating(data.id, option.id as PerformanceRating)
              }}
              value={value}
              width={480}
            />
          </div>
        )
      },
    },
  ],
})

interface Props {
  isView: boolean
  employeeId: number
  id: number
}

const KPI = ({ isView, employeeId, id }: Props) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const state = useLape<{
    performance: number
    rating: PerformanceRating | null
  }>({
    performance: 0,
    rating: null,
  })

  const kpiSection = isView
    ? (values as ReviewSummaryInterface).summary?.kpis_section
    : (values as ReviewScorecardInterface).review_data.kpis_section

  const table = useTable<KpiInterface>({
    getItems: () =>
      new Promise(resolve => {
        resolve({
          data: {
            results: kpiSection?.kpi_items || [],
            count: kpiSection?.kpi_items.length,
          },
        } as AxiosResponse<GetRequestInterface<KpiInterface>>)
      }),
  })

  const changeRating = (rowId: number, rating: PerformanceRating) => {
    table.setData(
      produce(table.data, draft => {
        const row = draft.find(item => item.id === rowId)
        if (row) {
          row.rating = rating
        }
      }),
    )
  }

  const fetchRecommendation = async () => {
    if (!kpiSection || isView) {
      return
    }

    const resp = await getProbationKPIRecommendation(employeeId, id, kpiSection)

    state.rating = resp.data.result.rating
    state.performance = resp.data.result.performance
  }

  useEffect(() => {
    if (kpiSection && !isView) {
      kpiSection.kpi_items = [...table.data]
    }
  }, [table.data])

  useEffect(() => {
    if (kpiSection?.kpi_items?.length) {
      fetchRecommendation()
    }
  }, [kpiSection?.kpi_items])

  if (!kpiSection) {
    return null
  }

  return (
    <Box width={1055} mb="s-32">
      <TableWrapper mt="s-16">
        <KpiStatistic
          performance={isView ? kpiSection.performance : state.performance}
          rating={isView ? kpiSection.rating : state.rating}
          ratingLabel="Your recommendation"
        />

        <Box mt="s-16">
          <MoreBar>
            <MoreInfoButton href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1575586897/Review+deliverables#FAQs">
              Rating calculation info
            </MoreInfoButton>
          </MoreBar>
        </Box>

        <Box mt="s-16" width="1020px">
          <AdjustableTable<KpiInterface>
            name={TableNames.ProbationKPI}
            useWindowScroll
            dataType="KPI"
            row={ROW(changeRating, isView)}
            {...table}
            noDataMessage="No KPI defined"
            onRowClick={onKPITableRowClick}
            noReset
          />
        </Box>
      </TableWrapper>

      <Box mt="s-16">
        {isView ? (
          <CardJustification
            reviews={kpiSection.comments!.map(item => item.review)}
            card={kpiSection}
            cardIndex={0}
            type={PerformanceReviewTypes.kpi}
            viewMode
          />
        ) : (
          <LapeNewTextArea
            rows={4}
            label="Comments"
            name="review_data.kpis_section.comment"
            data-testid="comment"
          />
        )}
      </Box>
    </Box>
  )
}

export default connect(KPI)
