import React from 'react'
import { Link } from '@revolut/ui-kit'

import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { getVendorOwnership } from '@src/api/ownership'
import { VendorOwnershipInterface } from '@src/interfaces/ownership'

import { Banner } from './common'
import {
  vendorOwnershipActionColumn,
  vendorOwnershipLegalEntityColumn,
  vendorOwnershipNameColumn,
  vendorOwnershipRevolutLegalEntityColumn,
  vendorOwnershipStatusColumn,
} from '@src/constants/columns/ownership'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

export const vendorBannerTitle = (
  <>
    Please make sure you follow the vendor owner transfer checklist as shown{' '}
    <Link
      href="https://docs.google.com/document/d/1R_pTGJJGyxYZSDYJVrE347Law5A_mzgCmkTYN34XnSg"
      target="_blank"
    >
      here
    </Link>
  </>
)

const ROW: RowInterface<VendorOwnershipInterface> = {
  cells: [
    {
      ...vendorOwnershipNameColumn,
      width: 100,
    },
    {
      ...vendorOwnershipLegalEntityColumn,
      width: 100,
    },
    {
      ...vendorOwnershipStatusColumn,
      width: 100,
    },
    {
      ...vendorOwnershipRevolutLegalEntityColumn,
      width: 100,
    },
    {
      ...vendorOwnershipActionColumn,
      width: 100,
    },
  ],
}

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
}

const VendorOwnership = ({ data, stats }: Props) => {
  const table = useTable<VendorOwnershipInterface>(getVendorOwnership(data.id))

  return (
    <>
      <Banner
        title={vendorBannerTitle}
        description={
          <>
            You must transfer vendor ownership{' '}
            <Link href="https://backoffice.revolut.com/vendors" target="_blank">
              in Backoffice
            </Link>{' '}
            before leaving the organisation or moving internally.
          </>
        }
      />

      <TableWrapper mb="s-20" mt="s-16">
        <Stat label="Vendors" val={stats?.owned_vendors_count} mb="s-24" />

        <AdjustableTable
          name={TableNames.EmployeeVendorOwnership}
          useWindowScroll
          row={ROW}
          {...table}
          noDataMessage="Vendor ownership will appear here"
        />
      </TableWrapper>
    </>
  )
}

export default VendorOwnership
