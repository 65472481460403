import React from 'react'
import { EntityTypes } from '@src/constants/api'
import {
  analyticsDashboardNameColumn,
  analyticsDashboardRelatedKpisColumn,
  analyticsDashboardOrganisationUnitsColumn,
  analyticsDashboardOwnerColumn,
  analyticsDashboardRatingColumn,
  analyticsDashboardSourceColumn,
} from '@src/constants/columns/analyticsDashboard'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { RowInterface } from '@src/interfaces/data'
import { AnalyticsDashboardsTab } from '@src/features/AnalyticsDashboardsTab'
import { useGetCompany } from '@src/api/company'
import Loader from '@components/CommonSC/Loader'

const initialFilterBy = [
  {
    filters: [{ id: 'true', name: 'true' }],
    columnName: 'company_related',
    nonResettable: true,
  },
]

const row: RowInterface<GenericAnalyticsDashboardInterface> = {
  cells: [
    {
      ...analyticsDashboardNameColumn,
      width: 150,
    },
    {
      ...analyticsDashboardOrganisationUnitsColumn,
      width: 100,
    },
    {
      ...analyticsDashboardRelatedKpisColumn,
      width: 150,
    },
    {
      ...analyticsDashboardRatingColumn,
      width: 30,
    },
    {
      ...analyticsDashboardSourceColumn,
      width: 30,
    },
    {
      ...analyticsDashboardOwnerColumn,
      width: 100,
    },
  ],
}

interface DashboardsProps {
  useStatsNavigation?: boolean
}

export const Dashboards = ({ useStatsNavigation = true }: DashboardsProps) => {
  const { data, isLoading } = useGetCompany()

  if (isLoading) {
    return <Loader />
  }

  return (
    <AnalyticsDashboardsTab
      data={data}
      entity={EntityTypes.company}
      initialFilter={initialFilterBy}
      row={row}
      useStatsNavigation={useStatsNavigation}
    />
  )
}

export const DashboardsWithNav = () => {
  return <Dashboards useStatsNavigation />
}

export const DashboardsWithoutNav = () => {
  return <Dashboards useStatsNavigation={false} />
}
