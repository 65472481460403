import React from 'react'
import { useParams } from 'react-router'
import { useGetReviewCycle } from '@src/api/reviewCycles'
import { CycleSummary } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSummary/CycleSummary'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import PageLoading from '@components/PageLoading/PageLoading'
import { useIsTestPerformanceCycleEnabled } from '@src/pages/Forms/ReviewCycle/ReviewCycle/useIsTestPerformanceCycleEnabled'
import ReviewCycleGeneral from '@src/pages/Forms/ReviewCycle/General'

export const ReviewCycle = () => {
  const params = useParams<{ id: string }>()

  const { data: reviewCycle, isLoading } = useGetReviewCycle(params.id)

  if (isLoading || reviewCycle === undefined) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <PageHeader
        title={<CycleSummary cycle={reviewCycle} />}
        backUrl={ROUTES.PERFORMANCE.REVIEWS_CYCLES}
      />
    </PageWrapper>
  )
}

export const ReviewCyclePage = () => {
  const isTestCycleEnabled = useIsTestPerformanceCycleEnabled()

  return (
    <>
      {isTestCycleEnabled && <ReviewCycle />}
      <ReviewCycleGeneral />
    </>
  )
}
