import React, { useRef } from 'react'
import { Flex, Token, Grid, VStack, Relative, Scrollbar } from '@revolut/ui-kit'
import styled from 'styled-components'

import Favourites from '@components/Favourites/Favourites'
import { FavouritesV2 } from '@components/Favourites/FavouritesV2'
import { DashboardToDo } from '@src/pages/Home/HomeSubTabs/Dashboard/DashboardToDo'
import { PageBody } from '@components/Page/PageBody'
import Announcements from '@src/pages/Home/HomeSubTabs/Dashboard/components/Announcements/Announcements'
import { useGetOnboardingCheckpoints } from '@src/api/onboardingChecklistV2'
import { checkIfFirstStepsCompleted } from '@src/pages/OnboardingChecklistV2/common/helpers'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { ProgressBanner } from '@src/pages/OnboardingChecklistV2/LandingPage/ProgressBanner'
import { ChecklistSection } from '@src/pages/OnboardingChecklistV2/LandingPage/ChecklistSection'
import { onboardingCheckpointsSectionsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { useIsOnboardingDashboardEnabled } from '@src/pages/OnboardingChecklistV2/common/hooks'
import { useBanner } from '@src/features/UIKitWithThemeProvider/BannerProvider'
import { useCoreNavigation } from '@src/features/MainLayout/useCoreNavigation'

const OnboardingChecklist = () => {
  const { checkpoints, isLoading } = useGetOnboardingCheckpoints()
  const firstStepsCompleted = checkIfFirstStepsCompleted(checkpoints)

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <>
      <PageBody>
        <VStack space="s-16">
          <ProgressBanner />
          {firstStepsCompleted ? (
            <>
              <ChecklistSection
                title={onboardingCheckpointsSectionsConfig.performance.title}
                configs={onboardingCheckpointsSectionsConfig.performance.configs}
              />
              <ChecklistSection
                title={onboardingCheckpointsSectionsConfig.recruitment.title}
                configs={onboardingCheckpointsSectionsConfig.recruitment.configs}
              />
              <ChecklistSection
                title={onboardingCheckpointsSectionsConfig.hr.title}
                configs={onboardingCheckpointsSectionsConfig.hr.configs}
              />
              <ChecklistSection
                title={onboardingCheckpointsSectionsConfig.launch.title}
                configs={onboardingCheckpointsSectionsConfig.launch.configs}
              />
            </>
          ) : (
            <ChecklistSection
              canPostpone={false}
              title={onboardingCheckpointsSectionsConfig.firstSteps.title}
              configs={onboardingCheckpointsSectionsConfig.firstSteps.configs}
            />
          )}
        </VStack>
      </PageBody>
    </>
  )
}

const PageFeedContainer = styled.div<{ withBanner: boolean }>`
  border-radius: ${Token.radius.widget};
  overflow: hidden;

  @media ${Token.media.xl} {
    position: sticky;
    top: ${props => (props.withBanner ? '106px' : '74px')};
    max-height: calc(100vh - ${props => (props.withBanner ? '122px' : '92px')});
  }
`

const Dashboard = () => {
  const { enabled, isLoading } = useIsOnboardingDashboardEnabled()
  const scrollRef = useRef(null)
  const { visible } = useBanner()
  const isCoreNavigation = useCoreNavigation()

  if (isLoading) {
    return <PageLoading />
  }

  return enabled ? (
    <PageBody maxWidth={Token.breakpoint.xl}>
      <OnboardingChecklist />
    </PageBody>
  ) : isCoreNavigation ? (
    <Grid
      columns={{ xl: '1fr 400px', all: '1fr' }}
      columnGap="s-16"
      rowGap="s-16"
      mt="s-4"
    >
      <VStack minWidth={0} space="s-16">
        <Announcements />
        <DashboardToDo />
      </VStack>

      <PageFeedContainer withBanner={visible}>
        <Relative height="100%" ref={scrollRef}>
          <FavouritesV2 />
        </Relative>
        <Scrollbar scrollRef={scrollRef} />
      </PageFeedContainer>
    </Grid>
  ) : (
    <PageBody maxWidth={Token.breakpoint.xl}>
      <Flex gap="s-16" flexDirection="column" overflow={{ all: 'hidden', md: 'visible' }}>
        <Announcements />
        <DashboardToDo />
        <Favourites />
      </Flex>
    </PageBody>
  )
}

export default Dashboard
