import React, { useEffect, useState } from 'react'
import { addMonths, addYears, getYear, startOfYear, subYears } from 'date-fns'

import { Color, Flex, IconButton, Text } from '@revolut/ui-kit'
import { ChevronLeft, ChevronRight } from '@revolut/icons'
import Loader from '@components/CommonSC/Loader'
import { Month } from './Month'

const MONTHS_IN_YEAR = 12

type Props = {
  isLoading?: boolean
  renderDay?: (day: Date) => React.ReactNode
  onCurrentDateChange?: (date: Date) => void
}
export const Calendar = ({ renderDay, onCurrentDateChange, isLoading }: Props) => {
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    onCurrentDateChange?.(currentDate)
  }, [currentDate])

  const firstDayOfYear = startOfYear(currentDate)

  const monthsFirstDays = new Array(MONTHS_IN_YEAR)
    .fill(null)
    .map((_, idx) => addMonths(firstDayOfYear, idx))

  return (
    <Flex flexDirection="column" width="100%">
      <Flex width="100%" justifyContent="space-between" alignItems="center" pb="s-20">
        <IconButton
          useIcon={ChevronLeft}
          color={Color.GREY_TONE_50}
          onClick={() => {
            setCurrentDate(subYears(currentDate, 1))
          }}
          aria-label="Previous year"
        />
        <Text variant="h5">{getYear(currentDate)}</Text>
        <IconButton
          useIcon={ChevronRight}
          color={Color.GREY_TONE_50}
          onClick={() => {
            setCurrentDate(addYears(currentDate, 1))
          }}
          aria-label="Next year"
        />
      </Flex>
      {isLoading ? (
        <Flex width="100%" height={512} alignItems="center" justifyContent="center">
          <Loader />
        </Flex>
      ) : (
        <Flex gap="s-24" mt="s-32" flexWrap="wrap" justifyContent="space-around">
          {monthsFirstDays.map((day, idx) => (
            <Month renderDay={renderDay} key={idx} idx={idx} firstDay={day} />
          ))}
        </Flex>
      )}
    </Flex>
  )
}
