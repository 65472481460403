import React, { forwardRef } from 'react'
import { Bar, Flex, Text, Widget, Icon, ActionButton } from '@revolut/ui-kit'
import {
  InterviewFeedbackInterface,
  InterviewFeedbackStatus,
  InterviewRoundInterface,
  InterviewType,
} from '@src/interfaces/interviewTool'
import LapeForm from '@src/features/Form/LapeForm'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  getLocationDescriptor,
  useGetPathWithWorkspace,
} from '@src/actions/RouterActions'
import Overview from '@src/pages/Forms/Candidate/InterviewProgress/components/InterviewFeedbackSidebar/Overview'
import Notes from '@src/pages/Forms/Candidate/InterviewProgress/components/InterviewFeedbackSidebar/Notes'
import ChangesDetected from '@src/pages/Forms/Candidate/InterviewProgress/components/InterviewFeedbackSidebar/ChangesDetected'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import SidebarForm from '@src/pages/Forms/Candidate/InterviewProgress/components/InterviewFeedbackSidebar/SidebarForm'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

type InterviewFeedbackProps = {
  round?: InterviewRoundInterface
  scorecard?: InterviewFeedbackInterface
  stageType?: InterviewType
}

export const InterviewFeedback = forwardRef<HTMLDivElement, InterviewFeedbackProps>(
  ({ round, scorecard, stageType }, ref) => {
    const getPathWithWorkspace = useGetPathWithWorkspace()
    const user = useSelector(selectUser)
    const scorecardUrl = pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, {
      id: scorecard?.id,
    })
    const openInNewTabUrl = getPathWithWorkspace(scorecardUrl)
    const canEdit =
      scorecard?.is_feedback_editable && user.id === scorecard?.interviewer?.id
    if (scorecard?.status === InterviewFeedbackStatus.completed) {
      return (
        <>
          <Bar mb="s-16" ref={ref}>
            {canEdit && (
              <ActionButton
                use={InternalLink}
                // @ts-expect-error object works fine here, but UI kit expects string
                to={getLocationDescriptor(scorecardUrl)}
              >
                Edit Scorecard
              </ActionButton>
            )}
            <ActionButton use={InternalLink} to={openInNewTabUrl} target="_blank">
              Open in new tab
            </ActionButton>
          </Bar>
          <Overview expectedSeniority={round?.seniority?.name} scorecard={scorecard} />
          {scorecard && <ChangesDetected scorecard={scorecard} />}
          <Notes scorecard={scorecard} />
          <LapeForm onSubmit={() => Promise.resolve({})}>
            <SidebarForm scorecard={scorecard} round={round} stageType={stageType} />
          </LapeForm>
        </>
      )
    }
    return (
      <>
        {scorecard?.id && (
          <Bar mb="s-16">
            <ActionButton use={InternalLink} to={openInNewTabUrl} target="_blank">
              View Scorecard
            </ActionButton>
          </Bar>
        )}
        <Widget p="s-48">
          <Flex alignItems="center" flexDirection="column">
            <Icon name="Document" color="grey-20" />
            <Text color="grey-20" variant="h6">
              Scorecard not submitted yet
            </Text>
          </Flex>
        </Widget>
      </>
    )
  },
)
