import React, { useState } from 'react'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Switch, TableWidget, Text, Token } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { ReferralBonusInterface } from '@src/interfaces/referralBonus'
import { referralBonusRequests } from '@src/api/referralBonus'
import {
  referralBonusAmountColumn,
  referralBonusCurrencyColumn,
  referralBonusFunctionColumn,
  referralBonusLocationColumn,
  referralBonusSeniorityColumn,
  referralBonusSpecialisationColumn,
  referralBonusTaxColumn,
  referralBonusTypeColumn,
} from '@src/constants/columns/referralBonus'
import { TableNames } from '@src/constants/table'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'

const RowNotifications: RowInterface<ReferralBonusInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.REFERRAL_BONUSES.GENERAL, { id })),
  highlight: data => (data.amount ? '' : Token.color.red_20),
  cells: [
    {
      ...referralBonusSpecialisationColumn,
      width: 400,
    },
    {
      ...referralBonusFunctionColumn,
      width: 230,
    },
    {
      ...referralBonusSeniorityColumn,
      width: 200,
    },
    {
      ...referralBonusLocationColumn,
      width: 200,
    },
    {
      ...referralBonusTaxColumn,
      width: 120,
    },
    {
      ...referralBonusCurrencyColumn,
      width: 100,
    },
    {
      ...referralBonusTypeColumn,
      width: 120,
    },
    {
      ...referralBonusAmountColumn,
      width: 600,
    },
  ],
}

export const ReferralBonusTable = () => {
  const sort = [
    {
      sortBy: 'role__function__name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const table = useTable<ReferralBonusInterface>(referralBonusRequests, [], sort)
  const [withoutRoles, setWithoutRoles] = useState(
    table.filterBy.find(filter => filter.columnName === 'amount__isnull')?.filters?.[0]
      ?.id === 'True',
  )

  return (
    <PageWrapper>
      <PageHeader
        title="Referral bonus"
        backUrl={pathToUrl(ROUTES.RECRUITMENT.CANDIDATES)}
      />
      <PageBody maxWidth="100%">
        <TableWidget>
          <TableWidget.Info>
            <Stat
              label="Total"
              val={table?.loading ? undefined : table?.count}
              mr="s-32"
            />
          </TableWidget.Info>
          <TableWidget.Filters>
            <Switch
              onChange={event => {
                table.onFilterChange({
                  filters: [
                    {
                      name: !withoutRoles ? 'True' : '',
                      id: !withoutRoles ? 'True' : '',
                    },
                  ],
                  columnName: 'amount__isnull',
                })
                setWithoutRoles(event.target.checked)
              }}
              checked={withoutRoles}
            >
              <Text>Show rows without bonus</Text>
            </Switch>
          </TableWidget.Filters>
          <TableWidget.Table>
            <AdjustableTable<ReferralBonusInterface>
              name={TableNames.ReferralBonus}
              useWindowScroll
              dataType="Referral bonus"
              row={RowNotifications}
              {...table}
              noDataMessage="Referral bonuses will appear here."
            />
          </TableWidget.Table>
        </TableWidget>
      </PageBody>
    </PageWrapper>
  )
}
