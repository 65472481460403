import React, { PropsWithChildren } from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useIsNewTable } from '@components/TableV2/hooks'

export const ActionsCell = ({ children }: PropsWithChildren<{}>) => {
  const isNewTable = useIsNewTable()

  if (!isNewTable) {
    return <>{children}</>
  }

  return (
    <MoreBar maxCount={1} onClick={e => e.stopPropagation()}>
      {children}
    </MoreBar>
  )
}
