import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Cell,
  Color,
  Flex,
  Header,
  Image,
  Item,
  MoreBar,
  Popup,
  StatusPopup,
  Text,
  Token,
} from '@revolut/ui-kit'
import { Check, LockOpened, ExclamationTriangle, Pencil, Upload } from '@revolut/icons'

import {
  approveDepartmentCompensation,
  getCompensationCSV,
  unapproveDepartmentCompensation,
} from '@src/api/compensation'
import { EmployeeCompensationEditInterface } from '@src/interfaces/compensation'
import { formatDate, formatMoney } from '@src/utils/format'
import {
  employeeCompensationBonusGrantColumn,
  employeeCompensationBonusGrantRecommendationColumn,
  employeeCompensationCommentsColumn,
  employeeCompensationCurrentSalaryColumn,
  employeeCompensationEmployeeColumn,
  employeeCompensationFlagsColumn,
  employeeCompensationLatestGradeColumn,
  employeeCompensationLocationColumn,
  employeeCompensationNewSalaryColumn,
  employeeCompensationSalaryBandColumn,
  employeeCompensationSalaryChangeColumn,
  employeeCompensationSalaryChangePercentColumn,
  employeeCompensationSalaryChangeRecommendationColumn,
  employeeCompensationSeniorityColumn,
  employeeCompensationSpecialisationColumn,
} from '@src/constants/columns/compensation'
import SettingsButtons from '@src/features/SettingsButtons'
import { PermissionTypes } from '@src/store/auth/types'
import {
  AllocatedBudgetStats,
  BudgetDistributionHeader,
  EmptyState,
  SHOW_IN_LOCAL_CURRENCIES_KEY,
  ShowInLocalCurrenciesButton,
  useBudgetDistributionContext,
} from '@src/features/BudgetDistribution/BudgetDistribution'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ExportTypes } from '@src/constants/export'
import { ExportRequest } from '@src/interfaces'
import ExportMenu from '../ExportMenu/ExportMenu'
import { TableNames } from '@src/constants/table'
import { BudgetViewingRecommendationBanner } from '@src/pages/Forms/Budgets/EditDepartmentBudget/EditDepartmentBudget'

interface DistributionTableProps {
  navigation?: React.ReactElement
}

export const rowHasScheduledTermination = (row: EmployeeCompensationEditInterface) => {
  return row.termination != null
}

export const rowIneligibleForBonus = (row: EmployeeCompensationEditInterface) => {
  return !row.is_eligible_for_bonus
}

export const rowIneligibleForCompensation = (row: EmployeeCompensationEditInterface) => {
  return !row.is_eligible
}

export const scheduledForTerminationOrIneligibleHighlight = (
  data: EmployeeCompensationEditInterface,
) => {
  return rowHasScheduledTermination(data) || rowIneligibleForCompensation(data)
    ? Token.color.greyTone2
    : ''
}

export const budgetDistributionViewRow: RowInterface<EmployeeCompensationEditInterface> =
  {
    highlight: scheduledForTerminationOrIneligibleHighlight,
    cells: [
      {
        ...employeeCompensationEmployeeColumn,
        width: 200,
      },
      {
        ...employeeCompensationSeniorityColumn,
        width: 110,
      },
      {
        ...employeeCompensationSpecialisationColumn,
        width: 150,
      },
      {
        ...employeeCompensationLocationColumn,
        width: 100,
      },
      {
        ...employeeCompensationLatestGradeColumn,
        width: 100,
      },
      {
        ...employeeCompensationCurrentSalaryColumn,
        width: 100,
      },
      {
        ...employeeCompensationNewSalaryColumn,
        width: 100,
      },
      {
        ...employeeCompensationSalaryChangeColumn,
        width: 100,
      },
      {
        ...employeeCompensationSalaryChangePercentColumn,
        width: 100,
      },
      {
        ...employeeCompensationSalaryBandColumn,
        width: 150,
      },
      {
        ...employeeCompensationBonusGrantColumn,
        width: 100,
      },
      {
        ...employeeCompensationFlagsColumn,
        width: 120,
      },
      {
        ...employeeCompensationCommentsColumn,
        width: 150,
      },
      {
        ...employeeCompensationSalaryChangeRecommendationColumn,
        width: 300,
      },
      {
        ...employeeCompensationBonusGrantRecommendationColumn,
        width: 300,
      },
    ],
  }

export const BudgetDistributionTable = ({ navigation }: DistributionTableProps) => {
  const {
    selectedCompensationReview,
    table,
    isEmpty,
    currencyCode,
    showInLocalCurrencies,
    details,
  } = useBudgetDistributionContext()

  const [acceptPopupOpen, setAcceptPopupOpen] = useState(false)
  const [acceptPending, setAcceptPending] = useState(false)
  const [acceptSuccessOpen, setAcceptSuccessOpen] = useState(false)
  const [unlockPending, setUnlockPending] = useState(false)

  const totals = useMemo(() => {
    const emptyValues = {
      total_salary_budget: undefined,
      total_bonus_budget: undefined,
      allocated_salary_budget: undefined,
      allocated_bonus_budget: undefined,
      total_salary_budget_num: undefined,
      total_bonus_budget_num: undefined,
      allocated_salary_budget_num: undefined,
      allocated_bonus_budget_num: undefined,
    }

    if (
      !selectedCompensationReview ||
      !('exchanged' in selectedCompensationReview) ||
      selectedCompensationReview.exchanged?.total_salary_budget == null ||
      selectedCompensationReview.exchanged?.total_bonus_budget == null ||
      selectedCompensationReview.exchanged?.allocated_bonus_budget == null ||
      selectedCompensationReview.exchanged?.allocated_salary_budget == null
    ) {
      return emptyValues
    }

    return {
      total_salary_budget: formatMoney(
        selectedCompensationReview.exchanged.total_salary_budget,
        selectedCompensationReview.exchanged.target_currency.iso_code,
      ),
      total_salary_budget_num: selectedCompensationReview.exchanged.total_salary_budget,
      total_bonus_budget: formatMoney(
        selectedCompensationReview.exchanged.total_bonus_budget,
        selectedCompensationReview.exchanged.target_currency.iso_code,
      ),
      total_bonus_budget_num: selectedCompensationReview.exchanged.total_bonus_budget,
      allocated_salary_budget: formatMoney(
        selectedCompensationReview.exchanged.allocated_salary_budget,
        selectedCompensationReview.exchanged.target_currency.iso_code,
      ),
      allocated_salary_budget_num:
        selectedCompensationReview.exchanged.allocated_salary_budget,
      allocated_bonus_budget: formatMoney(
        selectedCompensationReview.exchanged.allocated_bonus_budget,
        selectedCompensationReview.exchanged.target_currency.iso_code,
      ),
      allocated_bonus_budget_num:
        selectedCompensationReview.exchanged.allocated_bonus_budget,
    }
  }, [selectedCompensationReview])

  const canEdit = details.data?.field_options?.permissions?.includes(
    PermissionTypes.ChangeDepartmentCompensationReview,
  )
  const canAccept = details.data?.field_options?.permissions?.includes(
    PermissionTypes.ApproveDepartmentCompensationReview,
  )
  const canUnlock = details.data?.field_options?.permissions?.includes(
    PermissionTypes.UnapproveDepartmentCompensationReview,
  )
  const isPending = details.data?.status.id === 'pending'
  const isApproved = details.data?.status.id === 'approved'

  const helperText = (() => {
    if (details.data?.department) {
      return {
        confirmationTitle: 'Department budgets successfully confirmed',
        finaliseTitle: `You're going to finalise the recommendations for department salaries and bonus budgets`,
      }
    }
    if (details.data?.budget_pool_config) {
      return {
        confirmationTitle: 'Custom pool budgets successfully confirmed',
        finaliseTitle: `You're going to finalise the recommendations for custom pool salaries and bonus budgets`,
      }
    }
    return {}
  })()

  return (
    <>
      {details.data && isApproved && (
        <Box mb="s-16">
          <Item>
            <Item.Avatar>
              <Image
                src="https://assets.revolut.com/assets/3d-images/3D151A.png"
                srcSet="https://assets.revolut.com/assets/3d-images/3D151A@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D151A@3x.png 3x"
              />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                Budget for this cycle was already allocated and accepted
              </Item.Title>
              {details.data.approved_by != null &&
                details.data?.approved_date_time != null && (
                  <Item.Description>
                    Performed by {details.data.approved_by.full_name} on{' '}
                    {formatDate(details.data.approved_date_time)}
                  </Item.Description>
                )}
            </Item.Content>
          </Item>
        </Box>
      )}

      <Cell>
        <Flex flexDirection="column" width="100%" id="comment-portal-target">
          {navigation}

          <BudgetDistributionHeader stats={<AllocatedBudgetStats {...totals} />} />

          <SettingsButtons mb="s-16">
            {canAccept && isPending && (
              <MoreBar.Action
                variant="primary"
                onClick={() => setAcceptPopupOpen(true)}
                useIcon={Check}
              >
                Finalise recommendations
              </MoreBar.Action>
            )}
            {canUnlock && isApproved && (
              <MoreBar.Action
                onClick={() => {
                  if (selectedCompensationReview) {
                    setUnlockPending(true)
                    unapproveDepartmentCompensation(selectedCompensationReview.id)
                      .then(() => details.refetch())
                      .finally(() => setUnlockPending(false))
                  }
                }}
                pending={unlockPending}
                useIcon={LockOpened}
              >
                Unlock recommendations
              </MoreBar.Action>
            )}
            {isPending && canEdit && (
              <MoreBar.Action
                onClick={() =>
                  navigateTo(
                    `${pathToUrl(ROUTES.FORMS.EDIT_DEPARTMENT_BUDGET, {
                      id: selectedCompensationReview?.id,
                      currencyCode,
                    })}${
                      showInLocalCurrencies ? `?${SHOW_IN_LOCAL_CURRENCIES_KEY}=true` : ''
                    }`,
                  )
                }
                useIcon={Pencil}
                disabled={isEmpty}
              >
                Edit budget
              </MoreBar.Action>
            )}
            <ShowInLocalCurrenciesButton />
            <MoreBar.Action
              onClick={() => {
                navigateTo(
                  pathToUrl(ROUTES.FORMS.IMPORT_DATA.DEPARTMENT_BUDGET.UPLOAD_FILE, {
                    id: details.data?.id,
                  }),
                )
              }}
              useIcon={Upload}
            >
              Import data
            </MoreBar.Action>
            {!!selectedCompensationReview && (
              <ExportMenu
                request={
                  (() =>
                    getCompensationCSV(
                      selectedCompensationReview.id,
                      currencyCode,
                    )) as ExportRequest
                }
                fileName={details.data?.department?.name || 'file'}
                supportedTypes={[ExportTypes.csv]}
              />
            )}
          </SettingsButtons>

          <BudgetViewingRecommendationBanner />

          <Flex style={{ position: 'relative' }} flex="1 0" mt="s-16">
            <AdjustableTable
              name={TableNames.BudgetDistribution}
              row={budgetDistributionViewRow}
              {...table}
              loading={isEmpty ? false : table.loading}
              useWindowScroll
              emptyState={<EmptyState showDepartmentDetail />}
            />
          </Flex>
        </Flex>
      </Cell>

      <Popup
        open={acceptPopupOpen}
        onClose={() => setAcceptPopupOpen(false)}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet" displayMode="inline">
          <Header.Actions>
            <ExclamationTriangle color={Color.BLUE} size={40} />
          </Header.Actions>
          <Text fontSize="h5">{helperText.finaliseTitle}</Text>
          <Header.Subtitle>
            <Box mt="s-16" mb="-s-16">
              That will be subject to review of the Compensation Team
            </Box>
          </Header.Subtitle>
        </Header>
        <Popup.Actions horizontal>
          <Button onClick={() => setAcceptPopupOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (selectedCompensationReview) {
                setAcceptPending(true)
                approveDepartmentCompensation(selectedCompensationReview.id)
                  .then(() => {
                    setAcceptSuccessOpen(true)
                    setAcceptPopupOpen(false)
                    details.refetch()
                  })
                  .finally(() => setAcceptPending(false))
              }
            }}
            pending={acceptPending}
            elevated
          >
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>

      <StatusPopup
        variant="success"
        open={acceptSuccessOpen}
        onClose={() => setAcceptSuccessOpen(false)}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>{helperText.confirmationTitle}</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}
