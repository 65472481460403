import React from 'react'
import { useTable } from '@components/Table/hooks'
import {
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { fieldChangelogRequisitionRequests } from '@src/api/changelog'
import {
  changelogChangedBy,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import { Statuses } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import AdjustableTable from '@components/Table/AdjustableTable'
import { Cell, Token } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { TableNames } from '@src/constants/table'

const FieldsChangelogRequisitionRow: RowInterface<
  ChangelogInterface<RequisitionInterface>
> = {
  highlight: data => {
    if (data.status?.id === Statuses.pending) {
      return Token.color.orange_5
    }
    return ''
  },
  noChildrenRequest: true,
  isNotNested: true,
  cells: [
    {
      ...changelogFieldsChangedFields,
      selectorsKey: selectorKeys.requisition_changelog_fields,
      width: 210,
    },
    {
      ...changelogFieldsNewValue,
      filterKey: null,
      width: 205,
    },
    {
      ...changelogChangedBy,
      width: 205,
    },
    {
      ...changelogEffectiveDate,
      title: 'Changed on',
      width: 205,
    },
    {
      ...changelogStatus,
      width: 205,
    },
  ],
}

const Changelog = () => {
  const { values } = useLapeContext<RequisitionInterface>()
  const initialFilter: FilterByInterface[] = [
    {
      columnName: 'target__id',
      nonResettable: true,
      filters: [{ id: values.id, name: values.full_name }],
    },
  ]

  const initialSort: SortByInterface[] = [
    {
      sortBy: 'effective_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const changelogFieldsTable = useTable<ChangelogInterface<RequisitionInterface>>(
    fieldChangelogRequisitionRequests,
    initialFilter,
    initialSort,
  )

  const handleRowClick = (change: ChangelogInterface<RequisitionInterface>) => {
    navigateReplace(
      `${pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: values.id })}?change=${
        change.id
      }`,
    )
  }

  return (
    <Cell mt="s-32">
      <AdjustableTable<ChangelogInterface<RequisitionInterface>>
        name={TableNames.RequisitionChangelog}
        useWindowScroll
        dataType="Data Change"
        row={FieldsChangelogRequisitionRow}
        {...changelogFieldsTable}
        onRowClick={handleRowClick}
        noDataMessage="There are no changes to display"
      />
    </Cell>
  )
}

export default Changelog
