import { RowInterface, FilterByInterface } from '@src/interfaces/data'
import { IssueInterface, IssuesCounts } from '@src/interfaces/issues'
import {
  issueLinkColumn,
  issueSummaryColumn,
  issueAssigneeColumn,
  issueOpenDateColumn,
  issueDueDateColumn,
  issueSeverityColumn,
  issueDaysLeftColumn,
  issueTeamColumn,
  issueTypeColumn,
  issueStatusColumn,
} from '@src/constants/columns/issues'
import { TableWidget, Token, Flex, FilterButton } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import React, { useState } from 'react'
import { useTableReturnType } from '@components/Table/hooks'
import Stat from '@components/Stat/Stat'
import { AuditStatuses } from '@src/interfaces/audit'

interface IssuesTableProps {
  table: useTableReturnType<IssueInterface>
  name: TableNames
  counts?: IssuesCounts
  showTeams?: boolean
  showIssueType?: boolean
  showIssueStatus?: boolean
}

const row: RowInterface<IssueInterface> = {
  highlight: data => {
    if (data.severity?.id === 'blocker' || data.severity?.id === 'critical') {
      return Token.color.inputError
    }
    return ''
  },
  cells: [
    {
      ...issueLinkColumn,
      width: 200,
    },
    {
      ...issueTypeColumn,
      width: 200,
    },
    {
      ...issueSummaryColumn,
      width: 400,
    },
    {
      ...issueTeamColumn,
      width: 200,
    },
    {
      ...issueAssigneeColumn,
      width: 200,
    },
    {
      ...issueOpenDateColumn,
      width: 200,
    },
    {
      ...issueDueDateColumn,
      width: 200,
    },
    {
      ...issueSeverityColumn,
      width: 200,
    },
    {
      ...issueDaysLeftColumn,
      width: 200,
    },
    {
      ...issueStatusColumn,
      width: 200,
    },
  ],
}

export const IssuesTable = ({
  table,
  name,
  counts,
  showTeams = true,
  showIssueType = true,
  showIssueStatus = false,
}: IssuesTableProps) => {
  const [showHistory, setShowHistory] = useState(false)

  const getFilter = (enabled: boolean): FilterByInterface => ({
    filters: enabled
      ? [
          { id: AuditStatuses.PENDING_FILING, name: AuditStatuses.PENDING_FILING },
          { id: AuditStatuses.QUALITY_REVIEW, name: AuditStatuses.QUALITY_REVIEW },
          { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
          { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
          { id: AuditStatuses.DONE, name: AuditStatuses.DONE },
        ]
      : [
          { id: AuditStatuses.PENDING_FILING, name: AuditStatuses.PENDING_FILING },
          { id: AuditStatuses.QUALITY_REVIEW, name: AuditStatuses.QUALITY_REVIEW },
          { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
          { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
        ],
    columnName: 'status',
    nonResettable: true,
  })

  const onShowHistoricData = () => {
    setShowHistory(prev => {
      const newShowHistory = !prev
      table.onFilterChange(getFilter(newShowHistory))
      return newShowHistory
    })
  }

  return (
    <>
      {counts ? (
        <TableWidget.Info>
          <Stat label="Total" val={table.count} />
          <Stat label="Blocker" color={Token.color.red} val={counts.blocker} />
          <Stat label="Critical" color={Token.color.red} val={counts.critical} />
          <Stat label="Major" color={Token.color.warning} val={counts.major} />
          <Stat label="Medium" color={Token.color.yellow} val={counts.medium} />
          <Stat label="Minor" color={Token.color.greyTone50} val={counts.minor} />
        </TableWidget.Info>
      ) : null}
      <TableWidget.Search>
        <Flex justifyContent="flex-end">
          <FilterButton onClick={onShowHistoricData} active={showHistory}>
            Show historic data
          </FilterButton>
        </Flex>
      </TableWidget.Search>
      <TableWidget.Table>
        <AdjustableTable<IssueInterface>
          dataType="Issue"
          name={name}
          row={row}
          useWindowScroll
          hiddenCells={{
            [issueTeamColumn.idPoint]: !showTeams,
            [issueTypeColumn.idPoint]: !showIssueType,
            [issueStatusColumn.idPoint]: !showIssueStatus,
          }}
          {...table}
        />
      </TableWidget.Table>
    </>
  )
}
