import React, { useEffect, useState } from 'react'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import {
  Highlights,
  TabBar,
  HStack,
  Text,
  StatusWidget,
  Item,
  Image,
  VStack,
  Token,
} from '@revolut/ui-kit'
import Stat from '@src/components/Stat/Stat'
import { RoundSelector } from '@src/apps/People/Engagement/Results/components/RoundSelector'
import { IdAndName } from '@src/interfaces'
import { useSurveyRounds } from '@src/apps/People/Engagement/Results/hooks'
import Loader from '@src/components/CommonSC/Loader'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { ScoreType, ViewType } from '../types'
import { useLocation, useParams } from 'react-router-dom'
import {
  useEngagementResultStats,
  useEngagementSurveyParticipationStats,
  useEngagementSurveyRounds,
} from '@src/api/engagement'
import {
  overallScoreToColor,
  participationRateToColor,
} from '@src/apps/People/Engagement/helpers'
import { isNumber, round } from 'lodash'
import { TableComponent } from './TableComponent'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { TabTooltip } from './TabTooltip'
import { SurveySelector } from '@src/apps/People/Engagement/Results/components/SurveySelector'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { PermissionTypes } from '@src/store/auth/types'
import { formatPercentage } from '@src/utils/format'
import Table from '@src/components/TableV2/Table'

interface Props {
  entity: {
    type: EngagementResultsScope
    id: number
    permissions: string[] | undefined
  }
}

const entityToRouteMap: Record<EngagementResultsScope, string> = {
  [EngagementResultsScope.Departments]: ROUTES.FORMS.DEPARTMENT.ENGAGEMENT.ANY,
  [EngagementResultsScope.Teams]: ROUTES.FORMS.TEAM.ENGAGEMENT.ANY,
  [EngagementResultsScope.Roles]: ROUTES.FORMS.ROLE.ENGAGEMENT.ANY,
  [EngagementResultsScope.Functions]: ROUTES.FORMS.FUNCTION.ENGAGEMENT.ANY,
  [EngagementResultsScope.Specialisations]: ROUTES.FORMS.SPECIALISATIONS.ENGAGEMENT.ANY,
  [EngagementResultsScope.Employees]:
    ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.ANY,
  [EngagementResultsScope.Company]: ROUTES.FORMS.COMPANY.ENGAGEMENT.ANY,
}

export const SurveyResultsPublished = ({ entity }: Props) => {
  const { state } = useLocation<{ roundId?: number; surveyId?: number }>()
  const [selectedSurvey, setSelectedSurvey] = useState<IdAndName>()
  const [selectedRound, setSelectedRound] = useState<IdAndName>()
  const { rounds: options, asyncState } = useSurveyRounds(selectedSurvey?.id)
  const { subtab: tableType = 'categories' } =
    useParams<{ subtab: 'categories' | 'questions' | 'comments' }>()
  const [type, setType] = useState<ScoreType>('engagement')
  const [view] = useState<ViewType>('table')
  const {
    data: stats,
    refetch,
    isLoading,
  } = useEngagementResultStats(selectedSurvey?.id, selectedRound?.id)
  const {
    data: participationStats,
    isLoading: isParticipationLoading,
    isError,
  } = useEngagementSurveyParticipationStats({
    surveyRoundId: selectedRound?.id,
    surveyId: selectedSurvey?.id,
    entityType: entity.type,
    entityId: entity.id,
  })

  const { data: rounds, isLoading: isRoundsLoading } = useEngagementSurveyRounds(
    selectedSurvey?.id,
  )

  const { asyncState: surveysOptionsAsyncState, options: surveyOptionsRaw } =
    useFetchOptions<IdAndName>(selectorKeys.engagement_survey_titles_sorted)
  const surveysOptions = surveyOptionsRaw.map(({ value }) => value)

  useEffect(() => {
    if (asyncState === 'ready') {
      let initRound = options.at(0)
      if (state?.roundId) {
        const preselectedRound = options.find(option => option.id === state.roundId)
        if (preselectedRound) {
          initRound = preselectedRound
        }
      }
      setSelectedRound(initRound)
    }
  }, [asyncState, rounds])

  useEffect(() => {
    if (surveysOptionsAsyncState === 'ready') {
      let initSurvey = surveysOptions.at(0)
      if (state?.surveyId) {
        const preselectedSurvey = surveysOptions.find(
          option => option.id === state.surveyId,
        )
        if (preselectedSurvey) {
          initSurvey = preselectedSurvey
        }
      }
      setSelectedSurvey(initSurvey)
    }
  }, [surveysOptionsAsyncState])

  useEffect(() => {
    if (selectedRound) {
      refetch()
    }
  }, [selectedRound])

  const canSeeComments = !!entity.permissions?.includes(
    PermissionTypes.ViewEngagementComments,
  )

  if ([surveysOptionsAsyncState, asyncState].includes('pending')) {
    return <Loader />
  }

  if (!surveysOptions.length && surveysOptionsAsyncState === 'ready') {
    return (
      <StatusWidget>
        <StatusWidget.Image
          src="https://assets.revolut.com/assets/3d-images/3D086.png"
          srcSet="https://assets.revolut.com/assets/3d-images/3D086@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D086@3x.png 3x"
        />
        <StatusWidget.Title>No surveys found</StatusWidget.Title>
      </StatusWidget>
    )
  }

  return (
    <VStack space="s-16">
      {stats && !stats.can_show_details && (
        <Item>
          <Item.Avatar>
            <Image src="https://assets.revolut.com/assets/3d-images/3D173a.png" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Some results are hidden to protect anonymity</Item.Title>
            <Item.Description>
              This is because there are not enough answers submitted.
            </Item.Description>
          </Item.Content>
        </Item>
      )}
      <Table.Widget>
        <Table.Widget.Info>
          <Highlights>
            <SurveySelector
              value={selectedSurvey}
              options={surveysOptions}
              asyncState={surveysOptionsAsyncState}
              onChange={value => {
                if (value?.id) {
                  setSelectedSurvey(value)
                  setSelectedRound(undefined)
                }
              }}
            />
            <RoundSelector
              showHorizontalNavigation={false}
              value={selectedRound}
              surveyId={selectedSurvey?.id}
              onChange={value => {
                if (value?.id) {
                  setSelectedRound(value)
                }
              }}
            />
            <Stat
              color={overallScoreToColor(stats?.nps_score)}
              val={
                isLoading
                  ? undefined
                  : stats?.nps_score
                  ? round(stats.nps_score, 2)
                  : 'N/A'
              }
              tooltip="The NPS (net promoter score) is equal to the percentage of promoters minus the percentage of detractors, it ranges from -100 to +100"
              label="NPS score"
            />
            <Stat
              val={
                isError || !selectedRound
                  ? 'N/A'
                  : isLoading || isParticipationLoading || isRoundsLoading
                  ? undefined
                  : participationStats?.audience_size
              }
              label="Audience size"
              tooltip="This is the number of active employees who were requested to complete the survey"
            />
            <Stat
              tooltip="This is based on the amount of employees who finished the survey (i.e. who answered all questions)"
              val={
                isError || !selectedRound
                  ? 'N/A'
                  : isLoading || isRoundsLoading
                  ? undefined
                  : formatPercentage(participationStats?.participation_rate || null, 2)
              }
              label="Participation"
              color={
                !participationStats?.participation_rate
                  ? Token.color.foreground
                  : participationRateToColor(
                      Math.round(
                        isNumber(participationStats?.participation_rate)
                          ? participationStats!.participation_rate * 100
                          : 0,
                      ),
                    )
              }
            />
          </Highlights>
        </Table.Widget.Info>
        <Table.Widget.Filters>
          <HStack gap="s-16">
            {/* 
          TODO https://revolut.atlassian.net/browse/REVC-6347
          at the moment we just display table version
          <TabBar
            variant="segmented fit"
            mx="auto"
            value={view}
            onChange={value => {
              setView(value || 'table')
            }}
          >
            <TabBar.Item to="chart" useIcon="AvatarGrid" />
            <TabBar.Item to="table" useIcon="Menu" />
          </TabBar> */}
            {tableType !== 'comments' ? (
              <TabBar
                variant="segmented fit"
                mx="auto"
                value={type}
                onChange={value => {
                  setType(value || 'engagement')
                }}
              >
                <TabBar.Item to="engagement">
                  <HStack space="s-8">
                    <Text>NPS</Text>
                    <TabTooltip text="The NPS (net promoter score) is equal to the percentage of promoters minus the percentage of detractors, it ranges from -100 to +100" />
                  </HStack>
                </TabBar.Item>
                <TabBar.Item to="average">
                  <HStack space="s-8">
                    <Text>Average</Text>
                    <TabTooltip text="Average score is equal to the average of all answers for each particular question or driver, it ranges from 1 to 5" />
                  </HStack>
                </TabBar.Item>
              </TabBar>
            ) : null}
          </HStack>
        </Table.Widget.Filters>
        <Table.Widget.Actions>
          <RadioSelectInput<IdAndName<'categories' | 'questions' | 'comments'>>
            inputProps={{ width: 240 }}
            label="Analyse"
            searchable={false}
            value={{ id: tableType, name: tableType }}
            options={[
              { value: { id: 'categories', name: 'Categories' }, label: 'Categories' },
              { value: { id: 'questions', name: 'Questions' }, label: 'Questions' },
              ...(canSeeComments
                ? [
                    {
                      value: { id: 'comments' as const, name: 'Comments' },
                      label: 'Comments',
                    },
                  ]
                : []),
            ]}
            onChange={value => {
              if (value) {
                navigateReplace(
                  pathToUrl(entityToRouteMap[entity.type], {
                    id: entity.id,
                    subtab: value.id,
                  }),
                )
              }
            }}
          />
        </Table.Widget.Actions>
        <Table.Widget.Table>
          {!selectedRound && !isRoundsLoading ? (
            <StatusWidget>
              <StatusWidget.Image src="https://assets.revolut.com/assets/3d-images-v2/3D018.png" />
              <StatusWidget.Title>Select Survey and Round</StatusWidget.Title>
              <StatusWidget.Description>
                In order to show results table select survey and round first
              </StatusWidget.Description>
            </StatusWidget>
          ) : (
            selectedSurvey && (
              <TableComponent
                scoreType={type}
                view={view}
                selectedRound={selectedRound}
                surveyId={selectedSurvey?.id}
                entity={entity}
                canSeeComments={canSeeComments}
              />
            )
          )}
        </Table.Widget.Table>
      </Table.Widget>
    </VStack>
  )
}
