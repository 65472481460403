import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Box, VStack } from '@revolut/ui-kit'

import {
  PerformanceSelector,
  ReviewCategory,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { OptionInterface } from '@src/interfaces/selectors'
import { useGetProbationCheckpoints } from '@src/api/probationReview'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import { CheckpointType } from '@src/interfaces/probationReview'
import { useGetPipCheckpoints } from '@src/api/pip'
import CheckpointsFilter from './CheckpointsFilter'
import ManagerRecommendations from './ManagerRecommendations'
import ReviewersFilter from './ReviewersFilter'
import { useFetchPerformanceSummary } from './hooks'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { getReviewsUrl } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/common'
import SidebarFeedbacks from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SidebarFeedbacks'
import { FeedbackSection } from '@src/pages/EmployeeProfile/Layout/Performance/FeedbackSection'
import { AnytimeFeedbackListInterface } from '@src/interfaces/anytimeFeedback'
import { SummaryReviewTables } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewTables'
import { BarRaiserSummary } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserSummary'
import { GoalsSummaryTable } from './GoalsSummaryTable'
import { QuickFilter } from './Filter'
import SidebarSkeleton from './SidebarSkeleton'
import { useNewPerformanceSummary } from '@src/pages/EmployeeProfile/Layout/Performance/useNewPerformanceSummary'
import { SummaryReviewCollapsableTables } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewCollapsableTables'
import { GoalsSummaryCollapsableTable } from './GoalsSummaryCollapsableTable'
import { BarRaiserSummaryCollapsable } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserSummaryCollapsable'
import ManagerRecommendationsCollapsable from './ManagerRecommendationsCollapsable'

export interface SummaryWidgetProps {
  cycleId?: string
  cycleName?: string
  category?: ReviewCategory
  predefinedCheckpointFilter?: OptionInterface[]
  predefinedReviewer?: boolean
  employeeName?: string
  employeeSeniority?: string
  employeeSpecialisation?: string
  onClickRequestReview?: () => void
  narrativeCell?: React.ReactNode
  employee: EmployeeInterface
  stats?: EmployeeStats
  selectedPeriod: PerformanceSelector
  periodBeforeOngoing?: PerformanceSelector
  setPerformanceLink: Dispatch<SetStateAction<string | undefined>>
  feedback?: AnytimeFeedbackListInterface
  refetchFeedbackList: () => void
}

const SummaryWidget = ({
  cycleId,
  category,
  predefinedCheckpointFilter,
  predefinedReviewer = false,
  employee,
  stats,
  selectedPeriod,
  periodBeforeOngoing,
  setPerformanceLink,
  feedback,
  refetchFeedbackList,
}: SummaryWidgetProps) => {
  const isNewPerformanceSummary = useNewPerformanceSummary()
  const [filters, setFilters] = useState<OptionInterface[]>()

  const [activeFilters, setActiveFilters] = useState<QuickFilter[]>([])
  const [data, setData] = useState<ReviewSummaryInterface | undefined>()
  const [checkpointFilters, setCheckpointFilters] = useState<
    OptionInterface[] | undefined
  >(predefinedCheckpointFilter)

  const employeeId = employee.id

  const { deleteQueryParam } = useQuery()
  const { data: checkpoints } = useGetProbationCheckpoints(
    category === ReviewCategory.Probation ? employeeId || null : null,
    cycleId === undefined ? null : cycleId,
  )

  const { data: pipCheckpoints } = useGetPipCheckpoints(
    category === ReviewCategory.PIP_V2 ? employeeId || null : null,
    cycleId === undefined ? null : cycleId,
  )

  const {
    data: currentData,
    reviews,
    refresh: refreshSummary,
    isLoading,
  } = useFetchPerformanceSummary(cycleId, employeeId, category)

  const { data: previousPeriodData } = useFetchPerformanceSummary(
    periodBeforeOngoing?.id ? String(periodBeforeOngoing.id) : undefined,
    employeeId,
    category,
  )

  const canSeeRecommendations = !!checkpoints?.checkpoints?.filter?.(
    c => c.checkpoint_type === CheckpointType.Recommendation,
  )?.length

  useEffect(() => {
    if (currentData) {
      setData(currentData)
    }
  }, [currentData])

  useEffect(() => {
    if (!category || cycleId === undefined || predefinedReviewer) {
      return
    }

    deleteQueryParam(Queries.CheckpointNumber)
    deleteQueryParam(Queries.ReviewerId)
    setFilters(undefined)
    if (predefinedCheckpointFilter) {
      setCheckpointFilters(predefinedCheckpointFilter)
      refreshSummary(undefined, predefinedCheckpointFilter)
    } else {
      setCheckpointFilters(undefined)
      refreshSummary()
    }
  }, [cycleId, employeeId])

  const showPerformanceLink = !!category && !!cycleId
  if (data?.reviews?.length && showPerformanceLink) {
    const reviewLink = getReviewsUrl(category, cycleId, employee.id)
    setPerformanceLink(reviewLink)
  }

  const handleReviewerFilterChange = (options?: OptionInterface[]) => {
    setFilters(options)
    refreshSummary(options, checkpointFilters, false)
  }

  const handleCheckpointsFilterChange = (options?: OptionInterface[]) => {
    setCheckpointFilters(options)
    refreshSummary(filters, options, false)
  }

  return (
    <Box>
      {!!data?.reviews?.length && (
        <ReviewersFilter
          isNewDesign={isNewPerformanceSummary}
          setActiveFilters={setActiveFilters}
          data={reviews}
          onFilterChange={handleReviewerFilterChange}
          showFilters={
            category === ReviewCategory.Performance ||
            category === ReviewCategory.Probation ||
            category === ReviewCategory.PIP_V2 ||
            category === ReviewCategory.Upwards
          }
        />
      )}

      {category === ReviewCategory.Probation &&
        !!data?.reviews &&
        !predefinedCheckpointFilter && (
          <Box mt="s-16">
            <CheckpointsFilter
              isNewDesign={isNewPerformanceSummary}
              checkpoints={checkpoints?.checkpoints}
              onFilterChange={handleCheckpointsFilterChange}
              showFilters
            />
          </Box>
        )}

      {category === ReviewCategory.PIP_V2 && !predefinedCheckpointFilter && (
        <Box mt="s-16">
          <CheckpointsFilter
            isNewDesign={isNewPerformanceSummary}
            checkpoints={pipCheckpoints?.checkpoints}
            onFilterChange={handleCheckpointsFilterChange}
            showFilters
          />
        </Box>
      )}

      {isLoading && !data ? (
        <SidebarSkeleton />
      ) : (
        <>
          {!data?.reviews?.length ? (
            <Box my="s-16">
              {isNewPerformanceSummary ? (
                <GoalsSummaryCollapsableTable
                  employee={employee}
                  selectedPeriod={selectedPeriod}
                  employeeStats={stats}
                  reviewSummary={data}
                  ratings={data?.summary?.deliverables?.ratings}
                  lineManagerId={employee.line_manager?.id}
                  calculatedDeliverablesRating={
                    data?.summary?.calculated_deliverables_ratings?.recommended_rating ||
                    data?.summary?.deliverables?.rating
                  }
                />
              ) : (
                <GoalsSummaryTable
                  employee={employee}
                  selectedPeriod={selectedPeriod}
                  employeeStats={stats}
                  reviewSummary={data}
                />
              )}
              {category === ReviewCategory.Performance ||
                (category === ReviewCategory.Probation &&
                  !!previousPeriodData?.reviews?.length &&
                  previousPeriodData.summary && (
                    <Box my="s-16">
                      {isNewPerformanceSummary ? (
                        <SummaryReviewCollapsableTables
                          lineManagerId={employee.line_manager?.id}
                          activeFilters={activeFilters}
                          reviewData={previousPeriodData.summary}
                          reviewValues={previousPeriodData}
                          cycleId={cycleId}
                          isPreviousValues
                          hideExpectations
                        />
                      ) : (
                        <SummaryReviewTables
                          reviewData={previousPeriodData.summary}
                          reviewValues={previousPeriodData}
                          cycleId={cycleId}
                          isPreviousValues
                          hideExpectations
                        />
                      )}

                      {data?.summary &&
                        (isNewPerformanceSummary ? (
                          <BarRaiserSummaryCollapsable data={data.summary} hideBanner />
                        ) : (
                          <BarRaiserSummary data={data.summary} hideBanner />
                        ))}
                    </Box>
                  ))}
            </Box>
          ) : (
            <>
              {data.summary && (
                <Box my="s-16">
                  <VStack space="s-24">
                    {isNewPerformanceSummary ? (
                      <SummaryReviewCollapsableTables
                        lineManagerId={employee.line_manager?.id}
                        activeFilters={activeFilters}
                        reviewData={data.summary}
                        reviewValues={data}
                        cycleId={cycleId}
                        hideExpectations
                      />
                    ) : (
                      <SummaryReviewTables
                        reviewData={data.summary}
                        reviewValues={data}
                        cycleId={cycleId}
                        hideExpectations
                      />
                    )}
                    {isNewPerformanceSummary ? (
                      <BarRaiserSummaryCollapsable data={data.summary} hideBanner />
                    ) : (
                      <BarRaiserSummary data={data.summary} hideBanner />
                    )}
                  </VStack>
                </Box>
              )}
              {canSeeRecommendations &&
                checkpoints?.decision_checkpoints &&
                !data.summary?.recommendation_extra_sections?.length &&
                (isNewPerformanceSummary ? (
                  <ManagerRecommendationsCollapsable
                    cycleId={cycleId}
                    employeeId={employeeId}
                    wide={false}
                  />
                ) : (
                  <ManagerRecommendations
                    cycleId={cycleId}
                    employeeId={employeeId}
                    wide={false}
                  />
                ))}
            </>
          )}
        </>
      )}

      <FeedbackSection
        isTabBar={isNewPerformanceSummary}
        data={employee}
        feedback={feedback}
        refetchFeedbackList={refetchFeedbackList}
        performanceContent={
          data?.summary?.overall_feedbacks.length ? (
            <SidebarFeedbacks
              feedbacks={data.summary.overall_feedbacks}
              ratings={data.summary.ratings}
              renderTitle={false}
            />
          ) : undefined
        }
      />
    </Box>
  )
}

export default SummaryWidget
