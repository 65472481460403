import React from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import General from '@src/pages/Forms/OfferCreation/OfferCreation'
import Preview, { getSigningStatusText } from '@src/pages/Forms/OfferCreation/Preview'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { Link, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import { offerCreationFormRequest } from '@src/api/offerCreation'
import { useGetCandidate } from '@src/api/recruitment/interviews'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Box, chain, Link as UIKitLink, Text, Color } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OfferCreationInterface, SigningStatuses } from '@src/interfaces/offerCreation'
import { Status } from '@components/CommonSC/General'
import Changelog from '@src/pages/Forms/OfferCreation/Changelog'
import { useGetOfferSettings } from '@src/api/settings'

const SigningStatus = ({
  status,
  signingEnabled,
}: {
  status: SigningStatuses
  signingEnabled: boolean
}) => {
  if (!status) {
    return null
  }

  const signingStatus = getSigningStatusText(status, signingEnabled)

  if (!signingStatus) {
    return null
  }

  let color

  switch (status) {
    case 'pending_candidate_signature':
      color = Color.ORANGE
      break
    case 'offer_signed':
      color = Color.GREEN
      break
    case 'offer_expired':
    case 'offer_declined_recruiter':
    case 'offer_declined_candidate':
    case 'offer_cancelled_recruiter':
      color = Color.RED
      break
    default:
      color = Color.FOREGROUND
  }

  return <Text color={color}>{signingStatus}</Text>
}

const OfferCreationIndex = () => {
  const { candidateId, id } = useParams<{ candidateId: string; id?: string }>()
  const { values, initialValues, reset } = useLapeContext<OfferCreationInterface>()
  const { data: candidate, isLoading: candidateLoading } = useGetCandidate(candidateId)
  const { data: offerSettings } = useGetOfferSettings()

  const isFormRoute = !!useRouteMatch(ROUTES.FORMS.OFFER_CREATION.GENERAL)

  const onRefresh = async () => {
    const resp = await offerCreationFormRequest.get({ id })
    reset(resp.data)
  }

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.FORMS.OFFER_CREATION.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.OFFER_CREATION.PREVIEW, { candidateId, id }),
      component: <Preview onRefresh={onRefresh} />,
    },
    {
      title: 'Changelog',
      path: ROUTES.FORMS.OFFER_CREATION.CHANGELOG,
      to: pathToUrl(ROUTES.FORMS.OFFER_CREATION.CHANGELOG, { candidateId, id }),
      component: <Changelog />,
    },
  ]

  let title: React.ReactNode[] | string = 'Create a new offer'

  if (values.id) {
    title = chain(
      `${values.candidate_first_name} ${values.candidate_last_name} offer letter`,
      <Status status={initialValues.status!}>{initialValues.status}</Status>,
      values.signing_status && values.signing_status !== 'hired' ? (
        <SigningStatus
          status={values.signing_status}
          signingEnabled={!!offerSettings?.enable_offer_signing}
        />
      ) : undefined,
    )
  }

  if (!offerSettings?.enable_offer_templates) {
    title = 'Hire candidate'
  }

  return (
    <PageWrapper>
      <PageHeader
        title={title}
        backUrl={
          id
            ? pathToUrl(ROUTES.FORMS.OFFER_CREATION.PREVIEW, { id, candidateId })
            : pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                id: candidateId,
              })
        }
        subtitle={
          candidate ? (
            <UIKitLink
              use={Link}
              to={pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                id: candidate.id,
              })}
              target="_blank"
              color="inherit"
            >
              Candidate: {candidate.full_name}
            </UIKitLink>
          ) : null
        }
      >
        {isFormRoute ? null : (
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={tabs} />
          </Box>
        )}
      </PageHeader>

      <Switch>
        <Route exact path={ROUTES.FORMS.OFFER_CREATION.GENERAL}>
          <General candidate={candidate} candidateLoading={candidateLoading} />
        </Route>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            {tab.component}
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={offerCreationFormRequest}>
    <OfferCreationIndex />
  </Form>
))
