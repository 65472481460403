import { Box, Token } from '@revolut/ui-kit'
import css from '@styled-system/css'
import styled from 'styled-components'
import * as SS from 'styled-system'

import { FEATURE_ITEMS_STYLES } from './constants'
import { LAYOUT_MAX_WIDTH } from '@src/pages/Landing/styles'

interface FeatureItemsBaseProps {
  contentAlign?: string
  coverAlign?: string
}

export const FeatureItemsBlockBase = styled(Box)<FeatureItemsBaseProps>(
  ({ coverAlign = 'center' }) =>
    css({
      position: 'relative',
      width: '100%',
      maxWidth: LAYOUT_MAX_WIDTH,
      textAlign: 'start',
      paddingTop: FEATURE_ITEMS_STYLES.paddingTop,
      paddingBottom:
        !coverAlign || coverAlign === 'center' ? FEATURE_ITEMS_STYLES.all.padding : 0,
      [`@media ${Token.media.lg}`]: {
        paddingBottom: FEATURE_ITEMS_STYLES.lg.padding,
      },
      [`@media ${Token.media.xxl}`]: {
        paddingBottom: FEATURE_ITEMS_STYLES.xxl.padding,
      },
    }),
  SS.variant({
    prop: 'contentAlign',
    variants: {
      left: {
        display: 'grid',
        gridTemplateAreas: [`'content' 'media'`, null, null, `'content media'`],
        gridTemplateRows: ['auto 1fr', null, null, 'initial'],
        gridTemplateColumns: ['1fr', null, null, '488px 450px', null, '696px 600px'],
        justifyContent: 'space-between',
        textAlign: ['start', null, 'center', 'start'],
      },
      center: {
        display: 'grid',
        gridTemplateAreas: `'content' 'media'`,
        gridTemplateRows: 'auto 1fr',
        gridTemplateColumns: 'auto',
        alignContent: 'center',
        textAlign: ['start', null, 'center'],
      },
      right: {
        display: 'grid',
        gridTemplateAreas: [`'content' 'media'`, null, null, `'media content'`],
        gridTemplateRows: ['auto 1fr', null, null, 'initial'],
        gridTemplateColumns: ['1fr', null, null, '450px 488px ', null, '600px 696px'],
        justifyContent: 'space-between',
        textAlign: ['start', null, 'center', 'start'],
      },
    },
  }),
)
