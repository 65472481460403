import React from 'react'
import { ActionButton, TableWidget } from '@revolut/ui-kit'

import { EmployeeInterface, RightToWorkInterface } from '@src/interfaces/employees'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { getRightsToWork } from '@src/api/employees'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import {
  rightToWorkCountryColumn,
  rightToWorkDocumentType,
  rightToWorkIsSponsoredByCompany,
  rightToWorkLastVerifiedOn,
  rightToWorkStatus,
  rightToWorkType,
  rightToWorkUpdatedOn,
  rightToWorkValidFrom,
  rightToWorkValidUntil,
} from '@src/constants/columns/rightToWork'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<RightToWorkInterface> = {
  cells: [
    {
      ...rightToWorkCountryColumn,
      width: 140,
    },
    {
      ...rightToWorkDocumentType,
      width: 140,
    },
    {
      ...rightToWorkValidFrom,
      width: 140,
    },
    {
      ...rightToWorkValidUntil,
      width: 140,
    },
    {
      ...rightToWorkType,
      width: 140,
    },
    {
      ...rightToWorkIsSponsoredByCompany,
      width: 140,
    },
    {
      ...rightToWorkLastVerifiedOn,
      width: 140,
    },
    {
      ...rightToWorkUpdatedOn,
      width: 140,
    },
    {
      ...rightToWorkStatus,
      width: 140,
    },
  ],
}

interface Props {
  data: EmployeeInterface
}

export const RightToWork = ({ data }: Props) => {
  const table = useTable<RightToWorkInterface>({
    getItems: getRightsToWork(data.id),
  })

  const handleNewRowClick = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_RIGHT_TO_WORK.GENERAL, {
        employeeId: data.id,
      }),
    )
  }

  return (
    <TableWidget>
      <TableWidget.Actions>
        <ActionButton onClick={handleNewRowClick} mr="s-16" useIcon="Plus">
          Add right to work
        </ActionButton>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable<RightToWorkInterface>
          name={TableNames.EmployeeRightToWork}
          useWindowScroll
          dataType="Right to work"
          row={ROW}
          {...table}
          noDataMessage="No right to work"
          onRowClick={rightToWork =>
            navigateTo(
              pathToUrl(ROUTES.FORMS.EMPLOYEE_RIGHT_TO_WORK.GENERAL, {
                employeeId: data.id,
                id: rightToWork.id,
              }),
            )
          }
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
