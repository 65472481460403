import React, { useMemo, useRef, useState } from 'react'
import {
  Button,
  Flex,
  H4,
  Skeleton,
  VStack,
  IconButton,
  Box,
  Token,
} from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import FilterSelect, {
  FilterSelectType,
} from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { AsyncState } from '@components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'

type Props = {
  onChange: (option: IdAndName) => void
  value: IdAndName | undefined
  options: IdAndName[]
  asyncState: AsyncState
}

export const SurveySelector = ({ onChange, value, options, asyncState }: Props) => {
  const [open, setOpen] = useState(false)
  const selectRef = useRef<HTMLButtonElement>(null)
  const isNewLayout = useIsNewLayout()

  const currentValueIdx = useMemo(() => {
    return options.findIndex(survey => survey?.id === value?.id)
  }, [options])

  if (asyncState !== 'ready') {
    return (
      <VStack>
        <Skeleton width={72} height={20} my="s-4" />
        <Skeleton width={36} height={12} my="s-4" ml="s-2" />
      </VStack>
    )
  }
  const surveysAvailable = !!options.length

  const isOnNextClickEnabled = currentValueIdx > 0
  const onNextSurveyClick = () => {
    if (isOnNextClickEnabled) {
      onChange(options[currentValueIdx - 1])
    }
  }

  const isOnPrevClickEnabled = currentValueIdx < options.length - 1
  const onPrevSurveyClick = () => {
    if (isOnPrevClickEnabled) {
      onChange(options[currentValueIdx + 1])
    }
  }

  return (
    <Flex>
      {surveysAvailable && !isNewLayout && (
        <Box py="s-2" pr="s-4">
          <IconButton
            disabled={!isOnPrevClickEnabled}
            useIcon="ChevronLeft"
            onClick={onPrevSurveyClick}
            color={isOnPrevClickEnabled ? Token.color.foreground : Token.color.grey20}
          />
        </Box>
      )}
      <Stat
        label="Survey"
        val={
          <Button
            ref={selectRef}
            onClick={() => setOpen(!open)}
            variant="text"
            height="fit-content"
            p={0}
          >
            <Flex>
              <H4
                use="span"
                fontWeight="bold"
                color={surveysAvailable ? Token.color.foreground : Token.color.greyTone50}
              >
                {value?.name || 'N/A'}
              </H4>
              <FilterSelect
                open={open}
                selector={() => Promise.resolve({ options })}
                anchorRef={selectRef}
                onClose={() => {
                  setOpen(false)
                }}
                value={[value!]}
                onChange={newValue => {
                  const [option] = newValue

                  if (option) {
                    setOpen(false)
                    onChange(option)
                  }
                }}
                type={FilterSelectType.SingleSelect}
                width={100}
                placement={isNewLayout ? 'bottom-end' : undefined}
              />
              {isNewLayout && (
                <Box py="s-2" pl="s-4">
                  <IconButton
                    useIcon={open ? 'ChevronUp' : 'ChevronDown'}
                    color={Token.color.foreground}
                  />
                </Box>
              )}
            </Flex>
          </Button>
        }
      />
      {surveysAvailable && !isNewLayout && (
        <Box py="s-2" pl="s-4">
          <IconButton
            disabled={!isOnNextClickEnabled}
            useIcon="ChevronRight"
            onClick={onNextSurveyClick}
            color={isOnNextClickEnabled ? Token.color.foreground : Token.color.grey20}
          />
        </Box>
      )}
    </Flex>
  )
}
