import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { API, COOKIE } from '@src/constants/api'
import Cookies from 'js-cookie'
import Dialog from '../../components/Modals/Dialog/Dialog'
import { isDev, isSubdomainDevelopment } from '@src/utils'
import { useLoginDevInterface } from '@src/pages/Login/common'
import {
  ActionButton,
  Bar,
  Box,
  Button,
  copyToClipboard,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  TextArea,
  VStack,
  FAB,
  Fixed,
  Subheader,
  Item,
  Switch,
  Group,
  Token,
  Text,
} from '@revolut/ui-kit'
import { connect } from 'lape'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { pushNotification } from '@src/store/notifications/actions'
import {
  ERROR_DEFAULT_DURATION,
  SUCCESS_DEFAULT_DURATION,
} from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { MSW_MOCK } from '@src/api/mocks/localstorage'
import useTabBarSwitcher from '../TabBarSwitcher/useTabBarSwitcher'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'
import { OnboardingCheckpointCategory } from '@src/interfaces/onboardingChecklist'
import { OnboardingCheckpointCategory as OnboardingCheckpointCategoryV2 } from '@src/interfaces/onboardingChecklistV2'
import {
  resetInitialSetupCheckpoint,
  resetOnboardingCheckpoint,
  useInvalidateOnboardingCheckpoints,
} from '@src/api/onboardingChecklist'
import {
  completeOnboardingCheckpoint,
  enableAllCheckpoints,
  resetOnboardingCheckpoint as resetOnboardingCheckpointV2,
  useInvalidateOnboardingCheckpoints as useInvalidateOnboardingCheckpointsV2,
} from '@src/api/onboardingChecklistV2'
import { successNotification } from '@src/actions/NotificationActions'
import { AxiosPromise } from 'axios'
import { LocalStorageKeys } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import {
  finalStepsCategories,
  firstStepsCategories,
  hrCategories,
  performanceCategories,
  recruitmentCategories,
} from '@src/pages/OnboardingChecklistV2/common/constants'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { useQueryClient } from 'react-query'

const Container = styled.div`
  margin-top: 16px;
  width: 500px;
  max-height: calc(100vh - 200px);
  scrollbar-width: none;
  overflow: scroll;
`

const TextButton = styled.button`
  color: ${Token.color.blue};

  &:hover {
    text-decoration: underline;
  }
`

interface AdminInterfaceProps {
  className?: string
}

interface AdminFormData {
  api_endpoint: string
  wss_endpoint: string
  enable_msw: boolean
  enable_theme: boolean
  msw_mocks: MSW_MOCK[]
  auth_token: string
  predefined_auth_token?: { id: string; name: string }
}

export const devUsers = [
  { id: 'normal-user-token', name: 'Normal User' },
  { id: 'super-user-token', name: 'Super User' },
]

const environments = [
  {
    name: 'Localhost',
    api_endpoint: 'http://localhost:8000/api/',
    wss_endpoint: 'wss://localhost:8000/api/',
  },
  {
    name: 'Dev',
    api_endpoint: 'https://dev.revoluters.com/employee/api/',
    wss_endpoint: 'wss://dev.revoluters.com/ws/v1/user/',
  },
  {
    name: 'Pipeline1',
    api_endpoint: 'https://pipeline1.revoluters.com/employee/api/',
    wss_endpoint: 'wss://pipeline1.revoluters.com/ws/v1/user/',
  },
  {
    name: 'Pipeline2',
    api_endpoint: 'https://pipeline2.revoluters.com/employee/api/',
    wss_endpoint: 'wss://pipeline2.revoluters.com/ws/v1/user/',
  },
  {
    name: 'Pipeline3',
    api_endpoint: 'https://pipeline3.revoluters.com/employee/api/',
    wss_endpoint: 'wss://pipeline3.revoluters.com/ws/v1/user/',
  },
  {
    name: 'Pipeline4',
    api_endpoint: 'https://pipeline4.revoluters.com/employee/api/',
    wss_endpoint: 'wss://pipeline4.revoluters.com/ws/v1/user/',
  },
  {
    name: 'Pipeline5',
    api_endpoint: 'https://pipeline5.revoluters.com/employee/api/',
    wss_endpoint: 'wss://pipeline5.revoluters.com/ws/v1/user/',
  },
  {
    name: 'Pipeline6',
    api_endpoint: 'https://pipeline6.revoluters.com/employee/api/',
    wss_endpoint: 'wss://pipeline6.revoluters.com/ws/v1/user/',
  },
]

const AdminInterfaceForm = ({ className }: AdminInterfaceProps) => {
  const { values } = useLapeContext<AdminFormData>()
  const [openLinkInterface, setOpenLinkInterface] = useState(false)
  const { onSubmitDevInterface, loginDevInterface, loading } = useLoginDevInterface()
  const { theme, setTheme } = useAppTheme()

  useEffect(() => {
    document.addEventListener('keydown', handleOpenLinkInterface)
    return () => {
      document.removeEventListener('keydown', handleOpenLinkInterface)
    }
  }, [])

  const handleClean = () => {
    if (!Cookies.get(COOKIE.ORIGINAL_TOKEN)) {
      setOpenLinkInterface(false)
      return
    }
    const prevToken = Cookies.get(COOKIE.ORIGINAL_TOKEN)
    localStorage.removeItem('api_endpoint')
    localStorage.removeItem('wss_endpoint')
    localStorage.removeItem('enable_msw')
    localStorage.removeItem('enable_theme')
    localStorage.removeItem('msw_mocks')
    if (prevToken) {
      loginDevInterface(prevToken)
      Cookies.remove(COOKIE.ORIGINAL_TOKEN)
    } else {
      Cookies.remove(COOKIE.AUTHENTICATED)
      Cookies.remove(COOKIE.API_TOKEN)
      window.location?.reload()
      setOpenLinkInterface(false)
    }
  }

  const handleOpenLinkInterface = (e: KeyboardEvent) => {
    if (e.composed && e.ctrlKey && e.shiftKey && e.code === 'KeyL' && isDev()) {
      setOpenLinkInterface(!openLinkInterface)
    }
  }

  const tabs = ['Admin', 'Workspaces', 'Onboarding', 'Onboarding v2']
  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs: values.enable_msw ? [...tabs, 'Mocks'] : tabs,
    defaultTab: 'Admin',
    highlightSelected: true,
  })

  return (
    <>
      <Dialog open={openLinkInterface} onClose={() => setOpenLinkInterface(false)}>
        {tabBar}
        <Container className={className}>
          {currentTab === 'Admin' && (
            <InputGroup>
              <LapeNewInput
                name="auth_token"
                label="Auth token"
                required
                renderAction={() => (
                  <IconButton
                    useIcon="Copy"
                    aria-label="Action"
                    color={Token.color.greyTone50}
                    onClick={() =>
                      copyToClipboard(values.auth_token).then(() => {
                        pushNotification({
                          value: 'Token copied to clipboard',
                          duration: SUCCESS_DEFAULT_DURATION,
                          type: NotificationTypes.success,
                        })
                      })
                    }
                  />
                )}
              />
              <Flex gap="s-8">
                {environments.map(environment => (
                  <TextButton
                    onClick={e => {
                      e.preventDefault()
                      values.api_endpoint = environment.api_endpoint
                      values.wss_endpoint = environment.wss_endpoint
                    }}
                    key={environment.name}
                  >
                    {environment.name}
                  </TextButton>
                ))}
              </Flex>
              <LapeNewInput
                name="api_endpoint"
                label="API endpoint (optional)"
                placeholder="https://people-ops-dev.revolutlabs.com/employee/api/"
                hideOptional
              />
              <LapeNewInput
                name="wss_endpoint"
                label="WSS endpoint (optional)"
                placeholder="wss://people-ops-dev.revolutlabs.com/ws/v1/user/"
                hideOptional
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable MSW',
                  description: 'Enables mock service worker',
                }}
                name="enable_msw"
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable theme toggle',
                  description: 'Displays the light/dark theme toggle on screen',
                }}
                name="enable_theme"
              />
            </InputGroup>
          )}
          {currentTab === 'Mocks' && <LocalStorageMocks />}
          {currentTab === 'Onboarding' ? (
            <Onboarding />
          ) : currentTab === 'Onboarding v2' ? (
            <OnboardingV2 />
          ) : currentTab === 'Workspaces' ? (
            <Workspaces />
          ) : (
            <Bar mt="s-16">
              <Button
                pending={loading}
                onClick={() => {
                  onSubmitDevInterface(
                    values.api_endpoint,
                    values.wss_endpoint,
                    values.enable_msw,
                    values.enable_theme,
                    values.msw_mocks,
                    values.auth_token,
                  )
                }}
              >
                Submit
              </Button>
              <Button variant="secondary" onClick={handleClean}>
                {Cookies.get(COOKIE.ORIGINAL_TOKEN) ? 'Clean' : 'Close'}
              </Button>
            </Bar>
          )}
        </Container>
      </Dialog>

      {values.enable_theme ? (
        <Fixed bottom={64} left={24} zIndex={1}>
          <FAB
            onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
            useIcon="Lightbulb"
          />
        </Fixed>
      ) : null}
    </>
  )
}

const AdminInterface = connect(() => {
  const initialValues = {
    api_endpoint: localStorage.getItem('api_endpoint') || '',
    wss_endpoint: localStorage.getItem('wss_endpoint') || '',
    enable_msw: localStorage.getItem('enable_msw') === 'true',
    enable_theme: localStorage.getItem('enable_theme') === 'true',
    auth_token: Cookies.get(COOKIE.API_TOKEN) || '',
    predefined_auth_token: devUsers.find(
      user => user.id === Cookies.get(COOKIE.API_TOKEN),
    ),
    msw_mocks: localStorage.getItem('msw_mocks')
      ? JSON.parse(localStorage.getItem('msw_mocks')!)
      : [],
  }

  if (
    !initialValues.api_endpoint &&
    isSubdomainDevelopment() &&
    localStorage.getItem(LocalStorageKeys.ENABLE_DEV_WORKSPACES) !== 'True'
  ) {
    initialValues.api_endpoint = 'https://dev.revoluters.com/employee/api/'
    initialValues.wss_endpoint = 'wss://dev.revoluters.com/ws/v1/user/'
  }

  return (
    <LapeForm onSubmit={() => Promise.resolve({})} initialValues={initialValues}>
      <AdminInterfaceForm />
    </LapeForm>
  )
})

export default AdminInterface

function LocalStorageMocks() {
  const { values } = useLapeContext<AdminFormData>()
  const getMockUpdater =
    (idxToUpdate: number) => (key: keyof MSW_MOCK, value: unknown) => {
      values.msw_mocks = values.msw_mocks.map((m, idx) => {
        if (idxToUpdate === idx) {
          return { ...m, [key]: value }
        }
        return m
      })
    }
  const renderMock = (mock: MSW_MOCK, idx: number) => {
    const update = getMockUpdater(idx)
    return (
      <>
        <InputGroup
          variant="horizontal"
          use={HStack}
          style={{ alignItems: 'center' }}
          key={idx}
        >
          <Input
            value={mock.url}
            label="Unique URL part"
            onChange={ev => update('url', ev.currentTarget.value)}
          />
          <Input
            value={mock.method}
            onChange={ev => update('method', ev.currentTarget.value)}
            label="HTTP Method"
          />

          <ActionButton
            variant="negative"
            size="xs"
            iconOnly
            useIcon="Delete"
            onClick={() => {
              values.msw_mocks = values.msw_mocks.filter(m => m !== mock)
            }}
          />
        </InputGroup>

        <TextArea
          value={mock.json}
          label="Response"
          onChange={ev => update('json', ev.currentTarget.value.replace('\n', ''))}
        />
      </>
    )
  }

  return (
    <Box>
      <VStack space="s-16">
        <Box maxHeight="80vh" style={{ overflowY: 'scroll' }}>
          <InputGroup>{values.msw_mocks.map(renderMock)}</InputGroup>
        </Box>

        <ActionButton
          onClick={() => {
            values.msw_mocks = values.msw_mocks.concat([
              { url: '', json: '', method: 'GET' },
            ])
          }}
        >
          Add mock
        </ActionButton>
      </VStack>
    </Box>
  )
}

const Workspaces = () => {
  const [workspacesEnabled, setWorkspacesEnabled] = useState(
    () => !!(localStorage.getItem(LocalStorageKeys.ENABLE_DEV_WORKSPACES) === 'True'),
  )
  const [newWorkspace, setNewWorkspace] = useState('')

  const [workspaces, setWorkspaces] = useLocalStorage<string[]>(
    LocalStorageKeys.WORKSPACES,
    [],
    false,
  )

  return (
    <VStack space="s-16">
      <Item use="label">
        <Item.Content>
          <Item.Title>Enable workspaces</Item.Title>
        </Item.Content>
        <Item.Side>
          <Switch
            onChange={e => {
              setWorkspacesEnabled(e.currentTarget.checked)
            }}
            checked={workspacesEnabled}
          />
        </Item.Side>
      </Item>

      {workspacesEnabled ? (
        <VStack>
          <HStack align="center" space="s-8">
            <Input
              label="New workspace"
              value={newWorkspace}
              onChange={e => setNewWorkspace(e.currentTarget.value)}
              style={{ height: 20 }}
            />
            <ActionButton
              variant="accent"
              onClick={() => {
                localStorage.setItem(LocalStorageKeys.ENABLE_DEV_WORKSPACES, 'True')
                localStorage.setItem(LocalStorageKeys.ACTIVE_WORKSPACE, newWorkspace)
                localStorage.setItem(
                  'api_endpoint',
                  `https://peopleops.dev/api/${newWorkspace}/`,
                )
                localStorage.setItem(
                  'wss_endpoint',
                  `https://peopleops.dev/api/${newWorkspace}/v1/wss_token`,
                )

                if (!workspaces.includes(newWorkspace)) {
                  setWorkspaces([newWorkspace, ...workspaces])
                }

                window.location.href = `${document.location.origin}/${newWorkspace}`
              }}
              disabled={!newWorkspace}
            >
              Connect
            </ActionButton>
          </HStack>
          <Group overflow="hidden">
            {workspaces.map(ws => (
              <Item
                onClick={() => {
                  localStorage.setItem(LocalStorageKeys.ENABLE_DEV_WORKSPACES, 'True')
                  localStorage.setItem(LocalStorageKeys.ACTIVE_WORKSPACE, ws)
                  localStorage.setItem('api_endpoint', `https://peopleops.dev/api/${ws}/`)
                  localStorage.setItem(
                    'wss_endpoint',
                    `https://peopleops.dev/api/${ws}/v1/wss_token`,
                  )

                  window.location.href = `${document.location.origin}/${ws}${ROUTES.LOGIN.MAIN}`
                }}
                useIcon="Services"
                use="button"
                py="s-24"
                key={ws}
              >
                <Item.Content>
                  <Item.Title>{ws}</Item.Title>
                </Item.Content>
                <Item.Side>
                  <Item.Value>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation()
                        setWorkspaces(workspaces.filter(w => w !== ws))
                      }}
                      useIcon="Cross"
                      color={Token.color.red}
                    />
                  </Item.Value>
                </Item.Side>
              </Item>
            ))}
          </Group>
        </VStack>
      ) : null}

      <ActionButton
        variant="accent"
        onClick={() => {
          localStorage.setItem(
            LocalStorageKeys.ENABLE_DEV_WORKSPACES,
            workspacesEnabled ? 'True' : 'False',
          )
          if (!workspacesEnabled) {
            localStorage.removeItem('api_endpoint')
            localStorage.removeItem('wss_endpoint')
          }
          window.location.href = '/'
        }}
      >
        Submit
      </ActionButton>
    </VStack>
  )
}

const Onboarding = () => {
  const invalidateOnboardingCheckpoints = useInvalidateOnboardingCheckpoints()

  const [resetAllPending, setResetAllPending] = useState(false)

  const categories = [
    'departmentsTeams',
    'roles',
    'countries',
    'timeOff',
    'documents',
    'contracts',
    'lifecycle',
    'payroll',
    'performance',
    'skills',
    'goals',
    'roadmaps',
    'probation',
    'requisitions',
    'jobPostings',
    'hiring',
    'careers',
    'candidates',
    'finish',
    'importEmployees',
    'engagement',
  ] as const

  const onResetAll = () => {
    setResetAllPending(true)

    Promise.allSettled([
      resetInitialSetupCheckpoint(),
      ...categories.map(category => resetOnboardingCheckpoint(category)),
    ]).then(result => {
      const failed = result.filter(r => r.status === 'rejected')
      if (failed.length) {
        pushNotification({
          value: `Reset failed`,
          duration: ERROR_DEFAULT_DURATION,
          type: NotificationTypes.error,
        })
      } else {
        successNotification(`All categories reset successfully`)
      }
      invalidateOnboardingCheckpoints()
      setResetAllPending(false)
    })
  }

  const onReset = (
    promise: Promise<any> | AxiosPromise,
    category: OnboardingCheckpointCategory,
  ) => {
    promise
      .then(() => {
        successNotification(`${category} reset successfully`)
        invalidateOnboardingCheckpoints()
      })
      .catch(() => {
        pushNotification({
          value: `${category} reset failed`,
          duration: ERROR_DEFAULT_DURATION,
          type: NotificationTypes.error,
        })
      })
  }

  return (
    <Box>
      <Subheader>
        <Subheader.Title>Reset onboarding checkpoints</Subheader.Title>
      </Subheader>
      <Flex gap="s-4" flexWrap="wrap">
        <ActionButton
          variant="accent"
          onClick={() => onReset(resetInitialSetupCheckpoint(), 'initialSetup')}
          size="xs"
        >
          initialSetup
        </ActionButton>
        {categories.map(category => (
          <ActionButton
            variant="accent"
            onClick={() => onReset(resetOnboardingCheckpoint(category), category)}
            size="xs"
            key={category}
          >
            {category}
          </ActionButton>
        ))}
        <ActionButton onClick={onResetAll} size="xs" pending={resetAllPending}>
          Reset all checkpoints
        </ActionButton>
      </Flex>
    </Box>
  )
}

const OnboardingV2 = () => {
  const queryClient = useQueryClient()

  const invalidateOnboardingCheckpoints = useInvalidateOnboardingCheckpointsV2()

  const [resetAllPending, setResetAllPending] = useState(false)
  const [enableAllPending, setEnableAllPending] = useState(false)

  const categories = [
    ...firstStepsCategories,
    ...performanceCategories,
    ...recruitmentCategories,
    ...hrCategories,
    ...finalStepsCategories,
  ] as const

  const onResetAll = () => {
    setResetAllPending(true)

    Promise.allSettled(
      categories.map(category => resetOnboardingCheckpointV2(category)),
    ).then(result => {
      const failed = result.filter(r => r.status === 'rejected')
      if (failed.length) {
        pushNotification({
          value: `Reset failed`,
          duration: ERROR_DEFAULT_DURATION,
          type: NotificationTypes.error,
        })
      } else {
        successNotification(`All categories reset successfully`)
      }
      invalidateOnboardingCheckpoints()
      setResetAllPending(false)
    })
  }

  const onEnableAll = async () => {
    try {
      setEnableAllPending(true)
      await enableAllCheckpoints()
    } finally {
      setEnableAllPending(false)
    }
  }

  const onReset = (
    promise: Promise<any> | AxiosPromise,
    category: OnboardingCheckpointCategoryV2,
  ) => {
    promise
      .then(() => {
        successNotification(`${category} reset successfully`)
        invalidateOnboardingCheckpoints()
      })
      .catch(() => {
        pushNotification({
          value: `${category} reset failed`,
          duration: ERROR_DEFAULT_DURATION,
          type: NotificationTypes.error,
        })
      })
  }

  const renderCategories = (
    categoriesToRender: OnboardingCheckpointCategoryV2[],
    sectionName: string,
  ) => (
    <VStack space="s-4">
      <Text variant="h6">{sectionName}</Text>
      <Flex gap="s-4" flexWrap="wrap">
        {categoriesToRender.map(category => (
          <ActionButton
            variant="accent"
            key={category}
            size="xs"
            onClick={() => onReset(resetOnboardingCheckpointV2(category), category)}
          >
            {category}
          </ActionButton>
        ))}
      </Flex>
    </VStack>
  )

  return (
    <Box>
      <ActionButton
        onClick={e => {
          e.stopPropagation()
          completeOnboardingCheckpoint('paymentMethod').then(() => {
            queryClient.invalidateQueries(API.ONBOARDING_CHECKPOINTS_V2)
          })
        }}
        variant="black"
        size="xs"
      >
        Mark payment step as complete
      </ActionButton>
      <Subheader>
        <Subheader.Title>Reset onboarding checkpoints</Subheader.Title>
      </Subheader>
      <VStack space="s-12">
        {renderCategories(firstStepsCategories, 'First steps')}
        {renderCategories(performanceCategories, 'Performance')}
        {renderCategories(recruitmentCategories, 'Recruitment')}
        {renderCategories(hrCategories, 'HR')}
        {renderCategories(finalStepsCategories, 'Final steps')}
        <HStack space="s-8">
          <ActionButton mt="s-16" onClick={onEnableAll} pending={enableAllPending}>
            Enable all
          </ActionButton>
          <ActionButton
            mt="s-16"
            variant="accent"
            onClick={onResetAll}
            pending={resetAllPending}
          >
            Reset all
          </ActionButton>
        </HStack>
      </VStack>
    </Box>
  )
}
