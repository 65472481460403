import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import { RequestInterfaceNew, TableRequestInterface } from '@src/interfaces'
import { CultureValueInterface } from '@src/interfaces/cultureValues'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const cultureValuesRequests: TableRequestInterface<CultureValueInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.VALUES}${API.COMPANY}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const cultureValuesRequestsNew: RequestInterfaceNew<CultureValueInterface> = {
  get: async ({ id }) => api.get(`${API.VALUES}${API.COMPANY}/${id}`),
  update: async (_, { id }, data) =>
    apiWithoutHandling.put(`${API.VALUES}${API.COMPANY}/${id}`, data),
  submit: async data => apiWithoutHandling.post(`${API.VALUES}${API.COMPANY}`, data),
}

export const deleteCultureValues = (id: number) =>
  apiWithoutHandling.delete(`${API.VALUES}${API.COMPANY}/${id}`)

export const deleteValue = (id: number) => api.delete(`${API.VALUES}${API.COMPANY}/${id}`)
