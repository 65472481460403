import { Absolute, AspectRatio, Relative, Token } from '@revolut/ui-kit'
import css from '@styled-system/css'
import React, { type FC, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import {
  FEATURE_CAROUSEL_CARD_PADDING_COEFFICIENT,
  FEATURE_CARD_SCALE,
  FEATURE_CAROUSEL_TRANSITION_DURATION,
  FEATURE_CAROUSEL_TRANSITION_STEP_DURATION,
  FEATURE_HIDDEN_CARD_SCALE,
} from './constants'
import { useSwipe } from '@src/pages/Landing/hooks/useSwipe'
import { PageIndicator } from '@src/pages/Landing/components/PageIndicator'
import { FeatureItemContent } from '@src/pages/Landing/components/types'

interface FeatureItemsCarouselCenterModeProps {
  activeIndex: number
  swipeable: boolean
  updateActiveIndex: (value: number, isUserEvent?: boolean) => void
  items: FeatureItemContent[]
  ratio: number
  children: (props: { item: FeatureItemContent }) => React.ReactNode
}

const TIMING_FUNCTION = 'ease'
const TRANSITION = [
  `transform ${FEATURE_CAROUSEL_TRANSITION_DURATION}ms ${TIMING_FUNCTION}`,
  `opacity ${FEATURE_CAROUSEL_TRANSITION_DURATION}ms ${TIMING_FUNCTION}`,
].join(',')

const TransitionCard = styled(AspectRatio)<{ zIndex?: number | 'auto' }>(props =>
  css({
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    transition: TRANSITION,
    zIndex: props.zIndex,
  }),
)

const adjustPosition = (position: number, visibleCentralElementIndex: number) =>
  Math.abs(position) > visibleCentralElementIndex
    ? Math.sign(position) * visibleCentralElementIndex
    : position

const getPosition = (activeIndex: number, index: number, itemsLength: number) => {
  const centralElementIndex = Math.floor(itemsLength / 2)

  if (index !== activeIndex) {
    const stepsToLeft = getStepsIfMovingToTheLeft(activeIndex, index, itemsLength)
    const stepsToRight = getStepsIfMovingToTheRight(activeIndex, index, itemsLength)
    return stepsToRight > centralElementIndex ? -stepsToLeft : stepsToRight
  }

  return 0
}

const getStepsIfMovingToTheRight = (nextIndex: number, index: number, length: number) => {
  const steps = index - nextIndex
  return steps < 0 ? steps + length : steps
}

const getStepsIfMovingToTheLeft = (nextIndex: number, index: number, length: number) => {
  const steps = nextIndex - index
  return steps < 0 ? steps + length : steps
}

const getPaddingCoefficient = (position: number) => {
  if (position < 0) {
    return -FEATURE_CAROUSEL_CARD_PADDING_COEFFICIENT
  }

  if (position > 0) {
    return FEATURE_CAROUSEL_CARD_PADDING_COEFFICIENT
  }

  return 0
}

const getTranslateX = (position: number) => {
  const offset = `translateX(calc(${position} * var(--website-feature-items-carousel-gap)))`
  const translate =
    position * 100 - getPaddingCoefficient(position) * (Math.abs(position) * 2 - 1)
  return `${offset} translateX(${translate}%)`
}

const isMovingToTheRight = (
  nextActiveIndex: number,
  activeIndex: number,
  length: number,
) =>
  getStepsIfMovingToTheLeft(nextActiveIndex, activeIndex, length) >
  getStepsIfMovingToTheRight(nextActiveIndex, activeIndex, length)

interface CarouselCardPlaceholderProps extends React.PropsWithChildren<any> {
  appearing: boolean
  left: number
  ratio: number
}

const CarouselCardPlaceholder = ({
  appearing,
  left,
  children,
  ratio,
}: CarouselCardPlaceholderProps) => {
  const translateX = getTranslateX(left)

  return (
    <TransitionCard
      height="100%"
      opacity={appearing ? 1 : 0}
      style={{
        aspectRatio: ratio.toString(),
        transform: appearing
          ? `${translateX} scale(${FEATURE_CARD_SCALE})`
          : `${translateX} scale(${FEATURE_HIDDEN_CARD_SCALE})`,
        transition: appearing ? TRANSITION : 'none',
      }}
    >
      {children}
    </TransitionCard>
  )
}

interface CarouselCardProps extends React.PropsWithChildren<any> {
  isActive: boolean
  cardPosition: number
  animating: boolean
  disappearing: boolean
  onClick: VoidFunction
  hidden?: boolean
  ratio: number
}

const CarouselCard: FC<CarouselCardProps> = ({
  isActive,
  animating,
  disappearing,
  cardPosition,
  onClick,
  children,
  hidden: initialHidden,
  ratio,
}) => {
  const hidden = (disappearing && animating) || initialHidden
  let transform = getTranslateX(cardPosition)

  if (!isActive) {
    transform += ` scale(${FEATURE_CARD_SCALE})`
  }
  if (hidden) {
    transform += ` scale(${FEATURE_HIDDEN_CARD_SCALE})`
  }

  return (
    <TransitionCard
      onClick={onClick}
      height="100%"
      opacity={hidden ? 0 : 1}
      zIndex={hidden ? 0 : 1}
      style={{
        aspectRatio: ratio.toString(),
        transition: animating ? TRANSITION : 'none',
        ...(transform && { transform }),
      }}
    >
      {children}
    </TransitionCard>
  )
}

const FeatureItemsCarouselContainer = styled(Relative)({
  '--website-feature-items-carousel-gap': '16px',
  // Need to use percentage here because the cards are scalable on desktop and 8% is close to the 24px on md and 32px on xxl
  [`@media ${Token.media.md}`]: { '--website-feature-items-carousel-gap': '8%' },
})

export const FeatureItemsCarouselCenter: FC<FeatureItemsCarouselCenterModeProps> = ({
  items,
  activeIndex: initialActiveIndex,
  updateActiveIndex,
  children,
  ratio,
  swipeable,
}) => {
  const visibleItemsLength = items.length % 2 === 0 ? items.length - 1 : items.length
  const visibleCentralElementIndex = Math.floor(visibleItemsLength / 2)

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const [isAnimating, setIsAnimating] = useState(false)
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex)
  const [nextActiveIndex, setNextActiveIndex] = useState(initialActiveIndex)

  const movingToTheRight = isMovingToTheRight(nextActiveIndex, activeIndex, items.length)
  const nextFirstItemIndex = activeIndex - visibleCentralElementIndex - 1
  const nextLastItemIndex = activeIndex + visibleCentralElementIndex + 1

  const firstItemIndex =
    nextFirstItemIndex < 0 ? nextFirstItemIndex + items.length : nextFirstItemIndex

  const lastItemIndex =
    nextLastItemIndex >= items.length
      ? nextLastItemIndex - items.length
      : nextLastItemIndex

  const handleUpdateIndex = useCallback(
    (activeIdx: number, nextActiveIdx: number) => {
      if (!swipeable) {
        return
      }
      if (nextActiveIdx !== activeIdx && !isAnimating) {
        setIsAnimating(true)
        const isNeighborBecomingActive =
          activeIdx + 1 === nextActiveIdx ||
          activeIdx - 1 === nextActiveIdx ||
          (activeIdx === items.length - 1 && nextActiveIdx === 0) ||
          (activeIdx === 0 && nextActiveIdx === items.length - 1)

        if (isNeighborBecomingActive) {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
          }
          setNextActiveIndex(nextActiveIdx)
          updateActiveIndex(nextActiveIdx, false)
          timeoutRef.current = setTimeout(() => {
            setActiveIndex(nextActiveIdx)
            setIsAnimating(false)
          }, FEATURE_CAROUSEL_TRANSITION_DURATION)
        } else {
          const movingToRight = isMovingToTheRight(nextActiveIdx, activeIdx, items.length)
          const tempIndex = movingToRight
            ? (activeIdx - 1 + items.length) % items.length
            : (activeIdx + 1) % items.length

          handleUpdateIndex(activeIdx, tempIndex)

          setTimeout(
            () => handleUpdateIndex(tempIndex, nextActiveIdx),
            FEATURE_CAROUSEL_TRANSITION_DURATION +
              FEATURE_CAROUSEL_TRANSITION_STEP_DURATION,
          )
        }
      }
    },
    [swipeable, items.length, isAnimating, updateActiveIndex],
  )

  useEffect(() => {
    handleUpdateIndex(activeIndex, initialActiveIndex)
  }, [activeIndex, initialActiveIndex, handleUpdateIndex])

  const { onTouchStart, onTouchMove, onTouchEnd } = useSwipe({
    onSwipedLeft: () => handleUpdateIndex(activeIndex, (activeIndex + 1) % items.length),
    onSwipedRight: () =>
      handleUpdateIndex(activeIndex, (activeIndex - 1 + items.length) % items.length),
  })

  return (
    <FeatureItemsCarouselContainer
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      height="100%"
      margin="auto"
      style={{ aspectRatio: ratio.toString() }}
    >
      <CarouselCardPlaceholder
        appearing={isAnimating && movingToTheRight}
        left={-visibleCentralElementIndex}
        ratio={ratio}
      >
        {children({ item: items[firstItemIndex] })}
      </CarouselCardPlaceholder>
      {items.map((item, index) => {
        let position = getPosition(activeIndex, index, items.length)
        let nextPosition = getPosition(nextActiveIndex, index, items.length)

        const hidden =
          Math.abs(position) > visibleCentralElementIndex ||
          Math.abs(nextPosition) > visibleCentralElementIndex

        position = adjustPosition(position, visibleCentralElementIndex)
        nextPosition = adjustPosition(nextPosition, visibleCentralElementIndex)

        const disappearing =
          (nextPosition < 0 && position > 0) || (nextPosition > 0 && position < 0)

        return (
          <CarouselCard
            key={index}
            isActive={nextActiveIndex === index}
            animating={isAnimating}
            disappearing={disappearing}
            onClick={() => updateActiveIndex(index)}
            cardPosition={!disappearing && isAnimating ? nextPosition : position}
            hidden={hidden}
            ratio={ratio}
          >
            {children({ item })}
          </CarouselCard>
        )
      })}
      <CarouselCardPlaceholder
        appearing={isAnimating && !movingToTheRight}
        left={visibleCentralElementIndex}
        ratio={ratio}
      >
        {children({ item: items[lastItemIndex] })}
      </CarouselCardPlaceholder>
      {swipeable && (
        <Absolute left={0} right={0} top="100%">
          <PageIndicator
            mt={{ all: 's-16', md: 's-24', xxl: 's-32' }}
            current={activeIndex}
            total={items.length}
            onClick={updateActiveIndex}
          />
        </Absolute>
      )}
    </FeatureItemsCarouselContainer>
  )
}
