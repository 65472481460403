import React, { useEffect, useState } from 'react'
import {
  InputGroup,
  Input,
  Button,
  StatusPopup,
  Box,
  Group,
  Popup,
  Header,
  Color,
  Text,
  Flex,
  ErrorWidget,
  Subheader,
  DetailsCell,
  HStack,
  ActionButton,
} from '@revolut/ui-kit'
import { Delete, ExclamationTriangle } from '@revolut/icons'
import { useLape } from 'lape'
import diff from 'lodash/difference'
import { Route, useParams } from 'react-router-dom'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { DependantNotFoundPage, useEmployeeBenefitsParams } from './common'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  BenefitPackage,
  CustomFieldsInterface,
  DependantInterface,
  EmployeeBenefitInterface,
  ExistingDependantInterface,
} from '@src/interfaces/benefits'
import { PageActions } from '@src/components/Page/PageActions'
import { goBack, navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { deleteDependant, updateEmployeeBenefit } from '@src/api/benefits'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { FormPreview, getCFValue } from '@src/components/FormPreview/FormPreview'
import { useQuery } from '@src/utils/queryParamsHooks'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { Line } from '@components/CommonSC/Line'
import { CustomFieldTypes } from '@src/constants/customFields'
import NewMultiSelect from '@src/components/Inputs/NewMultiSelect/NewMultiSelect'
import { IdAndName } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import Icon from '@src/components/Icon/Icon'
import { useGetEmployeeSettings } from '@src/api/settings'
import { DatePickerInput } from '@src/components/Inputs/DatePickerInput/DatePickerInput'

const getGlobalFields = (selectedPackage: BenefitPackage) => {
  const firstName = selectedPackage.dependant_global_fields.find(
    f => f.id === 'first_name',
  )
  const lastName = selectedPackage.dependant_global_fields.find(f => f.id === 'last_name')
  const relationship = selectedPackage.dependant_global_fields.find(
    f => f.id === 'relationship',
  )
  const dateOfBirth = selectedPackage.dependant_global_fields.find(
    f => f.id === 'date_of_birth',
  )
  const email = selectedPackage.dependant_global_fields.find(f => f.id === 'email')
  const gender = selectedPackage.dependant_global_fields.find(f => f.id === 'gender')
  const middleName = selectedPackage.dependant_global_fields.find(
    f => f.id === 'middle_name',
  )
  const mobilePhone = selectedPackage.dependant_global_fields.find(
    f => f.id === 'mobile_phone',
  )

  return {
    firstName,
    middleName,
    lastName,
    relationship,
    dateOfBirth,
    email,
    gender,
    mobilePhone,
  }
}

const formatLabel = (field: CustomFieldsInterface) => {
  return field.mandatory ? field.name : `${field.name} (optional)`
}

interface CustomFieldProps {
  field: CustomFieldsInterface
  form: DependantInterface
}

const CustomField = ({ field, form }: CustomFieldProps) => {
  switch (field?.input_type?.id) {
    case CustomFieldTypes.Number:
    case CustomFieldTypes.Text:
      return (
        <Input
          label={formatLabel(field)}
          value={form.form_data[field.id] as string}
          onChange={e => {
            form.form_data[field.id] = e.currentTarget.value
          }}
          message={field.tooltip}
        />
      )
    case CustomFieldTypes.Dropdown:
      return (
        <RadioSelectInput
          label={formatLabel(field)}
          options={field.validation_checks?.validation_choices?.map(option => ({
            label: option.name,
            value: option,
          }))}
          value={form.form_data[field.id] as IdAndName<string>}
          onChange={value => {
            form.form_data[field.id] = value || undefined
          }}
          message={field.tooltip}
        />
      )
    case CustomFieldTypes.Date:
      return (
        <DatePickerInput
          label={formatLabel(field)}
          value={form.form_data[field.id] as string}
          onChange={value => {
            form.form_data[field.id] = value?.toISOString()
          }}
          message={field.tooltip}
        />
      )
    case CustomFieldTypes.MultipleSelector:
      return (
        <NewMultiSelect
          placeholder={formatLabel(field)}
          options={field.validation_checks?.validation_choices?.map((choice: any) => ({
            label: choice.name,
            value: choice,
          }))}
          value={
            (form.form_data[field.id] as IdAndName<string>[])?.map(value => ({
              value,
              label: value.name,
            })) || []
          }
          onChange={options => {
            form.form_data[field.id] = options.map(option => option.value)
          }}
          message={field.tooltip}
        />
      )
    default:
      return null
  }
}

interface DependantFormProps {
  data: DependantInterface
}

const DependantForm = ({ data }: DependantFormProps) => {
  const { values } = useLapeContext<EmployeeBenefitInterface>()
  const { data: settings } = useGetEmployeeSettings()

  const selectedPackageId = values.enrolment?.selected_package?.id
  const actualSelectedPackage = selectedPackageId
    ? values.template.packages.find(p => p.id === selectedPackageId)
    : null
  const selectedPackage = values.selected_package || actualSelectedPackage

  const {
    firstName,
    middleName,
    lastName,
    email,
    mobilePhone,
    gender,
    dateOfBirth,
    relationship,
  } = getGlobalFields(selectedPackage)

  return (
    <>
      <InputGroup>
        {firstName ? (
          <Input
            label={firstName.name}
            value={data.form_data.first_name || ''}
            onChange={e => {
              data.form_data.first_name = e.currentTarget.value
            }}
          />
        ) : null}
        {middleName ? (
          <Input
            label={middleName.name}
            value={data.form_data.middle_name || ''}
            onChange={e => {
              data.form_data.middle_name = e.currentTarget.value
            }}
          />
        ) : null}
        {lastName ? (
          <Input
            label={lastName.name}
            value={data.form_data.last_name || ''}
            onChange={e => {
              data.form_data.last_name = e.currentTarget.value
            }}
          />
        ) : null}
        {relationship ? (
          <RadioSelectInput
            label={relationship.name}
            value={data.form_data.relationship}
            onChange={value => {
              data.form_data.relationship = value || undefined
            }}
            selector={selectorKeys.benefits_package_relationship_types}
            {...(selectedPackage.restricted_relationship
              ? {
                  options: selectedPackage.types_of_relationship.map(option => ({
                    label: option.name,
                    value: option,
                  })),
                }
              : {})}
          />
        ) : null}
        {email ? (
          <Input
            label={email.name}
            value={data.form_data.email || ''}
            onChange={e => {
              data.form_data.email = e.currentTarget.value
            }}
          />
        ) : null}
        {mobilePhone ? (
          <Input
            label={mobilePhone.name}
            value={data.form_data.mobile_phone || ''}
            onChange={e => {
              data.form_data.mobile_phone = e.currentTarget.value
            }}
          />
        ) : null}
        {gender ? (
          <RadioSelectInput
            label={gender.name}
            selector={selectorKeys.legal_sexes}
            value={data.form_data.gender}
            onChange={value => {
              data.form_data.gender = value || undefined
            }}
          />
        ) : null}
        {dateOfBirth ? (
          <DatePickerInput
            label={dateOfBirth.name}
            value={data.form_data.date_of_birth}
            onChange={value => {
              data.form_data.date_of_birth = value?.toISOString()
            }}
            clearable={false}
          />
        ) : null}
      </InputGroup>

      {selectedPackage.dependant_custom_fields?.length &&
      !!settings?.enable_custom_fields ? (
        <>
          <Box mt="s-24">
            <Line />
          </Box>

          <Subheader variant="nested">
            <Subheader.Title>Custom fields</Subheader.Title>
          </Subheader>
          <InputGroup>
            {selectedPackage.dependant_custom_fields.map(field => (
              <CustomField field={field} form={data} key={field.id} />
            ))}
          </InputGroup>
        </>
      ) : null}
    </>
  )
}

const getSelectedPackage = (benefit: EmployeeBenefitInterface) =>
  benefit.template.packages.find(p => p.id === benefit.enrolment!.selected_package.id)!

interface CommonProps {
  data: ExistingDependantInterface
}

const DependantPreview = ({ data }: CommonProps) => {
  const { values } = useLapeContext<EmployeeBenefitInterface>()
  const { data: settings } = useGetEmployeeSettings()

  const selectedPackage = getSelectedPackage(values)

  const {
    firstName,
    middleName,
    lastName,
    email,
    mobilePhone,
    gender,
    dateOfBirth,
    relationship,
  } = getGlobalFields(selectedPackage)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <PageBody>
      <FormPreview data={data.form_data}>
        <Group>
          {firstName ? (
            <FormPreview.Item title={firstName.name} field="first_name" />
          ) : null}
          {middleName ? (
            <FormPreview.Item title={middleName.name} field="middle_name" />
          ) : null}
          {lastName ? <FormPreview.Item title={lastName.name} field="last_name" /> : null}
          {relationship ? (
            <FormPreview.Item title={relationship.name} field="relationship.name" />
          ) : null}
          {email ? <FormPreview.Item title={email.name} field="email" /> : null}
          {mobilePhone ? (
            <FormPreview.Item title={mobilePhone.name} field="mobile_phone" />
          ) : null}
          {gender ? <FormPreview.Item title={gender.name} field="gender.name" /> : null}
          {dateOfBirth ? (
            <FormPreview.Item
              title={dateOfBirth.name}
              field="date_of_birth"
              type="date"
            />
          ) : null}
          {!!settings?.enable_custom_fields &&
            selectedPackage.dependant_custom_fields.map(field => {
              return (
                <DetailsCell key={field.id}>
                  <DetailsCell.Title>
                    <HStack space="s-8" align="center">
                      <Text>{field.name}</Text>
                      <Icon type="CF" />
                    </HStack>
                  </DetailsCell.Title>
                  <DetailsCell.Content>
                    {getCFValue(data.form_data[field.id], field.input_type.id)}
                  </DetailsCell.Content>
                </DetailsCell>
              )
            })}
        </Group>
      </FormPreview>
    </PageBody>
  )
}

const DependantEdit = (props: CommonProps) => {
  const formData = useLape({ ...props.data })
  const { initialValues, reset } = useLapeContext<EmployeeBenefitInterface>()
  const params = useParams<DependantRouteParams>()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)

  const onSaveChanges = () => {
    setIsSubmitting(true)

    updateEmployeeBenefit(
      {
        dependants: initialValues.enrolment?.dependants.map(d =>
          d.id === formData.id ? formData : d,
        ),
      },
      params.id,
      params.employeeId,
    )
      .then(response => {
        setSuccessPopupOpen(true)
        reset({
          ...initialValues,
          ...response.data,
          field_options: initialValues.field_options,
        })
      })
      .finally(() => setIsSubmitting(false))
  }

  return (
    <>
      <PageBody>
        <DependantForm data={formData} />
      </PageBody>

      <PageActions>
        <Button onClick={onSaveChanges} pending={isSubmitting}>
          Save changes
        </Button>
      </PageActions>

      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)
          navigateReplace(
            pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.PREVIEW, params),
          )
        }}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>Dependant updated</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

interface DependantRouteParams {
  employeeId: string
  id: string
  dependantId: string
  dependantTab: 'preview' | 'edit'
}

export const Dependant = () => {
  const { values, initialValues, reset } = useLapeContext<EmployeeBenefitInterface>()
  const params = useParams<DependantRouteParams>()

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [minimumDependantWarningOpen, setMinimumDependantWarningOpen] = useState(false)
  const [deletePending, setDeletePending] = useState(false)

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT.PREVIEW, params)

  const dependant = values.enrolment?.dependants.find(d => d.id === +params.dependantId!)

  if (!dependant) {
    return <DependantNotFoundPage backUrl={backUrl} />
  }

  const selectedPackage = getSelectedPackage(values)
  const isMinimumAllowedDependants =
    selectedPackage.min_dependants != null &&
    values.enrolment!.dependants.length <= +selectedPackage.min_dependants

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.PREVIEW, params),
      component: DependantPreview,
    },
    {
      title: 'Edit',
      path: ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.EDIT,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.EDIT, params),
      component: DependantEdit,
    },
  ]

  const onDeleteDependant = () => {
    if (params.dependantId) {
      setDeletePending(true)
      deleteDependant(initialValues, params.id, params.employeeId, dependant.id)
        .then(response => {
          reset({
            ...initialValues,
            ...response.data,
            field_options: initialValues.field_options,
          })
          goBack(backUrl)
        })
        .catch(() => setDeletePending(false))
    }
  }

  return (
    <>
      <PageWrapper>
        <PageHeader pb="s-8" title="Dependant's details" backUrl={backUrl}>
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={tabs} />
          </Box>
        </PageHeader>

        <ActionButton
          onClick={() =>
            isMinimumAllowedDependants
              ? setMinimumDependantWarningOpen(true)
              : setDeleteConfirmationOpen(true)
          }
          variant="negative"
          my="s-8"
          useIcon={Delete}
        >
          Delete
        </ActionButton>

        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component data={dependant} />
          </Route>
        ))}
      </PageWrapper>

      <Popup
        open={minimumDependantWarningOpen}
        onClose={() => setMinimumDependantWarningOpen(false)}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet" displayMode="inline">
          <Header.Actions>
            <ExclamationTriangle color={Color.BLUE} size={40} />
          </Header.Actions>
          <Text fontSize="h5">
            To delete dependant from your benefit you need to change package first
          </Text>
          <Header.Subtitle>
            <Box mt="s-16" mb="-s-16">
              You cannot delete dependant, as this package requires minimum number of
              dependants. To manage dependants' enrolment proceed with the package change
              first.
            </Box>
          </Header.Subtitle>
        </Header>
        <Popup.Actions>
          <Button onClick={() => goBack()} elevated>
            Go back
          </Button>
        </Popup.Actions>
      </Popup>

      <ConfirmationDialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onConfirm={onDeleteDependant}
        loading={deletePending}
        onReject={() => setDeleteConfirmationOpen(false)}
        yesMessage="Confirm"
        noMessage="Cancel"
        label="You're deleting dependant from this benefit"
        body="This action cannot be undone. All changes made will be efective since the cut-off date in the next month."
      />
    </>
  )
}

export const NewDependant = () => {
  const { values, initialValues, reset } = useLapeContext<EmployeeBenefitInterface>()
  const params = useEmployeeBenefitsParams()
  const { query } = useQuery<{ index?: string }>()
  const index = query.index ? +query.index : null

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [newDependantId, setNewDependantId] = useState<number>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isModifyingDependant = index != null && values.dependants[index]
  const isUpdatingSelectedPackage =
    initialValues.enrolment?.selected_package?.id === values.selected_package?.id

  const formData = useLape<DependantInterface>(
    isModifyingDependant ? { ...values.dependants[index] } : { form_data: {} },
  )

  const onSubmitForNewPackageSelection = () => {
    if (isModifyingDependant) {
      values.dependants[index] = formData
    } else if (values.dependants) {
      values.dependants.push(formData)
    } else {
      values.dependants = [formData]
    }
    goBack()
  }

  const onSubmitForSelectedPackage = () => {
    setIsSubmitting(true)

    updateEmployeeBenefit(
      {
        dependants: initialValues.enrolment?.dependants
          ? [...initialValues.enrolment.dependants, formData]
          : [formData],
      },
      params.id,
      params.employeeId,
    )
      .then(response => {
        setSuccessPopupOpen(true)
        setNewDependantId(
          diff(
            response.data.enrolment?.dependants.map(p => p.id),
            initialValues.enrolment!.dependants.map(p => p.id),
          )[0],
        )
        reset({ ...values, ...response.data, field_options: initialValues.field_options })
      })
      .finally(() => setIsSubmitting(false))
  }

  const onDelete = () => {
    values.dependants = values.dependants.filter((_, i) => i !== index)
    goBack()
  }

  if (!values.selected_package) {
    return (
      <Flex width="100vw" height="100vh" alignItems="center" justifyContent="center">
        <ErrorWidget>
          <ErrorWidget.Image src="https://assets.revolut.com/assets/3d-images/3D087@2x.png" />
          <ErrorWidget.Title>
            The page you're looking for could not be found
          </ErrorWidget.Title>
          <ErrorWidget.Action onClick={() => navigateTo(ROUTES.MAIN)}>
            Go to dashboard
          </ErrorWidget.Action>
        </ErrorWidget>
      </Flex>
    )
  }

  return (
    <>
      <PageWrapper>
        <PageHeader
          pb="s-8"
          title="Add dependant"
          backUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT.PACKAGE_SELECT, params)}
        />

        {isModifyingDependant ? (
          <ActionButton onClick={onDelete} mb="s-24" variant="negative" useIcon={Delete}>
            Delete
          </ActionButton>
        ) : null}

        <PageBody>
          <DependantForm data={formData} />
        </PageBody>

        <PageActions>
          {isUpdatingSelectedPackage ? (
            <Button onClick={onSubmitForSelectedPackage} pending={isSubmitting}>
              Add dependant
            </Button>
          ) : (
            <Button onClick={onSubmitForNewPackageSelection}>Add dependant</Button>
          )}
        </PageActions>
      </PageWrapper>

      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)

          if (newDependantId) {
            navigateReplace(
              pathToUrl(ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.PREVIEW, {
                id: params.id,
                employeeId: params.employeeId,
                dependantId: newDependantId,
              }),
            )
          }
        }}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>Dependant added</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}
