import { FieldOptions, IdAndName, Statuses } from '@src/interfaces/index'
import { EmployeeOptionInterface } from './employees'
import { FunctionOptionInterface } from '@src/interfaces/functions'
import { SkillLevels } from '@src/interfaces/roles'
import { PerformanceRating } from '@src/interfaces/performance'

export enum SkillType {
  Executive = 'executive',
  Functional = 'functional',
  Culture = 'culture',
}

export const SkillTypeTitle = {
  [SkillType.Executive]: 'Company skill',
  [SkillType.Functional]: 'Functional skill',
  [SkillType.Culture]: 'Cultural value',
}

export interface SkillInterface {
  id: number
  name: string
  description: string
  mastery_level_definition_poor?: string[]
  mastery_level_definition_basic?: string[]
  mastery_level_definition_intermediate?: string[]
  mastery_level_definition_advanced?: string[]
  mastery_level_definition_expert?: string[]
  status: Statuses
  function: FunctionOptionInterface
  skill_type: SkillType
  owner: EmployeeOptionInterface
  playbook_url: string
  valid_until: string | null
  approval_date_time?: string
  field_options: FieldOptions
  role_skills: IdAndName[]
}

export interface SkillCompetencyInterface {
  Chief: SkillLevels
  Director: SkillLevels
  Graduate: SkillLevels
  Junior: SkillLevels
  Lead: SkillLevels
  Mid: SkillLevels
  Senior: SkillLevels
  'Senior Director': SkillLevels
  'Senior Vice President': SkillLevels
  'Vice President': SkillLevels
}

export interface SkillSpecialisationInterface {
  id: number
  name: number
  headcount: number
  function: IdAndName
  competency: SkillCompetencyInterface
}

export interface CulturalSkillInterface {
  id: number
  name: string
  owner?: EmployeeOptionInterface
  rating_avg?: PerformanceRating
  status: { id: Statuses; name: string }
  valid_until?: string
}

export interface SkillsTalentInterface {
  employee: EmployeeOptionInterface
  team: IdAndName
  specialisation: IdAndName
  seniority: IdAndName
  department: IdAndName
  function: IdAndName
  rating: PerformanceRating | null
  expected_rating: PerformanceRating | null
}

export interface SkillsTalentStatsInterface {
  cycle_name: string
  rating_advanced_count: number
  rating_avg: PerformanceRating | null
  rating_basic_count: number
  rating_expert_count: number
  rating_intermediate_count: number
  rating_score_avg: number | null
  total: number
}
