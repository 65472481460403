import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Route, Switch } from 'react-router-dom'
import CompanyTalentPerformance from '@src/pages/Forms/CommonTalentTab/TalentPerformance'
import { CompanyInterface } from '@src/interfaces/company'
import { TalentType } from '@src/interfaces/talent/talent'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const Talent = ({ data }: { data: CompanyInterface }) => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.FORMS.COMPANY.TALENT.PERFORMANCE}
        key={ROUTES.FORMS.COMPANY.TALENT.PERFORMANCE}
      >
        <CompanyTalentPerformance data={data} talentType={TalentType.Company} />
      </Route>
      <InternalRedirect to={ROUTES.FORMS.COMPANY.TALENT.PERFORMANCE} />
    </Switch>
  )
}

export default Talent
