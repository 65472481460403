import React, { useEffect, useMemo, useState } from 'react'
import omit from 'lodash/omit'

import {
  CalibrationFactor,
  KpiInterface,
  KpiReviewCycle,
  KpiTargetEpics,
  KpiTargets,
  UpdateTypes,
} from '@src/interfaces/kpis'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { selectorKeys } from '@src/constants/api'
import { getKpiPerformanceGraph } from '@src/api/kpis'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { BaseChartInner } from '@components/Charts/BaseChart/BaseChartInner'
import Icon from '@components/Icon/Icon'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { mergeFormValues, useChangelog, useChangelogApi } from '@src/utils/form'
import { PermissionTypes } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import {
  fixChangelogTargets,
  getEntity,
  renderParentSelectorOption,
} from '@src/utils/kpi'
import { Box, Flex, Input, InputGroup, Text, Widget } from '@revolut/ui-kit'
import { ArrowUp } from '@revolut/icons'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { IconButtonWithBorders } from '@components/CommonSC/TableStatistics'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import AutoStepper from '@components/Stepper/AutoStepper'
import CollapsibleStepperSection from '@components/Stepper/CollapsibleStepperSection'
import { ReviewCycleCategory, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { getKPIParentSelector } from '@src/features/FormTabs/Kpi/KPITargets/common/ParentSelector'
import { submitForm, useIsApprovalsEnabled } from '@src/pages/Forms/KpiForm/common/common'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { KPITypes } from '@src/constants/table'
import SettingsButtons from '@src/features/SettingsButtons'
import useKPISidebars from '@src/pages/Forms/KpiForm/common/useKPISidebars'
import RadioSelectInput, {
  RadioSelectInputProps,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import RadioSelectOption from '@components/Inputs/RadioSelectInput/RadioSelectOption'
import { teamsRequests } from '@src/api/teams'
import { TeamInterface } from '@src/interfaces/teams'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { changelogApiKPI } from './General'
import { departmentRequests } from '@src/api/department'
import BannerForCreation from '@src/pages/Forms/KpiForm/common/BannerForCreation'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { SearchEpicsField } from './common/SearchEpicsField'
import { NewKPITargetFields } from './common/NewKPITargetFields'
import { TargetCycleSelector } from './common/TargetCycleSelector'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const descriptionPlaceholder = `Summary of what this KPI measures (context):
How will it be measured (formula & unit):
The business goal this KPI supports:
Any risks linked with achieving this KPI:
`

const selectDefaultCycleOffset: RadioSelectInputProps<ReviewCyclesInterface>['selectDefaultOption'] =
  options => options.find(option => option.value.id === -1)

interface Props {
  hideSettingsButtons?: boolean
  onAfterSubmit?: () => void
  ignoreAfterRedirect?: boolean
}

export const getTargetCycleValue = (values: KpiInterface) => {
  if (values.update_type === UpdateTypes.roadmap) {
    return (
      values.target_epics?.[0].employee_cycle || values.target_epics?.[0].review_cycle
    )
  }
  return values.targets?.[0].employee_cycle || values.targets?.[0].review_cycle
}

const New = ({
  hideSettingsButtons = false,
  onAfterSubmit,
  ignoreAfterRedirect = false,
}: Props) => {
  const { clearHistory } = useChangelogApi()
  const changelogFromUrl = useChangelog(changelogApiKPI)
  const form = useLapeContext<KpiInterface>()
  const { values, initialValues } = form
  const { sidebars, buttons } = useKPISidebars(!!hideSettingsButtons)
  const [cycleValue, setCycleValue] = useState<ReviewCyclesInterface>()
  const [entity, setEntity] = useState<DepartmentInterface | TeamInterface>()
  const [parentEntity, setParentEntity] = useState<string | undefined>()
  const [cycleOffset, setCycleOffset] = useState<number | string | undefined>()

  const { data: performanceSettings } = useGetPerformanceSettings()

  useEffect(() => {
    setParentEntity(values.is_employee ? getEntity(values.parent || {}) : undefined)
  }, [values.is_employee, values.parent])

  useEffect(() => {
    const selectedCycle = getTargetCycleValue(values) as KpiReviewCycle
    setCycleOffset(values.parent ? undefined : selectedCycle?.offset || 0)
  }, [values.parent])

  const approvalsEnabled = useIsApprovalsEnabled()

  const hasTarget =
    values.update_type === UpdateTypes.sql || values.update_type === UpdateTypes.manual
  const hasDeliverables = values.update_type === UpdateTypes.roadmap

  const canSetCalibrationFactor = values.field_options?.permissions?.includes(
    PermissionTypes.SetCalibrationFactor,
  )

  const fetchKPIParentSelector = useMemo(
    () => getKPIParentSelector(values, cycleOffset),
    [values, cycleOffset],
  )

  useEffect(() => {
    const fetchEntity = async () => {
      let entityValue
      if (initialValues.department?.id) {
        entityValue = await departmentRequests.getItem(initialValues.department?.id)
      } else if (initialValues.team?.id) {
        entityValue = await teamsRequests.getItem(initialValues.team?.id)
      }
      if (entityValue) {
        setEntity(entityValue.data)
      }
    }
    fetchEntity()

    if (!values.description) {
      values.description = descriptionPlaceholder
    }

    if (hasTarget && !values.targets?.length) {
      values.targets = [
        {
          is_top_down: false,
          owner: values.owner,
        } as KpiTargets,
      ]
    }
    if (hasDeliverables && !values.target_epics?.length) {
      values.target_epics = [
        {
          owner: values.owner,
        } as KpiTargetEpics,
      ]
    }
  }, [])

  useEffect(() => {
    if (!values.is_inherited) {
      values.unit = '%'
    }
  }, [values.is_inherited])

  useEffect(() => {
    if (changelogFromUrl.data) {
      form.changelog = fixChangelogTargets(changelogFromUrl.data, form.values)
      form.changelogApi = changelogApiKPI
      form.values = mergeFormValues(
        values,
        omit(changelogFromUrl.data, ['field_options', 'status']),
      )
      form.disabled = true
    }
  }, [changelogFromUrl.data])

  const saveButton = (
    <PageActions>
      <NewSaveButtonWithPopup<KpiInterface>
        onClick={() => submitForm(form)}
        useValidator
        successText={approvalsEnabled ? 'KPI submitted for approval' : 'KPI submitted'}
        previewUrl={
          ignoreAfterRedirect
            ? undefined
            : res => {
                return pathToUrl(ROUTES.FORMS.KPI.PREVIEW, {
                  id: res.id,
                })
              }
        }
        onAfterSubmit={onAfterSubmit}
      />
    </PageActions>
  )

  const typeSelected = !!values.id || !!values.update_type || values.is_inherited

  if (!typeSelected) {
    return <InternalRedirect to={ROUTES.FORMS.KPI.SELECT_TYPE} />
  }

  const onCycleChanged = (cycle: ReviewCyclesInterface) => {
    setCycleOffset(cycle?.offset || 0)
    const target = hasDeliverables ? values.target_epics[0] : values.targets[0]
    target.parent_kpi = null

    if (
      [ReviewCycleCategory.Probation, ReviewCycleCategory.PIP].includes(cycle.category)
    ) {
      target.employee_cycle = cycle
    } else {
      target.review_cycle = cycle
      delete target.employee_cycle
    }
  }

  if (values.is_inherited) {
    return (
      <>
        {sidebars}
        <PageBody>
          <SettingsButtons mb="s-16">{buttons}</SettingsButtons>
          <AutoStepper>
            {entity?.kpi_champions && (
              <BannerForCreation champions={entity.kpi_champions} />
            )}
            <Box>
              <NewStepperTitle title="General info" />
              <InputGroup>
                <RadioSelectInput<ReviewCyclesInterface>
                  label="Review cycle"
                  selector={selectorKeys.cycle_offsets}
                  value={cycleValue}
                  onChange={option => {
                    setCycleOffset(option?.id)
                    values.parent = null
                    if (option) {
                      setCycleValue(option)
                    }
                  }}
                  selectDefaultOption={
                    values.parent ? undefined : selectDefaultCycleOffset
                  }
                />
                <LapeRadioSelectInput<KpiInterface>
                  name="parent"
                  label="Parent KPI"
                  message="Select the higher level KPI which you want to cascade"
                  selector={fetchKPIParentSelector}
                  referenceText={values.parent ? 'Open' : ''}
                  referenceUrl={
                    values.parent
                      ? getLocationDescriptor(
                          pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: values.parent.id }),
                          {
                            returnAs: 'parent',
                          },
                        )
                      : ''
                  }
                  onChange={selectedKPI => {
                    if (!selectedKPI) {
                      return
                    }
                    if (values.kpi_type.id === KPITypes.employee_kpi) {
                      setParentEntity(getEntity(selectedKPI))
                    }
                    values.parent = selectedKPI
                  }}
                >
                  {renderParentSelectorOption}
                </LapeRadioSelectInput>
                <LapeRadioSelectInput
                  name="owner"
                  label="Owner"
                  disabled={
                    values.kpi_type.id === KPITypes.employee_kpi &&
                    !values.is_assigned_from_relevant
                  }
                  selector={selectorKeys.employee}
                />
                {values.kpi_type.id === KPITypes.employee_kpi && (
                  <Input placeholder="Entity" value={parentEntity || ''} disabled />
                )}
              </InputGroup>
            </Box>
            <Box>
              <NewStepperTitle title="Preview" />
              <Box
                mt="14px"
                mb="s-20"
                p="s-16"
                bg="widget-background"
                color="black-opaque-90"
                radius="widget"
              >
                <Text fontSize="caption">
                  Information below is gathered from the chosen higher level KPI.
                </Text>
              </Box>
              {values.parent ? (
                <Widget height={300} pt="s-24" mb="s-8">
                  <BaseChartInner
                    fetchData={getKpiPerformanceGraph}
                    id={values.parent.id}
                  />
                </Widget>
              ) : (
                <Widget p="s-16">
                  <Flex
                    height={224}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <ArrowUp color="grey-tone-50" />
                    <Text use="p" color="grey-tone-50" pt="s-8">
                      Select Parent KPI to preview its parameters
                    </Text>
                  </Flex>
                </Widget>
              )}
              {canSetCalibrationFactor && (
                <CollapsibleStepperSection title="Advanced settings">
                  <LapeRadioSelectInput<CalibrationFactor>
                    name="calibration_factor"
                    label="Calibration factor"
                    selector={selectorKeys.kpi_calibration_factors}
                  >
                    {option => (
                      <RadioSelectOption
                        label={`${option.label} (${option.value.highlight})`}
                      />
                    )}
                  </LapeRadioSelectInput>
                </CollapsibleStepperSection>
              )}
            </Box>
          </AutoStepper>
        </PageBody>
        {saveButton}
      </>
    )
  }

  return (
    <>
      {sidebars}
      <PageBody data-testid="main_kpi_form">
        <SettingsButtons>{buttons}</SettingsButtons>
        {changelogFromUrl.data && (
          <Flex alignItems="center" mb="s-16">
            <IconButtonWithBorders onClick={clearHistory}>
              <Icon type="Close" />
            </IconButtonWithBorders>
            <Text ml="s-8">Close changelog mode.</Text>
          </Flex>
        )}
        {entity?.kpi_champions && <BannerForCreation champions={entity.kpi_champions} />}
        <AutoStepper mb="s-32">
          <NewStepperTitle title="General info" />
          <InputGroup>
            <TargetCycleSelector
              onSelect={onCycleChanged}
              ownerId={values.owner?.id}
              isEmployee={values.is_employee}
              disabled={form.disabled}
            />
            <LapeRadioSelectInput<KpiInterface>
              name="parent"
              label="Parent KPI"
              message="The higher goal that this KPI is contributing to. These are specific to each cycle."
              selector={fetchKPIParentSelector}
              required={performanceSettings?.enable_mandatory_parent_kpi}
              value={
                hasDeliverables
                  ? values.target_epics?.[0].parent_kpi
                  : values.targets?.[0].parent_kpi
              }
              onChange={selectedKPI => {
                if (selectedKPI) {
                  if (hasDeliverables) {
                    values.target_epics[0].parent_kpi = selectedKPI
                  } else {
                    values.targets[0].parent_kpi = selectedKPI
                  }
                }
              }}
            >
              {renderParentSelectorOption}
            </LapeRadioSelectInput>
            <LapeNewTextArea label="KPI Name" name="name" required />
            <LapeNewTextArea name="description" label="Description" required rows={1} />
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              disabled={values.kpi_type.id === KPITypes.employee_kpi}
              selector={selectorKeys.employee}
            />
          </InputGroup>
          {hasDeliverables && ( // Roadmap KPI
            <>
              <NewStepperTitle title="Target Info" />
              <SearchEpicsField />
            </>
          )}
          {hasTarget && ( // Manual KPI
            <>
              <NewStepperTitle title="Target Info" />
              <NewKPITargetFields />
            </>
          )}
        </AutoStepper>
        {saveButton}
      </PageBody>
    </>
  )
}

export default connect(New)
