import React from 'react'
import { Flex, Widget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { PermissionInterface } from '@src/interfaces/accessManagement'
import { useTable } from '@components/Table/hooks'
import { permissionsRequests } from '@src/api/accessManagement'
import { RowInterface } from '@src/interfaces/data'
import {
  permissionApplicationColumn,
  permissionCodeNameColumn,
  permissionNameColumn,
  permissionUsersColumn,
} from '@src/constants/columns/accessManagement/permissions'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'

const Row: RowInterface<PermissionInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_PERMISSION.DETAILS, { id })),
  cells: [
    {
      ...permissionNameColumn,
      width: 200,
    },
    {
      ...permissionCodeNameColumn,
      width: 200,
    },
    {
      ...permissionApplicationColumn,
      width: 200,
    },
    {
      ...permissionUsersColumn,
      width: 200,
    },
  ],
}

const PermissionsTable = () => {
  const table = useTable<PermissionInterface>(permissionsRequests)

  return (
    <Widget p="s-16">
      <Flex flexDirection="column" width="100%">
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<PermissionInterface>
            name={TableNames.Permissions}
            useWindowScroll
            dataType="Permissions"
            row={Row}
            {...table}
            noDataMessage="Permissions will appear here."
          />
        </Flex>
      </Flex>
    </Widget>
  )
}

export default PermissionsTable
