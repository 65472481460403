import React from 'react'
import { useTable } from '@src/components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import { kpisRequests } from '@src/api/kpis'
import GraphIconChart from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { RowInterface, SORT_DIRECTION, Stats } from '@src/interfaces/data'

import Icon from '@src/components/Icon/Icon'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import IconButton from '@src/components/ButtonIcon/IconButton'
import {
  kpiCalibratedPerformanceColumn,
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiStatusColumn,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { useSelector } from 'react-redux'
import { canAddFunctionKpi, selectUser } from '@src/store/auth/selectors'
import {
  CycleFilter,
  CycleFilterType,
} from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { getKpiPerformanceGraph } from '@src/api/functions'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import { EntityTypes } from '@src/constants/api'
import { PermissionTypes } from '@src/store/auth/types'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { ActionButton, Button, Flex, TableWidget, Text, Token } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { FunctionInterface } from '@src/interfaces/functions'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import Tooltip from '@components/Tooltip/Tooltip'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { TableNames } from '@src/constants/table'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'

interface StructureProps {
  data: FunctionInterface
}

const departmentRow: RowInterface<KpiInterface> = {
  highlight: data => {
    if (data.target_status === Statuses.pending) {
      return Token.color.orange_5
    }

    return ''
  },
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...kpiGenericNameColumn,
      width: 600,
    },
    {
      ...kpiWeightColumn,
      width: 80,
    },
    {
      ...kpiPerformanceColumn,
      width: 80,
    },
    {
      ...kpiCalibratedPerformanceColumn,
      width: 80,
    },
    {
      ...kpiInitialValueColumn,
      width: 90,
    },
    {
      ...kpiCurrentValueColumn,
      width: 90,
    },
    {
      ...kpiTargetColumn,
      width: 90,
    },
    {
      ...kpiUnitColumn,
      width: 90,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 100,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 100,
    },
    {
      ...kpiStatusColumn,
      width: 80,
    },
  ],
}

const KPI = ({ data }: StructureProps) => {
  const initialDepartmentFilter = [
    {
      filters: [{ name: `${data.id}`, id: data.id }],
      columnName: 'function__id',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      filters: [
        { name: Statuses.active, id: Statuses.active },
        { name: Statuses.draft, id: Statuses.draft },
        { name: 'new', id: 'new' },
      ],
      columnName: 'status',
    },
    {
      filters: [{ name: 'true', id: 'true' }],
      columnName: 'include_unlinked',
      nonInheritable: true,
      nonResettable: true,
    },
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]
  const initialDepartmentSortBy = [
    {
      sortBy: 'has_children',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const table = useTable<KpiInterface, Stats>(
    kpisRequests,
    initialDepartmentFilter,
    initialDepartmentSortBy,
  )

  const canAdd = useSelector(canAddFunctionKpi)
  const user = useSelector(selectUser)

  const canChangeKpiWeight = data.field_options.permissions?.includes(
    PermissionTypes.ChangeKpiWeight,
  )

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL, {}), {
      initialValues: getKPIFormInitialValues(user, {
        function: { name: data.name, id: data.id },
      }),
    })
  }

  const handleEditWeights = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.KPI_WEIGHTS.GENERAL), {
      initialValues: {
        entity_id: data.id,
        entity_type: EntityTypes.function,
        kpi_weight_mode: data.kpi_weight_mode,
        function: {
          id: data.id,
          name: data.name,
        },
        employee: null,
        team: null,
        department: null,
      },
    })
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat
          label="Overall Progress"
          val={
            table?.stats?.avg_performance !== undefined ? (
              <Flex>
                <Tooltip
                  text="Overall progress is calculated as the weighted average of all approved KPIs."
                  placement="right"
                >
                  <Text
                    use="div"
                    mr="5px"
                    color={getPercentColor((table?.stats?.avg_performance || 0) * 100)}
                  >
                    {formatPercentage(table?.stats?.avg_performance || 0)}
                  </Text>
                </Tooltip>
                <GraphIconChart
                  id={data.id}
                  vertical="right"
                  fetchData={getKpiPerformanceGraph}
                >
                  <IconButton>
                    <Icon type="Graph" size="small" />
                  </IconButton>
                </GraphIconChart>
              </Flex>
            ) : undefined
          }
          mr="s-32"
        />
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={table.onFilterChange}
          columnName="review_cycle__offset"
          filter={table.filterBy}
          filterInputType={FilterSelectType.SingleSelect}
        />
      </TableWidget.Info>
      <TableWidget.Actions>
        {canAdd && (
          <Button onClick={handleNewRow} mr="s-16" useIcon="Plus">
            Add New KPI
          </Button>
        )}
        {canChangeKpiWeight && (
          <ActionButton onClick={handleEditWeights} mr="s-16">
            Edit Weights
          </ActionButton>
        )}
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable<KpiInterface>
          name={TableNames.FunctionKPIs}
          useWindowScroll
          dataType="KPI"
          row={departmentRow}
          {...table}
          fetchChildren={(parentIndexes, id) => {
            return table.fetchChildren(parentIndexes, id, [
              {
                filters: [{ name: `${data.id}`, id: data.id }],
                columnName: 'role__function__id',
                nonResettable: true,
              },
            ])
          }}
          onRowClick={onKPITableRowClick}
          noDataMessage="Please add your function KPIs to see them here."
          useFetchedChildren
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export default KPI
