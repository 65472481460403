import {
  Archive,
  Check,
  Cross,
  IconComponentType,
  Reverted,
  SandWatch,
} from '@revolut/icons'
import { Button, Color, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import React, { useEffect } from 'react'
import { LocalStorageKeys } from '@src/store/auth/types'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { OfficeSuiteProviderType } from '@src/api/officeSuiteProvider'
import { UNKNOWN_ERROR } from '@src/pages/Forms/QueryForm/constants'

export const mapAccountStatusToProps = (
  status?: Statuses,
): {
  statusText: string
  statusIcon: IconComponentType
  color: Color
} => {
  switch (status) {
    case 'no_account':
      return {
        statusText: 'No account',
        statusIcon: Reverted,
        color: Color.GREY_TONE_50,
      }
    case 'pending':
      return {
        statusText: 'Pending action',
        statusIcon: SandWatch,
        color: Color.ORANGE,
      }
    case 'linked':
      return {
        statusText: 'Linked',
        statusIcon: Check,
        color: Color.GREEN,
      }
    case 'failed':
      return {
        statusText: 'Failed',
        statusIcon: Cross,
        color: Color.RED,
      }
    case 'archived':
      return {
        statusText: 'Archived',
        statusIcon: Archive,
        color: Color.GREY_TONE_50,
      }
    default:
      return {
        statusText: 'No account',
        statusIcon: Reverted,
        color: Color.GREY_TONE_50,
      }
  }
}

export const useShowOfficeSuitePermissionsError = () => {
  const statusPopup = useStatusPopup()

  useEffect(() => {
    const data = workspaceLocalStorage.getItem(LocalStorageKeys.GOOGLE_PERMISSIONS_ERROR)

    if (data) {
      workspaceLocalStorage.removeItem(LocalStorageKeys.GOOGLE_PERMISSIONS_ERROR)

      let label = 'provider'
      let error

      try {
        const parsed = JSON.parse(data)

        if ('type' in parsed) {
          switch (parsed.type as unknown as OfficeSuiteProviderType) {
            case 'microsoftCalendar':
              label = 'Microsoft Calendar'
              break

            case 'microsoftEmail':
              label = 'Microsoft Email'
              break

            case 'googleEmail':
              label = 'Gmail'
              break

            case 'googleCalendar':
              label = 'Google Calendar'
              break
          }
        }

        if ('error' in parsed && typeof parsed.error === 'string') {
          error = parsed.error
        }
      } catch (e) {
        error = UNKNOWN_ERROR
      }

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Could not link {label} account</StatusPopup.Title>
          {error && <StatusPopup.Description>{error}</StatusPopup.Description>}
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>Close</Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }
  }, [])
}
