import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { Cell, FilterButton, Flex, MoreBar } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import {
  createdOnColumn,
  hrManagerColumn,
  isKeyPersonColumn,
  isRegrettableColumn,
  lineManagerColumn,
  noticePeriodColumn,
  resignatingEmployeeNameColumn,
  resignationStatusColumn,
  roleNameColumn,
  seniorityNameColumn,
} from '@src/constants/columns/resignation'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { getResignationsList, useResignationsStats } from '@src/api/employeeResignation'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { TableNames } from '@src/constants/table'

const rowResignation: RowInterface<EmployeeResignationInterface> = {
  hideAddButton: true,
  cells: [
    {
      ...resignatingEmployeeNameColumn,
      width: 200,
    },
    {
      ...roleNameColumn,
      width: 140,
    },
    {
      ...seniorityNameColumn,
      width: 100,
    },
    {
      ...isKeyPersonColumn,
      width: 70,
    },
    {
      ...isRegrettableColumn,
      width: 70,
    },
    {
      ...lineManagerColumn,
      width: 70,
    },
    {
      ...createdOnColumn,
      width: 70,
    },
    {
      ...noticePeriodColumn,
      width: 100,
    },
    {
      ...resignationStatusColumn,
      width: 100,
    },
    {
      ...hrManagerColumn,
      width: 100,
    },
  ],
}

const ResignationsTable = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_RESIGNATION_ASSIGNED_TO_ME,
    false,
  )

  const user = useSelector(selectUser)

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'hr_manager',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const table = useTable<EmployeeResignationInterface>(
    { getItems: getResignationsList },
    getInitialFilters(),
  )
  const { data: stats } = useResignationsStats()

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <Cell display="flex" width="100%">
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-16">
          <Stat label="Pending review" val={stats?.total_headcount} mr="s-32" />
          <Stat
            label="Pending notice"
            val={stats?.pending_notice_headcount}
            mr="s-32"
            color="orange"
          />
          <Stat
            label="Notice pending review"
            val={stats?.notice_pending_review_headcount}
            mr="s-32"
            color="orange"
          />
          <Stat
            label="Pending completion"
            val={stats?.pending_review_headcount}
            mr="s-32"
            color="orange"
          />
        </Flex>
        <Flex mb="s-16" flexDirection="row-reverse">
          <MoreBar>
            <FilterButton active={assignedToMe} onClick={onToggleAssignedToMe}>
              Assigned to me
            </FilterButton>
          </MoreBar>
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<EmployeeResignationInterface>
            name={TableNames.Resignations}
            useWindowScroll
            row={rowResignation}
            {...table}
            noDataMessage="Resignating employees will appear here."
            onRowClick={({ id, employee }) => {
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.DASHBOARD, {
                  employeeId: employee.id,
                  id,
                }),
              )
            }}
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default ResignationsTable
