import React from 'react'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { CommonActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSummary/Actions/CommonActions'
import { useCloseReviewCycle } from '@src/api/reviewCycles'
import { MoreBar } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface Props {
  readonly cycle: ReviewCyclesInterface
}

export const OngoingCycleActions = ({ cycle }: Props) => {
  const { id } = cycle
  const { mutateAsync: closeCycle, isLoading } = useCloseReviewCycle(id)
  const showStatusPopup = useShowStatusPopup()

  const handleStopCycle = async () => {
    await closeCycle()
    showStatusPopup({ title: `${cycle.name} cycle is successfully stopped` })
  }

  return (
    <MoreBar maxCount={2} aria-label="action panel">
      <CommonActions cycle={cycle} />
      <MoreBar.Action useIcon="Stop" onClick={handleStopCycle} pending={isLoading}>
        Stop Cycle
      </MoreBar.Action>
    </MoreBar>
  )
}
