import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { MoreBar, TableWidget } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { teamNameColumn } from '@src/constants/columns/team'
import {
  recruitmentRoleColumn,
  recruitmentTeamOwner,
} from '@src/constants/columns/recruitmentGroups'
import { RecruitmentGroupsInterface } from '@src/interfaces/recruitmentGroups'
import { recruitmentGroupsRequests } from '@src/api/recruitmentGroups'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'

const RowNotifications: RowInterface<RecruitmentGroupsInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.RECRUITMENT_GROUPS.GENERAL, { id })),
  cells: [
    {
      ...teamNameColumn,
      width: 382,
    },
    {
      ...recruitmentTeamOwner,
      width: 250,
    },
    {
      ...recruitmentRoleColumn,
      width: 1000,
    },
  ],
}

const RecruitmentGroupsTable = () => {
  const table = useTable<RecruitmentGroupsInterface>(recruitmentGroupsRequests)
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddRecruitmentgroups)

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat label="Total" val={table?.loading ? undefined : table?.count} mr="s-32" />
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          {canAdd && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.RECRUITMENT_GROUPS.GENERAL)}
              useIcon={Plus}
            >
              Add Recruitment Group
            </MoreBar.Action>
          )}
          <SettingsButton
            path={ROUTES.SETTINGS.CANDIDATES.RECRUITMENT_GROUPS}
            canView={[PermissionTypes.ViewHiringProcessPreferences]}
          />
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable<RecruitmentGroupsInterface>
          name={TableNames.RecruitmentGroups}
          useWindowScroll
          dataType="Recruitment Group"
          row={RowNotifications}
          {...table}
          noDataMessage="Recruitment will appear here."
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export const RecruitmentGroupsPage = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Recruitment groups"
        backUrl={pathToUrl(ROUTES.RECRUITMENT.INTERVIEWS)}
      />
      <PageBody maxWidth="100%">
        <RecruitmentGroupsTable />
      </PageBody>
    </PageWrapper>
  )
}
