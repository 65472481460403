import { Token } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'

export const companyPreferencesItemConfig = {
  path: ROUTES.SETTINGS.GENERAL.PREVIEW,
  title: 'Company preferences',
  description: 'Company details, company logo and more',
  icon: 'Switches' as const,
  color: Token.color.blue,
  bg: Token.color.background,
  canView: [
    PermissionTypes.ViewCompanySettings,
    PermissionTypes.CanManageCompanyLogo,
    PermissionTypes.ChangeCompanyPreferences,
  ],
}

export const securitySettingsItemConfig = {
  path: ROUTES.SETTINGS.SECURITY,
  title: 'Security settings',
  color: Token.color.blue,
  bg: Token.color.background,
  description: 'Login option, 2-factor authentication and single sign on',
  icon: 'Shield' as const,
  canView: [PermissionTypes.ViewSecuritySettings],
}
