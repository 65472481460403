import { Avatar, Cell, Image, Item, VStack, Text, Token } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { useGetSlackPreferences } from '@src/api/integrations'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const Notifications = () => {
  const { data: slackPreferences } = useGetSlackPreferences()
  const { submit } = useLapeContext()
  const isOnboarding = isOnboardingPath()
  const isCommercial = useIsCommercial()

  const hasSlackEnabled = slackPreferences?.enabled

  return (
    <>
      <Cell>
        <VStack gap="s-16" width="100%">
          <Item p="0">
            <Item.Content>
              <Item.Title>
                <FormattedMessage
                  id="engagement.form.notification.details.title"
                  defaultMessage="Notification details"
                />
              </Item.Title>
              <Item.Description>
                <FormattedMessage
                  id="engagement.form.notification.details.description"
                  defaultMessage="Create notification which will be sent on the run of the survey to all participants"
                />
              </Item.Description>
            </Item.Content>
          </Item>
          {isCommercial ? (
            <>
              <LapeNewInput required label="Subject" name="display_name" />
              <LapeNewTextArea rows={3} required label="Body" name="description" />
            </>
          ) : (
            <>
              <LapeNewInput required label="Name" name="display_name" />
              <LapeNewTextArea rows={3} label="Description" name="description" />
              <LapeRadioSelectInput
                name="campaign"
                label="Select campaign"
                selector={selectorKeys.engagement_survey_campaigns}
              />
              <LapeNewInput
                required
                label="Completion time in minutes"
                type="number"
                name="completion_minutes"
              />
            </>
          )}
        </VStack>
      </Cell>

      <Cell>
        <VStack gap="s-16" width="100%">
          <Item p="0">
            <Item.Content>
              <Item.Title>
                <FormattedMessage
                  id="engagement.form.notification.channels.title"
                  defaultMessage="Channels"
                />
              </Item.Title>
              <Item.Description>
                <FormattedMessage
                  id="engagement.form.notification.channels.description"
                  defaultMessage="Define what channels you want to use"
                />
              </Item.Description>
            </Item.Content>
          </Item>
          {isCommercial && (
            <>
              <LapeNewSwitch
                name="system_notification_enabled"
                itemTypeProps={{
                  title: 'System notification',
                  description: 'Enable system notifications',
                  avatar: (
                    <Item.Avatar>
                      <Avatar useIcon="Bell" />
                    </Item.Avatar>
                  ),
                }}
              />
              <LapeNewSwitch
                name="email_notification_enabled"
                itemTypeProps={{
                  title: 'Email notification',
                  description: 'Enable email notifications',
                  avatar: (
                    <Item.Avatar>
                      <Avatar useIcon="Envelope" />
                    </Item.Avatar>
                  ),
                }}
              />
            </>
          )}
          {hasSlackEnabled ? (
            <LapeNewSwitch
              name="slack_notification_enabled"
              itemTypeProps={{
                avatar: (
                  <Item.Avatar>
                    <Image src="https://assets.revolut.com/assets/apps/Slack.png" />
                  </Item.Avatar>
                ),
                title: 'Slack',
                description: 'Enable Slack notifications',
              }}
            />
          ) : (
            <Item
              use="button"
              variant="disclosure"
              onClick={async () => {
                // save the form before navigating out
                await submit()
                navigateTo(
                  isOnboarding
                    ? ROUTES.ONBOARDING_CHECKLIST_V2.INTEGRATIONS.SLACK.ALL
                    : ROUTES.FEATURES.INTEGRATION.SLACK.ALL,
                )
              }}
            >
              <Item.Avatar>
                <Image src="https://assets.revolut.com/assets/apps/Slack.png" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Slack</Item.Title>
                <Item.Description>Setup Slack integration</Item.Description>
              </Item.Content>
              <Item.Side>
                <Text color={Token.color.greyTone50}>Not connected</Text>
              </Item.Side>
            </Item>
          )}
        </VStack>
      </Cell>
    </>
  )
}
