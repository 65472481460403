import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import {
  ContractorInterface,
  DiversityInterface,
  EmployeeBadge,
  EmployeeContractsInterface,
  EmployeeInterface,
  EmployeeSettingsInterface,
  EmployeeProbationAdditionalSettings,
  EmployeeStats,
  PayrollInterface,
  PersonalInfoInterface,
  RightToWorkInterface,
  TerminationInterface,
  EmployeePositionHistoryItem,
  EmployeeInactivityInterface,
  EmployeeBioInterface,
  EmployeeContactsInterface,
  EmployeeLaptopDeliveryAddressInterface,
  EmployeeEmergencyContactInterface,
  EmployeeContractsStatsInterface,
  JiraLifecycleTicketCreationInterface,
  EmployeeStatusTransitionsInterface,
  EmployeeLaptopDelivery,
  EmployeeAboutInterface,
  EmployeesSimpleCreateInterface,
  EmployeesSimpleStatsInterface,
  EmployeeSimpleStatsFormattedInterface,
} from '../interfaces/employees'
import { filterSortPageIntoQuery, groupFiltersIntoURLQuery } from '../utils/table'
import { api, apiWithoutHandling } from './index'
import { API, PerformanceTimeRange } from '../constants/api'
import {
  GetRequestData,
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
} from '../interfaces'
import { FetchDataQueryInterface, FilterByInterface } from '../interfaces/data'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { getMonthsByRange } from '@src/utils/kpi'
import { OptionInterface } from '@src/interfaces/selectors'
import { FinalGrade, FinalGradeToGraphNumber } from '@src/interfaces/performance'
import { UseQueryOptions, useFetch, usePost } from '@src/utils/reactQuery'
import { getCommentsAPI } from './comments'
import { ChangelogInterface } from '@src/interfaces/changelog'
import {
  PayCycleDetailedReportInterface,
  PayCyclePersonalReportsStatsInterface,
  PayCycleReportInterface,
} from '@src/interfaces/payroll'
import { AxiosPromise } from 'axios'

export const employeesRequests: RequestInterface<EmployeeInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.EMPLOYEES, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.EMPLOYEES}/${id}`),
  patchItem: async (data, employeeId) =>
    api.patch(`${API.EMPLOYEES}/${employeeId}`, data),
  putItem: async (data, employeeId) => api.put(`${API.EMPLOYEES}/${employeeId}`, data),
  deleteItem: async id => api.delete(`${API.EMPLOYEES}/${id}`),
  postItem: async data => api.post(API.EMPLOYEES, data),
}

export const getEmployeesStats = (id: string) =>
  api.get<EmployeeStats>(`${API.EMPLOYEES}/${id}/stats`)

export const useGetEmployeeStats = (id: string | number | undefined) =>
  useFetch<EmployeeStats>(id ? `${API.EMPLOYEES}/${id}/stats` : null)

export const getEmployeeWithManagerFilter = (
  id: number | string,
  filter: 'line_manager__id' | 'quality_control__id',
) => api.get<EmployeeInterface>(`${API.EMPLOYEES}/${id}?${filter}=${id}`)

export const useGetEmployeeWithManagerFilter = (
  id: number | string,
  filter: 'line_manager__id' | 'quality_control__id',
) => {
  return useFetch<EmployeeInterface>(`${API.EMPLOYEES}/${id}`, undefined, {
    params: { [filter]: id },
  })
}

export const employeesRequestsNew: RequestInterfaceNew<EmployeeInterface> = {
  get: async ({ id }) => api.get(`${API.EMPLOYEES}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.EMPLOYEES}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.EMPLOYEES, data),
  delete: async ({ id }) => api.delete(`${API.EMPLOYEES}/${id}`),
}

export const anonymiseEmployeeData = (id: number) =>
  api.put(`${API.EMPLOYEES}/${id}/anonymize`)

export const getEmployeeFields = (id: number, fields: string[]) =>
  api.get<EmployeeInterface>(`${API.EMPLOYEES}/${id}?fields=${fields.join(',')}`)

export const useEmployeeFields = <T>(
  id: string | number | undefined,
  fields?: string[],
  isExternalEmployee?: boolean,
) => {
  const url = isExternalEmployee ? `${API.CONTRACTORS}/${id}` : `${API.EMPLOYEES}/${id}`

  return useFetch<T>(id ? url : null, undefined, {
    params: fields?.length ? { fields: fields.join(',') } : undefined,
  })
}

export const useGetEmployee = (id?: number | string) => {
  return useFetch<EmployeeInterface>(id ? `${API.EMPLOYEES}/${id}` : null)
}

export const contractorsRequests: RequestInterfaceNew<ContractorInterface> = {
  get: async ({ id }) => api.get(`${API.CONTRACTORS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.CONTRACTORS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.CONTRACTORS, data),
  delete: async ({ id }) => api.delete(`${API.CONTRACTORS}/${id}`),
}

export const getContractorFields = (id: number, fields: string[]) =>
  api.get<ContractorInterface>(`${API.CONTRACTORS}/${id}?fields=${fields.join(',')}`)

export const updateEmployeeStatus = (
  id: number,
  data: Partial<EmployeeStatusTransitionsInterface>,
) =>
  api.put<EmployeeStatusTransitionsInterface>(
    `${API.EMPLOYEES}/${id}/statusTransition`,
    data,
  )

export const useGetEmployeeStatusTransitions = (employeeId: number | null) =>
  useFetch<EmployeeStatusTransitionsInterface>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/statusTransition` : null,
  )

export const useGetEmployeeInactivityData = (employeeId: number | null) =>
  useFetch<EmployeeInactivityInterface>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/inactivity` : null,
  )

export const updateEmployeeInactivityData = (
  employeeId: number,
  data: EmployeeInactivityInterface,
) =>
  api.put<EmployeeInactivityInterface>(`${API.EMPLOYEES}/${employeeId}/inactivity`, data)

export const cancelEmployeeInactivity = (employeeId: number) =>
  api.post(`${API.EMPLOYEES}/${employeeId}/inactivity/cancel`)

export const profileRequests: RequestInterfaceNew<PersonalInfoInterface> = {
  get: async ({ id }) => api.get(`${API.EMPLOYEES}/${id}/personalInfo`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.EMPLOYEES}/${id}/personalInfo`, data),
  submit: async (data, { id }) =>
    apiWithoutHandling.post(`${API.EMPLOYEES}/${id}/personalInfo`, data),
}

export const useGetPersonalInfo = (
  employeeId: string | number,
  enabled: boolean = true,
) =>
  useFetch<PersonalInfoInterface>(
    enabled ? `${API.EMPLOYEES}/${employeeId}/personalInfo` : null,
  )

export const getProfileFields = (id: number, fields: string[]) =>
  api.get<PersonalInfoInterface>(
    `${API.EMPLOYEES}/${id}/personalInfo?fields=${fields.join(',')}`,
  )

export const terminationRequests: RequestInterfaceNew<TerminationInterface> = {
  get: async ({ id }) => apiWithoutHandling.get(`${API.EMPLOYEES}/${id}/termination`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.EMPLOYEES}/${id}/termination`, data),
  submit: async (data, { id }) =>
    apiWithoutHandling.post(`${API.EMPLOYEES}/${id}/termination`, data),
}

export const terminationRequestsV2: RequestInterfaceNew<TerminationInterface> = {
  get: async ({ id }) =>
    apiWithoutHandling.get(`${API.EMPLOYEES}/${id}/termination`, undefined, 'v2'),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.EMPLOYEES}/${id}/termination`, data, undefined, 'v2'),
  submit: async (data, { id }) =>
    apiWithoutHandling.post(`${API.EMPLOYEES}/${id}/termination`, data, undefined, 'v2'),
}

export const cancelTermination = (id: number, version: 'v1' | 'v2') =>
  api.delete(`${API.EMPLOYEES}/${id}/termination`, undefined, version)

export const payrollRequests: RequestInterfaceNew<PayrollInterface> = {
  get: async ({ id }) => apiWithoutHandling.get(`${API.EMPLOYEES}/${id}/payrollDetails`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.EMPLOYEES}/${id}/payrollDetails`, data),
  submit: async (data, { id }) =>
    apiWithoutHandling.post(`${API.EMPLOYEES}/${id}/payrollDetails`, data),
}

export const useGetEmployeeBankDetails = (
  employeeId: number | string | undefined,
  fields?: string[],
) =>
  useFetch<PayrollInterface>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/payrollDetails` : null,
    undefined,
    { params: fields?.length ? { fields: fields.join(',') } : undefined },
  )

export const diversityRequests: RequestInterfaceNew<DiversityInterface> = {
  get: async ({ id }) => api.get(`${API.EMPLOYEES}/${id}/diversity`),
  update: async (data, { id }) => api.patch(`${API.EMPLOYEES}/${id}/diversity`, data),
  submit: async (data, { id }) => api.post(`${API.EMPLOYEES}/${id}/diversity`, data),
}

export const useGetEmployeeDiversity = (
  employeeId: number | string | undefined,
  fields?: string[],
) =>
  useFetch<DiversityInterface>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/diversity` : null,
    undefined,
    { params: fields?.length ? { fields: fields.join(',') } : undefined },
  )

export const rightToWorkRequests: RequestInterfaceNew<RightToWorkInterface> = {
  get: async ({ id, employeeId }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/rightToWork/${id}`),
  update: async (data, { id, employeeId }) =>
    apiWithoutHandling.patch(`${API.EMPLOYEES}/${employeeId}/rightToWork/${id}`, data),
  submit: async (data, { employeeId }) =>
    apiWithoutHandling.post(`${API.EMPLOYEES}/${employeeId}/rightToWork`, data),
  delete: async (_, { id, employeeId }) =>
    api.delete(`${API.EMPLOYEES}/${employeeId}/rightToWork/${id}`),
}

export const getRightsToWork =
  (employeeId: number) =>
  ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<RightToWorkInterface>>(
      `${API.EMPLOYEES}/${employeeId}/rightToWork`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    )

export const useGetEmployeeRightsToWork = (
  employeeId: number | string | undefined,
  filters?: FilterByInterface[],
) =>
  useFetch<GetRequestInterface<RightToWorkInterface>>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/rightToWork` : null,
    undefined,
    {
      params: filters?.length ? filterSortPageIntoQuery(undefined, filters) : undefined,
    },
  )

export const contractRequests: RequestInterfaceNew<EmployeeContractsInterface> = {
  get: async ({ id, employeeId }) => {
    return api.get(`${API.EMPLOYEES}/${employeeId}/contracts/${id}`)
  },
  update: async (data, { employeeId, id }) => {
    return apiWithoutHandling.patch(
      `${API.EMPLOYEES}/${employeeId}/contracts/${id}`,
      data,
    )
  },
  submit: async (data, { employeeId }) => {
    return apiWithoutHandling.post(`${API.EMPLOYEES}/${employeeId}/contracts`, data)
  },
}

export const useEmployeeContracts = (
  employeeId: number | string | undefined,
  filters?: FilterByInterface[],
) =>
  useFetch<GetRequestInterface<EmployeeContractsInterface>>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/contracts` : null,
    undefined,
    {
      params: filters?.length ? filterSortPageIntoQuery(undefined, filters) : undefined,
    },
  )

export const useEmployeeContractsStats = (
  employeeId: number | string | undefined,
  filters?: FilterByInterface[],
) =>
  useFetch<EmployeeContractsStatsInterface>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/contracts/stats` : null,
    undefined,
    {
      params: filters?.length ? filterSortPageIntoQuery(undefined, filters) : undefined,
    },
  )

export const setEmployeeContractEndDate = async ({
  id,
  employeeId,
  endDate,
}: {
  id: string
  employeeId: string
  endDate: Date
}) => {
  return api.post(`${API.EMPLOYEES}/${employeeId}/contracts/${id}/set_end_date`, {
    end_date: endDate.toISOString(),
  })
}

export const probationAdditionSettings: RequestInterfaceNew<EmployeeProbationAdditionalSettings> =
  {
    get: async ({ id, employeeId }) => {
      return api.get(
        `${API.EMPLOYEES}/${employeeId}/performance/employeePerformanceCycles/${id}`,
        {},
        'v2',
      )
    },
    update: async (data, { employeeId, id }) => {
      return apiWithoutHandling.patch(
        `${API.EMPLOYEES}/${employeeId}/performance/employeePerformanceCycles/${id}`,
        data,
        {},
        'v2',
      )
    },
    submit: async (data, { employeeId, id }) => {
      return apiWithoutHandling.post(
        `${API.EMPLOYEES}/${employeeId}/performance/employeePerformanceCycles/${id}`,
        data,
        {},
        'v2',
      )
    },
  }

export const getEmployeeContracts =
  (employeeId: number) =>
  ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<EmployeeContractsInterface>>(
      `${API.EMPLOYEES}/${employeeId}/contracts`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    )

export const getEmployeeContractsStats =
  (employeeId: string | number) =>
  ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<EmployeeContractsStatsInterface>(
      `${API.EMPLOYEES}/${employeeId}/contracts/stats`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    )

export const getEmployeePayrollReports =
  (employeeId: number | string) =>
  ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<PayCycleReportInterface>>(
      `${API.EMPLOYEES}/${employeeId}/payroll/reports`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    )

export const useEmployerPayrollReportStats = (employeeId: number | string) =>
  useFetch<PayCyclePersonalReportsStatsInterface>(
    `${API.EMPLOYEES}/${employeeId}/payroll/reports/stats`,
  )

export const getEmployeeDetailedPayrollReport = (id: string, employeeId: string) =>
  api.get<PayCycleDetailedReportInterface>(
    `${API.EMPLOYEES}/${employeeId}/payroll/reports/${id}`,
  )

export const cancelEmployeeContract = (employeeId: number, contractId: number) =>
  api.post(`${API.EMPLOYEES}/${employeeId}/contracts/${contractId}/cancel`)

export const getEmployeePaygroup = ({ sortBy, filters, page }: FetchDataQueryInterface) =>
  api.get<
    GetRequestInterface<{
      company_entity: OptionInterface
      country: OptionInterface
      id: number
      name: string
    }>
  >(`${API.PAY_GROUPS}`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getEmployeePerformanceGraph =
  (additionalFilters: FilterByInterface[]) =>
  (id: string, range: PerformanceTimeRange = PerformanceTimeRange.month) =>
    api
      .get<PerformanceChartCycles<FinalGrade>>(
        `${API.EMPLOYEES}/${id}/performanceHistory`,
        {
          params: {
            date_partitioning: range,
            months: '-9,3',
            ...groupFiltersIntoURLQuery(additionalFilters),
          },
        },
      )
      .then(res => {
        return {
          ...res,
          data: {
            ...res.data,
            targets: [],
            progress_history: res.data.progress_history.map(bar => ({
              ...bar,
              progress: bar.progress && FinalGradeToGraphNumber[bar.progress],
              estimated: bar.estimated && FinalGradeToGraphNumber[bar.estimated],
            })),
          } as PerformanceChartCycles,
        }
      })

export const getEmployeeGradesGraph =
  (field: string) => (additionalFilters: FilterByInterface[]) => (id: string) =>
    api
      .get<PerformanceChartCycles<FinalGrade>>(
        `${API.PERFORMANCE}/employeesCalibratedGrades/${id}/history`,
        {
          params: {
            field,
            ...groupFiltersIntoURLQuery(additionalFilters),
          },
        },
      )
      .then(res => {
        return {
          ...res,
          data: {
            ...res.data,
            targets: [],
            progress_history: res.data.progress_history.map(bar => ({
              ...bar,
              progress: bar.progress && FinalGradeToGraphNumber[bar.progress],
              estimated: bar.estimated && FinalGradeToGraphNumber[bar.estimated],
            })),
          } as PerformanceChartCycles,
        }
      })

export const getKpiPerformanceGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
) =>
  api.get<PerformanceChartCycles>(`${API.EMPLOYEES}/${id}/kpiPerformance`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
    },
  })

export const getEmployeeBadges = (id: number) =>
  api.get<GetRequestInterface<EmployeeBadge>>(`${API.EMPLOYEES}/${id}/badges`)

export const useGetEmployeeBadges = (employeeId: number | string | undefined) =>
  useFetch<GetRequestInterface<EmployeeBadge>>({
    url: employeeId ? `${API.EMPLOYEES}/${employeeId}/badges` : null,
    queryOptions: {
      staleTime: 1000 * 60 * 60,
    },
  })

export const getActivationUrl = (id: number) =>
  api.get<{ activation_url: string }>(`${API.ACCOUNT}/activationUrl/${id}`)

export const getMagicLinkUrl = (id: number) =>
  api.get<{ magic_link: string }>(`/magicLink/${id}`)

export const getEmployeeProfileCommentsAPI = (employeeId: number) =>
  getCommentsAPI({
    baseUrl: `${API.EMPLOYEES}/${employeeId}/comments`,
  })

export const getEmployeeFieldChangelog = async (id: number, field: string) =>
  api.get<GetRequestInterface<ChangelogInterface<EmployeeInterface>>>(
    `${API.EMPLOYEES}/${id}${API.POSITIONS_CHANGELOG_FIELDS}`,
    {
      params: {
        field_name: field,
      },
    },
  )

export const employeeSettingsRequests: RequestInterfaceNew<EmployeeSettingsInterface> = {
  get: async ({ id }) => {
    return api.get(`${API.EMPLOYEES}/${id}/employeeSettings`)
  },
  update: async (data, { id }) => {
    return api.patch(`${API.EMPLOYEES}/${id}/employeeSettings`, data)
  },
  submit: async (data, { id }) => {
    return api.post(`${API.EMPLOYEES}/${id}/employeeSettings`, data)
  },
}

export const useGetEmployeePersonalSettings = (
  queryOptions?: UseQueryOptions<EmployeeSettingsInterface>,
) => {
  const user = useSelector(selectUser)

  return useFetch<EmployeeSettingsInterface>(
    user.id ? `${API.EMPLOYEES}/${user.id}/employeeSettings` : null,
    undefined,
    undefined,
    undefined,
    queryOptions,
  )
}

export const getEmployeePositionHistory = (employeeId: number | string) =>
  api.get<GetRequestData<EmployeePositionHistoryItem>>(
    `${API.EMPLOYEES}/${employeeId}/positionHistory`,
  )

export const getEmployeeContractCommentsAPI = (employeeId: string, contractId: string) =>
  getCommentsAPI({
    baseUrl: `${API.EMPLOYEES}/${employeeId}/contracts/${contractId}/comments`,
  })

export const getEmployeeBio = (employeeId: number | string) =>
  api.get<EmployeeBioInterface>(`${API.EMPLOYEES}/${employeeId}/personalInfo/bio`)

export const useGetEmployeeBio = (
  employeeId: number | string | undefined,
  fields?: string[],
) =>
  useFetch<EmployeeBioInterface>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/personalInfo/bio` : null,
    undefined,
    { params: fields?.length ? { fields: fields.join(',') } : undefined },
  )

export const employeeBioRequests: RequestInterfaceNew<EmployeeBioInterface> = {
  get: async ({ id }) =>
    apiWithoutHandling.get(`${API.EMPLOYEES}/${id}/personalInfo/bio`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.EMPLOYEES}/${id}/personalInfo/bio`, data),
  submit: async (data, { id }) =>
    apiWithoutHandling.post(`${API.EMPLOYEES}/${id}/personalInfo/bio`, data),
}

export const getEmployeeContacts = (employeeId: number | string) =>
  api.get<EmployeeContactsInterface>(
    `${API.EMPLOYEES}/${employeeId}/personalInfo/contactInfo`,
  )

export const useGetEmployeeContactInfo = (employeeId?: number | string) =>
  useFetch<EmployeeContactsInterface>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/personalInfo/contactInfo` : null,
  )

export const useGetEmployeeContacts = (
  employeeId: number | string | undefined,
  fields?: string[],
) =>
  useFetch<EmployeeContactsInterface>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/personalInfo/contactInfo` : null,
    undefined,
    { params: fields?.length ? { fields: fields.join(',') } : undefined },
  )

export const employeeContactsRequests: RequestInterfaceNew<EmployeeContactsInterface> = {
  get: async ({ id }) =>
    apiWithoutHandling.get(`${API.EMPLOYEES}/${id}/personalInfo/contactInfo`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.EMPLOYEES}/${id}/personalInfo/contactInfo`, data),
  submit: async (data, { id }) =>
    apiWithoutHandling.post(`${API.EMPLOYEES}/${id}/personalInfo/contactInfo`, data),
}

export const getEmployeeLaptopDeliveryAddress = (employeeId: number | string) =>
  api.get<EmployeeLaptopDeliveryAddressInterface>(
    `${API.EMPLOYEES}/${employeeId}/personalInfo/laptopDelivery`,
  )

export const useGetEmployeeLaptopDeliveryAddress = (
  employeeId: number | string | undefined,
  fields?: string[],
) =>
  useFetch<EmployeeLaptopDelivery>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/personalInfo/laptopDelivery` : null,
    undefined,
    { params: fields?.length ? { fields: fields.join(',') } : undefined },
  )

export const employeeLaptopDeliveryRequests: RequestInterfaceNew<EmployeeLaptopDeliveryAddressInterface> =
  {
    get: async ({ id }) =>
      apiWithoutHandling.get(`${API.EMPLOYEES}/${id}/personalInfo/laptopDelivery`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(
        `${API.EMPLOYEES}/${id}/personalInfo/laptopDelivery`,
        data,
      ),
    submit: async (data, { id }) =>
      apiWithoutHandling.post(`${API.EMPLOYEES}/${id}/personalInfo/laptopDelivery`, data),
  }

export const getEmployeeEmergencyContact = (employeeId: number | string) =>
  api.get<EmployeeEmergencyContactInterface>(
    `${API.EMPLOYEES}/${employeeId}/personalInfo/emergencyContact`,
  )

export const useGetEmployeeEmergencyContact = (
  employeeId: number | string | undefined,
  fields?: string[],
) =>
  useFetch<EmployeeEmergencyContactInterface>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/personalInfo/emergencyContact` : null,
    undefined,
    { params: fields?.length ? { fields: fields.join(',') } : undefined },
  )

export const employeeEmergencyContactRequests: RequestInterfaceNew<EmployeeEmergencyContactInterface> =
  {
    get: async ({ id }) =>
      apiWithoutHandling.get(`${API.EMPLOYEES}/${id}/personalInfo/emergencyContact`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(
        `${API.EMPLOYEES}/${id}/personalInfo/emergencyContact`,
        data,
      ),
    submit: async (data, { id }) =>
      apiWithoutHandling.post(
        `${API.EMPLOYEES}/${id}/personalInfo/emergencyContact`,
        data,
      ),
  }

export const getEmployeeAbout = (employeeId: number | string) =>
  api.get<EmployeeAboutInterface>(`${API.EMPLOYEES}/${employeeId}/personalInfo/about`)

export const useGetEmployeeAbout = (
  employeeId: number | string | undefined,
  fields?: string[],
) =>
  useFetch<EmployeeAboutInterface>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/personalInfo/about` : null,
    undefined,
    { params: fields?.length ? { fields: fields.join(',') } : undefined },
  )

export const employeeAboutRequests: RequestInterfaceNew<EmployeeEmergencyContactInterface> =
  {
    get: async ({ id }) =>
      apiWithoutHandling.get(`${API.EMPLOYEES}/${id}/personalInfo/about`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.EMPLOYEES}/${id}/personalInfo/about`, data),
    submit: async (data, { id }) =>
      apiWithoutHandling.post(`${API.EMPLOYEES}/${id}/personalInfo/about`, data),
  }

export const getEmployeeContractChangelogRequests =
  ({ contractId, employeeId }: { contractId: string; employeeId: string }) =>
  ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<ChangelogInterface<EmployeeContractsInterface>>>(
      `${API.EMPLOYEES}/${employeeId}/contracts/${contractId}/changelog`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    )

export const createJiraLifecycleTicket = (id: number | string) =>
  api.post<JiraLifecycleTicketCreationInterface>(`${API.EMPLOYEES}/${id}/lifecycleTicket`)

export const createEmployeeSimple = (data: EmployeesSimpleCreateInterface) =>
  apiWithoutHandling.post(API.EMPLOYEES_SIMPLE, {
    ...data,
    /** API model accepts array, but we need single select in the form */
    access_groups: data.access_groups?.id ? [{ group_id: data.access_groups.id }] : [],
  })

export const getEmployeesSimpleStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<EmployeeSimpleStatsFormattedInterface> => {
  return api
    .get<EmployeesSimpleStatsInterface>(`${API.EMPLOYEES_SIMPLE}/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
    .then(response => ({
      ...response,
      data: {
        ...response.data,
        errors: 0,
        success: 0,
        warnings: 0,
        state_pending: 0,
        state_success: 0,
        state_failure: 0,
      },
    }))
}

export const useEmployeesSimpleSelector = (params: Record<string, string>) => {
  const result = useFetch<{ ids: number[] }>(
    `${API.EMPLOYEES_SIMPLE}/ids`,
    'v1',
    { params },
    true,
  )
  return { options: result.data?.ids || [], refetch: result.refetch }
}

export const employeesArchive = (id: number) =>
  apiWithoutHandling.post(`/employees/${id}/archive`)

export const employeesBulkArchive = (ids: number[]) =>
  apiWithoutHandling.post(`/employees/bulkArchive`, { ids })

export const useGenerateSecretKey = (id: number) =>
  usePost<{ secretkey: string }, { secretkey: string }, number>(
    `${API.SERVICE_ACCOUNTS}/${id}/secretKey`,
  )
