import React from 'react'
import { MoreBar, Dropdown, Icon, Token } from '@revolut/ui-kit'

import { EmployeeButtonProps } from '../../types'
import { useCanViewGetActivationLink } from '../../common'
import { useEmployeeActivationLinkAction } from './hooks'
import { GetActivationLinkPopup } from './GetActivationLinkPopup'

type GetActivationLinkProps = EmployeeButtonProps & {
  pending: boolean
  onClick: () => void
}
export const GetActivationLinkAction = ({
  data,
  isDropdownItem,
  pending,
  onClick,
}: GetActivationLinkProps) => {
  const canView = useCanViewGetActivationLink({ data })

  if (!canView) {
    return null
  }

  const icon = <Icon name="16/Link" size={16} color={Token.color.blue} />

  if (isDropdownItem) {
    return (
      <Dropdown.Item use="button" useIcon={icon} onClick={onClick} disabled={pending}>
        Get activation link
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action onClick={onClick} pending={pending} useIcon={icon}>
      Get activation link
    </MoreBar.Action>
  )
}

export const GetActivationLink = ({ data }: EmployeeButtonProps) => {
  const { pending, onClick, showPopup, setShowPopup, link } =
    useEmployeeActivationLinkAction({
      employee: data,
    })
  return (
    <>
      <GetActivationLinkAction data={data} pending={pending} onClick={onClick} />
      <GetActivationLinkPopup
        open={showPopup}
        onClose={() => setShowPopup(false)}
        link={link}
      />
    </>
  )
}
