import React, { useState } from 'react'
import { useTable } from '@components/Table/hooks'
import {
  ColumnCellInterface,
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import { Action, TableWidget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { BaseBulkActionsInterface } from '@src/interfaces/hiringProccess'
import { TableRequestInterface } from '@src/interfaces'
import {
  bulkActionsActionsColumn,
  bulkActionsCompleteDateTimeColumn,
  bulkActionsCreateDateTimeColumn,
  bulkActionsFailedItemsCountColumn,
  bulkActionsItemsCountColumn,
  bulkActionsStatusColumn,
  bulkActionsTriggeredByColumn,
} from '@src/constants/columns/bulkActions'
import BulkActionsResultSidebar from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/BulkActionsResultSidebar'

const rows: <T extends BaseBulkActionsInterface>(
  columns: ColumnCellInterface<T>[],
  setOpenSidebarData: (item: T) => void,
) => RowInterface<T> = (columns, setOpenSidebarData) => ({
  cells: [
    {
      ...bulkActionsTriggeredByColumn,
      width: 140,
    },
    {
      ...bulkActionsCreateDateTimeColumn,
      width: 140,
    },
    {
      ...bulkActionsCompleteDateTimeColumn,
      width: 140,
    },
    {
      ...bulkActionsItemsCountColumn,
      width: 80,
    },
    {
      ...bulkActionsFailedItemsCountColumn,
      width: 80,
    },
    ...columns,
    {
      ...bulkActionsStatusColumn,
      width: 140,
    },
    {
      ...bulkActionsActionsColumn,
      width: 80,
      insert: ({ data }) => (
        <Action onClick={() => setOpenSidebarData(data)}>View</Action>
      ),
    },
  ],
})

interface Props<T> {
  filterBy?: FilterByInterface[]
  sortBy?: SortByInterface[]
  tableRequest: TableRequestInterface<T>
  columns: ColumnCellInterface<T>[]
  dataType: string
}

const BulkActions = <T extends BaseBulkActionsInterface>({
  tableRequest,
  columns,
  filterBy,
  sortBy,
  dataType,
}: Props<T>) => {
  const [openSidebarData, setOpenSidebarData] = useState<T>()
  const table = useTable<T>(tableRequest, filterBy, [
    ...(sortBy || []),
    {
      sortBy: 'creation_date_time',
      direction: SORT_DIRECTION.DESC,
    },
  ])

  return (
    <>
      <TableWidget>
        <TableWidget.Table>
          <AdjustableTable<T>
            name={TableNames.BulkActions}
            onRowClick={data => {
              setOpenSidebarData(data)
            }}
            useWindowScroll
            dataType={dataType}
            row={rows(columns, setOpenSidebarData)}
            {...table}
            noDataMessage="Bulk actions will appear here."
          />
        </TableWidget.Table>
      </TableWidget>
      {openSidebarData && (
        <BulkActionsResultSidebar
          onClose={() => setOpenSidebarData(undefined)}
          item={openSidebarData}
        />
      )}
    </>
  )
}

export default BulkActions
