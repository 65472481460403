import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiWithoutHandling } from './index'
import { API, PerformanceTimeRange } from '../constants/api'
import { GetRequestInterface, RequestInterface, RequestInterfaceNew } from '../interfaces'
import { FunctionInterface, TalentStatsInterface } from '../interfaces/functions'
import { AxiosPromise } from 'axios'
import { PerformanceChartCycles } from '../interfaces/chart'
import { getMonthsByRange } from '@src/utils/kpi'
import { HierarchyNodeInterface } from '@src/interfaces/hierarchy'
import { EmployeeInterface } from '@src/interfaces/employees'
import { getTalentStats } from './talent'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { useFetch } from '@src/utils/reactQuery'

export const functionsRequests: RequestInterface<
  FunctionInterface,
  TalentStatsInterface
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.FUNCTIONS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.FUNCTIONS}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.FUNCTIONS}/${id}`, data),
  deleteItem: async id => api.delete(`${API.FUNCTIONS}/${id}`),
  postItem: async data => api.post(API.FUNCTIONS, data),
}

export const functionsRequestsNew: RequestInterfaceNew<FunctionInterface> = {
  get: async ({ id }) => api.get(`${API.FUNCTIONS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.FUNCTIONS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.FUNCTIONS, data),
  delete: async ({ id }) => api.delete(`${API.FUNCTIONS}/${id}`),
}

export const silentDeleteFunction = async ({ id }: { id: number }) =>
  apiWithoutHandling.delete(`${API.FUNCTIONS}/${id}`)

export const archiveFunction = async (id: number) =>
  api.post(`${API.FUNCTIONS}/${id}/archive`)

export const unarchiveFunction = async (id: number) =>
  api.post(`${API.FUNCTIONS}/${id}/unarchive`)

export const getFunctionsStats = async (id: string | number) =>
  api.get<TalentStatsInterface>(`${API.FUNCTIONS}/${id}/stats`)

export const getKpiPerformanceGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.FUNCTIONS}/${id}/kpiPerformance`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
    },
  })

export const getFunctionalHierarchyEmployee = (
  functionId: number,
  employeeId: number,
) => {
  return api.get<HierarchyNodeInterface>(
    `${API.EMPLOYEES}/${employeeId}/functionalHierarchy/downward${
      functionId ? `?function__id=${functionId}` : ''
    }`,
  )
}

export const getFunctionalHierarchy = (
  functionId: number,
  employeeId: number,
  filters: { only_from_function?: boolean; employee_type?: 'internal' },
) => {
  return api.get<HierarchyNodeInterface>(
    `${API.FUNCTIONS}/${functionId}/hierarchy/${employeeId}`,
    {
      params: filters,
    },
  )
}

export const getDirectHierarchyEmployee = (
  employeeId: number,
  isRequisition: boolean,
) => {
  return api.get<HierarchyNodeInterface>(
    `${API.EMPLOYEES}/${employeeId}/directHierarchy/downward`,
    {
      params: {
        include_requisitions: isRequisition,
      },
    },
  )
}

export const getEmployeeHierarchyFunctional = (employeeId: number) => {
  return api.get<HierarchyNodeInterface>(
    `${API.EMPLOYEES}/${employeeId}/functionalHierarchy/downwardWithManager`,
  )
}

export const getEmployeeHierarchyDirect = (
  employeeId: number,
  isRequisition: boolean,
) => {
  return api.get<HierarchyNodeInterface>(
    `${API.EMPLOYEES}/${employeeId}/directHierarchy/downwardWithManager`,
    {
      params: {
        include_requisitions: isRequisition,
      },
    },
  )
}

export const useGetEmployeeDirectHierarchy = (
  employeeId: number | string | undefined,
  isRequisition?: boolean,
) =>
  useFetch<HierarchyNodeInterface>(
    employeeId
      ? `${API.EMPLOYEES}/${employeeId}/directHierarchy/downwardWithManager`
      : null,
    undefined,
    { params: { include_requisitions: !!isRequisition } },
  )

export const useGetEmployeeDirectHierarchyUpward = (employeeId: number | string) =>
  useFetch<{ employees: HierarchyNodeInterface[] }>(
    `${API.EMPLOYEES}/${employeeId}/directHierarchy/upward`,
  )

export const getWrongReportingEmployee = (
  functionId: number,
): AxiosPromise<GetRequestInterface<EmployeeInterface>> => {
  return api.get<GetRequestInterface<EmployeeInterface>>(
    `${API.EMPLOYEES}/functionalHierarchy/missingEmployees/${functionId}`,
  )
}

export const getHierarchyEmployeePath = (
  functionId: number,
  employeeId: number | string,
) => {
  return api.get<{ employees: HierarchyNodeInterface[] }>(
    `${API.EMPLOYEES}/${employeeId}/functionalHierarchy/upward${
      functionId ? `?function__id=${functionId}` : ''
    }`,
  )
}

export const functionTalentTabStats = (id: string) =>
  getTalentStats('position__function__id', id)

export const getFunctionSeniorities = (id: string | number) => {
  return api.get<SeniorityInterface[]>(`${API.FUNCTIONS}/${id}/seniorities`)
}
