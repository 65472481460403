import { UpdateTypes } from '@src/interfaces/kpis'

export const updateTypeOptionMap: Record<
  UpdateTypes,
  { name: string; description: string }
> = {
  [UpdateTypes.manual]: {
    name: 'Manual',
    description: 'Manually update the current value',
  },
  [UpdateTypes.sql]: {
    name: 'SQL',
    description: 'Use a SQL query to automatically update the current value',
  },
  [UpdateTypes.roadmap]: {
    name: 'Roadmap',
    description: 'Track the total progress of linked roadmap items',
  },
  [UpdateTypes.looker]: {
    name: 'Looker',
    description: 'Use a Look to automatically update the current value',
  },
}
