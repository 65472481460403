import React, { useState } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Bar, FilterButton, MoreBar, TableWidget, Token } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { OfferTemplateInterface } from '@src/interfaces/offerTemplates'
import { offerTemplatesTableRequests } from '@src/api/offerTemplates'
import { Plus } from '@revolut/icons'
import SettingsButtons from '@src/features/SettingsButtons'
import {
  offerTemplateCreatedByColumn,
  offerTemplateCreatedOnColumn,
  offerTemplateNameColumn,
  offerTemplateStatusColumn,
} from '@src/constants/columns/offerForm/offerTemplates'
import OfferPreviewSidebar from '@components/OfferPreviewSidebar/OfferPreviewSidebar'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import SearchTable from '@components/Table/SearchTable/SearchTable'

const rows: RowInterface<OfferTemplateInterface> = {
  highlight: data => (data.status === 'archived' ? Token.color.greyTone5 : ''),
  cells: [
    {
      ...offerTemplateNameColumn,
      width: 140,
    },
    {
      ...offerTemplateCreatedByColumn,
      width: 300,
    },
    {
      ...offerTemplateCreatedOnColumn,
      width: 160,
    },
    {
      ...offerTemplateStatusColumn,
      width: 160,
    },
  ],
}

export const OfferTemplates = () => {
  const [openTemplateId, setOpenTemplateId] = useState<number>()
  const table = useTable<OfferTemplateInterface>(offerTemplatesTableRequests, [
    {
      filters: [{ name: 'active', id: 'active' }],
      columnName: 'status',
      nonResettable: true,
    },
  ])

  const [showArchived, setShowArchived] = useState(() =>
    table.filterBy.some(
      filter =>
        filter.columnName === 'status' && filter.filters.some(f => f.id === 'archived'),
    ),
  )
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddOfferFormTemplate)

  const onToggleArchived = () => {
    setShowArchived(!showArchived)
    table.onFilterChange([
      {
        filters: showArchived
          ? [{ id: 'active', name: 'active' }]
          : [
              { id: 'active', name: 'active' },
              { id: 'archived', name: 'archived' },
            ],
        columnName: 'status',
      },
    ])
  }

  return (
    <>
      <OfferPreviewSidebar
        templateId={openTemplateId}
        onClose={() => setOpenTemplateId(undefined)}
      />
      <TableWidget>
        <TableWidget.Info />
        <TableWidget.Search>
          <SearchTable
            placeholder="Search by name"
            mb="s-24"
            onFilter={table.onFilterChange}
          />
        </TableWidget.Search>
        <TableWidget.Actions>
          {canAdd && (
            <SettingsButtons mb="s-16">
              <MoreBar.Action
                useIcon={Plus}
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.OFFER_TEMPLATE.GENERAL)}
              >
                Create new
              </MoreBar.Action>
            </SettingsButtons>
          )}
        </TableWidget.Actions>
        <TableWidget.Filters>
          {' '}
          <Bar>
            <FilterButton onClick={onToggleArchived} active={showArchived}>
              Archived
            </FilterButton>
          </Bar>
        </TableWidget.Filters>
        <TableWidget.Table>
          <AdjustableTable<OfferTemplateInterface>
            name={TableNames.OfferTemplates}
            useWindowScroll
            dataType="Offer templates"
            row={rows}
            {...table}
            noDataMessage="Offer templates will appear here."
            onRowClick={data => setOpenTemplateId(data.id)}
          />
        </TableWidget.Table>
      </TableWidget>
    </>
  )
}
