import Form from '@src/features/Form/Form'
import { jiraPreferencesRequests } from '@src/api/integrations'
import React from 'react'
import { JiraForm } from '@src/pages/Hub/Integrations/Jira/Jira'

const JiraIntegration = () => {
  return (
    <Form api={jiraPreferencesRequests} forceParams={{ id: '1' }}>
      <JiraForm />
    </Form>
  )
}

export default JiraIntegration
