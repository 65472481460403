import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { env, Environments } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import ErrorBoundary from '@src/features/ErrorBoundary/ErrorBoundary'
import { getTenantSubdomain, isProd, isWorkspacesEnabled } from '@src/utils'
import { useCookieConsentContext } from '@src/pages/Landing/hooks/useCookieConsentContext'
import { useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'

const GOOGLE_ANALYTICS_ID = 'G-1RS7ZF8C0W'
const GOOGLE_ANALYTICS_TENANTS_ID = 'G-4PGRRMCE5N'

// we don't need to send pageview here, as we have SPA and send pageviews manually on page change
const getGAInitCode = (gaId: string) => `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${gaId}', { 'send_page_view': false });  
`

export enum AnalyticsEvents {
  page_view = 'page_view',
  update_form_success = 'update_form_success',
  update_form_error = 'update_form_error',
  submit_form_success = 'submit_form_success',
  submit_form_error = 'submit_form_error',
  table_loaded_success = 'table_loaded_success',
  table_loaded_error = 'table_loaded_error',
  click_screen_candidates_btn = 'click_screen_candidates_btn',
  click_bulk_archive_btn = 'click_bulk_archive_btn',
  click_bulk_send_emails_btn = 'click_bulk_send_emails_btn',
  prefill_candidate_from_pdf_cv = 'prefill_candidate_from_pdf_cv',
  click_candidates_table_filter_btn = 'click_candidates_table_filter_btn',
  pin_app = 'pin_app',
  unpin_app = 'unpin_app',
  reorder_apps = 'reorder_apps',
  pin_tab = 'pin_tab',
  unpin_tab = 'unpin_tab',
  reorder_tabs = 'reorder_tabs',
  click_update_table_column_settings_btn = 'click_update_table_column_settings_btn',
  click_show_existing_candidates = 'click_show_existing_candidates',
  booking_link_generated = 'booking_link_generated',
  booking_link_created = 'booking_link_created',
  booking_link_opened_by_candidate = 'booking_link_opened_by_candidate',
  booking_link_timeslot_selected_by_candidate = 'booking_link_timeslot_selected_by_candidate',
  booking_link_sent_by_candidate = 'booking_link_sent_by_candidate',
  new_candidate_sourced = 'new_candidate_sourced',
  existing_candidate_profile_visit = 'existing_candidate_profile_visit',
  click_full_candidate_profile = 'click_full_candidate_profile',
  edit_scheduled_interview = 'edit_scheduled_interview',
  schedule_interview_manual = 'schedule_interview_manual',
  schedule_interview_custom = 'schedule_interview_custom',
  schedule_interview_auto = 'schedule_interview_auto',
  schedule_prep_call_manual = 'schedule_prep_call_manual',
  schedule_prep_call_custom = 'schedule_prep_call_custom',
  schedule_prep_call_auto = 'schedule_prep_call_auto',
  cancel_interview = 'cancel_interview',
  click_add_time_code_interview = 'click_add_time_code_interview',
  press_shortcut_add_time_code_interview = 'press_shortcut_add_time_code_interview',
  click_add_feedback_candidate_profile = 'click_add_feedback_candidate_profile',
  click_change_stage_candidate_profile = 'click_change_stage_candidate_profile',
  click_next_stage_candidate_profile = 'click_next_stage_candidate_profile',
  click_generate_feedback_candidate_profile = 'click_generate_feedback_candidate_profile',
  change_app_theme = 'change_app_theme',
  change_login_theme = 'change_login_theme',
  careers_application_new_flow_view = 'CareersApplication_NewFlowView',
  careers_application_new_flow_submit_success = 'CareersApplication_NewFlowSubmitSuccess',
}

const queueGAEvents: { command: keyof Gtag.GtagCommands; action: string; params?: {} }[] =
  []

const sendGAEvent = (command: keyof Gtag.GtagCommands, action: string, params?: {}) => {
  if (typeof window?.gtag === 'function') {
    window.gtag(command, action, params)
  } else if (isProd()) {
    /* when user renders the page the first time we could have some events fired before the GA script loaded
     *  that's why we push them to the queue and send to GA as soon as it's loaded
     * */
    queueGAEvents.push({ command, action, params })
  } else {
    // eslint-disable-next-line no-console
    console.debug(`[GA event] ${action}`, params)
  }
}

// DO NOT USE IT DIRECTLY. INSTEAD, USE sendAnalyticsEvent FROM useAnalytics()
const sendGoogleAnalyticsEvent = (action: AnalyticsEvents, params?: object) => {
  sendGAEvent('event', action, params)
}

// DO NOT USE IT DIRECTLY. INSTEAD, USE sendPageView FROM useAnalytics()
const sendGooglePageViewEvent = () => {
  sendGAEvent('event', AnalyticsEvents.page_view)
}

const handleRouteChange = (url: string, gaId: string) => {
  sendGAEvent('config', gaId, {
    page_path: url,
  })
}

export interface AnalyticsContextInterface {
  sendAnalyticsEvent: (action: AnalyticsEvents, params?: object) => void
  sendPageView: (
    pageTitle: string,
    pageLocation: string,
    additionalParams?: object,
  ) => void
}

const getCompanyName = () => {
  switch (env) {
    case Environments.productionCommercialRoot:
      return 'commercial_root'

    case Environments.productionCommercial: {
      return getTenantSubdomain('Analytics') || 'commercial_unknown'
    }

    default:
      return 'revolut'
  }
}

const GoogleAnalyticsWrapperContent: React.FC = ({ children }) => {
  const location = useLocation()
  const [isGALoaded, setGALoaded] = useState(false)
  const user = useSelector(selectUser)
  const { isAnalyticsEnabled } = useCookieConsentContext()
  const workspaceContext = useWorkspaceContext()

  const tenantEnvs: Environments[] = [
    Environments.productionCommercial,
    Environments.productionCommercialRoot,
  ]

  const gaId =
    env && tenantEnvs.includes(env) ? GOOGLE_ANALYTICS_TENANTS_ID : GOOGLE_ANALYTICS_ID

  useEffect(() => {
    if (isGALoaded) {
      handleRouteChange(location.pathname, gaId)
    }
  }, [location, isGALoaded])

  useEffect(() => {
    if (isGALoaded && 'gtag' in window) {
      if (isWorkspacesEnabled() && workspaceContext?.workspace) {
        window.gtag?.('set', 'user_properties', {
          company: workspaceContext.workspace,
        })
      } else {
        window.gtag?.('set', 'user_properties', {
          company: getCompanyName(),
        })
      }
    }
  }, [isGALoaded, workspaceContext?.workspace])

  useEffect(() => {
    if (isGALoaded && 'gtag' in window && user?.user_uuid) {
      window.gtag?.('set', { user_id: String(user.user_uuid) })
    }
  }, [user?.user_uuid])

  useEffect(() => {
    if (!isProd() || !isAnalyticsEnabled) {
      setGALoaded(true)
      return () => {}
    }

    const gtmScript = document.createElement('script')
    gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`

    const initScript = document.createElement('script')
    initScript.innerHTML = getGAInitCode(gaId)

    document.head.appendChild(gtmScript)

    gtmScript.onload = () => {
      document.head.appendChild(initScript)
      if (typeof window?.gtag === 'function' && queueGAEvents.length) {
        queueGAEvents.forEach(event => {
          sendGAEvent(event.command, event.action, event.params)
        })
        queueGAEvents.length = 0
      }
      setGALoaded(true)
    }

    return () => {
      document.head.removeChild(gtmScript)
      document.head.removeChild(initScript)
    }
  }, [isAnalyticsEnabled])

  return <>{children}</>
}

export const GoogleAnalyticsWrapper: React.FC<{ isTestingEnv?: boolean }> = ({
  children,
  isTestingEnv,
}) => {
  if (isTestingEnv) {
    return <GoogleAnalyticsWrapperContent>{children}</GoogleAnalyticsWrapperContent>
  }
  return (
    <ErrorBoundary fallback={children}>
      <GoogleAnalyticsWrapperContent>{children}</GoogleAnalyticsWrapperContent>
    </ErrorBoundary>
  )
}

export const AnalyticsContext = createContext<AnalyticsContextInterface>({
  sendAnalyticsEvent: () => {
    console.error('App is not wrapped by AnalyticsContextProvider')
  },
  sendPageView: () => {
    console.error('App is not wrapped by AnalyticsContextProvider')
  },
})

export const useAnalytics = () => useContext(AnalyticsContext)

export const AnalyticsContextProvider: React.FC = ({ children }) => {
  const value = useMemo<AnalyticsContextInterface>(
    () => ({
      sendAnalyticsEvent: sendGoogleAnalyticsEvent,
      sendPageView: sendGooglePageViewEvent,
    }),
    [sendGoogleAnalyticsEvent, sendGooglePageViewEvent],
  )

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>
}
