import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Cell, Color, Dropdown, Flex, MoreBar, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

import { useTable } from '@components/Table/hooks'
import {
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import {
  ChangelogChangeRequestInterface,
  ChangelogInterface,
} from '@src/interfaces/changelog'
import {
  cancelEmployeeFieldRequest,
  cancelEmployeeFieldsRequest,
  changelogEmployeeRequests,
  fieldChangelogEmployeeRequests,
} from '@src/api/changelog'
import {
  changelogAction,
  changelogChangedBy,
  changelogChangedFields,
  changelogChangedOn,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { EmployeeInterface, IdStatuses } from '@src/interfaces/employees'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import Dialog from '@components/Modals/Dialog/Dialog'
import LegacyButton from '@components/Button/Button'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { Statuses } from '@src/interfaces'
import AdjustableTable from '@components/Table/AdjustableTable'
import { selectorKeys } from '@src/constants/api'
import { Plus, SwitchOff, SwitchOn } from '@revolut/icons'
import { EmployeeChangeRequestParams } from '@src/interfaces/employeeChangeRequest'
import { approveChangeRequest, rejectChangeRequest } from '@src/api/employeeChangeRequest'
import { TableNames } from '@src/constants/table'

const CustomDialog = styled(Dialog)`
  padding: 32px;
  max-width: 480px;
`

const DialogTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
`

const DialogMessage = styled.div`
  margin-bottom: 24px;
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  justify-content: end;
  align-items: center;
`

const commonButtonProps = {
  variant: 'text',
  size: 'sm',
  p: 0,
  height: 's-24',
} as const

interface ChangelogProps {
  data: EmployeeInterface
}

const ChangelogEmployeeRow: RowInterface<ChangelogInterface<EmployeeInterface>> = {
  highlight: data => {
    if (data.status?.id === Statuses.pending) {
      return Token.color.orange_5
    }

    return ''
  },
  noChildrenRequest: true,
  isNotNested: true,
  cells: [
    {
      ...changelogChangedFields,
      selectorsKey: selectorKeys.employee_changelog_fields,
      width: 240,
    },
    {
      ...changelogEffectiveDate,
      width: 240,
    },
    {
      ...changelogChangedBy,
      width: 240,
    },
    {
      ...changelogChangedOn,
      width: 240,
    },
    {
      ...changelogStatus,
      width: 240,
    },
  ],
}

const FieldsChangelogEmployeeRow: RowInterface<ChangelogInterface<EmployeeInterface>> = {
  highlight: data => {
    if (data.status?.id === Statuses.pending) {
      return Token.color.orange_5
    }

    return ''
  },
  noChildrenRequest: true,
  isNotNested: true,
  cells: [
    {
      ...changelogFieldsChangedFields,
      selectorsKey: selectorKeys.employee_changelog_fields,
      width: 210,
    },
    {
      ...changelogEffectiveDate,
      width: 205,
    },
    {
      ...changelogFieldsNewValue,
      selectorsKey: selectorKeys.employee_changelog_values,
      width: 205,
    },
    {
      ...changelogChangedBy,
      width: 205,
    },
    {
      ...changelogChangedOn,
      width: 205,
    },
    {
      ...changelogStatus,
      width: 205,
    },
  ],
}

export const Changelog = ({ data }: ChangelogProps) => {
  const [showIndividual, setShowIndividual] = useState(true)
  const [canceling, setCanceling] = useState(false)
  const [cancelingId, setCancelingId] = useState<(number | string)[] | null>(null)
  const [isChangeRequestDropdownOpen, setIsChangeRequestDropdownOpen] = useState(false)
  const permissions = useSelector(selectPermissions)
  const canCancel = permissions.includes(PermissionTypes.ChangeEmployeechangelogitem)
  const initialFilter: FilterByInterface[] = [
    {
      columnName: 'target__id',
      nonResettable: true,
      filters: [{ id: data.id, name: data.full_name }],
    },
  ]

  const requestChangeButtonRef = useRef(null)

  useEffect(() => {
    if (showIndividual) {
      changelogFieldsTable.refresh()
    } else {
      changelogTable.refresh()
    }
  }, [showIndividual])

  const initialSort: SortByInterface[] = [
    {
      sortBy: 'effective_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const changelogTable = useTable<ChangelogInterface<EmployeeInterface>>(
    changelogEmployeeRequests(data.id),
    initialFilter,
    initialSort,
  )
  const changelogFieldsTable = useTable<ChangelogInterface<EmployeeInterface>>(
    fieldChangelogEmployeeRequests(data.id),
    initialFilter,
    initialSort,
  )

  ChangelogEmployeeRow.cells[5] = {
    ...changelogAction,
    width: 240,
    insert: ({ data: cellData }) => {
      if (
        cellData.status?.id === Statuses.pending &&
        canCancel &&
        cellData.change_request == null
      ) {
        return (
          <Button
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              setCancelingId([cellData.id])
            }}
            {...commonButtonProps}
          >
            Cancel
          </Button>
        )
      }

      return (
        <ChangeRequestButtons
          request={cellData.change_request}
          employeeId={data.id}
          onSuccess={() => {
            changelogTable.refresh()
            changelogFieldsTable.refresh()
          }}
        />
      )
    },
  }

  FieldsChangelogEmployeeRow.cells[6] = {
    ...changelogAction,
    width: 205,
    insert: ({ data: cellData }) => {
      if (
        data.status?.id === IdStatuses.pending &&
        canCancel &&
        cellData.change_request == null
      ) {
        return (
          <Button
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              setCancelingId([cellData.changelog_item_id!, cellData.field_name])
            }}
            {...commonButtonProps}
          >
            Cancel
          </Button>
        )
      }

      return (
        <ChangeRequestButtons
          request={cellData.change_request}
          employeeId={data.id}
          onSuccess={() => {
            changelogTable.refresh()
            changelogFieldsTable.refresh()
          }}
        />
      )
    },
  }

  const cancelChanges = async (id: number) => {
    const result = await cancelEmployeeFieldsRequest(id, data.id)

    if (result.data) {
      changelogTable.refresh()
      changelogFieldsTable.refresh()

      pushNotification({
        value: 'Change was successfully cancelled.',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    }
  }

  const cancelChange = async (id: (number | string)[]) => {
    const result = await cancelEmployeeFieldRequest(
      id[0] as string,
      id[1] as number,
      data.id,
    )

    if (result.data) {
      changelogFieldsTable.refresh()
      changelogTable.refresh()

      pushNotification({
        value: 'Change was successfully cancelled.',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    }
  }

  const handleConfirmCancel = async () => {
    try {
      setCanceling(true)
      if (showIndividual) {
        cancelChange(cancelingId!)
      } else {
        cancelChanges(cancelingId?.[0] as number)
      }
      setCancelingId(null)
    } finally {
      setCanceling(false)
    }
  }

  const handleRowClick = (change: ChangelogInterface<EmployeeInterface>) => {
    if (change.change_request != null && change.change_request.can_approve) {
      navigateTo(
        pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.DETAILS, {
          employeeId: data.id,
          id: change.change_request.change_request_set.id,
        }),
      )
      return
    }

    if (showIndividual) {
      navigateReplace(
        `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, { id: data.id })}?change=${
          change.id
        }`,
      )
    } else {
      navigateReplace(
        `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, { id: data.id })}?changes=${
          change.id
        }`,
      )
    }
  }

  return (
    <>
      <Cell mb="s-20">
        <Flex flexDirection="column" width="100%">
          <Box mb="s-16" ref={requestChangeButtonRef}>
            <MoreBar>
              <MoreBar.Action
                onClick={() =>
                  setIsChangeRequestDropdownOpen(!isChangeRequestDropdownOpen)
                }
                useIcon={Plus}
              >
                Request a change
              </MoreBar.Action>
              <MoreBar.Action
                onClick={() => setShowIndividual(!showIndividual)}
                useIcon={showIndividual ? SwitchOn : SwitchOff}
              >
                Show individual fields
              </MoreBar.Action>
            </MoreBar>
          </Box>
          <Dropdown
            open={isChangeRequestDropdownOpen}
            anchorRef={requestChangeButtonRef}
            onClose={() => setIsChangeRequestDropdownOpen(false)}
          >
            <Dropdown.Item
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
                    employeeId: `${data.id}`,
                    type: 'name',
                  } as EmployeeChangeRequestParams),
                )
              }
              use="button"
            >
              Name
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
                    employeeId: `${data.id}`,
                    type: 'organisation',
                  } as EmployeeChangeRequestParams),
                )
              }
              use="button"
            >
              Organisation
            </Dropdown.Item>
          </Dropdown>
          {showIndividual ? (
            <AdjustableTable<ChangelogInterface<EmployeeInterface>>
              name={TableNames.EmployeeFieldsChangelog}
              useWindowScroll
              dataType="Change"
              row={FieldsChangelogEmployeeRow}
              {...changelogFieldsTable}
              onRowClick={handleRowClick}
              noDataMessage="There are no changes to display"
            />
          ) : (
            <AdjustableTable<ChangelogInterface<EmployeeInterface>>
              name={TableNames.EmployeeChangelog}
              useWindowScroll
              dataType="Change"
              row={ChangelogEmployeeRow}
              {...changelogTable}
              onRowClick={handleRowClick}
              noDataMessage="There are no changes to display"
            />
          )}
        </Flex>
      </Cell>

      <CustomDialog
        open={cancelingId !== null}
        onClose={() => setCancelingId(null)}
        showCloseButton
      >
        <DialogTitle>Cancel this future update?</DialogTitle>
        <DialogMessage>
          Cancelling the future update can not be undone. Continue only if you are 100%
          sure of this.
        </DialogMessage>
        <ButtonContainer>
          <LegacyButton square styles="tertiary" onClick={() => setCancelingId(null)}>
            Go back
          </LegacyButton>
          <LegacyButton square loading={canceling} onClick={handleConfirmCancel}>
            Confirm Cancellation
          </LegacyButton>
        </ButtonContainer>
      </CustomDialog>
    </>
  )
}

interface ChangeRequestButtonsProps {
  request?: ChangelogChangeRequestInterface | null
  employeeId: number
  onSuccess: () => void
}

const ChangeRequestButtons = ({
  request,
  employeeId,
  onSuccess,
}: ChangeRequestButtonsProps) => {
  const [approvePending, setApprovePending] = useState(false)
  const [rejectPending, setRejectPending] = useState(false)

  if (request == null) {
    return null
  }

  const onApproveOrReject = async (operation: 'approve' | 'reject') => {
    const buttonStateSetter =
      operation === 'approve' ? setApprovePending : setRejectPending
    const approveRejectApi =
      operation === 'approve' ? approveChangeRequest : rejectChangeRequest

    buttonStateSetter(true)

    try {
      await approveRejectApi(employeeId, request.id)
      onSuccess()
      pushNotification({
        value: `Change was successfully ${
          operation === 'approve' ? 'approved' : 'rejected'
        }`,
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    } catch {
      buttonStateSetter(false)
    }
  }

  if (request.can_approve) {
    return (
      <Flex>
        <Button
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            onApproveOrReject('approve')
          }}
          {...commonButtonProps}
          mr="s-8"
          pending={approvePending}
          disabled={rejectPending}
        >
          Approve
        </Button>
        <Button
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            onApproveOrReject('reject')
          }}
          color={Color.RED}
          pending={rejectPending}
          disabled={approvePending}
          {...commonButtonProps}
        >
          Reject
        </Button>
      </Flex>
    )
  }

  return (
    <Button
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        navigateTo(
          pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.DETAILS, {
            employeeId,
            id: request.change_request_set.id,
          }),
        )
      }}
      {...commonButtonProps}
    >
      View request
    </Button>
  )
}
