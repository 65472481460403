import { ExclamationTriangle } from '@revolut/icons'
import { Banner, Box } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import React from 'react'

type Props = {
  employee: Partial<EmployeeInterface>
}

export const AnonymizedWarning = ({ employee }: Props) => {
  const visible = !!employee.anonymized?.is_anonymous

  if (!visible) {
    return null
  }

  return (
    <Box mb="s-16">
      <Banner>
        <Banner.Avatar>
          <ExclamationTriangle size={56} color="blue" />
        </Banner.Avatar>
        <Banner.Content>
          <Banner.Title>Some or all data on this page has been anonymised</Banner.Title>
          <Banner.Description>
            This is because some or all the data on this page has reached the end of the
            applicable data retention period.
          </Banner.Description>
        </Banner.Content>
      </Banner>
    </Box>
  )
}
