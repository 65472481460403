import React from 'react'

import { VStack, Token, Text } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'

import { updateTypeOptionMap } from './updateTypeOptionMap'
import { selectorKeys } from '@src/constants/api'

export const MetricTypeSelector = () => {
  const { apiErrors, values } = useLapeContext<KpiInterface & { tempId?: number }>()

  const updateTypeOption = values.update_type
    ? { id: values.update_type, ...updateTypeOptionMap[values.update_type] }
    : undefined

  return (
    <LapeRadioSelectInput<IdAndName<UpdateTypes>>
      label="Metric type"
      hasError={!updateTypeOption && !!apiErrors.targets?.length}
      message={
        !updateTypeOption && !!apiErrors.targets?.length
          ? 'Metric type is required'
          : undefined
      }
      value={updateTypeOption}
      onChange={option => {
        values.update_type = option?.id
      }}
      name="update_type"
      selector={selectorKeys.goal_target_types}
      searchable={false}
    >
      {option => (
        <VStack m="-s-2">
          <Text variant="primary">{updateTypeOptionMap[option.value.id].name}</Text>
          <Text variant="caption" color={Token.color.greyTone50}>
            {updateTypeOptionMap[option.value.id].description}
          </Text>
        </VStack>
      )}
    </LapeRadioSelectInput>
  )
}
