import React, { useState } from 'react'
import addDays from 'date-fns/addDays'
import {
  BottomSheet,
  Button,
  Description,
  Header,
  InputGroup,
  Flex,
  StatusPopup,
  Tag,
  TextArea,
  VStack,
  Widget,
  useStatusPopup,
} from '@revolut/ui-kit'
import { Database, Document } from '@revolut/icons'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { accessDashboardRequest } from '@src/api/analyticsDashboards'
import { DatePickerInput } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { arrayErrorsToFormError } from '@src/utils/form'
import { localDateToUtc } from '@src/utils/timezones'

interface Props {
  isOpen: boolean
  onClose: () => void
}

interface ApiErrorsInterface {
  from_date_time?: string
  to_date_time?: string
  reason?: string
}

export const RequestAccessPopup = ({ isOpen, onClose }: Props) => {
  const statusPopup = useStatusPopup()
  const { values } = useLapeContext<AnalyticsDashboardInterface>()

  const [inputErrors, setInputErrors] = useState<ApiErrorsInterface>({})
  const [isPending, setIsPending] = useState(false)
  const [fromDate, setFromDate] = useState<Date | null>(new Date())
  const [toDate, setToDate] = useState<Date | null>(null)
  const [reason, setReason] = useState<string | undefined>()

  const handleSubmit = async () => {
    setIsPending(true)
    try {
      await accessDashboardRequest(values.id, {
        from_date_time: fromDate ? localDateToUtc(fromDate) : fromDate,
        to_date_time: toDate ? localDateToUtc(toDate) : toDate,
        reason,
      })
      onClose()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Request was submitted</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch (error) {
      const apiErrors = arrayErrorsToFormError<ApiErrorsInterface>(error?.response?.data)

      if (apiErrors.from_date_time || apiErrors.to_date_time || apiErrors.reason) {
        setInputErrors(apiErrors)
      } else {
        onClose()

        statusPopup.show(
          <StatusPopup onClose={statusPopup.hide} variant="error">
            <StatusPopup.Title>Request was not submitted</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      }
    } finally {
      setIsPending(false)
    }
  }

  return (
    <BottomSheet onClose={onClose} open={isOpen}>
      <Header>
        <Header.Title>Request necessary accesses</Header.Title>
        <Header.Description>{`Please specify access duration and reason to access ${values.name} report`}</Header.Description>
      </Header>
      <InputGroup>
        <DatePickerInput
          aria-invalid={!!inputErrors.from_date_time}
          disabledDays={{ before: new Date() }}
          label="From Date"
          message={inputErrors.from_date_time}
          onChange={newDate => {
            setFromDate(newDate)

            if (inputErrors.from_date_time) {
              setInputErrors(prevState => {
                const { from_date_time: _, ...remainingObject } = prevState
                return remainingObject
              })
            }
          }}
          value={fromDate}
        />
        <DatePickerInput
          aria-invalid={!!inputErrors.to_date_time}
          disabledDays={{
            after: addDays(new Date(), 365),
            before: new Date(),
          }}
          label="To Date"
          message={inputErrors.to_date_time}
          onChange={newDate => {
            setToDate(newDate)

            if (inputErrors.to_date_time) {
              setInputErrors(prevState => {
                const { to_date_time: _, ...remainingObject } = prevState
                return remainingObject
              })
            }
          }}
          value={toDate}
        />
        <TextArea
          aria-invalid={!!inputErrors.reason}
          label="Reason for request"
          message={inputErrors.reason}
          onChange={e => {
            setReason(e.currentTarget.value)

            if (inputErrors.reason) {
              setInputErrors(prevState => {
                const { reason: _, ...remainingObject } = prevState
                return remainingObject
              })
            }
          }}
          rows={2}
          value={reason}
        />
      </InputGroup>
      <Widget my="s-16" p="s-16">
        <VStack gap="s-16">
          <Description>After approval you will be granted access to:</Description>
          <Description>Folders</Description>
          {values.folder ? (
            <Tag useIcon={Document} variant="outlined">
              {values.folder.name}
            </Tag>
          ) : (
            <Description>-</Description>
          )}
          <Description>Models</Description>
          {values.lookml_models.length ? (
            <Flex flexWrap="wrap" gap="s-8" rowGap="s-8">
              {values.lookml_models.map(model => (
                <Tag key={model.name} useIcon={Database} variant="outlined">
                  {model.name}
                </Tag>
              ))}
            </Flex>
          ) : (
            <Description>-</Description>
          )}
        </VStack>
      </Widget>
      <BottomSheet.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button elevated onClick={handleSubmit} pending={isPending}>
          Confirm
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
