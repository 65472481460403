import React from 'react'
import { Avatar, Item, Link, VStack } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { SHAREWORKS_LINK } from '@src/constants/externalLinks'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { EmployeePageHeader } from '@src/pages/EmployeeProfile/Layout/common/EmployeePageHeader'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { EquityWidget } from '../Components/EquityWidget'
import { GrantsTable } from '../Components/GrantsTable'

type Props = {
  compensationParams: CompensationParams
  data: EmployeeInterface
}

export const EquityOverview = ({ compensationParams, data }: Props) => {
  return (
    <>
      <EmployeePageHeader data={data} title="Your equity" />
      <VStack gap="s-16">
        <EquityWidget compensationParams={compensationParams} id={data.id} />

        <HideIfCommercial>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="InfoOutline" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Values on this page are estimates </Item.Title>
              <Item.Description>
                For accurate up-to-date figures please refer to your{' '}
                <Link href={SHAREWORKS_LINK} target="_blank">
                  Shareworks account
                </Link>
              </Item.Description>
            </Item.Content>
          </Item>
        </HideIfCommercial>

        <GrantsTable id={data.id} />
      </VStack>
    </>
  )
}
