import React, { useMemo, useState } from 'react'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { useFetchInterviewStages } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'
import { FilterButtonSkeleton, VStack } from '@revolut/ui-kit'
import { StageTypeFilter } from '@src/pages/Forms/Candidate/Performance/Summary/StageTypeFilter'
import { OptionInterface } from '@src/interfaces/selectors'
import {
  OverallImpressionFeedback,
  OverallImpressionFeedbackSkeleton,
} from '@src/pages/Forms/Candidate/Performance/Summary/OverallImpressionFeedback'

type OverallImpressionsProps = {
  round?: InterviewRoundInterface
  onViewScorecard: (feedbackIds: number[]) => void
}

export const OverallImpressionsSkeleton = () => {
  return (
    <VStack gap="s-16" px="s-16">
      <FilterButtonSkeleton />
      <OverallImpressionFeedbackSkeleton />
    </VStack>
  )
}

export const OverallImpressions = ({
  round,
  onViewScorecard,
}: OverallImpressionsProps) => {
  const { data, isLoading } = useFetchInterviewStages(round?.id)
  const [stageTypeFilter, setStageTypeFilter] = useState<OptionInterface[]>([])
  const currentStages = useMemo(() => {
    const stages = data.filter(({ interview_type }) => interview_type !== 'cv_screening')
    if (!stageTypeFilter.length) {
      return stages
    }
    const stageTypes = stageTypeFilter.map(({ id }) => id)
    return stages.filter(({ interview_type }) => stageTypes.includes(interview_type))
  }, [data, stageTypeFilter])
  if (!round) {
    return null
  }
  if (isLoading) {
    return <OverallImpressionsSkeleton />
  }
  return (
    <VStack gap="s-16" px="s-16">
      <StageTypeFilter
        value={stageTypeFilter}
        onChange={value => {
          setStageTypeFilter(value)
        }}
      />
      {currentStages.map(stage =>
        stage.interview_feedbacks.length
          ? stage.interview_feedbacks.map(interviewFeedback => (
              <OverallImpressionFeedback
                key={interviewFeedback.id}
                interviewFeedback={interviewFeedback}
                stage={stage}
                onViewScorecard={onViewScorecard}
              />
            ))
          : null,
      )}
    </VStack>
  )
}
