import React, { Fragment, useState } from 'react'
import {
  CandidateSidebarTypes,
  InterviewFeedbackInterface,
  InterviewResult,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  InterviewStatuses,
  InterviewType,
  ScheduleSidebarModeType,
} from '@src/interfaces/interviewTool'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'
import {
  Avatar,
  chain,
  Color,
  createChain,
  HStack,
  Icon,
  Item,
  Text,
  Token,
} from '@revolut/ui-kit'
import StageActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StageActions'
import { PermissionTypes } from '@src/store/auth/types'
import { StagesTableFeedback } from '@src/pages/Forms/Candidate/StagesTable/StagesTableFeedback'
import { formatDateTime } from '@src/utils/format'
import pluralize from 'pluralize'
import isBefore from 'date-fns/isBefore'
import { OnlineTestResultInterface } from '@src/interfaces/hiringProccess'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { AutomatedStageIndicator } from '@src/pages/Forms/Candidate/StagesTable/AutomatedStageIndicator'

const getStageIcon = (interviewType: InterviewType): ResolveIconType => {
  switch (interviewType) {
    case 'cv_screening':
      return 'Document'

    case 'screen_call':
      return 'Call'

    case 'home_assessment':
      return 'Utilities'

    case 'skills_round':
      return 'BarChart'

    case 'online_test':
      return 'Questionnaire'

    case 'final_round':
      return 'AccountActions'

    case 'offer':
      return 'Cheque'

    case 'hiring_panel':
      return 'People'

    default:
      return 'Apps'
  }
}

export const getStageBadge = (
  status: InterviewStatuses,
  feedbacks: InterviewFeedbackInterface[],
  displayStatus: string,
  onlineTestResult?: OnlineTestResultInterface,
): {
  icon?: ResolveIconType
  color?: Color
  text?: React.ReactNode
  textColor?: Color
} => {
  switch (status) {
    case 'feedback_submitted': {
      let noHireCount = 0
      let hireCount = 0
      let strongNoHireCount = 0
      let strongHireCount = 0

      feedbacks.forEach(feedback => {
        if (feedback.status === 'completed' && feedback.result) {
          switch (feedback.result) {
            case InterviewResult.Hire: {
              hireCount += 1
              break
            }

            case InterviewResult.StrongHire: {
              strongHireCount += 1
              break
            }

            case InterviewResult.StrongNoHire: {
              strongNoHireCount += 1
              break
            }

            case InterviewResult.NoHire: {
              noHireCount += 1
              break
            }
          }
        }
      })

      const total = noHireCount + hireCount + strongNoHireCount + strongHireCount

      if (feedbacks.every(feedback => feedback.status === 'rejected')) {
        return {
          icon: '16/Cross',
          color: Token.color.red,
          textColor: Token.color.red,
          text: `All ${pluralize(
            'interviewer',
            feedbacks.length,
            true,
          )} rejected feedbacks`,
        }
      }

      if (
        feedbacks.every(
          feedback => feedback.status !== 'pending' && feedback.status !== 'draft',
        )
      ) {
        const latest = feedbacks.reduce((acc, item) => {
          if (!acc) {
            return item
          }

          if (
            isBefore(new Date(acc.updated_date_time), new Date(item.updated_date_time))
          ) {
            return item
          }

          return acc
        })

        const text = chain(
          `${pluralize('interviewer', total, true)} completed feedback`,
          latest && `Latest on ${formatDateTime(latest.updated_date_time)}`,
        )

        if ((noHireCount || strongNoHireCount) && !hireCount && !strongHireCount) {
          return {
            icon: 'SocialDislike',
            color: Token.color.red,
            text,
          }
        }

        if ((hireCount || strongHireCount) && !noHireCount && !strongNoHireCount) {
          return {
            icon: 'SocialLike',
            color: Token.color.green,
            text,
          }
        }

        return {
          icon: 'LikeDislike',
          color: Token.color.yellow,
          text,
        }
      }
      break
    }

    case 'awaiting_feedback': {
      const pending = feedbacks.filter(
        feedback => feedback.status === 'pending' || feedback.status === 'draft',
      ).length
      const completed = feedbacks.filter(
        feedback => feedback.status === 'completed',
      ).length
      return {
        icon: '16/SandWatch',
        color: Token.color.orange,
        textColor: Token.color.orange,
        text: createChain(', ')(
          !!pending &&
            `${pluralize('interviewer', pending, true)} ${pluralize(
              'have',
              pending,
            )} pending feedback`,
          !!completed &&
            `${pluralize('interviewer', completed, true)} ${pluralize(
              'have',
              completed,
            )} completed their feedback`,
        ),
      }
    }

    case 'scheduling_expired':
    case 'offer_expired':
      return {
        icon: '16/StatusClockArrows',
        color: Token.color.grey50,
        text: displayStatus,
      }

    case 'interview_scheduled':
      return {
        icon: '16/SandWatch',
        color: Token.color.blue,
        text: displayStatus,
      }

    case 'pending_scheduling':
    case 'pending_candidate_response':
    case 'awaiting_interviewer_availability':
    case 'pending_cv_screening':
    case 'pending_candidate_signature':
      return {
        icon: '16/SandWatch',
        color: Token.color.orange,
        textColor: Token.color.orange,
        text: displayStatus,
      }

    case 'interviewer_rejected':
    case 'interview_cancelled':
    case 'offer_declined_recruiter':
    case 'offer_declined_candidate':
      return {
        icon: '16/Cross',
        color: Token.color.red,
        textColor: Token.color.red,
        text: displayStatus,
      }

    case 'test_sent':
      return {
        icon: 'SendMessage',
        color: Token.color.blue,
        text: `${displayStatus}${
          onlineTestResult?.sent_date_time
            ? ` on ${formatDateTime(onlineTestResult.sent_date_time)}`
            : ''
        }`,
      }

    case 'test_completed': {
      if (typeof onlineTestResult?.is_passed !== 'boolean') {
        return {
          icon: '16/SandWatch',
          color: Token.color.orange,
          textColor: Token.color.orange,
          text: `Online test is completed${
            onlineTestResult?.completed_date_time
              ? ` on ${formatDateTime(onlineTestResult.completed_date_time)}`
              : ''
          }, but has not been assessed`,
        }
      }

      return {
        icon: onlineTestResult.is_passed ? 'SocialLike' : 'SocialDislike',
        color: onlineTestResult.is_passed ? Token.color.green : Token.color.red,
        text: `${displayStatus}${
          onlineTestResult.completed_date_time
            ? ` on ${formatDateTime(onlineTestResult.completed_date_time)}`
            : ''
        }`,
      }
    }

    case 'offer_signed':
      return {
        icon: '16/Check',
        color: Token.color.green,
        textColor: Token.color.green,
        text: displayStatus,
      }

    case 'offer_cancelled_recruiter':
      return {
        icon: 'Reverted',
        color: Token.color.deepGrey,
        textColor: Token.color.deepGrey,
        text: displayStatus,
      }
  }

  return {
    text: displayStatus,
  }
}

interface Props {
  round: InterviewRoundInterface
  onClick?: (
    data: InterviewStageWithoutRoundInterface | InterviewFeedbackInterface,
    mode?: ScheduleSidebarModeType,
    stageType?: InterviewType,
    actionType?: CandidateSidebarTypes,
  ) => void
  onRefresh?: (onlyTable?: boolean) => void
  selectedItemId?: number
  disableActions?: boolean
  canViewEditOffer: boolean
  stages: InterviewStageWithoutRoundInterface[]
  candidateId: number
  stage: InterviewStageWithoutRoundInterface
}
export const StagesTableStage = ({
  stage,
  round,
  stages,
  onRefresh,
  onClick,
  canViewEditOffer,
  candidateId,
  disableActions,
  selectedItemId,
}: Props) => {
  const isArchived = !!round.archived_reason
  const [expanded, setExpanded] = useState(false)

  const canCancel = !!round.field_options?.permissions?.includes(
    PermissionTypes.CancelPendingScorecard,
  )
  const canAddFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.SeeAddFeedbackButton,
  )

  const badge = getStageBadge(
    stage.scheduling_status,
    stage.interview_feedbacks,
    stage.scheduling_status_display,
    stage.online_test_result,
  )

  return (
    <>
      <Fragment key={stage.id}>
        <Item
          use={onClick ? 'label' : undefined}
          key={stage.id}
          onClick={e => {
            e.preventDefault()
            setExpanded(true)
            onClick?.(stage, undefined, stage.interview_type)
          }}
          aria-pressed={selectedItemId === stage.id}
          aria-label={`Stage ${stage.title}`}
        >
          <Item.Prefix
            width={27}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              setExpanded(!expanded)
            }}
          >
            {stage.interview_feedbacks.length ? (
              <>
                {expanded ? (
                  <Icon name="ChevronDown" color={Token.color.greyTone20} />
                ) : (
                  <Icon name="ChevronRight" color={Token.color.greyTone20} />
                )}
              </>
            ) : (
              <Icon name="Dot" color={Token.color.greyTone20} />
            )}
          </Item.Prefix>
          <Item.Avatar>
            <Avatar
              useIcon={getStageIcon(stage.interview_type)}
              color={
                stage.id === round.latest_interview_stage?.id
                  ? Token.color.blue
                  : Token.color.deepGrey
              }
            >
              {badge.icon && (
                <Avatar.Badge useIcon={badge.icon} backgroundColor={badge.color} />
              )}
            </Avatar>
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              <HStack gap="s-8" align="center">
                <Text>{stage.title}</Text>
                <AutomatedStageIndicator stage={stage} />
              </HStack>
            </Item.Title>
            <Item.Description color={badge.textColor}>{badge.text}</Item.Description>
          </Item.Content>

          <Item.Side>
            <HStack gap="s-16">
              {stage.average_rating && (
                <PerformanceRatingLabelTag
                  rating={stage.average_rating}
                  fontSize="caption"
                  fontWeight={400}
                />
              )}
              {!disableActions && (canCancel || canAddFeedback) && !isArchived && (
                <div onClick={e => e.stopPropagation()}>
                  <StageActions
                    stages={stages}
                    stage={stage}
                    roundId={round.id}
                    canCancel={canCancel}
                    canAddFeedback={canAddFeedback}
                    onRefresh={onRefresh}
                    currentStageId={round.latest_interview_stage?.id}
                    onOpenSidebar={(data, mode, actionType) => {
                      onClick?.(data, mode, undefined, actionType)
                    }}
                    menuType="dropdown"
                    canViewEditOffer={canViewEditOffer}
                    candidateId={candidateId}
                  />
                </div>
              )}
            </HStack>
          </Item.Side>
        </Item>
        {expanded &&
          stage.interview_feedbacks.map(feedback => (
            <StagesTableFeedback
              key={feedback.id}
              canCancel={canCancel}
              canAddFeedback={canAddFeedback}
              feedback={feedback}
              onClick={() => onClick?.(feedback, undefined, stage.interview_type)}
              disableActions={disableActions || isArchived}
              onRefresh={onRefresh}
              selected={selectedItemId === feedback.id}
              prefix={<Item.Prefix width={82} />}
            />
          ))}
      </Fragment>
    </>
  )
}
