import React from 'react'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { formatMoneyWithCode, formatPeriod } from '@src/utils/format'
import { PayCyclePaymentInterface } from '@src/interfaces/payroll'
import { ROUTES } from '../routes'
import { pathToUrl } from '@src/utils/router'
import TableCellLink from '@src/components/TableCellLink/TableCellLink'
import { getStatusColor } from '@src/components/CommonSC/General'
import { Statuses } from '@src/interfaces'
import { HStack } from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'
import Tooltip from '@src/components/Tooltip/Tooltip'

export const paymentsEmployeeColumn: ColumnInterface<PayCyclePaymentInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.name',
  sortKey: null,
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Employee',
  insert: ({ data }) =>
    data.is_rounding ? 'Rounding payment' : <UserWithAvatar {...data.employee} />,
}

export const paymentsPaygroupColumn: ColumnInterface<PayCyclePaymentInterface> = {
  type: CellTypes.insert,
  idPoint: 'pay_cycle.pay_group.id',
  dataPoint: 'pay_cycle.pay_group.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Pay group',
  insert: ({ data }) => (
    <TableCellLink
      to={
        data?.pay_cycle?.pay_group?.id
          ? pathToUrl(ROUTES.APPS.PAYROLL.PAY_GROUP.PREVIEW, {
              id: data?.pay_cycle?.pay_group?.id,
            })
          : ''
      }
    >
      {data?.pay_cycle?.pay_group?.name}
    </TableCellLink>
  ),
}

export const paymentsCycleColumn: ColumnInterface<PayCyclePaymentInterface> = {
  type: CellTypes.insert,
  idPoint: 'pay_cycle.id',
  dataPoint: 'pay_cycle.end_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Cycle date',
  insert: ({ data }) =>
    data.pay_cycle?.id ? (
      <TableCellLink
        to={pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.REPORTS, { id: data.pay_cycle?.id })}
      >
        {formatPeriod(
          new Date(data.pay_cycle?.start_date),
          new Date(data.pay_cycle?.end_date),
        )}
      </TableCellLink>
    ) : (
      '-'
    ),
}

export const paymentsElementColumn: ColumnInterface<PayCyclePaymentInterface> = {
  type: CellTypes.text,
  idPoint: 'payroll_element.id',
  dataPoint: 'payroll_element.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Element',
}

export const paymentsDirectionColumn: ColumnInterface<PayCyclePaymentInterface> = {
  type: CellTypes.insert,
  idPoint: 'entity_id',
  dataPoint: 'amount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Direction',
  insert: ({ data }) => (data.payroll_element?.is_payable ? 'Payable' : 'Receivable'),
}

export const paymentsAmountColumn: ColumnInterface<PayCyclePaymentInterface> = {
  type: CellTypes.insert,
  idPoint: 'amount',
  dataPoint: 'amount',
  sortKey: 'amount',
  filterKey: 'amount',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Amount',
  insert: ({ data }) => formatMoneyWithCode(data.amount, data.currency),
}

export const paymentsStatusColumn: ColumnInterface<PayCyclePaymentInterface> = {
  type: CellTypes.insert,
  idPoint: 'payroll_settlement.status.id',
  dataPoint: 'payroll_settlement.status.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  colors: data => getStatusColor(data.payroll_settlement?.status.id || Statuses.closed),
  title: 'Status',
  insert: ({ data }) =>
    data.payroll_settlement?.status?.name ? (
      <HStack align="center" gap="s-2">
        {!!data.payroll_settlement?.status_message && (
          <Tooltip text={data.payroll_settlement?.status_message} placement="left">
            <InfoOutline />
          </Tooltip>
        )}
        {data.payroll_settlement?.status?.name}
      </HStack>
    ) : (
      'Unreconciled'
    ),
}
