import { IconName } from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import { BenefitStatus } from '@src/interfaces/rewards'
import { GenericTimeSeriesInterface } from '@src/interfaces/totalCompensation'

export const getBenefitStatusText = (status: IdAndName<BenefitStatus>) => {
  if (status.id === 'pending_approval') {
    return 'Pending'
  }

  if (status.id === 'pending_employee') {
    return 'Enrol now'
  }

  return status.name
}

export const getBenefitIcon = (status: BenefitStatus): IconName => {
  switch (status) {
    case 'pending_approval':
    case 'pending_employee':
      return '16/SandWatch'
    case 'opted_out':
    case 'rejected':
      return 'Cross'
    default:
      return 'Check'
  }
}

export const useBarProjectedData = <T extends GenericTimeSeriesInterface>(data?: T[]) => {
  const projectedDataIdx: number[] = []

  data?.forEach((value, i) => {
    if (value.year > new Date().getFullYear()) {
      projectedDataIdx.push(i)
    }
  })

  return projectedDataIdx
}
