import React from 'react'
import { Token, MoreBar, TableWidget } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import {
  timeOffWorkScheduleCustomApproversHeadcountColumn,
  timeOffWorkScheduleCustomApproversNameColumn,
  timeOffWorkScheduleCustomApproversStatusColumn,
} from '@src/constants/columns/timeOffWorkScheduleCustomApprovers'
import { WorkScheduleCustomApproversListItemInterface } from '@src/interfaces/workSchedule'
import { workScheduleCustomApproversTableRequests } from '@src/api/workSchedule'
import { useTable } from '@components/Table/hooks'
import Stat from '@components/Stat/Stat'

const ROW: RowInterface<WorkScheduleCustomApproversListItemInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(
      pathToUrl(ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.PREVIEW, {
        id,
      }),
    ),
  cells: [
    {
      ...timeOffWorkScheduleCustomApproversNameColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleCustomApproversHeadcountColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleCustomApproversStatusColumn,
      width: 200,
    },
  ],
}

export const WorkScheduleCustomApproversList = () => {
  const initialFilters = [
    {
      filters: [{ id: 'active', name: 'active' }],
      columnName: 'status',
    },
  ]
  const table = useTable(workScheduleCustomApproversTableRequests, initialFilters)

  return (
    <PageWrapper>
      <PageHeader title="Custom approvers" backUrl={ROUTES.SETTINGS.TIME_OFF.GENERAL} />
      <PageBody maxWidth={Token.breakpoint.xl}>
        <TableWidget>
          <TableWidget.Info>
            <Stat label="Total" val={table?.loading ? undefined : table?.data.length} />
          </TableWidget.Info>
          <TableWidget.Actions>
            <MoreBar>
              <MoreBar.Action
                useIcon="Plus"
                variant="accent"
                use={InternalLink}
                to={pathToUrl(
                  ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.EDIT
                    .DETAILS,
                )}
              >
                Create new
              </MoreBar.Action>
            </MoreBar>
          </TableWidget.Actions>
          <TableWidget.Table>
            <AdjustableTable
              {...table}
              dataType="Custom approver"
              name={TableNames.WorkSchedulesCustomApprovers}
              noDataMessage="Custom approvers will appear here"
              row={ROW}
              useWindowScroll
            />
          </TableWidget.Table>
        </TableWidget>
      </PageBody>
    </PageWrapper>
  )
}
