import React, { type FC } from 'react'
import { Relative, type BoxProps, useMatchMedia, Token } from '@revolut/ui-kit'

import { FeatureItemContent } from '@src/pages/Landing/components/types'
import {
  FeatureImageBase,
  FeatureImageBackgroundSize,
} from '@src/pages/Landing/components/FeatureImageBase'

interface CarouselItemCardProps extends BoxProps {
  item: FeatureItemContent
  imageTitle?: string
  objectFit?: FeatureImageBackgroundSize
  swipeable?: boolean
  active?: boolean
  ratio: number
}

export const FeatureItemsItemCard: FC<CarouselItemCardProps> = ({
  item,
  ratio,
  imageTitle,
  active = true,
  objectFit = 'contain',
  children,
  ...props
}) => {
  const image =
    !useMatchMedia(Token.media.md) && item.imageSmall ? item.imageSmall : item.image

  if (!image) {
    return null
  }

  return (
    <Relative height="100%" style={{ aspectRatio: ratio.toString() }} {...props}>
      {image && (
        <FeatureImageBase
          image={image}
          backgroundSize={objectFit}
          prefersCoverOnMobile
          title={imageTitle}
        />
      )}
      {children}
    </Relative>
  )
}
