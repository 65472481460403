import React, { useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { Route, Switch, matchPath, useLocation, useParams } from 'react-router-dom'
import { Box, Flex, Icon, StatusWidget, Token } from '@revolut/ui-kit'

import {
  Colored,
  ColoredPercent,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import Loader from '@components/CommonSC/Loader'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { useGetEmployeeDynamicGroups } from '@src/api/dynamicGroups'
import { useGetEmployeeStats } from '@src/api/employees'
import {
  useGetRoadmapSettings,
  useGetTimeOffSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { useEmployeeTimeOffRequests } from '@src/api/timeOff'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { EntityTypes } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { TabBarNavigationNames } from '@src/constants/tabBarNavigation'
import { GoalsTab } from '@src/features/Goals/GoalsTab'
import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { RedirectToFirstTab } from '@src/features/TabBarNavigationEditable/RedirectToFirstTab'
import { TabBarNavigationEditable } from '@src/features/TabBarNavigationEditable/TabBarNavigationEditable'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { EmployeeInterface, InternalOrExternalEmployee } from '@src/interfaces/employees'
import { MeetingTrackerEntity } from '@src/interfaces/meetingsTracker'
import { Engagement } from '@src/pages/EmployeeProfile/Preview/Engagement'
import { KPI } from '@src/pages/EmployeeProfile/Preview/KPI'
import { Roadmap } from '@src/pages/EmployeeProfile/Preview/Roadmap'
import { Talent } from '@src/pages/EmployeeProfile/Preview/Talent'
import { getTalentStatsQuickSummary } from '@src/pages/Forms/CommonTalentTab/TalentStats'
import { MeetingsTracker } from '@src/pages/Forms/MeetingsTracker/MeetingsTracker'
import Page404 from '@src/pages/Page404/Page404'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

import { Changelog } from '../Preview/Changelog'
import { DropdownActionButtonsGroup } from '../Preview/components/Buttons/ActionButtonsGroup'
import { useEmployeeInactivityData } from '../Preview/components/Buttons/ChangeStatusDropdown/SetInactive/hooks'
import { useEmployeeStatusTransitions } from '../Preview/components/Buttons/ChangeStatusDropdown/StatusChange/hooks'
import { FormTitle } from '../Preview/components/FormTitle'
import { useEmployeeProfileData } from '../Preview/hooks'
import { canViewTimeOff, useCanViewMeetingsTab } from '../Preview/ProfileSummary/common'
import { useYear } from '../Preview/Rewards/common'
import { AnalyticsDashboards } from './AnalyticsDashboards/AnalyticsDashboards'
import { CompensationLayoutTab } from './Compensation/LayoutTab'
import { CompensationLayoutTabV2 } from './CompensationV2/LayoutTab'
import { ComplianceLayoutTab } from './Compliance/LayoutTab'
import { DocumentsLayoutTab } from './Documents/LayoutTab'
import { useGetExternalPages } from './External'
import { performanceRedirects, useIsNewLayout } from './helpers'
import { PerformanceLayoutTab } from './Performance/LayoutTab'
import { NewPerformanceLayoutTab } from './Performance/NewLayoutTab'
import { useNewPerformanceSummary } from './Performance/useNewPerformanceSummary'
import { SummaryLayoutTab } from './Summary/LayoutTab'
import { TimeManagementLayoutTab } from './TimeManagement/LayoutTab'

const backUrl = ROUTES.PEOPLE.EMPLOYEES

const routes = {
  profile: ROUTES.FORMS.EMPLOYEE.PREVIEW,
  performance: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY,
  goals: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.ANY,
  kpis: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.ANY,
  roadmap: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ROADMAP,
  talent: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.ANY,
  meetings: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS,
  engagement: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.ANY,
  documents: ROUTES.FORMS.EMPLOYEE.DOCUMENTS,
  timeOff: ROUTES.FORMS.EMPLOYEE.TIME_OFF.ANY,
  schedule: ROUTES.FORMS.EMPLOYEE.SCHEDULE,
  compensation: ROUTES.FORMS.EMPLOYEE.COMPENSATION_OVERVIEW,
  compliance: ROUTES.FORMS.EMPLOYEE.COMPLIANCE_OVERVIEW,
  analytics: ROUTES.FORMS.EMPLOYEE.ANALYTICS,
}

export const EmployeeProfileLayout = () => {
  const params = useParams<{ id?: string }>()
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const isValidEmployeeId = params.id && !isNaN(parseFloat(params.id))
  const employeeId = isValidEmployeeId ? params.id : undefined
  const isNewLayout = useIsNewLayout()

  useEffect(() => {
    // TODO: used for backwards compatibility, should be removed after switching to the new layout
    if (isNewLayout && window.location.hash === '#keyPerson') {
      navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.KEY_PERSON, params))
    }
  }, [])

  const { data, setData, handler, sections } = useEmployeeProfileData({
    employeeId,
    // For the new profile layout we need to load name, position & organisation sections data beforehand,
    // as it's needed to check availability for the "request change" buttons.
    // Should be optimized altogether with API (permissions should arrive from another endpoint)
    instantLoad: {
      name: true,
      position: true,
      organisation: true,
    },
  })
  const entity = useMemo<OrgEntityInterface | undefined>(
    () =>
      data
        ? {
            type: EntityTypes.employees,
            data,
          }
        : undefined,
    [data],
  )
  const { data: dynamicGroupsData } = useGetEmployeeDynamicGroups(employeeId)
  const dynamicGroups = dynamicGroupsData?.results || []
  const { data: stats, refetch: refreshStats } = useGetEmployeeStats(employeeId)
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const canViewMeetingsTab = useCanViewMeetingsTab(data)
  const location = useLocation()
  const isInternal = data?.employee_type === 'internal'
  const isNewPerformanceSummary = useNewPerformanceSummary()
  const isCommercial = useIsCommercial()

  const updateData = (update: Partial<InternalOrExternalEmployee>) => {
    if (!isEmpty(update)) {
      setData({ ...data, ...update } as EmployeeInterface)
    }
  }

  const statusTransitions = useEmployeeStatusTransitions({
    employee: data,
    updateData,
  })
  const employeeInactivity = useEmployeeInactivityData({ employee: data })
  const { settings: globalSettings } = useGlobalSettings()
  const { data: timeOffSettings } = useGetTimeOffSettings()

  const [compensationPeriodYear, setCompensationPeriodYear] = useYear()
  const [compensationCurrency, setCompensationCurrency] = useState<string>()

  const compensationParams = {
    year: compensationPeriodYear,
    setYear: setCompensationPeriodYear,
    currency: compensationCurrency,
    setCurrency: setCompensationCurrency,
  }

  const externalPages = useGetExternalPages({
    data,
    employeeId,
    dataHandler: handler,
    dynamicGroups,
    compensationParams,
  })

  const timeOffRequests = useEmployeeTimeOffRequests(
    !!timeOffSettings?.enabled,
    {
      filters: [
        {
          filters: [
            {
              id: 'in_progress',
              name: 'In Progress',
            },
          ],
          columnName: 'status',
        },
      ],
    },
    employeeId,
  )

  if (!data?.id) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }
  if (!data) {
    return <Page404 />
  }

  const tabs = [
    {
      key: 'profile',
      title: 'Profile' as const,
      icon: <Icon name="Profile" size={15} />,
      path: routes.profile,
      to: pathToUrl(routes.profile, params),
      component: SummaryLayoutTab,
      canView: true,
    },
    {
      key: 'performance',
      title: 'Performance' as const,
      icon: <Icon name="16/ChartBarWithArrow" size={15} />,
      path: routes.performance,
      to: pathToUrl(routes.performance, params),
      component: isNewPerformanceSummary ? NewPerformanceLayoutTab : PerformanceLayoutTab,
      canView: true,
    },
    {
      key: 'goals',
      title: 'Goals' as const,
      icon: <Icon name="Target" size={15} />,
      quickSummary: stats && Number.isFinite(stats.goals_progress_percent) && (
        <ColoredPercent percent={stats.goals_progress_percent! * 100} />
      ),
      path: routes.goals,
      to: pathToUrl(routes.goals, params),
      canView: featureFlags.includes(FeatureFlags.CanAddGoals),
      component: GoalsTab,
    },
    {
      key: 'kpis',
      title: 'KPIs' as const,
      icon: <Icon name="Target" size={15} />,
      quickSummary: stats && (
        <ColoredPercent percent={stats.kpi_performance_percent * 100} />
      ),
      path: routes.kpis,
      to: pathToUrl(routes.kpis, params),
      canView: featureFlags.includes(FeatureFlags.CanAddKPIs),
      component: KPI,
    },
    {
      key: 'roadmap',
      title: 'Roadmap' as const,
      icon: <Icon name="Map" size={15} />,
      path: routes.roadmap,
      to: pathToUrl(routes.roadmap, params),
      component: Roadmap,
      quickSummary: stats && (
        <ColoredPercent percent={stats.roadmap_progress_percent * 100} />
      ),
      canView: !!roadmapSettings?.enabled,
    },
    {
      key: 'analytics',
      title: 'Analytics',
      icon: <Icon name="BarChart" size={15} />,
      path: routes.analytics,
      to: pathToUrl(routes.analytics, params),
      component: AnalyticsDashboards,
      canView: !featureFlags.includes(FeatureFlags.CommercialProduct),
      quickSummary: <QuickSummaryCount count={stats?.dashboard_count} />,
    },
    {
      key: 'talent',
      title: 'Talent' as const,
      icon: <Icon name="StarFilled" size={15} />,
      path: routes.talent,
      to: pathToUrl(routes.talent, params),
      component: Talent,
      quickSummary: getTalentStatsQuickSummary({
        headcount: stats?.direct_reports_count,
        nips: stats?.direct_reports_nips,
      }),
      canView: true,
    },
    {
      key: 'meetings',
      title: 'Meetings' as const,
      icon: <Icon name="Chat" size={15} />,
      path: routes.meetings,
      to: pathToUrl(routes.meetings, params),
      component: MeetingsTracker,
      canView: canViewMeetingsTab,
    },
    {
      key: 'engagement',
      title: 'Engagement',
      icon: <Icon name="People" size={15} />,
      path: routes.engagement,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.CATEGORIES,
        params,
      ),
      component: Engagement,
      canView:
        globalSettings.engagement_enabled &&
        isInternal &&
        permissions.includes(PermissionTypes.ViewEngagementTabs) &&
        data.field_options.permissions?.includes(PermissionTypes.ViewEngagementTabs),
    },
    {
      key: 'documents',
      title: 'Documents' as const,
      icon: <Icon name="DocumentsPair" size={15} />,
      path: routes.documents,
      to: pathToUrl(routes.documents, params),
      component: DocumentsLayoutTab,
      canView: !!data?.field_options?.permissions?.includes(
        PermissionTypes.ViewDocuments,
      ),
      quickSummary: stats?.pending_document_count ? (
        <Colored color={Token.color.warning}>{stats.pending_document_count}</Colored>
      ) : null,
    },
    {
      key: 'timeOff',
      title: 'Time Management' as const,
      icon: <Icon name="Resort" size={15} />,
      path: routes.timeOff,
      to: pathToUrl(routes.timeOff, params),
      component: TimeManagementLayoutTab,
      canView: canViewTimeOff(data, !!timeOffSettings?.enabled),
      quickSummary: stats?.pending_time_off_request_count ? (
        <Colored color={Token.color.warning}>
          {stats.pending_time_off_request_count}
        </Colored>
      ) : null,
    },
    {
      key: 'compensation',
      title: 'Compensation' as const,
      icon: <Icon name="Credit" size={15} />,
      path: routes.compensation,
      to: pathToUrl(routes.compensation, params),
      component: featureFlags.includes(FeatureFlags.TotalCompensationV2)
        ? CompensationLayoutTabV2
        : CompensationLayoutTab,
      canView: true,
    },
    {
      key: 'compliance',
      title: 'Compliance' as const,
      icon: <Icon name="CenterPoint" size={15} />,
      path: routes.compliance,
      to: pathToUrl(routes.compliance, params),
      component: ComplianceLayoutTab,
      canView: !isCommercial,
    },
  ]

  const filteredTabs = tabs.filter(tab => tab.canView)
  const matchedPaths = matchPath(location.pathname, {
    path: filteredTabs.map(tab => tab.path),
  })

  if (data.anonymized?.is_shadow) {
    return (
      <PageWrapper>
        <Box maxWidth={Token.breakpoint.md} mt="s-64" mx="auto">
          <StatusWidget>
            <StatusWidget.Image
              src="https://assets.revolut.com/assets/3d-images/3D020.png"
              srcSet="https://assets.revolut.com/assets/3d-images/3D020@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D020@3x.png 3x"
            />
            <StatusWidget.Title>Anonymous Profile</StatusWidget.Title>
            <StatusWidget.Description>
              This profile is hidden because it is used to store employee data that has
              been anonymised.
            </StatusWidget.Description>
            <StatusWidget.Action onClick={() => goBack(ROUTES.PEOPLE.EMPLOYEES)}>
              Go Back
            </StatusWidget.Action>
          </StatusWidget>
        </Box>
      </PageWrapper>
    )
  }

  return (
    <OrgEntityProvider entity={entity}>
      <PageWrapper>
        <Switch>
          {Object.entries(performanceRedirects).map(([from, to]) => (
            <Route key={from} exact path={from}>
              <InternalRedirect to={pathToUrl(to, params)} />
            </Route>
          ))}
          {externalPages}
          <Route>
            <PageHeader
              title={
                <FormTitle
                  data={data as EmployeeInterface}
                  refreshData={handler.refetch}
                  timeOff={timeOffRequests?.[0]}
                  actions={
                    <DropdownActionButtonsGroup
                      data={data}
                      refreshData={handler.refetch}
                      statusTransitions={statusTransitions}
                      employeeInactivity={employeeInactivity}
                      sectionsData={sections}
                    />
                  }
                />
              }
              backUrl={backUrl}
            >
              <Box my="s-24" maxWidth={{ all: '100%', lg: Token.breakpoint.xl }}>
                <TabBarNavigationEditable
                  tabs={filteredTabs}
                  name={TabBarNavigationNames.EmployeeProfile}
                />
              </Box>
            </PageHeader>

            <Flex flexDirection="column" width="100%" flex={1} minHeight={0}>
              <Box maxWidth={{ all: '100%', lg: Token.breakpoint.xl }}>
                <Switch>
                  {filteredTabs.map(tab => (
                    <Route exact key={tab.path} path={tab.path}>
                      <tab.component
                        data={data}
                        primaryDataHandler={handler}
                        dynamicGroups={dynamicGroups}
                        stats={stats}
                        refreshStats={refreshStats}
                        statusTransitions={statusTransitions}
                        employeeInactivity={employeeInactivity}
                        sectionsData={sections}
                        compensationParams={compensationParams}
                        meetingEntityType={MeetingTrackerEntity.employee}
                      />
                    </Route>
                  ))}
                  <Route exact path={ROUTES.FORMS.EMPLOYEE.CHANGELOG}>
                    <Changelog data={data} />
                  </Route>
                  {!matchedPaths && (
                    <RedirectToFirstTab
                      name={TabBarNavigationNames.EmployeeProfile}
                      tabs={filteredTabs}
                    />
                  )}
                </Switch>
              </Box>
            </Flex>
          </Route>
        </Switch>
      </PageWrapper>
    </OrgEntityProvider>
  )
}
