import React, { useCallback, useState } from 'react'
import {
  AppIcon,
  Subheader,
  Widget,
  Avatar,
  Ellipsis,
  StatusWidget,
  ActionButton,
  Item,
  Group,
  Box,
  IconButton,
  TextButton,
  Token,
  DragAndDrop,
  ItemSkeleton,
  Spinner,
  BottomSheet,
  Header,
  Description,
  Input,
  Button,
  HStack,
} from '@revolut/ui-kit'
import { useQueryClient } from 'react-query'
import { DefaultSortableItemState } from '@revolut/ui-kit/types/dist/components/DragAndDrop/SortableItem'

import globalSearchState from '@components/GlobalSearchSidebar/GlobalSearchSidebarState'
import {
  changeFavouriteOrder,
  useDeleteFavourite,
  useGetFavourites,
  useUpdateFavourite,
} from '@src/api/favourites'
import { getGlobalSearchItemFormUrl } from '@src/components/GlobalSearchSidebar/GlobalSearchItem/common'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { getInitials } from '@src/utils/employees'
import { getFavouriteTypeIconProps } from '@components/Favourites/common'
import { FavouriteInterface } from '@src/interfaces/favourites'
import { API } from '@src/constants/api'
import { move } from '@src/utils/move'

const APP_ICON_SIZE = 40

interface EmployeeIconProps {
  name: string
  image?: string
}

const EmployeeIcon = ({ name, image }: EmployeeIconProps) => {
  const initials = getInitials(name)

  return <Avatar image={image} label={initials} aria-label={name} />
}

interface FavouriteItemProps {
  favourite: FavouriteInterface
  sortable?: DefaultSortableItemState<{}>
  overlayItem?: boolean
}

const FavouriteItem = ({ favourite, sortable, overlayItem }: FavouriteItemProps) => {
  const [popupOpen, setPopupOpen] = useState(false)

  const [name, setName] = useState(
    favourite?.display_name || favourite?.favourite_object?.object_name,
  )

  const { mutateAsync: deleteFavourite, isLoading: isDeleting } = useDeleteFavourite()
  const { mutateAsync: updateFavourite, isLoading: isRenaming } = useUpdateFavourite()

  const isEmployee = favourite.favourite_object?.category.id === 'employee'

  const url = getGlobalSearchItemFormUrl(
    favourite.favourite_object?.category.id,
    favourite.favourite_object?.object_id,
  )

  const handleRemove = () => {
    deleteFavourite(favourite.id)
  }

  const handleEdit = () => {
    setPopupOpen(true)
  }

  const updateName = async () => {
    await updateFavourite([favourite.id, { display_name: name }])
    setPopupOpen(false)
  }

  return (
    <>
      <Item
        ref={sortable?.setNodeRef}
        style={{
          transform: sortable?.transform
            ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
            : undefined,
          transition: sortable?.transition || 'none',
          opacity: sortable?.isDragging ? 0 : undefined,
        }}
        to={url}
        use={InternalLink}
        key={favourite.id}
      >
        {sortable || overlayItem ? (
          <Item.Prefix>
            {isDeleting ? (
              <Spinner color={Token.color.greyTone20} size={24} />
            ) : (
              <IconButton
                useIcon="MinusCircle"
                aria-label="Remove"
                color={Token.color.danger}
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  handleRemove()
                }}
                disabled={isDeleting}
                size={24}
                tapArea={24}
              />
            )}
          </Item.Prefix>
        ) : null}
        <Item.Avatar>
          {isEmployee ? (
            <EmployeeIcon
              name={favourite.display_name || favourite.favourite_object.object_name}
              image={favourite.favourite_object.thumbnail_url}
            />
          ) : (
            <AppIcon
              useIcon={
                getFavouriteTypeIconProps(favourite.favourite_object.category.id)[0]
              }
              bg={getFavouriteTypeIconProps(favourite.favourite_object.category.id)[1]}
              size={APP_ICON_SIZE}
              aria-label={favourite.favourite_object.object_name}
            />
          )}
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            {favourite.display_name || favourite.favourite_object.object_name}
          </Item.Title>
          <Item.Description>
            <Ellipsis>{favourite.favourite_object.subtitle}</Ellipsis>
          </Item.Description>
        </Item.Content>
        {sortable || overlayItem ? (
          <Item.Side>
            <HStack align="center" space="s-8">
              <IconButton
                useIcon="Pencil"
                aria-label="Rename"
                color={Token.color.greyTone20}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleEdit()
                }}
                size={18}
                tapArea={24}
              />

              <IconButton
                aria-label="Drag"
                useIcon="Drag"
                color={Token.color.greyTone20}
                {...sortable?.attributes}
                {...sortable?.listeners}
                size={24}
              />
            </HStack>
          </Item.Side>
        ) : null}
      </Item>

      <BottomSheet open={popupOpen} onClose={() => setPopupOpen(false)}>
        <Header>
          <Header.Title>Rename favourite</Header.Title>
        </Header>
        <Description>
          <Input
            label="Name"
            renderAction={() => {
              if (favourite.favourite_object.object_name === name) {
                return null
              }

              return (
                <IconButton
                  useIcon="RevertLeft"
                  aria-label="Rename"
                  color={Token.color.greyTone50}
                  onClick={() => setName(favourite.favourite_object.object_name)}
                />
              )
            }}
            value={name}
            onChange={event => setName(event.currentTarget.value)}
          />
        </Description>
        <BottomSheet.Actions horizontal>
          <Button variant="secondary" onClick={() => setPopupOpen(false)}>
            Cancel
          </Button>
          <Button
            elevated
            onClick={() => updateName()}
            disabled={isRenaming}
            pending={isRenaming}
          >
            Rename
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>
    </>
  )
}

export const FavouritesV2 = () => {
  const [isEditing, setIsEditing] = useState(false)
  const [activeId, setActiveId] = useState<number | null>(null)

  const queryClient = useQueryClient()
  const { data, isLoading, refetch } = useGetFavourites()

  const hasFavourites = data?.count && data.count > 0
  const favourites = data?.results

  const onAddFavourites = () => {
    globalSearchState.open = 'favourites-mode'
  }
  const onDragStart = useCallback(event => setActiveId(event.active.id), [])

  const onDragCancel = useCallback(() => setActiveId(null), [])

  const onDragEnd = useCallback(
    event => {
      if (event.over) {
        const startIndex = event.active.data.current.sortable.index
        const endIndex = event.over.data.current.sortable.index

        const newData =
          startIndex === endIndex || !favourites
            ? favourites
            : move(favourites, startIndex, endIndex)

        queryClient.setQueryData([API.FAVOURITES, 'v1', null], {
          ...data,
          results: newData,
        })

        changeFavouriteOrder(event.active.id, event.over.id).catch(() => {
          refetch()
        })
      }

      setActiveId(null)
    },
    [favourites],
  )

  const activeItem = !!activeId && favourites?.find(item => item.id === activeId)

  return (
    <Widget overflow="hidden">
      <Box px="s-16">
        <Subheader variant="nested">
          <Subheader.Title>Favourites</Subheader.Title>
          {isEditing ? (
            <TextButton onClick={() => setIsEditing(false)}>Done</TextButton>
          ) : null}
          {hasFavourites && !isEditing ? (
            <IconButton
              onClick={() => setIsEditing(true)}
              useIcon="Pencil"
              aria-label="Edit favourites"
              color={Token.color.accent}
              size={16}
              tapArea={32}
            />
          ) : null}
        </Subheader>
      </Box>
      {hasFavourites || isLoading || isEditing ? null : (
        <StatusWidget>
          <StatusWidget.Image src="https://assets.revolut.com/assets/3d-images-v2/3D241.png" />
          <StatusWidget.Title>Your favourites will appear here</StatusWidget.Title>
          <StatusWidget.Description>
            Search for items and use star to add them to your favourites
          </StatusWidget.Description>
          <StatusWidget.Action>
            <ActionButton onClick={onAddFavourites} useIcon="Plus">
              Add new
            </ActionButton>
          </StatusWidget.Action>
        </StatusWidget>
      )}
      <Group>
        {isEditing ? (
          <Item use="button" onClick={onAddFavourites}>
            <Item.Avatar>
              <Avatar useIcon="Plus" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title color={Token.color.accent}>Add to favourites</Item.Title>
            </Item.Content>
          </Item>
        ) : null}
        {!isEditing &&
          favourites?.map(favourite => (
            <FavouriteItem favourite={favourite} key={favourite.id} />
          ))}
        {favourites && isEditing ? (
          <DragAndDrop.Provider
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragCancel={onDragCancel}
          >
            <DragAndDrop.Sortable id="sortable-favourites" items={favourites}>
              {sortable => {
                const favourite = favourites.find(item => item.id === sortable.id)

                if (!favourite) {
                  return null
                }

                return (
                  <FavouriteItem
                    sortable={sortable}
                    favourite={favourite}
                    key={favourite.id}
                  />
                )
              }}
            </DragAndDrop.Sortable>

            <DragAndDrop.DragOverlay>
              {activeItem && <FavouriteItem favourite={activeItem} overlayItem />}
            </DragAndDrop.DragOverlay>
          </DragAndDrop.Provider>
        ) : null}

        {isLoading && !favourites ? (
          <>
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </>
        ) : null}
      </Group>
    </Widget>
  )
}
