import React, { useState } from 'react'
import { MoreBar, TableWidget } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import AdjustableTable from '@components/Table/AdjustableTable'
import Stat from '@components/Stat/Stat'
import { TableNames } from '@src/constants/table'
import { documentsBulkRequestsTableRequests } from '@src/api/documents'
import { DocumentsBulkRequestInterface } from '@src/interfaces/documents'
import {
  documentsBulkRequestsCreatedByColumn,
  documentsBulkRequestsCreatedOnColumn,
  documentsBulkRequestsEmployeesCountColumn,
  documentsBulkRequestsNameColumn,
  documentsBulkRequestsStatusColumn,
} from '@src/constants/columns/documentsBulkRequests'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { SelectBulkRequestOptionsPopup } from './SelectBulkRequestOptionsPopup'

const ROW: RowInterface<DocumentsBulkRequestInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.APPS.DOCUMENTS.BULK_REQUEST.PREVIEW, { id })),
  cells: [
    {
      ...documentsBulkRequestsNameColumn,
      width: 400,
    },
    {
      ...documentsBulkRequestsEmployeesCountColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestsCreatedByColumn,
      width: 300,
    },
    {
      ...documentsBulkRequestsCreatedOnColumn,
      width: 300,
    },
    {
      ...documentsBulkRequestsStatusColumn,
      width: 300,
    },
  ],
}

export const DocumentsBulkRequestsTable = () => {
  const permissions = useSelector(selectPermissions)
  const canAddBulkRequest = permissions?.includes(PermissionTypes.AddDocumentBulkRequest)

  const [openBulkRequestOptionsPopup, setOpenBulkRequestOptionsPopup] = useState(false)

  const table = useTable<DocumentsBulkRequestInterface>(
    documentsBulkRequestsTableRequests,
    undefined,
    // TODO: RHR-4953 Uncomment when fixed on BE
    // [
    //   {
    //     sortBy: 'creation_date_time',
    //     direction: SORT_DIRECTION.DESC,
    //   },
    // ],
  )

  return (
    <PageWrapper>
      <PageHeader backUrl={ROUTES.PEOPLE.DOCUMENTS} title="Manage requests" />
      <PageBody maxWidth="100%">
        <TableWidget>
          <TableWidget.Info>
            <Stat label="Total" val={table.loading ? undefined : table.count} mb="s-16" />
          </TableWidget.Info>
          <TableWidget.Actions>
            <MoreBar>
              {canAddBulkRequest && (
                <>
                  <MoreBar.Action
                    useIcon="Plus"
                    onClick={() => setOpenBulkRequestOptionsPopup(true)}
                  >
                    New bulk request
                  </MoreBar.Action>
                  <SelectBulkRequestOptionsPopup
                    isOpen={openBulkRequestOptionsPopup}
                    onClose={() => setOpenBulkRequestOptionsPopup(false)}
                  />
                </>
              )}
            </MoreBar>
          </TableWidget.Actions>
          <TableWidget.Table>
            <AdjustableTable
              name={TableNames.DocumentRules}
              useWindowScroll
              row={ROW}
              {...table}
              noDataMessage="All bulk requests will appear here"
            />
          </TableWidget.Table>
        </TableWidget>
      </PageBody>
    </PageWrapper>
  )
}
