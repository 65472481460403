import React, { useMemo, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Avatar,
  Item,
  VStack,
  Image,
  chain,
  Text,
  ActionButton,
  MoreBar,
  Widget,
  FilterButton,
  Bar,
  Flex,
  Subheader,
  Box,
  HStack,
  Token,
  Icon,
} from '@revolut/ui-kit'

import {
  getAllKeyPersonResponsibilitiesAssignments,
  getAllKeyPersonResponsibilitiesAssignmentsStats,
  useEmployeeIsSuccessorForResponsibilities,
  useEmployeeSuccessionPlans,
} from '@src/api/keyPerson'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { TablePreview } from '@components/TablePreview/TablePreview'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  getResponsibilityAssignmentStatusColor,
  getSuccessionPlanStatusColor,
} from '@src/apps/People/KeyPersons/helpers'
import { formatDate } from '@src/utils/format'
import { StatsConfig } from '@components/StatFilters/hooks'
import {
  KeyPersonResponsibilityAssignmentInterface,
  KeyPersonResponsibilityAssignmentsStatsInterface,
  KeyPersonRolesStatsInterface,
} from '@src/interfaces/keyPerson'
import { ResponsibilityAssignmentSidebar } from '@src/apps/People/KeyPersons/ResponsibilityAssignmentsTable/ResponsibilityAssignmentSidebar'
import AvatarItemSkeleton from '@components/Skeletons/AvatarItemSkeleton'
import Tooltip from '@components/Tooltip/Tooltip'
import { formatDateWithFallback } from '../common'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const statsConfig: StatsConfig<KeyPersonRolesStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'active',
    title: 'Active',
    filterKey: 'active',
    color: Token.color.green,
  },
  {
    key: 'pending_start',
    title: 'Pending start',
    filterKey: 'pending_start',
    color: Token.color.orange,
  },
  {
    key: 'expired',
    title: 'Expired',
    filterKey: 'expired',
    color: Token.color.red,
  },
  {
    key: 'archived',
    title: 'Archived',
    filterKey: 'archived',
    color: Token.color.greyTone50,
  },
]

const ResponsibilityAssignment = ({
  assignment,
  onClick,
}: {
  assignment: KeyPersonResponsibilityAssignmentInterface
  onClick: () => void
}) => {
  return (
    <Item use="button" onClick={onClick}>
      <Item.Avatar>
        <Avatar useIcon="Shield" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{assignment.key_person_type.name}</Item.Title>
        {assignment.entity?.name ? (
          <Item.Description>{assignment.entity.name}</Item.Description>
        ) : null}
      </Item.Content>
      <Item.Side>
        <Item.Value
          color={getResponsibilityAssignmentStatusColor(assignment.assignment_status?.id)}
        >
          {assignment.assignment_status?.name}
        </Item.Value>
        {assignment.assignment_period.id === 'interim' && (
          <Item.Description>
            {formatDateWithFallback(assignment.start_date, 'Unknown')} -{' '}
            {formatDateWithFallback(assignment.end_date, 'Present')}
          </Item.Description>
        )}
        {assignment.assignment_period.id === 'permanent' && (
          <Item.Description>{assignment.assignment_period.name}</Item.Description>
        )}
      </Item.Side>
    </Item>
  )
}

type Props = {
  data: EmployeeInterface
}
export const KeyPerson = ({ data }: Props) => {
  const params = useParams<{ id: string }>()
  const [tablePreviewUpdateKey, forceUpdateTablePreview] = useReducer(x => x + 1, 0)

  const { data: successionPlansData, isLoading: isLoadingSuccessionPlans } =
    useEmployeeSuccessionPlans(params.id)
  const [activePlan] = successionPlansData?.results || []

  const {
    data: employeeIsSuccessorForResponsibilities = [],
    isLoading: isSuccessorForResponsibilitiesLoading,
  } = useEmployeeIsSuccessorForResponsibilities(params.id)

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [selectedAssignmentId, setSelectedAssignmentId] = useState<number>()
  const [
    showArchivedSuccessorResponsibilities,
    setShowArchivedSuccessorResponsibilities,
  ] = useState(false)

  const hasArchivedSuccessorResponsibilities =
    employeeIsSuccessorForResponsibilities.some(
      responsibility => responsibility.assignment_status?.id === 'archived',
    )

  const activeSuccessorResponsibilities = useMemo(() => {
    if (!hasArchivedSuccessorResponsibilities) {
      return employeeIsSuccessorForResponsibilities
    }
    return showArchivedSuccessorResponsibilities
      ? employeeIsSuccessorForResponsibilities
      : employeeIsSuccessorForResponsibilities.filter(
          responsibility => responsibility.assignment_status?.id !== 'archived',
        )
  }, [
    employeeIsSuccessorForResponsibilities.length,
    showArchivedSuccessorResponsibilities,
    hasArchivedSuccessorResponsibilities,
  ])

  const openEditAssignment = (id: number) => {
    setSelectedAssignmentId(id)
    setIsSidebarOpen(true)
  }

  const renderActivePlan = () => {
    if (isLoadingSuccessionPlans) {
      return <AvatarItemSkeleton />
    }
    if (activePlan) {
      return (
        <>
          <Item>
            <Item.Avatar>
              <Image src="https://assets.revolut.com/assets/3d-images/3D021.png" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                A succession plan is required to be in place for this employee
              </Item.Title>
              <Item.Description>
                Please ensure that the succession plan document is up to date and
                successors are appointed
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="ArrowRightLeft" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                {chain(
                  'Succession planning',
                  activePlan && (
                    <Text color={getSuccessionPlanStatusColor(activePlan.status.id)}>
                      {activePlan.status.name}
                    </Text>
                  ),
                )}
              </Item.Title>
              <Item.Description>
                Valid until {formatDate(activePlan.valid_until_date_time)}
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <ActionButton
                onClick={() => {
                  navigateTo(
                    pathToUrl(ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.DETAILS, {
                      id: String(activePlan.id),
                    }),
                  )
                }}
              >
                Open
              </ActionButton>
            </Item.Side>
          </Item>
        </>
      )
    }
    return null
  }

  return (
    <Box maxWidth={{ all: '100%', lg: Token.breakpoint.md, md: Token.breakpoint.md }}>
      <VStack space="s-16">
        {renderActivePlan()}
        <Widget>
          <TablePreview<
            KeyPersonResponsibilityAssignmentInterface,
            KeyPersonResponsibilityAssignmentsStatsInterface
          >
            key={tablePreviewUpdateKey}
            statsConfig={statsConfig}
            filterByInitial={[
              {
                columnName: 'active_stats',
                filters: [{ id: 'active', name: 'active' }],
                disableQueryParam: true,
              },
            ]}
            renderActions={() => (
              <Box p="s-16" pt={0}>
                <MoreBar>
                  <MoreBar.Action
                    useIcon="Plus"
                    variant="accent"
                    onClick={() => setIsSidebarOpen(true)}
                  >
                    Assign responsibility
                  </MoreBar.Action>
                  <MoreBar.Action
                    use={InternalLink}
                    to={pathToUrl(
                      ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITIES,
                      params,
                    )}
                    useIcon="16/List"
                  >
                    Show details
                  </MoreBar.Action>
                </MoreBar>
              </Box>
            )}
            api={{
              getItems: getAllKeyPersonResponsibilitiesAssignments(data.id),
              getStats: getAllKeyPersonResponsibilitiesAssignmentsStats(data.id),
            }}
            emptyState={{
              title: 'No responsibilities found',
              icon: 'Shield',
            }}
            row={assignment => {
              return (
                <ResponsibilityAssignment
                  key={assignment.id}
                  assignment={assignment}
                  onClick={() => openEditAssignment(assignment.id)}
                />
              )
            }}
          />
        </Widget>
        <Box>
          <Subheader variant="nested">
            <Subheader.Title>
              <HStack space="s-8" align="center">
                <Text>Successor responsibilities</Text>
                <Tooltip
                  placement="top"
                  text="Responsibilities from succession plans for which an employee is assigned as a successor"
                >
                  <Icon name="InfoOutline" size={15} color={Token.color.greyTone50} />
                </Tooltip>
              </HStack>
            </Subheader.Title>
          </Subheader>
          {isSuccessorForResponsibilitiesLoading ? (
            <AvatarItemSkeleton />
          ) : (
            <Widget>
              {hasArchivedSuccessorResponsibilities && (
                <Flex justifyContent="flex-end" p="s-16">
                  <Bar>
                    <FilterButton
                      onClick={() =>
                        setShowArchivedSuccessorResponsibilities(
                          !showArchivedSuccessorResponsibilities,
                        )
                      }
                      active={showArchivedSuccessorResponsibilities}
                    >
                      Show archived
                    </FilterButton>
                  </Bar>
                </Flex>
              )}
              {activeSuccessorResponsibilities.length ? (
                <>
                  {activeSuccessorResponsibilities?.map(assignment => (
                    <ResponsibilityAssignment
                      key={assignment.id}
                      assignment={assignment}
                      onClick={() =>
                        navigateTo(
                          pathToUrl(
                            ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.DETAILS,
                            {
                              id: assignment.succession_page_reverse_link,
                            },
                          ),
                        )
                      }
                    />
                  ))}
                </>
              ) : (
                <Item>
                  <Item.Avatar>
                    <Avatar color={Token.color.greyTone20} useIcon="Document" />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title color={Token.color.greyTone50}>
                      {`Employee is not assigned as a successor to any ${
                        showArchivedSuccessorResponsibilities ||
                        !hasArchivedSuccessorResponsibilities
                          ? ''
                          : 'active '
                      }plan`}
                    </Item.Title>
                  </Item.Content>
                </Item>
              )}
            </Widget>
          )}
        </Box>
      </VStack>
      <ResponsibilityAssignmentSidebar
        employeeId={data.id}
        assignmentId={selectedAssignmentId}
        isOpen={isSidebarOpen}
        onClose={() => {
          setIsSidebarOpen(false)
          setSelectedAssignmentId(undefined)
        }}
        onUpdateResponsibility={() => {
          forceUpdateTablePreview()
        }}
      />
    </Box>
  )
}
