import React, { type FC } from 'react'
import { Absolute, Box, Relative, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

import {
  FEATURE_CARD_SCALE,
  FEATURE_HIDDEN_CARD_SCALE,
  FEATURE_CAROUSEL_CARD_PADDING_COEFFICIENT,
  FEATURE_CAROUSEL_TRANSITION_DURATION,
} from './constants'
import { useSwipe } from '@src/pages/Landing/hooks/useSwipe'
import { PageIndicator } from '@src/pages/Landing/components/PageIndicator'
import { FeatureItemContent } from '@src/pages/Landing/components/types'

interface FeatureItemsCarouselRightModeProps {
  activeIndex: number
  updateActiveIndex: (value: number) => void
  items: FeatureItemContent[]
  ratio: number
  children: (props: { item: FeatureItemContent }) => React.ReactNode
}

const TransitionCard = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  transition: `transform ${FEATURE_CAROUSEL_TRANSITION_DURATION}ms, opacity ${FEATURE_CAROUSEL_TRANSITION_DURATION}ms`,
})

const FeatureItemsCarouselContainer = styled(Relative)({
  '--website-feature-items-carousel-gap': '16px',
  // Need to use percentage here because the cards are scalable on desktop and 8% is close to the 24px on md and 32px on xxl
  [`@media ${Token.media.md}`]: { '--website-feature-items-carousel-gap': '8%' },
})

export const FeatureItemsCarouselRight: FC<FeatureItemsCarouselRightModeProps> = ({
  items,
  ratio,
  activeIndex,
  updateActiveIndex,
  children,
}) => {
  const { onTouchStart, onTouchMove, onTouchEnd } = useSwipe({
    onSwipedLeft: () => updateActiveIndex((activeIndex + 1) % items.length),
    onSwipedRight: () =>
      updateActiveIndex((activeIndex - 1 + items.length) % items.length),
  })
  const firstCardPreviewPosition = items.length - activeIndex
  const firstCardPreviewTranslate =
    firstCardPreviewPosition * 100 -
    FEATURE_CAROUSEL_CARD_PADDING_COEFFICIENT *
      (Math.abs(firstCardPreviewPosition) * 2 - 1)
  const firstCardPreviewOffset = `calc(${firstCardPreviewPosition} * var(--website-feature-items-carousel-gap))`
  const transform = `translateX(${firstCardPreviewOffset}) translateX(${firstCardPreviewTranslate}%) scale(${FEATURE_CARD_SCALE})`

  return (
    <FeatureItemsCarouselContainer
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      height="100%"
      style={{ aspectRatio: ratio.toString() }}
    >
      <TransitionCard
        height="100%"
        opacity={firstCardPreviewPosition < 3 ? 0.5 : 0}
        onClick={() => updateActiveIndex(0)}
        style={{ transform, aspectRatio: ratio.toString() }}
      >
        {children({ item: items[0] })}
      </TransitionCard>
      {items.map((item, index) => {
        const cardPosition = index - activeIndex
        const paddingCoefficient =
          activeIndex === index ? 0 : FEATURE_CAROUSEL_CARD_PADDING_COEFFICIENT
        const transformItem = [
          `translateX(calc(${
            index - activeIndex
          } * var(--website-feature-items-carousel-gap)))`,
          `translateX(${
            (index > activeIndex ? cardPosition : 0) * 100 -
            paddingCoefficient * (Math.abs(index - activeIndex) * 2 - 1)
          }%)`,
          index < activeIndex ? `scale(${FEATURE_HIDDEN_CARD_SCALE})` : '',
          index !== activeIndex ? `scale(${FEATURE_CARD_SCALE})` : '',
        ]
          .filter(val => !!val)
          .join(' ')

        return (
          <TransitionCard
            key={index}
            height="100%"
            opacity={index < activeIndex ? 0 : 1}
            onClick={() => updateActiveIndex(index)}
            // styled-components (or styled/css) adds px unit to the aspect ratio value that why we have to use `style`
            style={{ transform: transformItem, aspectRatio: ratio.toString() }}
          >
            {children({ item })}
          </TransitionCard>
        )
      })}
      <Absolute left={0} right={0} top="100%">
        <PageIndicator
          mt={{ all: 's-16', md: 's-24', xxl: 's-32' }}
          current={activeIndex}
          total={items.length}
          onClick={updateActiveIndex}
        />
      </Absolute>
    </FeatureItemsCarouselContainer>
  )
}
