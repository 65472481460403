import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { InterviewAvailabilityInterface } from '@src/interfaces/interviewAvailability'
import { selectorKeys } from '@src/constants/api'
import {
  InterviewAvailabilityStatus,
  getInterviewAvailabilityStatusText,
} from '@src/features/InterviewAvailability/InterviewAvailabilityStatus'
import { InterviewAvailabilityDate } from '@src/features/InterviewAvailability/InterviewAvailabilityDate'
import { OptionInterface } from '@src/interfaces/selectors'

type InterviewAvailabilityColumn = ColumnInterface<InterviewAvailabilityInterface>

export const interviewAvailabilityNameColumn: InterviewAvailabilityColumn = {
  type: CellTypes.text,
  idPoint: 'summary',
  dataPoint: 'summary',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const interviewAvailabilityDateColumn: InterviewAvailabilityColumn = {
  type: CellTypes.insert,
  idPoint: 'event_start_datetime',
  dataPoint: 'event_start_datetime',
  sortKey: 'event_start_datetime',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Date',
  insert: ({ data }) => (
    <InterviewAvailabilityDate
      startDateTime={data.event_start_datetime}
      endDateTime={data.event_end_datetime}
    />
  ),
}

export const interviewAvailabilityStatusColumn: InterviewAvailabilityColumn = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: null,
  filterKey: 'status',
  selectorsKey: () =>
    Promise.resolve({
      options: [
        {
          id: 'booked',
          name: getInterviewAvailabilityStatusText('booked'),
        },
        {
          id: 'pending',
          name: getInterviewAvailabilityStatusText('pending'),
        },
        {
          id: 'holiday',
          name: getInterviewAvailabilityStatusText('holiday'),
        },
      ] as OptionInterface[],
    }),
  title: 'Status',
  insert: ({ data }) => <InterviewAvailabilityStatus status={data.status} />,
}
